var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { withStore } from '../../../store/rootStore';
import React, { Component } from 'react';
import ChatNotesHeader from './Components/ChatNotesHeader';
import { observer } from 'mobx-react';
import styles from './ChatNotes.module.scss';
import ChatNoteInput from './Components/ChatNoteInput';
import ChatNotesList from './Components/ChatNotesList';
import { message } from 'antd';
import { Permissions } from '@whatsper/texterchat-common';
class ChatNotes extends Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                noteText: '',
                isNoteFieldOpen: true,
            }
        });
        Object.defineProperty(this, "toggleNoteFieldOpen", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setState({ isNoteFieldOpen: !this.state.isNoteFieldOpen });
            }
        });
        Object.defineProperty(this, "handleChangeNoteText", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (noteText) => {
                this.setState({ noteText });
            }
        });
        Object.defineProperty(this, "handleAddNote", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                try {
                    if (!this.state.noteText) {
                        message.error(`Please enter a note text!`);
                        return;
                    }
                    yield this.props.store.chatNotesStore.addChatNote(this.state.noteText);
                    this.setState({ noteText: "" });
                }
                catch (error) {
                    message.error(`Couldn't add the note, please try again!`);
                    console.error(`Couldn't add the chat note: ${error.message}`);
                }
            })
        });
        Object.defineProperty(this, "handleDeleteNote", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (note) => __awaiter(this, void 0, void 0, function* () {
                try {
                    yield this.props.store.chatNotesStore.deleteChatNote(String(note._id));
                }
                catch (error) {
                    message.error(`Couldn't delete the note, please try again!`);
                    console.error(`Couldn't delete the chat note: ${error.message}`);
                }
            })
        });
        Object.defineProperty(this, "checkAgentPermission", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (note) => {
                const noteAgent = this.getAgent(note);
                const { userDetails } = this.props.store.loginStore;
                const { hasPermissions } = this.props.store.agentsStore;
                return hasPermissions([Permissions.Permission.chats_notes_all_manage])
                    || (hasPermissions([Permissions.Permission.chats_notes_own_manage])
                        && (noteAgent === null || noteAgent === void 0 ? void 0 : noteAgent._id) === (userDetails === null || userDetails === void 0 ? void 0 : userDetails._id));
            }
        });
        Object.defineProperty(this, "getAgent", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (note) => {
                const agent = this.props.store.agentsStore.usersMap.get(note.createdBy);
                return agent || null;
            }
        });
        Object.defineProperty(this, "renderActiveChatNotes", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { activeChatStore, chatNotesStore } = this.props.store;
                return (React.createElement("div", { className: styles.chatNotesContainer },
                    React.createElement(ChatNotesHeader, { isNoteFieldOpen: this.state.isNoteFieldOpen, handleCloseField: this.toggleNoteFieldOpen }),
                    React.createElement(ChatNoteInput, { isNoteFieldOpen: this.state.isNoteFieldOpen, activeChat: activeChatStore.chat, noteText: this.state.noteText, handleSetText: this.handleChangeNoteText, handleAddNote: this.handleAddNote }),
                    React.createElement(ChatNotesList, { isLoading: chatNotesStore.notesListLoading, notesList: chatNotesStore.notesList, getAgent: this.getAgent, hasPermission: this.checkAgentPermission, handleDeleteNote: this.handleDeleteNote })));
            }
        });
    }
    render() {
        const { activeChatStore } = this.props.store;
        return activeChatStore.chat && this.renderActiveChatNotes();
    }
}
export default withStore(observer(ChatNotes));
