var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { action, observable, runInAction, computed, makeObservable } from 'mobx';
import { API_PATH, CHAT_STATUS } from '../../constants';
import { getAxios } from '../../backend';
import { throttle } from 'lodash';
import { CHAT_STATUSES_NAMES, } from '@whatsper/texterchat-common';
import moment from 'moment';
import { message } from 'antd';
import i18n from '../../i18n';
export default class DashboardStore {
    get latestTimestamp() {
        var _a;
        const timestamp = (_a = this.settingsWorkingHoursTimestampsData) === null || _a === void 0 ? void 0 : _a[this.settingsWorkingHoursTimestampsData.length - 1];
        if (timestamp) {
            if (!this.historicalModeEnabled) {
                return {
                    starts: timestamp.starts,
                    ends: moment(timestamp.starts).set({ hour: 24, minute: 0, second: 0, millisecond: 0 }).valueOf(),
                };
            }
            return timestamp;
        }
        return { starts: 0, ends: 0 };
    }
    get isLive() {
        return moment().isBetween(this.latestTimestamp.starts, this.latestTimestamp.ends);
    }
    get shouldHideOutsideWorkingHours() {
        return !this.historicalModeEnabled && !this.isLive;
    }
    get hasData() {
        var _a, _b;
        return !!((_a = this.data[CHAT_STATUS.RESOLVED]) === null || _a === void 0 ? void 0 : _a.total) || !!((_b = this.data[CHAT_STATUS.PENDING]) === null || _b === void 0 ? void 0 : _b.total);
    }
    constructor(rootStore) {
        Object.defineProperty(this, "rootStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "loading", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "SLALoading", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "pollingInterval", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "pollingIntervalMsec", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 10000
        });
        Object.defineProperty(this, "throttleIntervalMsec", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 3000
        });
        /* Postponed to V6 */
        Object.defineProperty(this, "historicalModeEnabled", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "data", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {}
        });
        Object.defineProperty(this, "liveData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {}
        });
        Object.defineProperty(this, "historicalData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {}
        });
        Object.defineProperty(this, "resolvedData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "settingsData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "settingsWorkingHoursData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {}
        });
        Object.defineProperty(this, "settingsWorkingHoursTimestampsData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "departmentSlaData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "startPollingData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if (!this.isLive)
                    return;
                this._fetchLiveData();
                this.pollingInterval = setInterval(() => {
                    this._fetchLiveData();
                }, this.pollingIntervalMsec);
            }
        });
        Object.defineProperty(this, "stopPollingData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if (this.pollingInterval) {
                    clearInterval(this.pollingInterval);
                }
            }
        });
        Object.defineProperty(this, "_fetchLiveData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: throttle(() => {
                if (!this.latestTimestamp)
                    return;
                const { starts, ends } = this.latestTimestamp;
                if (this.isLive) {
                    this.getLiveStats({ after: starts.toString() });
                }
                else {
                    this.getHistoricalStats({ after: starts.toString(), before: ends.toString() });
                }
            }, this.throttleIntervalMsec)
        });
        Object.defineProperty(this, "setLoading", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (loading) => {
                this.loading = loading;
            }
        });
        Object.defineProperty(this, "getLiveStats", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (query = {}) => __awaiter(this, void 0, void 0, function* () {
                try {
                    yield getAxios()
                        .then((axios) => {
                        return axios.get(`${API_PATH.STATS}/chats/status/live`, {
                            params: Object.assign({ statuses: [
                                    CHAT_STATUSES_NAMES[CHAT_STATUS.BOT],
                                    CHAT_STATUSES_NAMES[CHAT_STATUS.PENDING],
                                    CHAT_STATUSES_NAMES[CHAT_STATUS.ASSIGNED],
                                    CHAT_STATUSES_NAMES[CHAT_STATUS.RESOLVED],
                                ] }, query),
                        });
                    })
                        .then((response) => {
                        runInAction(() => {
                            this.data = observable(response.data);
                            this.liveData = observable(response.data);
                        });
                    });
                }
                catch (error) {
                    console.error('Error on downloading live stats', error);
                }
            })
        });
        Object.defineProperty(this, "getHistoricalStats", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (query = {}) => __awaiter(this, void 0, void 0, function* () {
                try {
                    yield getAxios()
                        .then((axios) => {
                        return axios.get(`${API_PATH.STATS}/chats/status/historical`, {
                            params: Object.assign({ statuses: [
                                    CHAT_STATUSES_NAMES[CHAT_STATUS.BOT],
                                    CHAT_STATUSES_NAMES[CHAT_STATUS.PENDING],
                                    CHAT_STATUSES_NAMES[CHAT_STATUS.ASSIGNED],
                                    CHAT_STATUSES_NAMES[CHAT_STATUS.RESOLVED],
                                ] }, query),
                        });
                    })
                        .then((response) => {
                        runInAction(() => {
                            this.historicalData = observable(response.data);
                        });
                    });
                }
                catch (error) {
                    console.error('Error on downloading historical stats', error);
                }
            })
        });
        Object.defineProperty(this, "getResolvedStats", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (query = {}) => __awaiter(this, void 0, void 0, function* () {
                try {
                    yield getAxios()
                        .then((axios) => {
                        return axios.get(`${API_PATH.STATS}/chats/resolved`, {
                            params: Object.assign({}, query),
                        });
                    })
                        .then((response) => {
                        runInAction(() => {
                            this.resolvedData = observable(response.data);
                        });
                    });
                }
                catch (error) {
                    console.error('Error on downloading resolved stats', error);
                }
            })
        });
        Object.defineProperty(this, "getSettings", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                try {
                    yield getAxios()
                        .then((axios) => {
                        return axios.get(`${API_PATH.STATS}/settings`);
                    })
                        .then((response) => {
                        runInAction(() => {
                            this.settingsData = observable(response.data);
                        });
                    });
                }
                catch (error) {
                    console.error('Error on downloading resolved stats', error);
                }
            })
        });
        Object.defineProperty(this, "updateSettings", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (query = {}) => __awaiter(this, void 0, void 0, function* () {
                runInAction(() => {
                    this.SLALoading = true;
                });
                try {
                    const axios = yield getAxios();
                    yield axios.patch(`${API_PATH.STATS}/settings`, query);
                    runInAction(() => {
                        var _a;
                        message.success(i18n.t('SLASettingsSaved'));
                        if (this.settingsData && ((_a = query.sla) === null || _a === void 0 ? void 0 : _a.time)) {
                            this.settingsData.sla.time = query.sla.time;
                        }
                    });
                }
                catch (error) {
                    console.error('Error on updating settings', error);
                }
                finally {
                    runInAction(() => {
                        this.SLALoading = false;
                    });
                }
            })
        });
        Object.defineProperty(this, "getSettingsWorkingHours", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (query) => __awaiter(this, void 0, void 0, function* () {
                try {
                    yield getAxios()
                        .then((axios) => {
                        return axios.get(`${API_PATH.STATS}/settings/working-hours`, {
                            params: Object.assign({}, query),
                        });
                    })
                        .then((response) => {
                        runInAction(() => {
                            this.settingsWorkingHoursData = observable(response.data);
                        });
                    });
                }
                catch (error) {
                    console.error('Error on getting settings working hours', error);
                }
            })
        });
        Object.defineProperty(this, "postSettingsWorkingHours", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (query) => __awaiter(this, void 0, void 0, function* () {
                try {
                    yield getAxios()
                        .then((axios) => {
                        return axios.post(`${API_PATH.STATS}/settings/working-hours`, { query });
                    });
                }
                catch (error) {
                    console.error('Error on updating settings working hours', error);
                }
            })
        });
        Object.defineProperty(this, "getSLACalculations", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                try {
                    yield getAxios()
                        .then((axios) => {
                        return axios.get(`${API_PATH.STATS}/chats/sla`, {
                            params: {
                                after: this.latestTimestamp.starts,
                            },
                        });
                    })
                        .then(({ data }) => {
                        runInAction(() => {
                            this.departmentSlaData = observable(data);
                        });
                    });
                }
                catch (error) {
                    console.error('Error on fetching sla data', error);
                }
            })
        });
        Object.defineProperty(this, "getSettingsWorkingHoursTimestamps", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (query) => __awaiter(this, void 0, void 0, function* () {
                try {
                    yield getAxios()
                        .then((axios) => {
                        return axios.get(`${API_PATH.STATS}/settings/working-hours/timestamps`, {
                            params: Object.assign(Object.assign({}, query), { start: `${new Date().getTime() - 1000 * 60 * 60 * 24 * 1}` }),
                        });
                    })
                        .then((response) => {
                        runInAction(() => {
                            this.settingsWorkingHoursTimestampsData = observable(response.data);
                        });
                    });
                }
                catch (error) {
                    console.error('Error on getting settings working hours', error);
                }
            })
        });
        Object.defineProperty(this, "parseSlaTimeFromMomentToMilliseconds", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (time) => {
                const hours = moment(time).hours();
                const minutes = moment(time).minutes();
                const seconds = moment(time).seconds();
                return hours * 3600000 + minutes * 60000 + seconds * 1000;
            }
        });
        Object.defineProperty(this, "parseSlaTimeFromMillisecondsToMoment", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (time) => {
                const momentObject = moment();
                momentObject.hours(time / 3600000);
                momentObject.minutes((time % 3600000) / 60000);
                momentObject.seconds(((time % 3600000) % 60000) / 1000);
                return momentObject;
            }
        });
        makeObservable(this);
        this.rootStore = rootStore;
    }
}
__decorate([
    observable
], DashboardStore.prototype, "loading", void 0);
__decorate([
    observable
], DashboardStore.prototype, "SLALoading", void 0);
__decorate([
    observable
], DashboardStore.prototype, "pollingInterval", void 0);
__decorate([
    observable
], DashboardStore.prototype, "data", void 0);
__decorate([
    observable
], DashboardStore.prototype, "liveData", void 0);
__decorate([
    observable
], DashboardStore.prototype, "historicalData", void 0);
__decorate([
    observable
], DashboardStore.prototype, "resolvedData", void 0);
__decorate([
    observable
], DashboardStore.prototype, "settingsData", void 0);
__decorate([
    observable
], DashboardStore.prototype, "settingsWorkingHoursData", void 0);
__decorate([
    observable
], DashboardStore.prototype, "settingsWorkingHoursTimestampsData", void 0);
__decorate([
    observable
], DashboardStore.prototype, "departmentSlaData", void 0);
__decorate([
    computed
], DashboardStore.prototype, "latestTimestamp", null);
__decorate([
    computed
], DashboardStore.prototype, "isLive", null);
__decorate([
    computed
], DashboardStore.prototype, "shouldHideOutsideWorkingHours", null);
__decorate([
    computed
], DashboardStore.prototype, "hasData", null);
__decorate([
    action
], DashboardStore.prototype, "startPollingData", void 0);
__decorate([
    action
], DashboardStore.prototype, "stopPollingData", void 0);
__decorate([
    action
], DashboardStore.prototype, "setLoading", void 0);
__decorate([
    action
], DashboardStore.prototype, "getLiveStats", void 0);
__decorate([
    action
], DashboardStore.prototype, "getHistoricalStats", void 0);
__decorate([
    action
], DashboardStore.prototype, "getResolvedStats", void 0);
__decorate([
    action
], DashboardStore.prototype, "getSettings", void 0);
__decorate([
    action
], DashboardStore.prototype, "updateSettings", void 0);
__decorate([
    action
], DashboardStore.prototype, "getSettingsWorkingHours", void 0);
__decorate([
    action
], DashboardStore.prototype, "postSettingsWorkingHours", void 0);
__decorate([
    action
], DashboardStore.prototype, "getSLACalculations", void 0);
__decorate([
    action
], DashboardStore.prototype, "getSettingsWorkingHoursTimestamps", void 0);
