import React, { useEffect, useState } from 'react';
import { ExclamationCircleOutlined, DisconnectOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import { MySelect } from '../../../../../../Base/Form';
import { useStore } from '../../../../../../../store/rootStore';
import classNames from 'classnames';
import { debounce } from 'lodash';
import { MessagingChannelFeature } from '@whatsper/texterchat-common';
import { observer } from 'mobx-react';
import { Trans } from 'react-i18next';
import s from './LinkedChats.module.scss';
const LinkedChats = () => {
    const [chats, setChats] = useState([]);
    const [selectedChat, setSelectedChat] = useState(null);
    const { channels, chatsStore, activeChatStore } = useStore();
    const { linkedChatsLoading, linkChat, unlinkChat, getLinkedChats, linkedChats, linkedChatsWithoutActiveChat, setActiveChatId, } = activeChatStore;
    const onSearchDebounced = debounce((query) => {
        chatsStore
            .fetchChats({ search: query })
            .then((response) => {
            const newChats = response.chats
                .filter((c) => !(linkedChats === null || linkedChats === void 0 ? void 0 : linkedChats.find((lc) => lc._id === c._id)))
                .sort((a, b) => (a.channelInfo.name > b.channelInfo.name ? -1 : 1));
            setChats(newChats);
        })
            .catch((e) => console.error('Error searching chats', e));
    }, 500);
    useEffect(() => {
        getLinkedChats();
    }, []);
    const onChatSearch = (query) => {
        if (query.length >= 2) {
            onSearchDebounced(query);
        }
    };
    const onChatSelect = (chatId) => {
        const chat = chats.find((c) => c._id === chatId);
        if (chat) {
            linkChat(chat).catch((e) => console.error('Error linking chat', e));
            setChats(chats.filter((c) => c._id !== chatId));
            setSelectedChat(null);
        }
    };
    const onChatDisconnect = (chatId) => {
        unlinkChat(chatId).catch((e) => console.error('Error unlinking chat', e));
    };
    const onChatClick = (chatId) => {
        setActiveChatId(chatId);
    };
    const renderLabel = ({ title, channelInfo }) => {
        const adapter = channels.getAccountAdapter({
            id: channelInfo.accountId,
            name: channelInfo.name,
        });
        const hasGlobalId = channels.hasFeatures([MessagingChannelFeature.contactGlobalId], adapter);
        const hasPhoneNumberId = channels.hasFeatures([MessagingChannelFeature.contactPhoneNumberId], adapter);
        const showId = adapter ? hasGlobalId || hasPhoneNumberId : false;
        let label = title || (showId ? channelInfo.accountId : '');
        return label + (showId ? ` (${channelInfo.id})` : '');
    };
    const renderIcon = (channelInfo) => {
        var _a, _b;
        const accountIdIcon = (_a = channels.themes[channelInfo.accountId || '']) === null || _a === void 0 ? void 0 : _a.icon;
        const channelNameIcon = (_b = channels.themes[channelInfo.name || '']) === null || _b === void 0 ? void 0 : _b.icon;
        if (!accountIdIcon && !channelNameIcon) {
            return React.createElement("div", { className: s.channelIcon });
        }
        return React.createElement("img", { src: accountIdIcon || channelNameIcon, className: s.channelIcon });
    };
    return (React.createElement("div", { className: classNames(s.container, {
            [s.loading]: linkedChatsLoading,
        }) },
        React.createElement("div", { className: s.title },
            React.createElement("div", null,
                React.createElement(Trans, { i18nKey: "linkedChatsTitle", values: { number: (linkedChatsWithoutActiveChat === null || linkedChatsWithoutActiveChat === void 0 ? void 0 : linkedChatsWithoutActiveChat.length) || 0 } })),
            React.createElement(Tooltip, { title: React.createElement(Trans, { i18nKey: "linkedChatsTooltip" }), placement: "right" },
                React.createElement(ExclamationCircleOutlined, { className: s.icon }))),
        React.createElement("div", { className: s.accountsContainer }, linkedChatsWithoutActiveChat === null || linkedChatsWithoutActiveChat === void 0 ? void 0 : linkedChatsWithoutActiveChat.map(({ channelInfo, title, _id }) => (React.createElement("div", { className: s.accountContainer, key: _id },
            React.createElement("div", { className: s.account, title: renderLabel({ title, channelInfo }), onClick: () => onChatClick(_id) },
                renderIcon(channelInfo),
                React.createElement("div", { className: s.accountLabel }, renderLabel({ title, channelInfo }))),
            React.createElement(Tooltip, { title: "Disconnect", placement: "right" },
                React.createElement(DisconnectOutlined, { className: classNames(s.icon, s.disconnect), onClick: () => onChatDisconnect(_id) })))))),
        React.createElement("div", { className: s.searchContainer },
            React.createElement("div", { className: s.searchLabel },
                React.createElement(Trans, { i18nKey: !!(linkedChats === null || linkedChats === void 0 ? void 0 : linkedChats.length) ? 'linkMoreChats' : 'linkAnotherChat' })),
            React.createElement(MySelect, { value: selectedChat, onSearch: onChatSearch, onSelect: onChatSelect, className: s.searchSelect, loading: linkedChatsLoading, placeholder: React.createElement(Trans, { i18nKey: "searchPlaceholder" }), options: chats.map((c) => ({
                    label: `${c.title} - ${c._id}`,
                    value: c._id,
                })), notFoundContent: null, filterOption: false, showSearch: true, allowClear: true }, chats.map(({ _id, title, channelInfo }) => (React.createElement(MySelect.Option, { key: _id, value: _id },
                React.createElement("div", { className: classNames(s.account, s.searchItem), title: renderLabel({ title, channelInfo }) },
                    renderIcon(channelInfo),
                    React.createElement("div", { className: s.accountLabel }, renderLabel({ title, channelInfo }))))))))));
};
export default observer(LinkedChats);
