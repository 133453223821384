import React from "react";
import { observer } from "mobx-react";
import { Button } from "antd";
import classNames from "classnames";
import Icon from "@ant-design/icons";
import Files from "react-files";
import { Trans } from "react-i18next";
import { ReactComponent as UploadCSV } from "../../../../../../assets/icons/arrows/uploadCSV.svg";
import { withStore } from "../../../../../../store/rootStore";
import "./FileUploadButton.css";
const FileUploadButton = ({ store: { wizardStore } }) => {
    const onFileSelected = (files) => {
        wizardStore.onFileSelected(files);
    };
    const onFilesError = () => {
        wizardStore.onFilesError("uploadedFileError");
    };
    return (React.createElement(Files, { multiple: false, onChange: onFileSelected, onError: onFilesError, accepts: [".csv"], clickable: true },
        React.createElement(Button, { disabled: false, className: classNames("big", "big-green-button", "upload-csv-button"), type: "primary" },
            React.createElement(Icon, { component: UploadCSV }),
            React.createElement(Trans, { i18nKey: "uploadCsv" }))));
};
export default withStore(observer(FileUploadButton));
