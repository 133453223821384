import React, { Component } from 'react';
import { observer } from "mobx-react";
import { Switch, TimePicker } from 'antd';
import { Trans, withTranslation } from "react-i18next";
import ColorSelector from "../../ColorSelector";
import { withStore } from '../../../store/rootStore';
import { AGENTS_DEPARTMENTS_COLORS, SLA_TIMEPICKER_FORMAT } from '../../../constants';
import { MyInput } from '../../Base/Form';
import styles from "./DepartmentForm.module.scss";
class DepartmentForm extends Component {
    constructor(props) {
        super(props);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                name: '',
                color: AGENTS_DEPARTMENTS_COLORS[0],
                _id: '',
                slaTime: null,
                isSlaEnabled: false,
                idEditDisabled: false,
            }
        });
        Object.defineProperty(this, "componentDidMount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.props.onInput(this.state, this.canBeSavedState());
                if (this.props.entity && this.props.entity._id)
                    this.setState({ idEditDisabled: true });
            }
        });
        Object.defineProperty(this, "onInput", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (formData) => {
                this.setState(Object.assign({}, formData), () => this.props.onInput(this.state, this.canBeSavedState()));
            }
        });
        Object.defineProperty(this, "canBeSavedState", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                return this.state.name.trim().length > 0;
            }
        });
        Object.defineProperty(this, "getAgents", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a;
                const agentIds = (_a = this.props.entity) === null || _a === void 0 ? void 0 : _a.agents;
                let agents = [];
                if (agentIds && agentIds.length) {
                    agents = this.props.store.agentsStore.users.filter(user => agentIds.includes(user.uid));
                }
                return agents;
            }
        });
        if (props.entity) {
            const slaTime = props.entity.sla && props.entity.sla.time !== 0
                ? props.store.dashboard.parseSlaTimeFromMillisecondsToMoment(props.entity.sla.time)
                : null;
            this.state = Object.assign(Object.assign(Object.assign({}, this.state), props.entity), { slaTime, isSlaEnabled: !!slaTime });
        }
    }
    render() {
        const { name, color, _id, slaTime, isSlaEnabled, idEditDisabled } = this.state, { t, error } = this.props, { loginStore } = this.props.store;
        return (React.createElement(React.Fragment, null,
            !!error &&
                React.createElement("div", { className: styles.addDepartmentError }, error),
            React.createElement("div", { className: styles.addDepartmentLabeledInput },
                React.createElement("label", null,
                    React.createElement(Trans, { i18nKey: "name" })),
                React.createElement(MyInput, { value: name, onChangeHandler: (value) => this.onInput({ name: value }), placeholder: t('enterName') })),
            React.createElement("div", { className: styles.addDepartmentLabeledInput },
                React.createElement("label", null,
                    React.createElement(Trans, { i18nKey: "departmentColor" })),
                React.createElement(ColorSelector, { onChange: (color) => this.onInput({ color }), color: color, colors: AGENTS_DEPARTMENTS_COLORS })),
            React.createElement("div", { className: styles.addDepartmentLabeledInput },
                React.createElement("label", null,
                    React.createElement(Trans, { i18nKey: "departmentId" })),
                React.createElement(MyInput, { value: _id, onChangeHandler: (value) => this.onInput({ _id: value }), disabled: idEditDisabled, placeholder: t("EnterDepartmentId") })),
            React.createElement("div", { className: styles.addDepartmentLabeledInput },
                React.createElement("label", null,
                    React.createElement(Trans, { i18nKey: "setSLATime" })),
                React.createElement("div", { className: styles.slaContainer },
                    React.createElement(Switch, { checked: isSlaEnabled, onChange: (isSlaEnabled) => this.onInput({ isSlaEnabled }), className: styles.slaSwitch }),
                    React.createElement(TimePicker, { value: isSlaEnabled ? slaTime : null, onChange: (slaTime) => this.onInput({ slaTime }), format: SLA_TIMEPICKER_FORMAT, placeholder: t('selectTime'), disabled: !isSlaEnabled, className: styles.slaTimePicker, showSecond: false, showNow: false }))),
            this.getAgents() && React.createElement("div", null,
                React.createElement("label", null,
                    React.createElement(Trans, { i18nKey: "associatedAgents" })),
                React.createElement("div", { className: styles.addDepartmentAgentsList }, this.getAgents().map(agent => (React.createElement("div", { className: styles.addDepartmentAgent, key: agent._id }, agent.displayName)))))));
    }
}
export default withTranslation()(withStore(observer(DepartmentForm)));
