import React, { useState } from 'react';
import { Pie, measureTextWidth } from '@ant-design/plots';
import { useTranslation } from 'react-i18next';
import { Empty } from 'antd';
import classnames from 'classnames';
import { formatAsPercent } from '../../BulkReportsDrawer/helpers';
import s from '../DashboardDrawer.module.scss';
const ResolvedChart = ({ resolvedData, inverted, extraData, className }) => {
    const { t } = useTranslation();
    const [ready, setReady] = useState(false);
    const hasNoData = (extraData === null || extraData === void 0 ? void 0 : extraData.total) === undefined || (extraData === null || extraData === void 0 ? void 0 : extraData.total) === 0;
    const noDataConfig = {
        data: [{ title: t('noData'), count: 0 }],
        color: [!inverted ? '#F5F5F5' : '#FFFFFF'],
    };
    function renderStatistic(containerWidth, text, style) {
        const { width: textWidth, height: textHeight } = measureTextWidth(text, style);
        const R = containerWidth / 2;
        let scale = 1;
        if (containerWidth < textWidth) {
            scale = Math.min(Math.sqrt(Math.abs(Math.pow(R, 2) / (Math.pow(textWidth / 2, 2) + Math.pow(textHeight, 2)))), 1);
        }
        const textStyleStr = `width:${containerWidth}px;`;
        const containerStyle = `display:flex;justify-content:center;align-items:center;`;
        return `<div style="${textStyleStr};${containerStyle};font-size:${scale}em;line-height:${scale < 1 ? 1 : 'inherit'};">${text}</div>`;
    }
    const config = Object.assign({ appendPadding: 0, data: resolvedData, angleField: 'count', colorField: 'title', color: ({ title }) => {
            if (title === 'Resolved')
                return '#0FB878';
            if (title === 'By Bot')
                return '#FADB14';
            if (title === 'Rest')
                return inverted ? '#FFFFFF' : '#F5F5F5';
            return '';
        }, radius: 1, innerRadius: 0.9, label: undefined, tooltip: false, interactions: [], legend: false, animation: false, onReady: () => {
            setReady(true);
        }, statistic: Object.assign({ title: {
                offsetY: inverted ? 8 : 0,
                style: {
                    color: inverted ? '#FFFFFF' : '#000000',
                    textShadow: '1px 1px 1px rgba(0, 0, 0, 0.25)',
                    lineHeight: '38px',
                    fontWeight: 'bold',
                    fontSize: inverted ? '28px' : '20px',
                    letterSpacing: '-1px',
                },
                customHtml: (container) => {
                    const { width, height } = container.getBoundingClientRect();
                    const d = Math.sqrt(Math.pow(width / 2, 2) + Math.pow(height / 2, 2)) + 150;
                    const text = hasNoData
                        ? ''
                        : formatAsPercent((extraData === null || extraData === void 0 ? void 0 : extraData.total) ? ((extraData.bot + extraData.agent) / extraData.total) * 100 : 0);
                    return renderStatistic(d, text, {
                        fontSize: hasNoData ? (inverted ? 28 : 20) : 28,
                    });
                },
            } }, (inverted
            ? {
                content: {
                    offsetY: 8,
                    style: {
                        color: '#FFFFFF',
                        textShadow: '1px 1px 1px rgba(0, 0, 0, 0.25)',
                        fontSize: '16px',
                        fontWeight: 'normal',
                    },
                    customHtml: (container) => {
                        const { width } = container.getBoundingClientRect();
                        const text = hasNoData
                            ? ''
                            : `(${(extraData === null || extraData === void 0 ? void 0 : extraData.bot) + (extraData === null || extraData === void 0 ? void 0 : extraData.agent)}/${extraData === null || extraData === void 0 ? void 0 : extraData.total})`;
                        return renderStatistic(width, text, {
                            fontSize: 16,
                        });
                    },
                },
            }
            : { content: false })) }, (hasNoData && noDataConfig));
    return (React.createElement("div", { className: classnames(s['box__value--chartContainer'], {
            [className]: ready,
        }) },
        React.createElement(Pie, Object.assign({}, config)),
        hasNoData && (React.createElement(Empty, { image: Empty.PRESENTED_IMAGE_SIMPLE, description: false, className: s['chart__noData'] }))));
};
export default ResolvedChart;
