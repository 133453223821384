import React, { useState, useEffect } from "react";
import { observer } from 'mobx-react';
import { Tooltip, Modal, Button, Typography } from 'antd';
import classNames from 'classnames';
import Icon, { UpOutlined, DownOutlined } from '@ant-design/icons';
import { Trans } from "react-i18next";
import { MessagingChannelFeature, Permissions, WhatsApp } from "@whatsper/texterchat-common";
import QuickRepliesEditor from '../Editor';
import ActiveIcon from '../../ActiveIcon';
import HightLayout from '../../HightLayout';
import EditTemplateMessage from "../EditTemplateMessage/EditTemplateMessage";
import * as constants from "../../../../constants";
import { ReactComponent as LeftArrowQuickReplies } from '../../../../assets/icons/arrows/quickRepliesLeftArrow.svg';
import { ReactComponent as QuickRepliesGear } from '../../../../assets/icons/leftMenuIcons/settingsOutlined.svg';
import { ReactComponent as QuickRepliesPlus } from '../../../../assets/icons/plus/quickRepliesPlus.svg';
import { ReactComponent as QuickRepliesFolder } from '../../../../assets/icons/file/folderIcon.svg';
import { withStore } from "../../../../store/rootStore";
import { TEMPLATE_USAGE } from "../../../../constants";
import TemplatesListFilterable from '../../../Templates/TemplateListFilterable';
import QuickRepliesList from '../../../QuickReplies/ListFilterable';
import { BsChatRightTextFill } from "react-icons/bs";
import './style.css';
const { Text } = Typography;
/**
 * Although here was made a lot of changes, and some child components
 * was refactored, this components still requires full refactoring
 *
 * @param props
 */
const QuickRepliesActivityBar = (props) => {
    var _a;
    const { onQuickReplySelected } = props, { activeChatStore, loginStore, templateMessagesStore, quickRepliesStore, channels, agentsStore } = props.store, channelInfo = (_a = activeChatStore.chat) === null || _a === void 0 ? void 0 : _a.channelInfo;
    const handleOpen = () => {
        setIsQuickRepliesVisible(!isQuickRepliesVisible);
        templateMessagesStore.setStoreToDefault();
    };
    const [isQuickRepliesVisible, setIsQuickRepliesVisible] = useState(false);
    const [isModalMoreVisible, setIsModalMoreVisible] = useState(false);
    const [templatesSupported, setTemplatesSupported] = useState(false);
    const [templatesLoading, setTemplatesLoading] = useState(false);
    const [templates, setTemplates] = useState([]);
    const [isModalTemplateMessagesVisible, setIsModalTemplateMessagesVisible] = useState(false);
    const closeAllModals = (e) => {
        setIsQuickRepliesVisible(false);
        setIsModalMoreVisible(false);
        // setIsModalSendVisible(false)
        setIsModalTemplateMessagesVisible(false);
    };
    useEffect(() => {
        window.addEventListener('popstate', closeAllModals);
        return () => {
            window.removeEventListener('popstate', closeAllModals);
        };
    }, []);
    useEffect(() => {
        const adapter = channelInfo ? channels.getAccountAdapter({
            id: channelInfo.accountId,
            name: channelInfo.name,
        }) : null;
        const supportsTemplates = channels.hasFeatures([MessagingChannelFeature.templates], adapter);
        setTemplatesSupported(supportsTemplates);
        if ((channelInfo === null || channelInfo === void 0 ? void 0 : channelInfo.accountId) && supportsTemplates) {
            setTemplates(templateMessagesStore.templateMessagesList);
        }
        else {
            setTemplates([]);
            setTemplatesLoading(false);
        }
        return () => {
            setTemplates([]);
            setTemplatesLoading(false);
        };
    }, [channelInfo, templateMessagesStore.templateMessagesList]);
    const handleOpenModalMore = () => {
        setIsModalMoreVisible(!isModalMoreVisible);
        setIsQuickRepliesVisible(false);
    };
    // const toggleSendModal = () => {
    //     if (!!activeChatStore.chat) {
    //       setIsModalSendVisible(!isModalSendVisible);
    //     } else {
    //       message.error('Please select a Contact')
    //     }
    // }
    const renderRepliesList = () => {
        if (!activeChatStore.sessionIsOver && quickRepliesStore.quickRepliesList.length) {
            return (React.createElement(QuickRepliesList, { items: quickRepliesStore.quickRepliesList, loading: quickRepliesStore.quickRepliesLoading, onClicked: (reply) => {
                    handleOpen();
                    onQuickReplySelected(reply);
                }, onClose: handleOpen }));
        }
        else if (activeChatStore.sessionIsOver && !!templateMessagesStore.templateMessagesList.length) {
            return (React.createElement(TemplatesListFilterable, { templates: templates, loading: templatesLoading, onClicked: (tpl) => {
                    handleOpen();
                    templateMessagesStore.setTemplateEdited(tpl);
                    setIsModalTemplateMessagesVisible(true);
                }, onClose: () => setIsQuickRepliesVisible(false), onlyUsage: TEMPLATE_USAGE.INBOX, onlyStatus: [WhatsApp.TemplateLocalizationStatus.APPROVED] }));
        }
        return (React.createElement("div", { className: "noRepliesContainer" },
            React.createElement("div", { className: "noRepliesBody" },
                React.createElement(Icon, { className: "noRepliesIcon", component: QuickRepliesFolder }),
                React.createElement("div", { className: "noRepliesBodyText" },
                    React.createElement(Text, { strong: true },
                        "You didn't create any ",
                        activeChatStore.sessionIsOver ? 'template messages' : 'quick replies',
                        " yet"))),
            activeChatStore.sessionIsOver ? "" :
                React.createElement(Tooltip, { className: "editReplyContainer", overlayStyle: { zIndex: 989 }, title: 'Add or Edit a Quick Reply' },
                    React.createElement(Button, { onClick: handleOpenModalMore, className: "addReplyButton" },
                        React.createElement(Icon, { component: QuickRepliesPlus }),
                        React.createElement(Text, { strong: true }, "Create quick reply")))));
    };
    const checkIfDisabled = () => {
        var _a, _b;
        return activeChatStore.chat && activeChatStore.chat.status === constants.CHAT_STATUS.ASSIGNED && ((_a = loginStore.userDetails) === null || _a === void 0 ? void 0 : _a._id) === ((_b = activeChatStore.chat.agent) === null || _b === void 0 ? void 0 : _b.uid);
    };
    if (activeChatStore.sessionIsOver && !templatesSupported)
        return null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: "quickRepliesField" }, activeChatStore.chat ?
            React.createElement(HightLayout, { isActive: isQuickRepliesVisible, onClick: () => setIsQuickRepliesVisible(false) },
                React.createElement("div", { className: "quickRepliesBarWithSyncButton" },
                    React.createElement("div", { className: classNames({
                            ["quickRepliesMainField"]: true,
                            ["quickRepliesDisabled"]: !checkIfDisabled(),
                        }) },
                        React.createElement("div", { className: "quickRepliesTitleHolder", onClick: checkIfDisabled() ?
                                () => {
                                    handleOpen();
                                }
                                : undefined },
                            React.createElement("div", { className: "quickRepliesTitleLeft" },
                                activeChatStore.chat && (activeChatStore.chat.status !== constants.CHAT_STATUS.ASSIGNED || activeChatStore.sessionIsOver) ?
                                    React.createElement(BsChatRightTextFill, { className: "coloredIcon" }) :
                                    React.createElement(Icon, { component: LeftArrowQuickReplies, className: "coloredIcon" }),
                                React.createElement(Text, { strong: true, className: "titleQuickReplies" }, activeChatStore.sessionIsOver ? React.createElement(Trans, { i18nKey: "messageTemplates" }) : React.createElement(Trans, { i18nKey: "quickReplies" }))),
                            isQuickRepliesVisible ? React.createElement(UpOutlined, null) : React.createElement(DownOutlined, null)),
                        agentsStore.hasPermissions([Permissions.Permission.quick_replies_manage]) && (React.createElement("div", { className: "quickRepliesTitleRight", onClick: () => {
                                if (!activeChatStore.sessionIsOver) {
                                    handleOpenModalMore();
                                }
                                else {
                                    templateMessagesStore.toggleNewTemplateDrawer();
                                }
                            } },
                            React.createElement(ActiveIcon, { component: QuickRepliesGear })))),
                    React.createElement("div", { className: classNames({
                            ["quickReplies"]: true,
                            ["displayNone"]: !isQuickRepliesVisible,
                        }) }, renderRepliesList())))
            : null),
        React.createElement(Modal, { open: isModalTemplateMessagesVisible, onCancel: () => {
                templateMessagesStore.setStoreToDefault();
                setIsModalTemplateMessagesVisible(!isModalTemplateMessagesVisible);
            }, centered: true, closable: false, footer: null, bodyStyle: {
                padding: '0',
                boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)',
                borderRadius: '2px',
                minWidth: '60vw',
            }, width: "60vw" }, isModalTemplateMessagesVisible && (React.createElement(EditTemplateMessage, { hideModal: () => {
                setIsModalTemplateMessagesVisible(!isModalTemplateMessagesVisible);
                templateMessagesStore.setStoreToDefault();
            } }))),
        React.createElement(Modal, { open: isModalMoreVisible, footer: null, onCancel: handleOpenModalMore, width: 800, bodyStyle: {
                padding: '0',
                boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)',
                borderRadius: '2px',
            }, closable: false, centered: true }, isModalMoreVisible && activeChatStore.chat && !activeChatStore.sessionIsOver &&
            React.createElement(QuickRepliesEditor, { closeFunction: () => setIsModalMoreVisible(false), isNoreplies: !quickRepliesStore.quickRepliesList.length })),
        isQuickRepliesVisible ? React.createElement("div", { className: "quickRepliesDarkBackground", onClick: handleOpen }) : null));
};
export default withStore(observer(QuickRepliesActivityBar));
