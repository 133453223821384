import React, {Component} from 'react';
import {Trans} from "react-i18next";

import "./NoInternetMessage.css"
import noInternetSign from "../../assets/icons/warnings/noInternetSign.png"

class NoInternetMessage extends Component {
    render() {
        return (
            <div className="noInternetMessageContainer">
                <img className="noInternetMessageImage" src={noInternetSign} />
                <p className="noInternetMessageText">
                    <Trans i18nKey="noInternetConnection"/>
                </p>
            </div>
        );
    }
}

export default NoInternetMessage;
