import React from 'react';
import { observer } from 'mobx-react';
import { useStore } from '../../../../store/rootStore';
import { RightSidebarModules } from '../../../../store/NavigationStore';
import CRM from './CRM';
const Module = () => {
    const store = useStore();
    const { rightSidebarModule: rightSidebarOpen } = store.navigation;
    switch (rightSidebarOpen) {
        case RightSidebarModules.CRM:
            return React.createElement(CRM, null);
        default:
            return null;
    }
};
export default observer(Module);
