import React, { useEffect } from 'react';
import { Divider } from 'antd';
import Drawer from '@material-ui/core/Drawer';
import Icon from '@ant-design/icons';
import classnames from 'classnames';
import { Trans } from 'react-i18next';
import { observer } from 'mobx-react';
import { ReactComponent as ListCheck } from '../../../assets/icons/noGroupIcons/listCheck.svg';
import { ReactComponent as ReportsIcon } from '../../../assets/icons/leftMenuIcons/reports.svg';
import { ReactComponent as QuickRepliesGear } from '../../../assets/icons/leftMenuIcons/settingsOutlined.svg';
import { ReactComponent as NotificationCircle } from '../../../assets/icons/chatStatuses/green-notification-circle.svg';
import KeyboardBackspaceOutlinedIcon from '@material-ui/icons/KeyboardBackspaceOutlined';
import { useStore } from '../../../store/rootStore';
import { useDrawerStyles } from '../../LeftDrawer/style';
import LimitDashboard from './LimitDashboard';
import { getDirection } from '../../../helpers';
import { Permissions } from '@whatsper/texterchat-common';
import s from './TemplateMessageDrawer.module.scss';
const TemplateMessageDrawer = ({ isOpen, toggleTemplateMessageDrawer, toggleBulkMessageDrawer, toggleNewTemplateDrawer, toggleBulkReportsDrawer, }) => {
    var _a;
    const { templateMessagesStore, agentsStore, generalSettings, bulkReports } = useStore();
    const classes = useDrawerStyles({ direction: getDirection() });
    useEffect(() => {
        if (isOpen) {
            templateMessagesStore.getTierLimitCount();
        }
    }, [isOpen]);
    return (React.createElement(Drawer, { className: classes.root, onClose: toggleTemplateMessageDrawer, anchor: getDirection() === 'rtl' ? 'right' : 'left', dir: getDirection(), open: isOpen },
        React.createElement("div", { className: classnames(s['template-message-container'], {
                [s.rtl]: getDirection() === 'rtl',
            }) },
            React.createElement("div", { className: s['template-message-drawer-title'] },
                React.createElement(Icon, { className: s['template-message-drawer-title-icon'], onClick: toggleTemplateMessageDrawer, component: KeyboardBackspaceOutlinedIcon }),
                React.createElement(Trans, { i18nKey: "templateMessages" })),
            React.createElement(LimitDashboard, { sent: templateMessagesStore.templateTierLimitCount, limit: ((_a = generalSettings.inbox.template_limit) === null || _a === void 0 ? void 0 : _a.limit) || 0, loading: templateMessagesStore.templateTierLimitLoading }),
            agentsStore.hasPermissions([Permissions.Permission.messages_bulk_send]) && (React.createElement(React.Fragment, null,
                React.createElement(Divider, { className: s['template-message-drawer-divider'] }),
                React.createElement("button", { className: s['template-message-button'], onClick: toggleBulkMessageDrawer },
                    React.createElement("div", null,
                        React.createElement(Icon, { className: s['template-message-drawer-icon'], component: ListCheck }),
                        React.createElement(Trans, { i18nKey: "sendBulkMessages" }))))),
            React.createElement(Divider, { className: s['template-message-drawer-divider'] }),
            React.createElement("button", { className: s['template-message-button'], onClick: toggleBulkReportsDrawer },
                React.createElement("div", null,
                    React.createElement(Icon, { className: s['template-message-drawer-icon'], component: ReportsIcon }),
                    React.createElement(Trans, { i18nKey: "bulkReports" })),
                bulkReports.hasScheduledReports && (React.createElement(Icon, { className: "template-message-drawer-icon", component: NotificationCircle }))),
            agentsStore.hasPermissions([Permissions.Permission.template_messages_view]) && (React.createElement(React.Fragment, null,
                React.createElement(Divider, { className: s['template-message-drawer-divider'] }),
                React.createElement("button", { className: s['template-message-button'], onClick: toggleNewTemplateDrawer },
                    React.createElement("div", null,
                        React.createElement(Icon, { className: s['template-message-drawer-icon'], component: QuickRepliesGear }),
                        React.createElement(Trans, { i18nKey: "templateManager" }))))))));
};
export default observer(TemplateMessageDrawer);
