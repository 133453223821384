import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import QReply from '../QuickReply';
import styles from './styles.module.scss';
import { Trans } from 'react-i18next';
const QuickRepliesList = ({ items, departmentsList, onClicked, loading }) => {
    if (loading)
        return React.createElement(LoadingOutlined, { className: styles.preloader });
    if (items.length === 0) {
        return (React.createElement("div", { className: styles.empty },
            React.createElement(Trans, { i18nKey: "noQuickReplies" })));
    }
    return (React.createElement(React.Fragment, null, items.map((item) => {
        return (React.createElement("div", { key: item._id },
            React.createElement(QReply, { reply: item, departmentsList: departmentsList, onClicked: () => (onClicked ? onClicked(item) : null) }),
            React.createElement("div", { className: styles.divider })));
    })));
};
export default QuickRepliesList;
