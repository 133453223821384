import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Collapse } from 'antd';
import classnames from 'classnames';
import { isNaN } from 'lodash';
import { CHAT_STATUS } from '@whatsper/texterchat-common';
import { BoxValueTypes } from '../components/Box';
import Row from '../components/Row';
import PanelSummary from '../components/PanelSummary';
import { withStore } from '../../../store/rootStore';
import { getHoursAndMinutes } from '../helpers';
import { ReactComponent as ClockCircle } from '../../../assets/icons/dashboard/ClockCircle.svg';
import { ReactComponent as CheckSquare } from '../../../assets/icons/dashboard/CheckSquare.svg';
import { ReactComponent as Hourglass } from '../../../assets/icons/dashboard/Hourglass.svg';
import { ReactComponent as IssuesClose } from '../../../assets/icons/dashboard/IssuesClose.svg';
import { ReactComponent as UserAdd } from '../../../assets/icons/dashboard/UserAdd.svg';
import { ReactComponent as Dashboard } from '../../../assets/icons/dashboard/Dashboard.svg';
import { ReactComponent as Bot } from '../../../assets/icons/leftMenuIcons/bot.svg';
import { formatAsPercent } from '../../BulkReportsDrawer/helpers';
import s from '../DashboardDrawer.module.scss';
const { Panel } = Collapse;
const Departments = ({ store: { departmentsStore, dashboard } }) => {
    const { t } = useTranslation();
    const [openPanels, setOpenPanels] = useState([]);
    const onPanelChange = (key) => {
        setOpenPanels(key);
    };
    const { [CHAT_STATUS.PENDING]: pending, [CHAT_STATUS.ASSIGNED]: assigned } = dashboard.data;
    return (React.createElement("div", { className: s['main__container'] },
        React.createElement(Collapse, { activeKey: openPanels, onChange: onPanelChange, ghost: true, destroyInactivePanel: true }, [
            ...departmentsStore.departments,
            {
                _id: null,
                name: t('withoutDepartment'),
                color: '#D9B64C',
                sla: { time: 1000 * 60 * 20 }, // 20 minutes
            },
        ].map(({ name, _id, color, sla }, i) => {
            var _a, _b;
            const pendingData = pending === null || pending === void 0 ? void 0 : pending.departments.find((d) => d._id === _id);
            const assignedData = assigned === null || assigned === void 0 ? void 0 : assigned.departments.find((d) => d._id === _id);
            const resolvedData = (_a = dashboard.resolvedData) === null || _a === void 0 ? void 0 : _a.departments.find((d) => d._id === _id);
            const SLAData = (_b = dashboard.departmentSlaData) === null || _b === void 0 ? void 0 : _b.find((d) => d._id === _id);
            const { chatsCount: totalPending = 0, avgTime: pendingTimeAvgValue, maxTime: pendingMaxTime, } = pendingData || {};
            const { avgTime: takenTimeAvgValue, maxTime: takenMaxTime } = assignedData || {};
            const { chatsCount: totalTaken = 0 } = assignedData || {};
            const { agent_resolved = 0, bot_resolved = 0, not_resolved = 0 } = resolvedData || {};
            const resolvedAll = agent_resolved + bot_resolved + not_resolved;
            const pendingTimeAvg = getHoursAndMinutes(pendingTimeAvgValue);
            const pendingTimeMax = getHoursAndMinutes(pendingMaxTime);
            const takenTimeAvg = getHoursAndMinutes(takenTimeAvgValue);
            const takenTimeMax = getHoursAndMinutes(takenMaxTime);
            const slaTime = getHoursAndMinutes(sla === null || sla === void 0 ? void 0 : sla.time, true);
            const slaPercent = SLAData ? SLAData.success / (SLAData.success + SLAData.failure) : 0;
            const getResolvedSummary = () => {
                if (!resolvedData)
                    return;
                const resolvedPercentAll = formatAsPercent(((agent_resolved + bot_resolved) / resolvedAll) * 100);
                return (React.createElement("div", { className: s['main__panel--resolvedSummary'] },
                    React.createElement("div", null, resolvedPercentAll)));
            };
            const showExtra = () => {
                if (_id === null && openPanels.includes('null'))
                    return false;
                return !openPanels.includes(_id || '');
            };
            return (React.createElement(Panel, Object.assign({ key: _id || i, header: React.createElement("div", { style: { color } }, name), className: classnames(s['main__panel--all']) }, (showExtra() && {
                extra: (React.createElement(PanelSummary, { panels: [
                        { icon: React.createElement(ClockCircle, null), title: t('pending'), value: totalPending },
                        {
                            icon: React.createElement(Hourglass, null),
                            title: t('averagePendingTime'),
                            value: dashboard.shouldHideOutsideWorkingHours
                                ? undefined
                                : pendingTimeAvg,
                        },
                        { icon: React.createElement(UserAdd, null), title: t('totalTaken'), value: totalTaken },
                        {
                            icon: React.createElement(CheckSquare, null),
                            title: t('totalResolved'),
                            value: getResolvedSummary(),
                            compact: true,
                        },
                        {
                            icon: React.createElement(IssuesClose, null),
                            title: t('averageTimeToResolve'),
                            value: dashboard.shouldHideOutsideWorkingHours
                                ? undefined
                                : takenTimeAvg,
                        },
                        {
                            icon: React.createElement(Dashboard, null),
                            title: t('SLA'),
                            value: formatAsPercent(slaPercent * 100),
                        },
                    ], color: color })),
            })),
                React.createElement(Row, { boxData: [
                        {
                            title: t('pendingChats'),
                            data: {
                                type: BoxValueTypes.SIMPLE,
                                value: totalPending,
                                extraIcon: React.createElement(Bot, null),
                            },
                            color,
                        },
                        {
                            title: t('pendingTime'),
                            data: {
                                type: BoxValueTypes.OVER,
                                value: dashboard.shouldHideOutsideWorkingHours
                                    ? [undefined, undefined]
                                    : [pendingTimeAvg, pendingTimeMax],
                            },
                            color,
                        },
                        {
                            title: t('totalTaken'),
                            data: {
                                type: BoxValueTypes.SIMPLE,
                                value: totalTaken,
                            },
                            color,
                        },
                        {
                            title: t('resolved'),
                            data: {
                                type: BoxValueTypes.CHART_SMALL,
                                extraIcon: React.createElement(Bot, null),
                                value: {
                                    total: resolvedAll,
                                    agent: agent_resolved,
                                    bot: bot_resolved,
                                },
                            },
                            color,
                        },
                        {
                            title: t('timeToResolve'),
                            data: {
                                type: BoxValueTypes.OVER,
                                value: dashboard.shouldHideOutsideWorkingHours
                                    ? [undefined, undefined]
                                    : [takenTimeAvg, takenTimeMax],
                            },
                            color,
                        },
                        {
                            title: t('SLA'),
                            data: {
                                type: BoxValueTypes.GAUGE,
                                value: {
                                    percent: isNaN(slaPercent) ? 0 : slaPercent,
                                    time: slaTime,
                                },
                            },
                            color,
                        },
                    ] })));
        }))));
};
export default withStore(observer(Departments));
