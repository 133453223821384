var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { action, runInAction, observable, makeObservable, reaction } from 'mobx';
export var RightSidebarModules;
(function (RightSidebarModules) {
    RightSidebarModules["CRM"] = "CRM";
    RightSidebarModules["Chat"] = "Chat";
})(RightSidebarModules || (RightSidebarModules = {}));
/**
 * Dedicated store for page top-level navigation activities
 * (e.g. sidebars, tabs, menus, etc.) that
 * not fit by logic into some other specific store
 */
export default class NavigationStore {
    constructor(rootStore) {
        Object.defineProperty(this, "rootStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "sectionOpen", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {}
        });
        Object.defineProperty(this, "rightSidebarModule", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: RightSidebarModules.CRM
        });
        Object.defineProperty(this, "isMessageDrawerOpen", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "isTemplateMessageDrawerOpen", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "isBulkReportsDrawerOpen", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "isDashboardDrawerOpen", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "isEditProfileDrawerOpen", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "isSupportModalOpen", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "isAssignToModalOpen", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "initSectionOpen", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (sectionOpen) => {
                runInAction(() => {
                    this.sectionOpen = sectionOpen;
                });
            }
        });
        Object.defineProperty(this, "toggleSection", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (name, open) => {
                runInAction(() => {
                    this.sectionOpen = Object.assign(Object.assign({}, this.sectionOpen), { [name]: typeof open === 'boolean' ? open : !this.sectionOpen[name] });
                });
            }
        });
        Object.defineProperty(this, "toggleMessageDrawer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.isMessageDrawerOpen = !this.isMessageDrawerOpen;
            }
        });
        Object.defineProperty(this, "toggleTemplateMessageDrawer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.isTemplateMessageDrawerOpen = !this.isTemplateMessageDrawerOpen;
            }
        });
        Object.defineProperty(this, "toggleBulkReportsDrawer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.isBulkReportsDrawerOpen = !this.isBulkReportsDrawerOpen;
            }
        });
        Object.defineProperty(this, "toggleDashboardDrawer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.isDashboardDrawerOpen = !this.isDashboardDrawerOpen;
            }
        });
        Object.defineProperty(this, "toggleEditProfileDrawer", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.isEditProfileDrawerOpen = !this.isEditProfileDrawerOpen;
            }
        });
        Object.defineProperty(this, "toggleSupportModal", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.isSupportModalOpen = !this.isSupportModalOpen;
            }
        });
        Object.defineProperty(this, "setRightSidebarModule", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (name) => {
                this.rightSidebarModule = name !== null && name !== void 0 ? name : RightSidebarModules.CRM;
            }
        });
        Object.defineProperty(this, "toggleAssignToModal", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.isAssignToModalOpen = !this.isAssignToModalOpen;
            }
        });
        makeObservable(this);
        this.rootStore = rootStore;
        reaction(() => { var _a; return (_a = this.rootStore.activeChatStore) === null || _a === void 0 ? void 0 : _a.chat; }, (chat) => {
            if (!chat) {
                this.setRightSidebarModule();
            }
        });
    }
}
__decorate([
    observable
], NavigationStore.prototype, "sectionOpen", void 0);
__decorate([
    observable
], NavigationStore.prototype, "rightSidebarModule", void 0);
__decorate([
    observable
], NavigationStore.prototype, "isMessageDrawerOpen", void 0);
__decorate([
    observable
], NavigationStore.prototype, "isTemplateMessageDrawerOpen", void 0);
__decorate([
    observable
], NavigationStore.prototype, "isBulkReportsDrawerOpen", void 0);
__decorate([
    observable
], NavigationStore.prototype, "isDashboardDrawerOpen", void 0);
__decorate([
    observable
], NavigationStore.prototype, "isEditProfileDrawerOpen", void 0);
__decorate([
    observable
], NavigationStore.prototype, "isSupportModalOpen", void 0);
__decorate([
    observable
], NavigationStore.prototype, "isAssignToModalOpen", void 0);
__decorate([
    action
], NavigationStore.prototype, "initSectionOpen", void 0);
__decorate([
    action
], NavigationStore.prototype, "toggleSection", void 0);
__decorate([
    action
], NavigationStore.prototype, "toggleMessageDrawer", void 0);
__decorate([
    action
], NavigationStore.prototype, "toggleTemplateMessageDrawer", void 0);
__decorate([
    action
], NavigationStore.prototype, "toggleBulkReportsDrawer", void 0);
__decorate([
    action
], NavigationStore.prototype, "toggleDashboardDrawer", void 0);
__decorate([
    action
], NavigationStore.prototype, "toggleEditProfileDrawer", void 0);
__decorate([
    action
], NavigationStore.prototype, "toggleSupportModal", void 0);
__decorate([
    action
], NavigationStore.prototype, "setRightSidebarModule", void 0);
