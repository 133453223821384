import moment from 'moment';
export const filterReportsByName = (report, query) => {
    var _a;
    return (((_a = report.title) === null || _a === void 0 ? void 0 : _a.toLowerCase().includes(query.toLowerCase())) ||
        report.templateTitle.toLowerCase().includes(query.toLowerCase()));
};
export const filterReportsByDate = (report, dateRange) => {
    if (dateRange[0] && dateRange[1]) {
        return moment(report.date).isBetween(dateRange[0], dateRange[1]);
    }
    return true;
};
export const sortReportsByDate = (report1, report2) => {
    if (!report1.date || !report2.date) {
        return 0;
    }
    return moment(report2.date).valueOf() - moment(report1.date).valueOf();
};
export const formatAsPercent = (num) => {
    if (Number.isNaN(num))
        num = 0;
    return new Intl.NumberFormat('en-US', {
        style: 'percent',
        minimumFractionDigits: 0,
        maximumFractionDigits: 1,
    }).format(num / 100);
};
