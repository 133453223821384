var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { MessagingChannel } from '@whatsper/texterchat-common';
import { isArray } from 'lodash';
import { COMPOSE_SESSION_STATE_KEY, TIME_FORMAT } from '../constants';
import moment from 'moment';
export const isSystemMessage = (message) => {
    return !!(typeof message === 'object' && message && message.systemMessage);
};
export const isAdMessage = (message) => {
    var _a, _b;
    return !!((_b = (_a = message.special) === null || _a === void 0 ? void 0 : _a.facebook) === null || _b === void 0 ? void 0 : _b.referral);
};
export const getBackgroundColor = (theme, params = {}) => {
    var _a, _b;
    if (((_a = theme.additionalData) === null || _a === void 0 ? void 0 : _a.secondaryColor) && !params.forcePrimaryColor) {
        return (_b = theme.additionalData) === null || _b === void 0 ? void 0 : _b.secondaryColor;
    }
    if (isArray(theme.color)) {
        if (params.swapColors) {
            return `linear-gradient(270deg, ${theme.color[1]} 0%, ${theme.color[0]} 100%)`;
        }
        return `linear-gradient(270deg, ${theme.color[0]} 0%, ${theme.color[1]} 100%)`;
    }
    return theme.color;
};
export const shadeColor = (col, amt) => {
    try {
        col = col.replace(/^#/, '');
        if (col.length === 3)
            col = col[0] + col[0] + col[1] + col[1] + col[2] + col[2];
        let r, g, b;
        [r, g, b] = col.match(/.{2}/g);
        [r, g, b] = [parseInt(r.toString(), 16) + amt, parseInt(g.toString(), 16) + amt, parseInt(b.toString(), 16) + amt];
        r = Math.max(Math.min(255, r), 0).toString(16);
        g = Math.max(Math.min(255, g), 0).toString(16);
        b = Math.max(Math.min(255, b), 0).toString(16);
        const rr = (r.length < 2 ? '0' : '') + r;
        const gg = (g.length < 2 ? '0' : '') + g;
        const bb = (b.length < 2 ? '0' : '') + b;
        return `#${rr}${gg}${bb}`;
    }
    catch (error) {
        console.error('Error trying shade color', error);
        return col;
    }
};
export const importAllWebpack = (r) => r.keys().map(r);
export const setCSSVariable = (name, value) => {
    document.documentElement.style.setProperty(name, value);
};
/* https://stackoverflow.com/a/26358856 */
export const isSafari = () => {
    if (navigator.userAgent.includes('Chrome'))
        return false;
    return navigator.userAgent.includes('Safari');
};
/**
 * Download a file from a URL when inside regular browser
 * and return Blob string of it,
 * or return the same URL string if inside React Native WebView
 *
 * @param url URL to file
 * @returns Same URL string if inside React Native WebView, or a downloaded file Blob if in browser
 */
export const getDownloadUrlBasedOnPlatform = (url) => __awaiter(void 0, void 0, void 0, function* () {
    return window.ReactNativeWebView ? url : yield fetch(url).then((resp) => resp.blob());
});
export const clearComposeSessionState = (id) => {
    if (id) {
        const sessionState = JSON.parse(sessionStorage.getItem(COMPOSE_SESSION_STATE_KEY) || '{}');
        delete sessionState[id];
        sessionStorage.setItem(COMPOSE_SESSION_STATE_KEY, JSON.stringify(sessionState));
    }
    else {
        sessionStorage.removeItem(COMPOSE_SESSION_STATE_KEY);
    }
};
export const getComposeSessionState = (id) => {
    const sessionState = JSON.parse(sessionStorage.getItem(COMPOSE_SESSION_STATE_KEY) || '{}');
    if (id) {
        const sessionStateInput = sessionState === null || sessionState === void 0 ? void 0 : sessionState[id];
        return sessionStateInput || '';
    }
    else {
        return sessionState || {};
    }
};
export const currencyFormatter = (options = {}) => new Intl.NumberFormat('en-US', Object.assign({ style: 'currency', currency: 'USD', maximumFractionDigits: 2 }, options));
export const getDirection = () => {
    const dir = localStorage.getItem('currentDirection') || document.documentElement.getAttribute('dir') || 'ltr';
    return dir;
};
export const setDirection = (direction) => {
    localStorage.setItem('currentDirection', direction);
    document.documentElement.setAttribute('dir', direction);
};
export const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
export const updateLocalStorage = (key, value) => {
    if (value) {
        localStorage.setItem(key, value);
    }
    else {
        localStorage.removeItem(key);
    }
};
export const getChannelSharableLink = (account) => {
    switch (account.name) {
        case MessagingChannel.whatsapp:
            return `https://wa.me/${account.id}`;
        case MessagingChannel.messenger:
            return `https://m.me/${account.id}`;
        case MessagingChannel.email:
            return `mailto:${account.id}`;
        case MessagingChannel.website_chat:
            return account.id;
        default:
            return '';
    }
};
export const formatRelativeTime = (timestamp, timeFormat, t) => {
    if (!timestamp)
        return null;
    const dateDifferenceInDays = moment().diff(moment(timestamp), 'days');
    if (dateDifferenceInDays < 1) {
        if (timeFormat === TIME_FORMAT.TWELVE_HOURS) {
            return moment(timestamp).format("LT"); // 2:04 pm
        }
        else if (timeFormat === TIME_FORMAT.TWENTY_FOUR_HOURS) {
            return moment(timestamp).format("HH:mm"); // 14:04
        }
    }
    else if (dateDifferenceInDays < 2) {
        return t("yesterdayTime"); // yesterday
    }
    else if (dateDifferenceInDays <= 7) {
        return t(moment(timestamp).format("dddd")); // monday, tuesday, wednesday...
    }
    else {
        return moment(timestamp).format("l"); // 05/23/2022
    }
};
export const readableFileSize = (size) => {
    const DEFAULT_SIZE = 0;
    const fileSize = size !== null && size !== void 0 ? size : DEFAULT_SIZE;
    if (!fileSize) {
        return `${DEFAULT_SIZE} KB`;
    }
    const sizeInKb = fileSize / 1024;
    if (sizeInKb > 1024 * 1024) {
        return `${(sizeInKb / (1024 * 1024)).toFixed(2)} GB`;
    }
    if (sizeInKb > 1024) {
        return `${(sizeInKb / 1024).toFixed(2)} MB`;
    }
    return `${sizeInKb.toFixed(2)} KB`;
};
export const audioRecordingSupported = () => __awaiter(void 0, void 0, void 0, function* () {
    var _a;
    try {
        const res = yield ((_a = navigator.mediaDevices) === null || _a === void 0 ? void 0 : _a.getUserMedia({ audio: true }));
        return res.active;
    }
    catch (err) {
        console.error('Error getting microphone permission: ', err.name);
        return false;
    }
});
export const convertSecondsToTimer = (seconds) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;
    return `${hours ? `${hours}:` : ''}${minutes}:${remainingSeconds < 10 ? `0${remainingSeconds}` : remainingSeconds}`;
};
