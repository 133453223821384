import React from 'react';
import { observer } from 'mobx-react';
import { Trans } from 'react-i18next';
import Button from 'antd/es/button';
import classnames from 'classnames';
import { Typography } from 'antd';
import { useStore } from '../../../../../store/rootStore';
import styles from './DeleteTemplate.module.scss';
const { Text } = Typography;
const DeleteTemplate = () => {
    const { templateMessagesStore } = useStore();
    const { currentTemplate, setStoreToDefault, handleDeleteTemplate, submitTemplateLoading } = templateMessagesStore;
    const checkTextDirection = () => {
        const { currentTemplate } = templateMessagesStore;
        if (currentTemplate) {
            return /[\u0590-\u05FF]/.test(currentTemplate.text);
        }
        else {
            return false;
        }
    };
    return (React.createElement("div", { className: styles.addReplyWrapper },
        React.createElement("div", { className: styles.deleteTemplateContent },
            React.createElement("div", { className: styles.deleteTemplateHolder },
                React.createElement("div", { className: classnames(styles.deleteTemplateText, {
                        [styles.deleteTemplateTextRtl]: checkTextDirection(),
                    }) },
                    React.createElement(Text, null, currentTemplate ? currentTemplate.text : null)))),
        React.createElement("div", { className: styles.addReplyBottom },
            React.createElement(Button, { className: styles.button, onClick: setStoreToDefault },
                React.createElement(Trans, { i18nKey: "cancel" })),
            React.createElement(Button, { className: styles.button, type: "primary", onClick: handleDeleteTemplate, disabled: submitTemplateLoading, loading: submitTemplateLoading, danger: true }, React.createElement(Trans, { i18nKey: "deleteSubmit" })))));
};
export default observer(DeleteTemplate);
