var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import SelectBotModal from '../../components/MainPage/RightBar/atoms/ChatStatusButtons/SelectBotModal';
import { AfterResolveChatAction } from '../../constants';
import { useStore } from '../../store/rootStore';
import { CHAT_STATUS } from '@whatsper/texterchat-common';
const LOCAL_STORAGE_KEY_SELECTED_BOT_NODE = 'selectedBotNode';
const LOCAL_STORAGE_KEY_AFTER_RESOLVE_CHAT_ACTION = 'afetrResolveChatAction';
const localStorageGetAfterResolveAction = () => {
    let resolveAfterAction = (localStorage.getItem(LOCAL_STORAGE_KEY_AFTER_RESOLVE_CHAT_ACTION) ||
        AfterResolveChatAction.SEND_CLOSING_MESSAGE);
    // Backward compatibility
    const sendClosingMessage = localStorage.getItem('sendClosingMessage');
    if (sendClosingMessage) {
        resolveAfterAction = JSON.parse(sendClosingMessage)
            ? AfterResolveChatAction.SEND_CLOSING_MESSAGE
            : AfterResolveChatAction.NONE;
    }
    return resolveAfterAction;
};
/**
 * useResolveChat is a hook that provides functionality to resolve chat. The main parts are selecting the actions
 * to perform after resolving chat, selecting bot node to run after resolving chat, and resolving chat.
 * @returns {UseResolveChatReturnType}
 */
const useResolveChat = () => {
    const { chatsStore, activeChatStore } = useStore();
    const [isSelectBotModalVisible, setIsSelectBotModalVisible] = useState(false);
    const [afterResolveAction, setAfterResolveAction] = useState(localStorageGetAfterResolveAction());
    const [selectedBotNode, setSelectedBotNode] = useState(localStorage.getItem(LOCAL_STORAGE_KEY_SELECTED_BOT_NODE) || '');
    const { chat: activeChat, sessionIsOver } = activeChatStore;
    useEffect(() => {
        if (sessionIsOver && afterResolveAction !== AfterResolveChatAction.NONE) {
            setAfterResolveAction(AfterResolveChatAction.NONE);
        }
        else {
            setAfterResolveAction(localStorageGetAfterResolveAction());
        }
    }, [activeChat === null || activeChat === void 0 ? void 0 : activeChat._id, sessionIsOver]);
    const onBotNodeSelect = (id) => {
        localStorage.setItem(LOCAL_STORAGE_KEY_SELECTED_BOT_NODE, id);
        setSelectedBotNode(id);
        if (afterResolveAction !== AfterResolveChatAction.RUN_BOT) {
            onAfterResolveActionChange(AfterResolveChatAction.RUN_BOT);
        }
    };
    const onAfterResolveActionChange = (value) => {
        if (localStorage.getItem('sendClosingMessage')) {
            localStorage.removeItem('sendClosingMessage');
        }
        setAfterResolveAction(value);
        localStorage.setItem(LOCAL_STORAGE_KEY_AFTER_RESOLVE_CHAT_ACTION, value);
        if (value === AfterResolveChatAction.RUN_BOT && !selectedBotNode) {
            setIsSelectBotModalVisible(true);
        }
    };
    const isResolveChatDisabled = () => {
        const { chat: activeChat, chatAccess } = activeChatStore;
        return !activeChat || activeChat.status === CHAT_STATUS.RESOLVED || !(chatAccess === null || chatAccess === void 0 ? void 0 : chatAccess.manage);
    };
    const toggleSelectBotModal = () => {
        setIsSelectBotModalVisible(!isSelectBotModalVisible);
    };
    const resolveChat = () => __awaiter(void 0, void 0, void 0, function* () {
        if (activeChat) {
            return chatsStore.resolveChat(activeChat._id, afterResolveAction === AfterResolveChatAction.SEND_CLOSING_MESSAGE, afterResolveAction === AfterResolveChatAction.RUN_BOT, selectedBotNode);
        }
        setIsSelectBotModalVisible(false);
    });
    const resolveChatModal = (React.createElement(SelectBotModal, { visible: isSelectBotModalVisible, closeModal: () => setIsSelectBotModalVisible(false), onBotNodeSelect: onBotNodeSelect, selectedBotNode: selectedBotNode }));
    return {
        afterResolveAction,
        onAfterResolveActionChange,
        toggleSelectBotModal,
        resolveChat,
        resolveChatModal,
        selectedBotNode,
        disabled: isResolveChatDisabled(),
    };
};
export default useResolveChat;
