import React, { useRef } from "react";
import { Modal } from "antd";
import s from "./PlayVideoModal.module.scss";
const PlayVideoModal = ({ visible, onClose, url, type }) => {
    const videoRef = useRef(null);
    const onCloseModal = () => {
        var _a;
        (_a = videoRef.current) === null || _a === void 0 ? void 0 : _a.pause();
        onClose();
    };
    return (React.createElement(Modal, { open: visible, closable: false, onCancel: onCloseModal, footer: null, centered: true, className: s.modal, bodyStyle: { padding: "0" } },
        React.createElement("div", { className: s.container },
            React.createElement("video", { ref: videoRef, src: url, controls: true, autoPlay: true },
                React.createElement("source", { src: url, type: type })))));
};
export default PlayVideoModal;
