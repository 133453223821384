import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { isNaN } from 'lodash';
import { CHAT_STATUS } from '@whatsper/texterchat-common/constants';
import { BoxValueTypes } from '../components/Box';
import { withStore } from '../../../store/rootStore';
import Row from '../components/Row';
import { getHoursAndMinutes } from '../helpers';
import { ReactComponent as Bot } from '../../../assets/icons/leftMenuIcons/bot.svg';
import s from '../DashboardDrawer.module.scss';
const Overview = ({ store: { dashboard, chatsStore, departmentsStore } }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j;
    const { t } = useTranslation();
    const { [CHAT_STATUS.BOT]: bot, [CHAT_STATUS.PENDING]: pending, [CHAT_STATUS.ASSIGNED]: assigned, [CHAT_STATUS.RESOLVED]: resolved, } = dashboard.data;
    const totalPending = ((_a = pending === null || pending === void 0 ? void 0 : pending.total[0]) === null || _a === void 0 ? void 0 : _a.chatsCount) || 0;
    const totalTaken = ((_b = assigned === null || assigned === void 0 ? void 0 : assigned.total[0]) === null || _b === void 0 ? void 0 : _b.chatsCount) || 0;
    const pendingTimeAvgValue = (_c = pending === null || pending === void 0 ? void 0 : pending.total[0]) === null || _c === void 0 ? void 0 : _c.avgTime;
    const resolveTimeAvgValue = (_d = resolved === null || resolved === void 0 ? void 0 : resolved.total[0]) === null || _d === void 0 ? void 0 : _d.avgTime;
    const slaValue = (_e = dashboard.settingsData) === null || _e === void 0 ? void 0 : _e.sla.time;
    const pendingTimeAvg = getHoursAndMinutes(pendingTimeAvgValue);
    const pendingTimeMax = getHoursAndMinutes((_f = pending === null || pending === void 0 ? void 0 : pending.total[0]) === null || _f === void 0 ? void 0 : _f.maxTime);
    const resolveTimeAvg = getHoursAndMinutes(resolveTimeAvgValue);
    const resolveTimeMax = getHoursAndMinutes((_g = resolved === null || resolved === void 0 ? void 0 : resolved.total[0]) === null || _g === void 0 ? void 0 : _g.maxTime);
    const slaTime = getHoursAndMinutes(slaValue, true);
    const slaSumOfDepartments = (_h = dashboard.departmentSlaData) === null || _h === void 0 ? void 0 : _h.reduce((acc, dept) => {
        let percent = dept.success / (dept.success + dept.failure);
        percent = isNaN(percent) ? 0 : percent;
        acc += percent;
        return acc;
    }, 0);
    const slaPercent = slaSumOfDepartments / departmentsStore.departments.length;
    const totalBotPendingChats = dashboard.isLive ? chatsStore.count.status.BOT : (bot === null || bot === void 0 ? void 0 : bot.total.chatsCount) || 0;
    const [resolvedAll, resolvedByBot, resolvedByAgent] = ((_j = dashboard.resolvedData) === null || _j === void 0 ? void 0 : _j.departments.reduce((acc, { agent_resolved, bot_resolved, not_resolved }) => {
        acc[0] += agent_resolved + bot_resolved + not_resolved;
        acc[1] += bot_resolved;
        acc[2] += agent_resolved;
        return acc;
    }, [0, 0, 0])) || [0, 0, 0];
    return (React.createElement("div", { className: s['overview__container'] },
        React.createElement(Row, { boxData: [
                {
                    title: t('pendingChats'),
                    data: {
                        type: BoxValueTypes.OVER_EXTRA,
                        value: [totalPending || 0, totalBotPendingChats],
                        extraIcon: React.createElement(Bot, null),
                    },
                },
                {
                    title: t('pendingTime'),
                    data: {
                        type: BoxValueTypes.OVER,
                        value: dashboard.shouldHideOutsideWorkingHours
                            ? [undefined, undefined]
                            : [pendingTimeAvg, pendingTimeMax],
                    },
                },
                {
                    title: t('totalTaken'),
                    data: { value: totalTaken, type: BoxValueTypes.SIMPLE },
                },
                {
                    title: t('resolved'),
                    data: {
                        type: BoxValueTypes.CHART,
                        value: {
                            total: resolvedAll,
                            agent: resolvedByAgent,
                            bot: resolvedByBot,
                        },
                    },
                },
                {
                    title: t('timeToResolve'),
                    data: {
                        type: BoxValueTypes.OVER,
                        value: dashboard.shouldHideOutsideWorkingHours
                            ? [undefined, undefined]
                            : [resolveTimeAvg, resolveTimeMax],
                    },
                },
                {
                    title: t('SLA'),
                    data: {
                        type: BoxValueTypes.GAUGE,
                        value: {
                            percent: slaPercent,
                            time: slaTime,
                        },
                    },
                },
            ], inverted: true })));
};
export default withStore(observer(Overview));
