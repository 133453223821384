import * as React from "react";
import Avatar from "@material-ui/core/Avatar";
import { createStyles, withStyles } from "@material-ui/core/styles";
import Badge from "@material-ui/core/Badge";
export default (props) => {
    let dotColor = '#ccc';
    switch (props.status) {
        case 'online':
            dotColor = '#44b700';
            break;
        case 'away':
            dotColor = '#bb9900';
            break;
    }
    const StyledBadge = withStyles((theme) => createStyles({
        badge: {
            backgroundColor: dotColor,
            color: dotColor,
            boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
            '&::after': {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                borderRadius: '50%',
                animation: '$ripple 1.2s infinite ease-in-out',
                border: '1px solid currentColor',
                content: '""',
            },
        },
        '@keyframes ripple': {
            '0%': {
                transform: 'scale(.8)',
                opacity: 1,
            },
            '100%': {
                transform: 'scale(2.4)',
                opacity: 0,
            },
        },
    }))(Badge);
    return (React.createElement(StyledBadge, { overlap: "circular", anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
        }, variant: "dot" },
        React.createElement(Avatar, Object.assign({ alt: props.displayName, src: props.src }, props.uiAvatarProps))));
};
