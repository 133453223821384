import React from 'react';
import { CiExport } from 'react-icons/ci';
import { RiChatSettingsLine } from 'react-icons/ri';
import Collapsable from './Collapsable';
const ChatSettings = ({ goToAppPath }) => {
    const routes = [
        {
            titleKey: 'exportChats',
            icon: React.createElement(CiExport, null),
            path: '/manage/chats/export',
        },
    ];
    return React.createElement(Collapsable, { title: "chatSettings", icon: React.createElement(RiChatSettingsLine, null), routes: routes, goToAppPath: goToAppPath });
};
export default ChatSettings;
