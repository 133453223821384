import React, { useState } from 'react';
import { Typography, Progress, Tooltip } from 'antd';
import Icon from '@ant-design/icons';
import cn from 'classnames';

import { ReactComponent as AttachedFileLogo } from '../../../../../assets/icons/file/attachedFileLogo.svg';
import { ReactComponent as AttachedFileErrorLogo } from '../../../../../assets/icons/file/attachedFileErrorLogo.svg';
import { ReactComponent as TrashOutlinedIcon } from '../../../../../assets/icons/deleteIcon/trashOutlined.svg';
import { ReactComponent as CloseCrossIcon } from '../../../../../assets/messageStatuses/closeCross.svg';

//TODO WTF IS THIS SHIT?!
import './styles.css';
import '../style.css';

const { Text } = Typography;

function UploadComponent(props) {

    const [files, setFiles] = useState([]);
    const [deletedFile, setDeletedFile] = useState();


    const handleRemove = (index) => {
        const newFiles = [...files];
        newFiles.splice(index, 1);
        setFiles(newFiles);
    }

    // const uploadProps = {
    //     onChange: ({ file, fileList, event }) => {
    //         setFiles([...fileList]);
    //     },
    //     showUploadList: false
    // };

    return (
        <>
            {files.length > 0 &&
                <div className={"attachTitle"}>
                    <Text>Attached files</Text>
                </div>
            }
            {
                files.map((file, index) => {
                    if (file.status === "uploading") {
                        return (
                            <div className={"attachedFileItem"} key={index}>
                                <Icon component={AttachedFileLogo} className={"attachedFileIcon"} />
                                <Text className={"attachedFileText"}>Uploading...</Text>
                                <Progress percent={file.percent} showInfo={false} className={"attachedFileProgress"} />
                                <Icon component={CloseCrossIcon} onClick={() => handleRemove(index)} className={"attachedFileRemoveButton"} />
                            </div>
                        );
                    }
                    else if (file.status === "done") {
                        return (

                            <div className={"attachedFileItem"} key={index}>
                                <Icon component={AttachedFileLogo} className={"attachedFileIcon"} />
                                <Text className={"attachedFileText"}>{file.name}</Text>
                                <Tooltip
                                    title={<div className={"tooltip"}>
                                        <Text className={"tooltipQuestion"}>Delete file?</Text>
                                        <Text className={"tooltipCancel"} onClick={() => setDeletedFile(null)}>CANCEL</Text>
                                        <Text className={"tooltipDelete"} onClick={() => handleRemove(index)} type="danger">DELETE</Text>
                                    </div>}
                                    placement='left'
                                    open={deletedFile === file}
                                >
                                    <Icon component={TrashOutlinedIcon} className={cn({ ["attachedFileRemoveButton"]: true, ["active"]: deletedFile === file })} onClick={() => setDeletedFile(file)} />
                                </Tooltip>
                            </div>
                        );
                    }
                    else if (file.status === "error") {
                        return (<div className={"attachedFileItem"} key={index}>
                            <Icon component={AttachedFileErrorLogo} className={"attachedFileIcon"} />
                            <Text className={"attachedFileErrorText"}>Unable to upload</Text>
                            <Icon component={CloseCrossIcon} onClick={() => handleRemove(index)} className={"attachedFileRemoveButton"} />
                        </div>);
                    }
                })
            }
            {/*<Upload  {...uploadProps} fileList={files}>*/}
            {/*    <Button className={cn("attachFileButton", 'big')}>*/}
            {/*        <Icon component={QuickRepliesAttachIcon} />*/}
            {/*        <Text>Attach file</Text>*/}
            {/*    </Button>*/}
            {/*</Upload>*/}
        </>
    );
}


export default UploadComponent;
