import { useEffect, useState } from 'react';
const useTabIsHidden = () => {
    const [tabHidden, setTabHidden] = useState(false);
    const handleVisibilityChange = () => {
        const isHidden = document.hidden;
        setTabHidden(isHidden);
    };
    useEffect(() => {
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, []);
    return tabHidden;
};
export default useTabIsHidden;
