import React from "react";
import { Modal } from "antd";
import { Trans } from "react-i18next";
import classnames from "classnames";
import Icon from "@ant-design/icons";
import { ReactComponent as QuickRepliesCross } from "../../../../assets/messageStatuses/closeCross.svg";
import { getDirection } from "../../../../helpers";
import s from "./ModalWithoutBottom.module.scss";
const ModalWithoutBottom = ({ visible, setModalVisible, afterClose = () => { }, children, titleTextKey, titleTextValues, error, height, width, centerContent, headerIcon: HeaderIcon, bodyStyle = {}, }) => {
    const isRTL = getDirection() === "rtl";
    return (React.createElement(Modal, { width: width || 800, open: visible, closable: false, onCancel: setModalVisible, afterClose: afterClose, footer: null, centered: true, bodyStyle: Object.assign({ padding: "0", boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.2)", borderRadius: "2px" }, bodyStyle) },
        React.createElement("div", { className: s.simpleModalContainer, style: { height } },
            React.createElement("div", { className: classnames(s.simpleModalHeader, { [s.rtl]: isRTL }) },
                React.createElement("div", { className: classnames(s.simpleModalTitle, {
                        [s.simpleModalGreenUnderline]: !error,
                        [s.simpleModalRedUnderline]: error,
                    }) },
                    HeaderIcon && React.createElement(Icon, { className: s.simpleModalTitleIcon, component: () => React.createElement(HeaderIcon, null) }),
                    React.createElement(Trans, { i18nKey: `${titleTextKey}`, values: titleTextValues })),
                React.createElement("div", { className: s.closeModal, onClick: setModalVisible },
                    React.createElement("div", { className: s.closeIconWrapper },
                        React.createElement(Icon, { component: QuickRepliesCross })))),
            React.createElement("div", { className: classnames(s.content, { [s.centerContent]: centerContent }) }, children))));
};
export default ModalWithoutBottom;
