var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import LoadingScreen from '../../../LoadingScreen';
import ContactFilters from './ContactFilters';
import ContactList from './ContactList';
import { FILTER_DEPARTMENTS } from '../../../../constants';
import { withStore } from '../../../../store/rootStore';
import { debounce } from 'lodash';
import SimpleModal from '../SimpleModal';
import { Button } from 'antd';
import { Trans } from 'react-i18next';
import { isChatSessionOver } from '../../../../store/ActiveChatStore';
import styles from './ContactListSelectorModal.module.scss';
class ContactListSelectorModal extends Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                currentDepartmentId: null,
                temporarySelectedMessages: [],
                text: '',
                selectedContacts: [],
                chats: [],
                loading: false,
            }
        });
        Object.defineProperty(this, "componentDidMount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.loadChats();
            }
        });
        Object.defineProperty(this, "setDepartmentFilter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (departmentId) => {
                this.setState({ currentDepartmentId: departmentId }, () => this.loadChats());
            }
        });
        Object.defineProperty(this, "setSearchFilter", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (text) => {
                this.setState({ text }, () => this.loadChatsDebounced());
            }
        });
        Object.defineProperty(this, "addToSelectedList", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (contact) => {
                const { multichoice } = this.props;
                const { selectedContacts } = this.state;
                if (!multichoice && !selectedContacts.find((selectedChat) => selectedChat._id === contact._id)) {
                    this.setState({ selectedContacts: [contact] });
                }
                else if (!multichoice && selectedContacts.find((selectedChat) => selectedChat._id === contact._id)) {
                    this.setState({ selectedContacts: [] });
                }
                else if (multichoice && !selectedContacts.find((selectedChat) => selectedChat._id === contact._id)) {
                    this.setState({ selectedContacts: [...selectedContacts, contact] });
                }
                else if (multichoice && selectedContacts.find((selectedChat) => selectedChat._id === contact._id)) {
                    const newList = selectedContacts.filter((selectedChat) => selectedChat._id !== contact._id);
                    this.setState({ selectedContacts: newList });
                }
            }
        });
        Object.defineProperty(this, "renderList", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { chats, loading, selectedContacts } = this.state;
                if (loading) {
                    return (React.createElement("div", { className: styles.loadingWrapper },
                        React.createElement(LoadingScreen, null),
                        ";"));
                }
                return (React.createElement(ContactList, { chats: chats, selectedContacts: selectedContacts, addToSelectedList: this.addToSelectedList }));
            }
        });
        Object.defineProperty(this, "loadChats", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                const { text, currentDepartmentId } = this.state;
                const { store, onlyActiveChats } = this.props;
                this.setState({ loading: true });
                store.chatsStore
                    .fetchChats({
                    search: text,
                    department: currentDepartmentId && currentDepartmentId !== FILTER_DEPARTMENTS.ALL_DEPARTMENTS
                        ? currentDepartmentId
                        : undefined,
                })
                    .then(({ chats }) => {
                    if (onlyActiveChats) {
                        this.setState({ chats: chats.filter((c) => !isChatSessionOver(c)) });
                    }
                    else {
                        this.setState({ chats: chats });
                    }
                })
                    .catch((error) => {
                    console.error('Error loading chats', error);
                })
                    .finally(() => {
                    this.setState({ loading: false });
                });
            })
        });
        Object.defineProperty(this, "loadChatsDebounced", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: debounce(this.loadChats, 500)
        });
    }
    render() {
        const { currentDepartmentId, text, loading, selectedContacts } = this.state;
        const { visible, setVisible, onForwardMessages } = this.props;
        return (React.createElement(SimpleModal, { visible: visible, setModalVisible: () => {
                setVisible(!visible);
                this.setState({ selectedContacts: [] });
            }, titleTextKey: 'forwardMessageTo', buttons: React.createElement(React.Fragment, null,
                React.createElement(Button, { className: styles.forwardMessageButton, onClick: () => setVisible(false) },
                    React.createElement(Trans, { i18nKey: "cancel" })),
                React.createElement(Button, { disabled: !selectedContacts.length, className: styles.forwardMessageButton, type: "primary", onClick: () => {
                        setVisible(!visible);
                        onForwardMessages(selectedContacts);
                    } },
                    React.createElement(Trans, { i18nKey: "forward" }))) },
            React.createElement("div", { className: styles.wrap },
                React.createElement(ContactFilters, { text: text, loading: loading, currentDepartmentId: currentDepartmentId, setSearchFilter: this.setSearchFilter, setDepartmentFilter: this.setDepartmentFilter }),
                this.renderList())));
    }
}
export default withStore(observer(ContactListSelectorModal));
