import React, { useEffect, useState } from 'react';
import { List } from 'antd';
import { observer } from 'mobx-react';
import ModalWithoutBottom from '../../../../Chat/common/ModalWithoutBottom';
import singleCheck from '../../../../../assets/icons/chatStatuses/singleCheck.svg';
import { useStore } from '../../../../../store/rootStore';
import s from './ResolveChatButton.module.scss';
const SelectBotModal = ({ closeModal, visible, onBotNodeSelect, selectedBotNode }) => {
    var _a;
    const { templateMessagesStore, activeChatStore, channels } = useStore();
    const [bot, setBot] = useState('');
    const { fetchBotNodeList, botNodeListLoading, getBotNodeList } = templateMessagesStore;
    const { chat } = activeChatStore;
    useEffect(() => {
        if (chat === null || chat === void 0 ? void 0 : chat.channelInfo) {
            const account = channels.getAccount({
                id: chat.channelInfo.accountId,
                name: chat.channelInfo.name,
            });
            if (account && account.bot) {
                setBot(account.bot);
            }
        }
    }, [(_a = chat === null || chat === void 0 ? void 0 : chat.channelInfo) === null || _a === void 0 ? void 0 : _a.accountId]);
    useEffect(() => {
        fetchBotNodeList(bot);
    }, [bot]);
    const onBotClick = (id) => {
        onBotNodeSelect(id);
        closeModal();
    };
    return (React.createElement(ModalWithoutBottom, { visible: visible, setModalVisible: closeModal, titleTextKey: 'selectBotNode', width: 400, height: "580px" },
        React.createElement(List, { dataSource: getBotNodeList(bot), loading: botNodeListLoading, renderItem: (item) => (React.createElement(List.Item, { onClick: () => onBotClick(item) },
                item,
                item === selectedBotNode && React.createElement("img", { src: singleCheck }))), className: s.selectBotModal, bordered: true })));
};
export default observer(SelectBotModal);
