import { MessagingChannelFeature } from '@whatsper/texterchat-common';
import classnames from 'classnames';
import { CopyOutlined, WarningOutlined, PhoneOutlined } from '@ant-design/icons';
import { Tooltip, message } from 'antd';
import { observer } from 'mobx-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useStore } from '../../../store/rootStore';
import { formatPhone } from '../../Base/Phone/Formatter';
import s from './ChatIdInfo.module.scss';
import { getDirection } from '../../../helpers';
const NO_ID_FALLBACK = '---';
function ChatIdInfo({ chat, className, inverted, }) {
    var _a;
    const { t } = useTranslation();
    const { phoneFormat, defaultCountry, channels } = useStore(), [idFormatted, setIdFormatted] = useState(NO_ID_FALLBACK), [isPhoneNumber, setIsPhoneNumber] = useState(false), [isVerified, setIsVerified] = useState(true), [copyText, setCopyText] = useState(''), [accountName, setAccountName] = useState('');
    const isClipboardSupported = typeof ((_a = navigator === null || navigator === void 0 ? void 0 : navigator.clipboard) === null || _a === void 0 ? void 0 : _a.writeText) === 'function';
    const isRtl = getDirection() === 'rtl';
    const _formatPhone = (rawPhone) => formatPhone(rawPhone, phoneFormat, defaultCountry);
    useEffect(() => {
        var _a, _b;
        setIsPhoneNumber(false); // reset
        setIsVerified(true);
        setCopyText('');
        const adapter = channels.adaptersByAccountId[`${chat.channelInfo.name}${chat.channelInfo.accountId}`];
        if (adapter) {
            if (channels.hasFeatures([MessagingChannelFeature.contactPhoneNumberId], adapter)) {
                const formattedPhone = _formatPhone(chat.channelInfo.id);
                setIdFormatted(formattedPhone);
                setIsPhoneNumber(true);
                setCopyText(formattedPhone.replace(/-/g, '')); // replace dashes for copy
            }
            else if (channels.hasFeatures([MessagingChannelFeature.contactGlobalId], adapter)) {
                setIdFormatted(chat.channelInfo.id);
                setCopyText(chat.channelInfo.id);
            }
            else if (typeof ((_a = chat.channelInfo.metadata) === null || _a === void 0 ? void 0 : _a.phoneNumber) === 'string') {
                const formattedPhone = _formatPhone((_b = chat.channelInfo.metadata) === null || _b === void 0 ? void 0 : _b.phoneNumber);
                setIdFormatted(formattedPhone);
                setIsPhoneNumber(true);
                setIsVerified(false);
                setCopyText(formattedPhone.replace(/-/g, '')); // replace dashes for copy
            }
            else {
                setIdFormatted(NO_ID_FALLBACK);
            }
        }
        else {
            setIdFormatted(chat.channelInfo.id);
            setCopyText(chat.channelInfo.id);
        }
    }, [chat._id, channels.adaptersByAccountId, phoneFormat, defaultCountry]);
    useEffect(() => {
        var _a;
        const account = channels.accountsById[`${chat.channelInfo.name}${chat.channelInfo.accountId}`];
        if (account) {
            setAccountName(account.title || ((_a = account.metadata) === null || _a === void 0 ? void 0 : _a.accountTitle) || account.accountId);
        }
    }, [chat._id, channels.accountsById]);
    const handleCopy = () => {
        if (isClipboardSupported) {
            navigator.clipboard.writeText(copyText)
                .then(() => {
                message.success(t('phoneCopiedToClipboard'));
            })
                .catch((err) => {
                message.error(t('failedToCopy'));
            });
        }
    };
    return (React.createElement("div", { className: classnames(s.container, className, {
            [s.inverted]: inverted,
        }) },
        React.createElement("div", { className: s.userInfoPhone },
            idFormatted !== NO_ID_FALLBACK && isPhoneNumber && !isVerified && (React.createElement(Tooltip, { title: t("phoneNotVerified") },
                React.createElement(WarningOutlined, { className: classnames(s.prefixIcon, s.warningIcon, { [s.rtl]: isRtl }), 
                    // otherwise anticon style color takes precedence
                    style: { color: '#ff7d00' } }))),
            isPhoneNumber && (React.createElement(PhoneOutlined, { className: classnames(s.prefixIcon, { [s.rtl]: isRtl }) })),
            idFormatted,
            idFormatted !== NO_ID_FALLBACK && isClipboardSupported && (React.createElement(Tooltip, { title: t("copyToClipboard") },
                React.createElement(CopyOutlined, { className: classnames(s.suffixIcon, s.copyIcon, { [s.rtl]: isRtl }), onClick: handleCopy })))),
        React.createElement("div", null, "|"),
        accountName && React.createElement("div", { className: s.accountName }, accountName)));
}
/**
 * Component to display chat formatted ID and channel account title
 */
export default observer(ChatIdInfo);
