import React, { Component } from 'react';
import { observer } from "mobx-react";
import { Alert, Avatar, Button, List, Select, Switch } from 'antd';
import { DownOutlined, UserOutlined } from '@ant-design/icons';
import { Trans, withTranslation } from "react-i18next";
import { withRouter } from 'react-router-dom';
import { MessagingChannelFeature } from '@whatsper/texterchat-common';
import Icon from '@ant-design/icons';
import moment from 'moment';
import { ReactComponent as QuickRepliesCross } from '../../../assets/messageStatuses/closeCross.svg';
import { isMatch, uniqBy } from 'lodash';
import { AGENTS_DEPARTMENTS_COLORS } from "../../../constants";
import { withStore } from '../../../store/rootStore';
import ChannelSelect from '../../Chat/ChatsList/ChannelSelect';
import ColorSelector from '../../ColorSelector';
import { MySelect, MyInput } from '../../Base/Form';
import "./AgentForm.scss";
const { Option } = Select;
class AgentForm extends Component {
    constructor(props) {
        var _a;
        super(props);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                openAdvanced: false,
                displayName: '',
                email: '',
                password: '',
                crmId: '',
                contacts: [],
                departments: [],
                disableInput: false,
                contactsSearchQuery: '',
                contactsTitles: {},
                color: '',
                disabled: false,
                temporary: null,
                roles: [],
                contactsChannelAccount: null,
            }
        });
        Object.defineProperty(this, "setInitialDepartments", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if (this.props.entity) {
                    const departments = [], { uid } = this.props.entity;
                    this.props.store.departmentsStore.departments.map(({ agents, _id }) => {
                        if (agents && agents.includes(uid)) {
                            departments.push(_id);
                        }
                    });
                    this.onInput({ departments });
                }
            }
        });
        Object.defineProperty(this, "setInputsDisabled", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a;
                if ((_a = this.props.entity) === null || _a === void 0 ? void 0 : _a.uid) {
                    this.setState({ disableInput: true });
                }
            }
        });
        Object.defineProperty(this, "supportsContactGlobalId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { contactsChannelAccount } = this.state;
                if (!contactsChannelAccount) {
                    return false;
                }
                const adapter = this.props.store.channels.getAccountAdapter(contactsChannelAccount);
                return !!(adapter === null || adapter === void 0 ? void 0 : adapter.features.includes(MessagingChannelFeature.contactGlobalId));
            }
        });
        Object.defineProperty(this, "onSearchContacts", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (query) => {
                const { contactsChannelAccount } = this.state;
                this.setState({ contactsSearchQuery: query });
                if (contactsChannelAccount && query && query.length >= 2) {
                    this.props.store.agentsStore.searchUsersThrottled(query, contactsChannelAccount.name, 
                    // If global ID not supported then search for one channel account,
                    // if supported - for all accounts of channel
                    !this.supportsContactGlobalId()
                        ? contactsChannelAccount.id
                        : undefined);
                }
            }
        });
        Object.defineProperty(this, "onSelectContact", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (value) => {
                var _a;
                this.setState({ contactsSearchQuery: value });
                if (!value)
                    return;
                const { usersSearchResults } = this.props.store.agentsStore;
                const contact = (_a = usersSearchResults.find((user) => user.channelInfo.id === value)) === null || _a === void 0 ? void 0 : _a.channelInfo;
                if (!contact) {
                    return this.onNewContact(value);
                }
                this.onContactsChange(uniqBy([...this.state.contacts, contact], 'id'));
            }
        });
        Object.defineProperty(this, "onNewContact", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (value) => {
                const { contactsChannelAccount } = this.state;
                if (contactsChannelAccount && value) {
                    const contact = {
                        accountId: contactsChannelAccount.id,
                        name: contactsChannelAccount.name,
                        id: value.trim(),
                    };
                    this.onContactsChange(uniqBy([...this.state.contacts, contact], 'id'));
                }
            }
        });
        Object.defineProperty(this, "onRemoveContact", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (id) => {
                const contacts = this.state.contacts.filter(c => c.id !== id);
                this.onContactsChange(contacts);
            }
        });
        Object.defineProperty(this, "fetchContactTitles", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (contacts) => {
                const { contactsTitles } = this.state;
                if (!contacts || contacts.length === 0)
                    return;
                contacts.forEach((contact) => {
                    if (contactsTitles[contact.id])
                        return;
                    this.props.store.chatsStore.fetchChatByChannelId(contact)
                        .then((chat) => {
                        if (chat) {
                            this.setState((state) => {
                                return {
                                    contactsTitles: Object.assign(Object.assign({}, state.contactsTitles), { [contact.id]: chat.title }),
                                };
                            });
                        }
                        else {
                            console.debug('No existing chat for: ', contact);
                        }
                    })
                        .catch(console.error);
                });
            }
        });
        Object.defineProperty(this, "dropdownMenu", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                return React.createElement(React.Fragment, null,
                    this.props.store.departmentsStore.departments.map((department, i) => (React.createElement(Option, { key: i, icon: React.createElement(UserOutlined, null), value: department._id },
                        React.createElement("div", { style: { backgroundColor: department.color ? department.color : "lightgrey" }, className: "select-department-color" }),
                        department.name))),
                    React.createElement(Option, { className: "manage-departments-option", disabled: true, value: "" },
                        React.createElement(Button, { className: "manage-departments-button", onClick: () => this.openDepartmentsManager() },
                            React.createElement(Trans, { i18nKey: "manageDepartments" }))));
            }
        });
        Object.defineProperty(this, "renderContactOptions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { t, store } = this.props;
                const { usersSearchResults } = store.agentsStore;
                const { contactsSearchQuery } = this.state;
                const globalId = this.supportsContactGlobalId();
                const results = usersSearchResults.map(({ channelInfo, title }) => ({
                    value: channelInfo.id,
                    label: globalId ? `${title} (${channelInfo.id})` : title,
                }));
                /* enter custom input mode */
                if (usersSearchResults.length === 0 && globalId && contactsSearchQuery) {
                    results.unshift({
                        label: `${contactsSearchQuery} (+${t('createNew')})`,
                        value: `${contactsSearchQuery} `, // trick to trigger onChange
                    });
                }
                return uniqBy(results, 'value');
            }
        });
        Object.defineProperty(this, "openDepartmentsManager", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.props.history.push('/manage/departments', { prevPath: this.props.location.pathname });
            }
        });
        Object.defineProperty(this, "onTextInputChange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (value, name) => {
                this.onInput({
                    [name]: value,
                });
            }
        });
        Object.defineProperty(this, "onContactsChange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (contacts) => {
                this.onInput({
                    contacts,
                });
            }
        });
        Object.defineProperty(this, "onSelectInputChange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (value, name) => {
                this.onInput({
                    [name]: value,
                });
            }
        });
        Object.defineProperty(this, "onInput", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (formData) => {
                this.setState(Object.assign({}, formData), () => this.props.onInput(this.state, this.canBeSavedState()));
            }
        });
        Object.defineProperty(this, "canBeSavedState", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                return this.state.displayName.trim().length > 0 && this.state.email.trim().length > 0 && this.state.roles.length === 1;
            }
        });
        if (props.store.channels.defaultAccount) {
            this.state = Object.assign(Object.assign({}, this.state), { contactsChannelAccount: {
                    id: props.store.channels.defaultAccount.accountId,
                    name: props.store.channels.defaultAccount.channel,
                } });
        }
        if (props.entity) {
            this.state = Object.assign(Object.assign(Object.assign({}, this.state), props.entity), { contacts: (_a = props.entity.contacts) !== null && _a !== void 0 ? _a : [] });
        }
    }
    componentDidMount() {
        const { entity } = this.props;
        this.setInitialDepartments();
        this.setInputsDisabled();
        if (entity) {
            if (typeof entity.displayName === 'string') {
                this.onSearchContacts(entity.displayName);
            }
            this.fetchContactTitles(entity.contacts);
        }
    }
    componentDidUpdate(prevProps, prevState) {
        if (!isMatch(this.state.contactsChannelAccount || {}, prevState.contactsChannelAccount || {})) {
            this.props.store.agentsStore.clearSearchUsers();
        }
        if (this.state.contacts.length !== prevState.contacts.length) {
            this.fetchContactTitles(this.state.contacts);
        }
    }
    render() {
        const { openAdvanced, displayName, email, crmId, departments, roles, disableInput, contactsSearchQuery, contactsChannelAccount, contacts, contactsTitles, color, disabled, temporary, } = this.state, { t, error, store } = this.props;
        return (React.createElement(React.Fragment, null,
            !!error &&
                React.createElement("div", { className: "add-agent-error" }, error),
            temporary && (React.createElement("div", { className: "add-agent-labeled-input" },
                React.createElement(Alert, { message: React.createElement(Trans, { i18nKey: "userTemporary" }), description: React.createElement("span", { className: "temporary-user-container" },
                        React.createElement(Trans, { i18nKey: "expirationDate" }),
                        React.createElement("span", { className: "temporary-user-date" }, moment(temporary.till).format('LLL'))), type: "info", showIcon: true }))),
            React.createElement("div", { className: "add-agent-labeled-input" },
                React.createElement("label", null,
                    React.createElement(Trans, { i18nKey: "name" })),
                React.createElement(MyInput, { value: displayName, onChangeHandler: (value) => this.onTextInputChange(value, 'displayName'), placeholder: t('enterName') })),
            React.createElement("div", { className: "add-agent-labeled-input" },
                React.createElement("label", null,
                    React.createElement(Trans, { i18nKey: "agentColor" })),
                React.createElement(ColorSelector, { onChange: (color) => this.onInput({ color }), color: color, colors: AGENTS_DEPARTMENTS_COLORS, showNoColor: true })),
            React.createElement("div", { className: "add-agent-labeled-input" },
                React.createElement("label", null,
                    React.createElement(Trans, { i18nKey: "email" })),
                React.createElement(MyInput, { disabled: disableInput, placeholder: t('enterEmail'), onChangeHandler: (value) => this.onTextInputChange(value, 'email'), value: email })),
            React.createElement("div", { className: "add-agent-labeled-input add-agent-labeled-input-contacts" },
                React.createElement("label", null,
                    React.createElement(Trans, { i18nKey: "contacts" }),
                    " (",
                    contacts.length,
                    ")"),
                React.createElement("div", { className: "show-contacts-container" }, (contacts === null || contacts === void 0 ? void 0 : contacts.length) > 0 && (React.createElement(List, { dataSource: contacts, itemLayout: "horizontal", size: "small", bordered: true, renderItem: ({ id, name }) => {
                        var _a;
                        return (React.createElement(List.Item, { actions: [
                                React.createElement("div", { onClick: () => this.onRemoveContact(id) },
                                    React.createElement("div", { className: "remove-cross" },
                                        React.createElement(Icon, { component: QuickRepliesCross })))
                            ] },
                            React.createElement(List.Item.Meta, { avatar: React.createElement(Avatar, { src: (_a = store.channels.themes[name]) === null || _a === void 0 ? void 0 : _a.icon }), title: contactsTitles[id]
                                    ? `${contactsTitles[id]} (${id})`
                                    : id })));
                    } }))),
                React.createElement("div", { className: "add-contacts-container" },
                    React.createElement("label", null,
                        React.createElement(Trans, { i18nKey: "addContact" }),
                        ":"),
                    React.createElement(ChannelSelect, { accounts: store.channels.accounts, activeChannel: contactsChannelAccount, onChannelClick: (contactsChannelAccount) => this.setState({ contactsChannelAccount }), showAll: false, className: "channel-select", showBorders: true }),
                    React.createElement(MySelect, { value: contactsSearchQuery, onSearch: this.onSearchContacts, onChange: this.onSelectContact, placeholder: t("searchPlaceholder"), defaultActiveFirstOption: true, filterOption: false, options: this.renderContactOptions(), showArrow: false, showSearch: true, allowClear: true }))),
            React.createElement("div", { className: "add-agent-labeled-input" },
                React.createElement("label", null,
                    React.createElement(Trans, { i18nKey: "password" })),
                React.createElement(MyInput, { placeholder: t('enterPassword'), onChangeHandler: (value) => this.onTextInputChange(value, 'password') })),
            React.createElement("div", { className: "add-agent-labeled-input" },
                React.createElement("label", null,
                    React.createElement(Trans, { i18nKey: "chooseRoles" })),
                React.createElement(MySelect
                // roles is an array but we are only allowing one role per agent, hence the [0]
                , { 
                    // roles is an array but we are only allowing one role per agent, hence the [0]
                    value: roles === null || roles === void 0 ? void 0 : roles[0], onChange: (value) => {
                        this.onSelectInputChange([value], 'roles');
                    }, options: store.agentsStore.roles.map((role) => ({
                        value: role.name,
                        label: role.title,
                    })) })),
            React.createElement("div", { className: "add-agent-labeled-input full-height" },
                React.createElement("label", null,
                    React.createElement(Trans, { i18nKey: "chooseDepartment" })),
                React.createElement(MySelect, { value: departments, dropdownStyle: { padding: 0 }, className: "add-agent-dropdown", mode: "multiple", allowClear: true, onChange: (departments) => this.onInput({ departments }) }, this.dropdownMenu())),
            React.createElement("div", { className: "add-agent-labeled-input" },
                React.createElement("label", null,
                    React.createElement(Trans, { i18nKey: "disabled" })),
                React.createElement(Switch, { checked: disabled, onChange: (disabled) => this.onInput({ disabled }), className: "disabled-switch" })),
            React.createElement("div", { className: "add-agent-advanced-handle", onClick: () => this.setState(({ openAdvanced }) => ({ openAdvanced: !openAdvanced })) },
                React.createElement(Trans, { i18nKey: "Advanced" }),
                React.createElement(DownOutlined, { className: "add-agent-advanced-handle-arrow" })),
            openAdvanced && React.createElement("div", { className: "add-agent-labeled-input" },
                React.createElement("label", null,
                    React.createElement(Trans, { i18nKey: "crmId" })),
                React.createElement(MyInput, { value: crmId, onChangeHandler: (value) => this.onTextInputChange(value, 'crmId'), placeholder: t('crmId') }))));
    }
}
export default withTranslation()(withRouter(
// @ts-expect-error // This types error really hard to understand. TODO: Fix when will be more time
withStore(observer(AgentForm))));
