import React from 'react';
import { observer } from 'mobx-react';
import { Select } from 'antd';
import { Trans } from 'react-i18next';
import { FILTER_DEPARTMENTS } from '../../../constants';
import { useStore } from '../../../store/rootStore';
import singleCheck from '../../../assets/icons/chatStatuses/singleCheck.svg';
import { MySelect } from '../../Base/Form';
import styles from './DepartmentsFilter.module.scss';
const { Option } = Select;
const DepartmentsFilter = ({ fullWidth }) => {
    const { chatsStore, departmentsStore } = useStore();
    const current = chatsStore.searchFilter.department || FILTER_DEPARTMENTS.ALL_DEPARTMENTS;
    const handleChange = (value) => {
        chatsStore.setDepartmentFilter(value);
    };
    const shortenName = (name) => {
        if (name.length > 15) {
            return `${name.substring(0, 15)}...`;
        }
        else
            return name;
    };
    const renderCheckmark = (visible) => visible && React.createElement("img", { src: singleCheck });
    return (React.createElement(MySelect, { className: styles.filterDepartmentSelect, defaultValue: chatsStore.searchFilter.department, onChange: handleChange, virtual: false, size: "small", fullWidth: fullWidth, value: current, onClick: e => {
            /* Allow filter to be open within a modal */
            e.stopPropagation();
        } },
        React.createElement(Option, { id: FILTER_DEPARTMENTS.ALL_DEPARTMENTS, value: FILTER_DEPARTMENTS.ALL_DEPARTMENTS },
            React.createElement("div", { className: styles.filterDepartmentsContent },
                React.createElement("div", { style: { backgroundColor: 'lightgrey' }, className: styles.filterDepartmentColor }),
                React.createElement("p", { className: styles.filterDepartmentsText },
                    React.createElement(Trans, { i18nKey: "allDepartments" })),
                renderCheckmark(!chatsStore.searchFilter.department))),
        React.createElement(Option, { id: FILTER_DEPARTMENTS.NOT_ASSIGNED, value: FILTER_DEPARTMENTS.NOT_ASSIGNED },
            React.createElement("div", { className: styles.filterDepartmentsContent },
                React.createElement("div", { style: { border: '2px solid lightgrey' }, className: styles.filterDepartmentColor }),
                React.createElement("p", { className: styles.filterDepartmentsText },
                    React.createElement(Trans, { i18nKey: "notAssigned" })))),
        departmentsStore.departments.map((department, i) => (React.createElement(Option, { key: i, id: department._id, value: department._id, className: styles.filterDepartmentsOption },
            React.createElement("div", { className: styles.filterDepartmentsContent },
                React.createElement("div", { style: { backgroundColor: department.color ? department.color : 'lightgrey' }, className: styles.filterDepartmentColor }),
                React.createElement("p", { className: styles.filterDepartmentsText }, shortenName(department.name))),
            renderCheckmark(department._id === chatsStore.searchFilter.department))))));
};
export default observer(DepartmentsFilter);
