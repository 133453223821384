import React, {Component} from 'react';
import {observer} from "mobx-react";
import {Trans, withTranslation} from "react-i18next"
import classnames from "classnames"
import {CHAT_STATUS, LANGUAGES_TYPES} from "../../../../constants"
import { withStore } from '../../../../store/rootStore';
import blankProfilePicture from "../../../../assets/avatars/blankProfilePicture.png"
import styles from "./SystemMessage.module.scss"
import {Typography} from "antd";
const {Text} = Typography


class SystemMessage extends Component {

    getAgent = (messageAgent) => {
        const agentsList = this.props.store.agentsStore.users
        const currentAgent = agentsList.find((agent)=> agent.uid === messageAgent)
        if (currentAgent){
            return currentAgent.displayName
        } else return messageAgent
    }

    getAvatar = (messageAgent) => {
        const agentsList = this.props.store.agentsStore.users
        const currentAgent = agentsList.find((agent)=> agent.uid === messageAgent)
        if (currentAgent){
            return currentAgent.avatar
        } else return null
    }

    getDepartment = (messageDepartment) => {
        const departmentsList = this.props.store.departmentsStore.departments
        const currentDepartment = departmentsList.find((department)=> department._id === messageDepartment)
        if (currentDepartment){
            return currentDepartment
        } else return messageDepartment
    }

    render() {
        const {newChatStatus, assignedToAgent, agent, time, assignedToDepartment, i18n} = this.props
        return (
            <div className={styles.systemMessageWrapper}>
                <div className={styles.systemMessageText}>

                    {newChatStatus===CHAT_STATUS.PENDING && !assignedToDepartment && !assignedToAgent &&
                        <Text className={classnames(styles.systemMessageTextInner, {[styles.reverseText]:i18n.language===LANGUAGES_TYPES.HE})}>
                            <Trans i18nKey="toPendingSystemMessage" values={{agent: this.getAgent(agent)}}/>
                        </Text>}
                    {newChatStatus===CHAT_STATUS.PENDING && !assignedToDepartment && assignedToAgent &&
                        <Text className={classnames(styles.systemMessageTextInner, {[styles.reverseText]:i18n.language===LANGUAGES_TYPES.HE})}>
                            <Trans i18nKey="toAssignedSystemMessage"
                                   components={{avatar:<img className={styles.systemMessageAvatar}
                                   src={this.getAvatar(assignedToAgent) || blankProfilePicture}/>}}
                                   values={{assignedTo:this.getAgent(assignedToAgent), agent: this.getAgent(agent)}}/>
                        </Text>}
                    {newChatStatus===CHAT_STATUS.PENDING && assignedToDepartment &&
                        <Text className={classnames(styles.systemMessageTextInner, {[styles.reverseText]:i18n.language===LANGUAGES_TYPES.HE})}>
                            <Trans i18nKey="toAssignedDepartmentSystemMessage"
                                   components={{color:<div style={{backgroundColor:this.getDepartment(assignedToDepartment).color || "white"}} className={styles.systemMessageDepartmentColor}/>}}
                                   values={{assignedTo: this.getDepartment(assignedToDepartment).name || this.getDepartment(assignedToDepartment), agent: this.getAgent(agent)}}/>
                        </Text>}
                    {newChatStatus===CHAT_STATUS.ASSIGNED &&
                        <Text className={classnames(styles.systemMessageTextInner, {[styles.reverseText]:i18n.language===LANGUAGES_TYPES.HE})}>
                            {assignedToAgent===agent ?
                                <Trans i18nKey="toSelfAssignedSystemMessage"
                                  components={{avatar:<img className={styles.systemMessageAvatar}
                                  src={this.getAvatar(assignedToAgent) || blankProfilePicture}/>}}
                                  values={{assignedTo:this.getAgent(assignedToAgent)}}/>
                                  :
                                <Trans i18nKey="toAssignedSystemMessage"
                                   components={{avatar:<img className={styles.systemMessageAvatar}
                                   src={this.getAvatar(assignedToAgent) || blankProfilePicture}/>}}
                                   values={{assignedTo:this.getAgent(assignedToAgent), agent: this.getAgent(agent)}}/>
                            }
                            </Text>}
                    {newChatStatus===CHAT_STATUS.RESOLVED &&
                        <Text className={classnames(styles.systemMessageTextInner, {[styles.reverseText]:i18n.language===LANGUAGES_TYPES.HE})}>
                            <Trans i18nKey="toResolvedSystemMessage"
                                   components={{avatar:<img className={styles.systemMessageAvatar} src={this.getAvatar(assignedToAgent) || blankProfilePicture}/>}}
                                   values={{agent: this.getAgent(agent)}}/>
                        </Text>}
                    {newChatStatus===CHAT_STATUS.BOT &&
                        <Text className={classnames(styles.systemMessageTextInner, {[styles.reverseText]:i18n.language===LANGUAGES_TYPES.HE})}>
                            <Trans i18nKey="toBotSystemMessage" values={{agent: this.getAgent(agent)}}/>
                        </Text>}
                    {newChatStatus===CHAT_STATUS.BULK &&
                        <Text className={classnames(styles.systemMessageTextInner, {[styles.reverseText]:i18n.language===LANGUAGES_TYPES.HE})}>
                            <Trans i18nKey="toBulkSystemMessage" values={{agent: this.getAgent(agent)}}/>
                        </Text>}
                </div>
                <p className={styles.systemMessageTime}>{time}</p>
            </div>
        );
    }
}

export default withTranslation()(
    withStore(
        observer(SystemMessage)
    )
);
