import React from 'react';
import { Modal } from 'antd';
import { Trans } from "react-i18next";
import classnames from "classnames";
import Icon from "@ant-design/icons";
import CommonButton from "../CommonButton";
import { ReactComponent as QuickRepliesCross } from "../../../../assets/messageStatuses/closeCross.svg";
import { getDirection } from '../../../../helpers';
import styles from "./CommonModal.module.scss";
const CommonModal = (props) => {
    const { visible, setModalVisible, modalAction, afterClose, children, titleTextKey, titleTextValues, error, modalActionText, height, width, disabledButton, centerContent, onCancel, destroyOnClose, headerIcon: HeaderIcon } = props;
    const onCancelHandler = () => {
        setModalVisible();
        if (onCancel)
            onCancel();
    };
    return (React.createElement(Modal, { width: width || 800, open: visible, closable: false, onCancel: onCancelHandler, afterClose: () => {
            if (afterClose)
                afterClose();
        }, footer: null, centered: true, bodyStyle: {
            padding: '0',
            boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)',
            borderRadius: '2px',
        }, destroyOnClose: destroyOnClose },
        React.createElement("div", { className: classnames(styles.simpleModalContainer, {
                [styles.rtl]: getDirection() === 'rtl',
            }), style: { height: height && `${height}vh` } },
            React.createElement("div", { className: styles.simpleModalHeader },
                React.createElement("div", { className: classnames(styles.simpleModalTitle, { [styles.simpleModalGreenUnderline]: !error, [styles.simpleModalRedUnderline]: error }) },
                    HeaderIcon && React.createElement(Icon, { className: styles.simpleModalTitleIcon, component: () => React.createElement(HeaderIcon, null) }),
                    React.createElement(Trans, { i18nKey: `${titleTextKey}`, values: titleTextValues })),
                React.createElement("div", { onClick: () => setModalVisible() },
                    React.createElement("div", { className: styles.closeIconWrapper },
                        React.createElement(Icon, { component: QuickRepliesCross })))),
            React.createElement("div", { className: classnames(styles.content, { [styles.centerContent]: centerContent }) }, children),
            React.createElement("div", { className: styles.simpleModalBottom },
                React.createElement(CommonButton, { action: onCancelHandler },
                    React.createElement(Trans, { i18nKey: "cancel" })),
                React.createElement(CommonButton, { action: () => modalAction(), alert: error, submit: !error, disabled: disabledButton },
                    React.createElement(Trans, { i18nKey: `${modalActionText}` }))))));
};
CommonModal.defaultProps = {
    centerContent: true,
};
export default CommonModal;
