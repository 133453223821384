var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { Button, Modal, TimePicker } from 'antd';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Trans } from 'react-i18next';
import classNames from 'classnames';
import CommonButton from '../../Chat/common/CommonButton';
import { useStore } from '../../../store/rootStore';
import { SLA_TIMEPICKER_FORMAT } from '../../../constants';
import { getDirection } from '../../../helpers';
import s from '../DashboardDrawer.module.scss';
const SLAModal = ({ visible, onCancel, updateSettings, toggleDashboardDrawer }) => {
    var _a, _b, _c, _d;
    const store = useStore();
    const history = useHistory();
    const parseSlaTime = (time) => {
        return time ? store.dashboard.parseSlaTimeFromMillisecondsToMoment(time) : null;
    };
    const slaTime = parseSlaTime((_b = (_a = store.dashboard.settingsData) === null || _a === void 0 ? void 0 : _a.sla) === null || _b === void 0 ? void 0 : _b.time);
    const [time, setTime] = useState(slaTime);
    useEffect(() => {
        var _a, _b;
        setTime(parseSlaTime((_b = (_a = store.dashboard.settingsData) === null || _a === void 0 ? void 0 : _a.sla) === null || _b === void 0 ? void 0 : _b.time));
    }, [(_d = (_c = store.dashboard.settingsData) === null || _c === void 0 ? void 0 : _c.sla) === null || _d === void 0 ? void 0 : _d.time]);
    const onTimeChange = (time) => {
        setTime(time);
    };
    const onDepartmentsManagerOpen = () => {
        history.push('/manage/departments');
        onCancel();
        toggleDashboardDrawer(false);
    };
    const onSave = () => __awaiter(void 0, void 0, void 0, function* () {
        yield updateSettings({
            sla: {
                time: time ? store.dashboard.parseSlaTimeFromMomentToMilliseconds(time) : 20 * 60 * 1000, // 20 min
            },
        });
        onCancel();
    });
    return (React.createElement(Modal, { open: visible, onCancel: onCancel, className: s['sla-modal__container'], width: 720, footer: null, closable: false },
        React.createElement("div", { className: s['sla-modal__wrapper'] },
            React.createElement("div", { className: classNames(s['sla-modal__header'], {
                    [s['sla-modal__header--rtl']]: getDirection() === 'rtl',
                }) },
                React.createElement("div", { className: s['sla-modal__header--title'] },
                    React.createElement(Trans, { i18nKey: "setSLATime" })),
                React.createElement("div", { className: classNames(s['sla-modal__greenBorder'], {
                        [s['sla-modal__greenBorder--rtl']]: getDirection() === 'rtl',
                    }) })),
            React.createElement("div", { className: classNames(s['sla-modal__body'], {
                    [s['sla-modal__body--rtl']]: getDirection() === 'rtl',
                }) },
                React.createElement("div", { className: s['sla-modal__section'] },
                    React.createElement("div", { className: s['sla-modal__title'] },
                        React.createElement(Trans, { i18nKey: "generalSLASettings" })),
                    React.createElement("div", null,
                        React.createElement(TimePicker, { value: time, onChange: onTimeChange, format: SLA_TIMEPICKER_FORMAT, className: s['sla-modal__timePicker'], popupStyle: { position: 'relative', width: 'fit-content' }, getPopupContainer: (trigger) => trigger.parentElement, direction: getDirection(), showSecond: false, showNow: false }),
                        React.createElement("div", { className: s['sla-modal__extraInfo'] },
                            React.createElement(Trans, { i18nKey: "defaultSLAValue" })))),
                React.createElement("div", { className: s['sla-modal__section'] },
                    React.createElement("div", { className: s['sla-modal__title'] },
                        React.createElement(Trans, { i18nKey: "SLASettingsPerDepartment" })),
                    React.createElement("div", null,
                        React.createElement(Button, { onClick: onDepartmentsManagerOpen, className: s['sla-modal__departmentsManagerButton'], type: "primary" },
                            React.createElement(Trans, { i18nKey: "openDepartmentsManager" }))))),
            React.createElement("div", { className: s['sla-modal__footer'] },
                React.createElement(CommonButton, { action: onCancel, additionalStyles: s['footer__buttons'] },
                    React.createElement(Trans, { i18nKey: "cancel" })),
                React.createElement(CommonButton, { action: onSave, loading: store.dashboard.SLALoading, disabled: store.dashboard.SLALoading, additionalStyles: s['footer__buttons'], submit: true },
                    React.createElement(Trans, { i18nKey: "save" }))))));
};
export default observer(SLAModal);
