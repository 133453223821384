import React from 'react';
import { Trans } from 'react-i18next';
import classnames from 'classnames';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { FcLock } from 'react-icons/fc';
import { useStore } from '../../../store/rootStore';
import { getDirection } from '../../../helpers';
import './SessionIsOverBlock.scss';
const SessionIsOverBlock = () => {
    const { activeChatStore } = useStore();
    const isRTL = getDirection() === 'rtl';
    return (React.createElement("div", { className: "session-over-container" },
        React.createElement("div", { className: "info-block-session" },
            React.createElement("div", { className: classnames('text-block', { rtl: isRTL }) },
                React.createElement(ExclamationCircleOutlined, { className: "info-icon" }),
                React.createElement("p", { className: "text" },
                    React.createElement(Trans, { i18nKey: activeChatStore.sessionIsOverMessage, components: { bold: React.createElement("strong", null), lock: React.createElement(FcLock, { className: "lock-icon" }) } }))))));
};
export default SessionIsOverBlock;
