import React, { Component } from 'react';
import { throttle } from 'lodash';
import ListPreloader from '../ListPreloader';
/**
 * Generic component to create lazy loading lists.
 * Optionally 2-directional (previous and next)
 * IMPORTANT: Component should have CSS styles to allow scroll-y and limit height!
 */
export default class LazyLoadList extends Component {
    constructor(props) {
        super(props);
        Object.defineProperty(this, "containerRef", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        /** Previous saved scroll value. DO NOT move to state - will cause performance problems!  */
        Object.defineProperty(this, "scrollTopPrev", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        /** Scrolling down or not */
        Object.defineProperty(this, "scrollDown", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        /** Previous container scroll height */
        Object.defineProperty(this, "heightPrev", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(this, "componentDidMount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const container = this.containerRef.current;
                if (!container)
                    return;
                // Set initial scroll value.
                // When there no scroll on element, it scroll height is equals to height,
                // which is a not right value, so set to zero in such case (as it is)
                this.heightPrev = container.scrollHeight > container.offsetHeight ? container.scrollHeight : 0;
                this.satisfyMinHeight();
                container.addEventListener('scroll', this.onScroll);
                // Check do we have proper styles and print warning if not
                if (window.getComputedStyle(container).overflowY === 'hidden') {
                    console.warn(`WARNING: LazyLoadList container styles should allow vertical scroll!`);
                }
            }
        });
        Object.defineProperty(this, "componentWillUnmount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a;
                (_a = this.containerRef.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('scroll', this.onScroll);
            }
        });
        Object.defineProperty(this, "componentDidUpdate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (prevProps) => {
                if (this.props.keepScroll) {
                    window.requestAnimationFrame(() => {
                        this.restoreScroll();
                    });
                }
                this.satisfyMinHeight();
            }
        });
        /** Restore scroll if container grown */
        Object.defineProperty(this, "restoreScroll", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const container = this.containerRef.current;
                if (!container)
                    return;
                const heightNow = container.scrollHeight > container.offsetHeight ? container.scrollHeight : 0;
                // Had no scroll on previous iteration
                if (!this.heightPrev) {
                    this.heightPrev = heightNow;
                    return;
                }
                if (heightNow > this.heightPrev) {
                    const diff = heightNow - this.heightPrev;
                    this.heightPrev = heightNow;
                    // No need to restore position when scroll down
                    if (!this.scrollDown) {
                        container.scroll({
                            top: container.scrollTop + diff,
                        });
                    }
                }
            }
        });
        /** Make element have scroll to be able detect loading need */
        Object.defineProperty(this, "satisfyMinHeight", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if (!this.containerRef.current)
                    return;
                if (!(this.containerRef.current.scrollHeight > this.containerRef.current.offsetHeight)) {
                    // Try to load next, and if not possible then try to load prev
                    if (this.props.canLoad) {
                        if (this.props.hasNext) {
                            this.props.onLoadNext();
                        }
                        else if (this.props.hasPrev && this.props.onLoadPrev) {
                            this.props.onLoadPrev();
                        }
                    }
                }
            }
        });
        Object.defineProperty(this, "shouldLoadPrev", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const container = this.containerRef.current;
                if (!container) {
                    return false;
                }
                // Have scrolable container (otherwise "scrollTop" always "0")
                return container.scrollHeight > container.offsetHeight
                    // Now scrolling top
                    && !this.scrollDown
                    // Scrolled to top less than trigger value
                    && container.scrollTop <= this.props.topTrigger
                    // Having what to load
                    && this.props.hasPrev
                    // Having handler
                    && this.props.onLoadPrev
                    // Currently not loading
                    && !this.props.loadingPrev
                    // Loading not blocked externally
                    && this.props.canLoad;
            }
        });
        Object.defineProperty(this, "shouldLoadNext", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const container = this.containerRef.current;
                if (!container) {
                    return false;
                }
                // Having scrolled to bottom closer than trigger value
                return container.offsetHeight + container.scrollTop + this.props.bottomTrigger >= container.scrollHeight
                    // Now scrolling down
                    && this.scrollDown
                    // Having what to load
                    && this.props.hasNext
                    // Currently not loading
                    && !this.props.loadingNext
                    // Loading not blocked externally
                    && this.props.canLoad;
            }
        });
        Object.defineProperty(this, "setScrollDirection", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const container = this.containerRef.current;
                if (container) {
                    this.scrollDown = container.scrollTop > this.scrollTopPrev;
                    this.scrollTopPrev = container.scrollTop;
                }
            }
        });
        Object.defineProperty(this, "onScroll", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: throttle(() => {
                window.requestAnimationFrame(() => {
                    this.setScrollDirection();
                    if (this.shouldLoadNext()) {
                        this.props.onLoadNext();
                    }
                    else if (this.shouldLoadPrev() && this.props.onLoadPrev) {
                        this.props.onLoadPrev();
                    }
                });
            }, 500, { trailing: true })
        });
        Object.defineProperty(this, "renderLoader", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (direction) => {
                if (typeof this.props.renderLoader === 'function') {
                    return this.props.renderLoader(direction);
                }
                return React.createElement(ListPreloader, null);
            }
        });
        this.containerRef = props.containerRef || React.createRef();
    }
    render() {
        const { loadingNext, loadingPrev, children, className, id } = this.props;
        return (React.createElement("div", { id: id, ref: this.containerRef, className: className },
            loadingPrev && this.renderLoader(LoadDirection.PREV),
            children,
            loadingNext && this.renderLoader(LoadDirection.NEXT)));
    }
}
Object.defineProperty(LazyLoadList, "defaultProps", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: {
        loadingPrev: false,
        hasPrev: false,
        topTrigger: 5,
        bottomTrigger: 30,
        canLoad: true,
        keepScroll: true,
    }
});
export var LoadDirection;
(function (LoadDirection) {
    LoadDirection["PREV"] = "PREV";
    LoadDirection["NEXT"] = "NEXT";
})(LoadDirection || (LoadDirection = {}));
