import { Filter } from 'interweave';
import imagePlaceholder from '../../../../assets/images/placeholders/image_placeholder_300.svg';
class ImageFilter extends Filter {
    attribute(name, value) {
        return value;
    }
    node(name, node) {
        if (['img', 'video'].includes(name)) {
            for (let i = node.attributes.length - 1; i >= 0; i--) {
                node.removeAttribute(node.attributes[i].name);
            }
            if (name === 'img') {
                node.setAttribute('src', imagePlaceholder);
            }
            if (name === 'video') {
                node.setAttribute('poster', imagePlaceholder);
            }
        }
        return node;
    }
}
export const imagefilter = new ImageFilter();
