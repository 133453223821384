import React, { Component } from 'react';
import classNames from 'classnames';
import { MESSAGE_STATUS } from '../../../../constants';
import SafeHTMLMarkdown from '../../../Base/Utils/SafeHTMLMarkdown';
import MessageMarkdown from '../../../Base/Utils/MessageMarkdown/MessageMarkdown';
import styles from './TextMessage.module.css';
export default class TextMessage extends Component {
    render() {
        const { text, status, invertColor, customColor, renderMessageAsHTML } = this.props;
        if (renderMessageAsHTML) {
            return (React.createElement(SafeHTMLMarkdown, { message: text, attributes: {
                    style: { color: customColor },
                    dir: 'auto',
                    className: classNames(styles.messageText, {
                        [styles.deletedText]: status === MESSAGE_STATUS.MESSAGE_DELETED,
                        [styles.whiteText]: invertColor,
                    }),
                } }));
        }
        return (React.createElement("p", { dir: "auto", className: classNames(styles.messageText, {
                [styles.deletedText]: status === MESSAGE_STATUS.MESSAGE_DELETED,
                [styles.whiteText]: invertColor,
            }), style: { color: customColor } },
            React.createElement(MessageMarkdown, { text: text })));
    }
}
