var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useRef, useState } from 'react';
import { useStore } from '../../../store/rootStore';
import { Button, message } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { isArray } from 'lodash';
import { has4ConsecutiveSpaces, hasNewLine, shadeColor } from '../../../helpers';
import classNames from 'classnames';
import { CloseOutlined, LoadingOutlined, SendOutlined, SmileOutlined } from '@ant-design/icons';
import TemplateInput from './TemplateInput';
import useCheckIfMobile from '../../../helpers/hooks/useCheckIfMobile';
import EmojiPalette from '../EmojiPalette';
import s from './DefaultTemplateReplyBar.module.scss';
const DefaultTemplateReplyBar = ({ value, onChange }) => {
    var _a, _b;
    const [hovered, setHovered] = useState(false);
    const [loading, setLoading] = useState(false);
    const [emojiPaletteOpen, setEmojiPaletteOpen] = useState(false);
    const [selectionStart, setSelectionStart] = useState(value.length);
    const [selectionEnd, setSelectionEnd] = useState(value.length);
    const { activeChatStore, templateMessagesStore } = useStore();
    const { t } = useTranslation();
    const inputRef = useRef(null);
    const isMobile = useCheckIfMobile();
    const { theme } = activeChatStore;
    const defaultTemplate = activeChatStore.defaultTemplate;
    const isRTL = (defaultTemplate === null || defaultTemplate === void 0 ? void 0 : defaultTemplate.language) === 'he';
    const [prefix, suffix] = ((_a = defaultTemplate === null || defaultTemplate === void 0 ? void 0 : defaultTemplate.text) === null || _a === void 0 ? void 0 : _a.split('{{1}}')) || [];
    const getBackgroundColor = () => {
        let backgroundColor = isArray(theme.color) ? [theme.color[0], theme.color[1]] : theme.color;
        if (hovered) {
            if (isArray(theme.color)) {
                backgroundColor[0] = shadeColor(theme.color[0], -25);
                backgroundColor[1] = shadeColor(theme.color[1], -25);
            }
            else {
                backgroundColor = shadeColor(theme.color, -25);
            }
        }
        const background = isArray(backgroundColor)
            ? `linear-gradient(270deg, ${backgroundColor[0]} 0%, ${backgroundColor[1]} 100%)`
            : backgroundColor;
        return background;
    };
    const onEmojiOpenHandle = (isOpen) => {
        setEmojiPaletteOpen(isOpen);
    };
    const handleAddEmoji = (emoji) => {
        const stringEnd = value.length;
        const newText = `${value.slice(0, selectionStart)}${emoji}${value.slice(selectionEnd, stringEnd)}`;
        onChange(newText);
    };
    const onEmojiSelect = (emojiObject) => {
        var _a, _b;
        handleAddEmoji(emojiObject.native);
        setSelectionStart(selectionStart + 2);
        setSelectionEnd(selectionEnd + 2);
        (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        (_b = inputRef.current) === null || _b === void 0 ? void 0 : _b.setSelectionRange(selectionStart + 2, selectionEnd + 2);
    };
    const onInputChange = (value, selectionStart = 0, selectionEnd = 0) => {
        setSelectionStart(selectionStart);
        setSelectionEnd(selectionEnd);
        onChange(value);
    };
    const isDisabled = () => loading || !value;
    const onEnter = (e) => {
        if (e.key === 'Enter') {
            /* Don't allow new line */
            e.preventDefault();
            if (isDisabled())
                return;
            onSendTemplate();
        }
    };
    const onSendTemplate = () => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        if (!defaultTemplate)
            return;
        let text = defaultTemplate.text;
        let parameter = value;
        text = text.replace('{{1}}', parameter);
        const hasExtraSpaceOrNewline = [parameter].some((param) => has4ConsecutiveSpaces(param) || hasNewLine(param));
        if (hasExtraSpaceOrNewline) {
            message.error(t('extraSpaceOrNewlineMessage'), 5);
            setLoading(false);
            return;
        }
        parameter = value || ' ';
        yield templateMessagesStore.sendTemplateMessage(defaultTemplate, text, [parameter])
            .then(() => {
            onChange('');
        })
            .catch((error) => {
            message.error(t('errorSendingTemplate', { values: { error: error.message } }), 5);
        })
            .finally(() => {
            setLoading(false);
        });
    });
    const emojiComponent = (React.createElement(React.Fragment, null,
        React.createElement("div", { className: s.emojiContainer, onClick: (e) => {
                e.stopPropagation();
                onEmojiOpenHandle(!emojiPaletteOpen);
            } }, !emojiPaletteOpen ? (React.createElement(SmileOutlined, { className: s.emojisSelectButton })) : (React.createElement(CloseOutlined, { className: s.emojisSelectButton }))),
        emojiPaletteOpen && (React.createElement(EmojiPalette, { onClickOutside: () => onEmojiOpenHandle(false), onEmojiSelectHandle: onEmojiSelect, className: s.emojiPalette }))));
    return (React.createElement("div", { className: classNames(s.wrapper, {
            [s.twoRows]: (!prefix && suffix) || (prefix && !suffix),
            [s.oneRow]: !prefix && !suffix,
            [s.rtl]: isRTL,
        }) },
        React.createElement("div", { className: s.content },
            isMobile ? emojiComponent : React.createElement("div", null),
            React.createElement("div", { className: s.contentContainer },
                React.createElement("div", { className: classNames(s.prefixContainer, {
                        [s.hide]: isMobile && !prefix,
                    }) },
                    !isMobile && emojiComponent,
                    prefix && (React.createElement("div", { className: classNames(s.extraInfo, s.preffix, {
                            [s.rtl]: isRTL,
                        }) },
                        React.createElement("div", null, prefix),
                        React.createElement("span", null)))),
                React.createElement(TemplateInput, { disabled: loading, textDirection: isRTL ? 'rtl' : 'ltr', value: value, onChange: onInputChange, placeholder: t('defaultTemplateInputPlaceholder', {
                        lng: defaultTemplate === null || defaultTemplate === void 0 ? void 0 : defaultTemplate.language,
                    }), onKeyDown: onEnter, forwardRef: inputRef }),
                suffix && (React.createElement("div", { className: classNames(s.extraInfo, s.suffix) },
                    React.createElement("div", null, suffix),
                    React.createElement("span", null)))),
            React.createElement(Button, { disabled: isDisabled(), onMouseEnter: () => setHovered(true), onMouseLeave: () => setHovered(false), className: classNames('big-green-button', s.sendButton, {
                    [s.invertColors]: theme.invertColors || ((_b = theme.additionalData) === null || _b === void 0 ? void 0 : _b.secondaryColor),
                }), onClick: onSendTemplate, style: { background: getBackgroundColor() } }, loading ? React.createElement(LoadingOutlined, null) : isMobile ? React.createElement(SendOutlined, null) : React.createElement(Trans, { i18nKey: "send" })))));
};
export default DefaultTemplateReplyBar;
