import React, { useRef } from 'react';
import { Button, Modal } from 'antd';
import { AiOutlineArrowUp } from 'react-icons/ai';
import turnOnNotificationsSrc from '../../assets/notifications/turnOnNotifications.png';
import turnOnNotificationsSrcHe from '../../assets/notifications/turnOnNotificationsHe.png';
import tuneSrc from '../../assets/notifications/tune.png';
import s from './NotificationSubscribeExample.module.scss';
import classNames from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { getDirection } from '../../helpers';
const NotificationSubscribeExample = ({ isOpen, setNotificationExampleOpen }) => {
    const messageRef = useRef(null);
    const exampleImgRef = useRef(null);
    const { t } = useTranslation();
    const isRTL = getDirection() === 'rtl';
    /* https://stackoverflow.com/a/71265754 */
    const browserLanguageIsHe = Intl.DateTimeFormat().resolvedOptions().locale === 'he';
    const onClickOutside = (e) => {
        var _a, _b;
        if (!((_a = messageRef.current) === null || _a === void 0 ? void 0 : _a.contains(e.target)) && !((_b = exampleImgRef.current) === null || _b === void 0 ? void 0 : _b.contains(e.target))) {
            setNotificationExampleOpen();
        }
    };
    return (React.createElement(Modal, { open: isOpen, closable: false, onCancel: setNotificationExampleOpen, footer: null, className: s.modalContainer, maskStyle: { backgroundColor: 'rgba(0, 0, 0, 0.75)' } },
        React.createElement("div", { className: s.container, onClick: onClickOutside },
            React.createElement("div", { className: classNames(s.centralMessageContainer, {
                    [s.rtl]: isRTL,
                    [s.right]: browserLanguageIsHe,
                }), ref: messageRef },
                React.createElement(AiOutlineArrowUp, { className: s.arrowIcon }),
                React.createElement("div", { className: s.messageContent },
                    React.createElement("span", { className: s.title },
                        React.createElement(Trans, { i18nKey: "howToEnableNotificationsTitle" })),
                    React.createElement("span", { className: s.subtitle },
                        React.createElement(Trans, { i18nKey: 'howToEnableNotificationsMessage', components: { icon: React.createElement("img", { src: tuneSrc, alt: "tune icon", className: s.lockIcon }) } })),
                    React.createElement(Button, { className: classNames('big', s.button), type: "primary", onClick: setNotificationExampleOpen },
                        React.createElement(Trans, { i18nKey: "ok" })))),
            React.createElement("div", { className: classNames(s.exampleNotificationImageContainer, { [s.rtl]: browserLanguageIsHe }), ref: exampleImgRef },
                React.createElement("img", { src: browserLanguageIsHe ? turnOnNotificationsSrcHe : turnOnNotificationsSrc, className: s.imageExample, alt: t('howToEnableNotifications') })))));
};
export default NotificationSubscribeExample;
