import React, { Component, memo } from "react";
import { observer } from "mobx-react";
import classnames from "classnames";
import { isButtonsMessage, isContactsMessage, isListMessage, isLocationMessage, isMediaMessage, isPostbackMessage, isRegularMessage, isTextMessage, MessagingChannelFeature, CHAT_STATUS, isSpecialMessage } from "@whatsper/texterchat-common";
import { isArray, isEqual } from "lodash";
import { MEDIA_TYPE, MESSAGE_DIRECTION, MESSAGE_STATUS, TIME_FORMAT } from "../../../../constants";
import doubleCheckWhite from "../../../../assets/messageStatuses/doubleCheckWhite.svg";
import doubleCheckGrey from "../../../../assets/messageStatuses/doubleCheckGrey.svg";
import singleCheck from "../../../../assets/messageStatuses/SingleWhiteCheck.svg";
import clock from "../../../../assets/messageStatuses/ClockWhiteStatus.svg";
import crossRed from "../../../../assets/messageStatuses/Cross red.png";
import cross from "../../../../assets/messageStatuses/Cross.png";
import DocumentMessage from "../DocumentMessage";
import ImageMessage from "../ImageMessage";
import TextMessage from "../TextMessage";
import DeletedMessage from "../DeletedMessage";
import AudioMessage from "../AudioMessage";
import RichMessage from "../RichMessage";
import VideoMessage from "../VideoMessage";
import LocationMessage from "../LocationMessage";
import SystemMessage from "../SystemMessage";
import MessagePostback from "../MessagePostback";
import MessageContextMenu from "./MessageContextMenu";
import MessageErrorIndicator from "./MessageErrorIndicator";
import ContactMessage from "../ContactMessage";
import StickerMessage from "../StickerMessage";
import ButtonsMessage from "../ButtonsMessage";
import ListMessage from "../ListMessage";
import AdMessage from "../AdMessage";
import SpecialMessage from "../SpecialMessage";
import UserStatusMessage, { isUserStatusMessage } from "../channelSpecific/website_chat/UserStatusMessage";
import { withStore } from "../../../../store/rootStore";
import { isAdMessage, isSystemMessage } from "../../../../helpers/functions";
import ReactionMessage from "../ReactionMessage";
import SendMessageReaction from "./SendMessageReaction";
import { BsChatRightTextFill } from "react-icons/bs";
import { Trans } from "react-i18next";
import styles from "./Message.module.scss";
class Message extends Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                hovered: false,
            }
        });
        Object.defineProperty(this, "getMessageStatus", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (status) => {
                switch (status) {
                    case MESSAGE_STATUS.CHANNEL_FAILED:
                        return React.createElement("img", { className: styles.messageStatusIcon, src: crossRed });
                    case MESSAGE_STATUS.MESSAGE_ACCEPTED:
                        return React.createElement("img", { className: classnames(styles.messageStatusIcon, styles.singleTick), src: singleCheck });
                    case MESSAGE_STATUS.MESSAGE_DELETED:
                        return null;
                    case MESSAGE_STATUS.MESSAGE_DELIEVERED:
                        return React.createElement("img", { className: styles.messageStatusIcon, src: doubleCheckGrey });
                    case MESSAGE_STATUS.MESSAGE_FAILED:
                        return React.createElement("img", { className: styles.messageStatusIcon, src: cross });
                    case MESSAGE_STATUS.MESSAGE_SEEN:
                        return React.createElement("img", { className: styles.messageStatusIcon, src: doubleCheckWhite });
                    case MESSAGE_STATUS.MESSAGE_SENT:
                        return React.createElement("img", { className: styles.messageStatusIcon, src: clock });
                    default:
                        return null;
                }
            }
        });
        /**
         *
         * @param unix_timestamp Timestamp in **Milliseconds**
         */
        Object.defineProperty(this, "getTime", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (unix_timestamp) => {
                const timeFormat = this.props.store.timeFormat;
                let date = new Date(unix_timestamp);
                let hours = date.getHours();
                let minutes = "0" + date.getMinutes();
                let formattedTime = hours + ":" + minutes.substr(-2);
                if (timeFormat === TIME_FORMAT.TWELVE_HOURS) {
                    if (hours > 12) {
                        let leadingZero = "0";
                        if (hours - 12 >= 10) {
                            leadingZero = "";
                        }
                        formattedTime = leadingZero + (hours - 12) + ":" + minutes.substr(-2) + " PM";
                    }
                    else if (hours === 12 && parseInt(minutes) > 0) {
                        formattedTime += " PM";
                    }
                    else if (hours < 12) {
                        let leadingZero = "0";
                        if (hours >= 10) {
                            leadingZero = "";
                        }
                        formattedTime = leadingZero + hours + ":" + minutes.substr(-2) + " AM";
                    }
                    else
                        formattedTime += " AM";
                }
                return formattedTime;
            }
        });
        Object.defineProperty(this, "getChatBubbleTitleColor", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a, _b;
                const { theme } = this.props.store.activeChatStore;
                if ((_a = theme.additionalData) === null || _a === void 0 ? void 0 : _a.chatBubbleTitleColor) {
                    return (_b = theme.additionalData) === null || _b === void 0 ? void 0 : _b.chatBubbleTitleColor;
                }
                if (isArray(theme.color)) {
                    return theme.color[1];
                }
                return theme.color;
            }
        });
        Object.defineProperty(this, "getBackground", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a, _b;
                const { theme } = this.props.store.activeChatStore;
                if ((_a = theme.additionalData) === null || _a === void 0 ? void 0 : _a.tertiaryColor) {
                    return (_b = theme.additionalData) === null || _b === void 0 ? void 0 : _b.tertiaryColor;
                }
                if (isArray(theme.color)) {
                    return `linear-gradient(270deg, ${theme.color[0]} 0%, ${theme.color[1]} 100%)`;
                }
                return theme.color;
            }
        });
        Object.defineProperty(this, "allowReactionInteraction", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a;
                const { activeChatStore, loginStore, channels } = this.props.store;
                const { sessionIsOver, chat } = activeChatStore;
                const { userDetails } = loginStore;
                const { hasFeatures } = channels;
                const showSendReaction = hasFeatures([MessagingChannelFeature.messageReactionSend]) &&
                    !sessionIsOver &&
                    chat &&
                    !((_a = chat.blockedChat) === null || _a === void 0 ? void 0 : _a.blocked) && (chat.status === CHAT_STATUS.ASSIGNED && (chat.agent && (userDetails === null || userDetails === void 0 ? void 0 : userDetails._id) === chat.agent.uid));
                return showSendReaction;
            }
        });
    }
    render() {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u;
        const { message, name, previousMessage, scrollToMessage, handleForwardMessage, isHighlighted, isReactionLoading, store, onSendMessageReaction, onRemoveMessageReaction, } = this.props;
        const { theme, indexedImageMessages, renderMessagesAsHTML } = store.activeChatStore;
        const background = this.getBackground();
        return (React.createElement("div", { className: styles.chatMessageWrapper },
            isRegularMessage(message) && message.direction === MESSAGE_DIRECTION.IN && (React.createElement("div", { className: classnames(styles.messageContainer, {
                    [styles.alignStart]: message.direction === MESSAGE_DIRECTION.IN,
                    [styles.highlighted]: isHighlighted,
                }) },
                React.createElement("div", { className: classnames(styles.messageBox, {
                        [styles.backgroundLight]: message.direction === MESSAGE_DIRECTION.IN,
                    }), onMouseEnter: () => this.setState({ hovered: true }), onMouseLeave: () => this.setState({ hovered: false }) },
                    this.allowReactionInteraction() && (React.createElement(SendMessageReaction, { onSendMessageReaction: onSendMessageReaction, incoming: true })),
                    isAdMessage(message) && (React.createElement("div", { className: styles.adMessageInfoBox },
                        React.createElement(BsChatRightTextFill, null),
                        React.createElement(Trans, { i18nKey: "messageSentViaAd" }))),
                    (message.direction === MESSAGE_DIRECTION.IN && (previousMessage === null || previousMessage === void 0 ? void 0 : previousMessage.direction) !== MESSAGE_DIRECTION.IN) ||
                        !previousMessage ? (React.createElement("p", { className: styles.nameText, style: { color: this.getChatBubbleTitleColor() } }, name)) : (''),
                    !!message.context && (React.createElement(MessagePostback, { onClick: () => { var _a; return ((_a = message.context) === null || _a === void 0 ? void 0 : _a._id) && scrollToMessage(message.context._id); }, incoming: true, context: message.context, name: name })),
                    isMediaMessage(message)
                        && message.media.map((media, i) => {
                            var _a, _b;
                            if ([MEDIA_TYPE.AUDIO].includes(media.mediaType)) {
                                return (React.createElement(AudioMessage, { message: message, key: media.fileId || i }));
                            }
                            if ([MEDIA_TYPE.DOC].includes(media.mediaType)) {
                                return (React.createElement(DocumentMessage, { message: message, shareFile: store.files.shareFile, showCaption: message.media.length - 1 === i, customColor: (_a = theme.additionalData) === null || _a === void 0 ? void 0 : _a.chatBubbleTextColor, key: media.fileId, renderMessageAsHTML: renderMessagesAsHTML }));
                            }
                            if (media.mediaType === MEDIA_TYPE.IMAGE) {
                                return (React.createElement(ImageMessage, { message: message, media: media, imageMessages: indexedImageMessages, customColor: (_b = theme.additionalData) === null || _b === void 0 ? void 0 : _b.chatBubbleTextColor, showCaption: message.media.length - 1 === i, key: `${i}-${media.fileId}`, renderMessageAsHTML: renderMessagesAsHTML }));
                            }
                            if (media.mediaType === MEDIA_TYPE.VIDEO) {
                                return (React.createElement(VideoMessage, { message: message, shareFile: store.files.shareFile, showCaption: message.media.length - 1 === i, key: media.fileId || i, renderMessageAsHTML: renderMessagesAsHTML }));
                            }
                            if (media.mediaType === MEDIA_TYPE.STICKER) {
                                return React.createElement(StickerMessage, { message: message, key: media.fileId || i });
                            }
                        }),
                    isListMessage(message) && (React.createElement(ListMessage, { list: message.list, text: message.text, status: message.status })),
                    isAdMessage(message) && (React.createElement(AdMessage, { referral: ((_a = message.special) === null || _a === void 0 ? void 0 : _a.facebook).referral, shareFile: store.files.shareFile })),
                    isSpecialMessage(message) && (React.createElement(SpecialMessage, { message: message, customColor: (_b = theme.additionalData) === null || _b === void 0 ? void 0 : _b.chatBubbleTextColor, background: background })),
                    isTextMessage(message) && (React.createElement(TextMessage, { text: message.text, status: message.status, renderMessageAsHTML: renderMessagesAsHTML })),
                    isPostbackMessage(message) &&
                        React.createElement(TextMessage, { text: message.postback.title || message.postback.payload, status: message.status, renderMessageAsHTML: renderMessagesAsHTML }),
                    isLocationMessage(message) && message.location ? (React.createElement(LocationMessage, { longitude: message.location.longitude, latitude: message.location.latitude })) : null,
                    isContactsMessage(message) && message.contacts ? (React.createElement(ContactMessage, { contacts: message.contacts, invertColor: ((_c = theme.additionalData) === null || _c === void 0 ? void 0 : _c.invertChatColor) || theme.invertColors, incoming: true })) : null,
                    isUserStatusMessage(message) ? (React.createElement(UserStatusMessage, { message: message, color: this.getChatBubbleTitleColor() })) : null,
                    message.status === MESSAGE_STATUS.MESSAGE_DELETED ? React.createElement(DeletedMessage, null) : null,
                    React.createElement("p", { className: styles.timestamp }, this.getTime(message.timestamp || message.received)),
                    React.createElement(MessageContextMenu, { hovered: this.state.hovered, incoming: message.direction === MESSAGE_DIRECTION.IN, messageId: message._id, message: message, handleForwardMessage: handleForwardMessage, background: background })),
                message.reactions && (React.createElement(ReactionMessage, { reactions: message.reactions, history: message.reactionsHistory, direction: message.direction, loading: isReactionLoading, onRemoveMessageReaction: this.allowReactionInteraction() ? onRemoveMessageReaction : undefined })))),
            isRegularMessage(message) &&
                message.direction === MESSAGE_DIRECTION.OUT
                && !message.systemMessage
                && (React.createElement("div", { className: classnames(styles.messageContainer, styles.alignEnd, {
                        [styles.highlighted]: isHighlighted,
                    }) },
                    ((_e = (_d = message.metadata) === null || _d === void 0 ? void 0 : _d.templateMessageData) === null || _e === void 0 ? void 0 : _e.id) ? (React.createElement(RichMessage, { timestamp: this.getTime(message.timestamp || message.received), status: this.getMessageStatus(message.status), templateId: message.metadata.templateMessageData.id, parameters: message.metadata.templateParameters, templateMessageData: message.metadata.templateMessageData, errorReason: message.errorReason, background: background, customColor: (_f = theme.additionalData) === null || _f === void 0 ? void 0 : _f.chatBubbleTextColor, timestampUserWhite: !((_g = theme.additionalData) === null || _g === void 0 ? void 0 : _g.invertTimestampColor) })) : isButtonsMessage(message) ? (React.createElement(ButtonsMessage, { buttons: message.buttons, text: message.text, timestamp: this.getTime(message.timestamp || message.received), status: this.getMessageStatus(message.status), background: background, customColor: (_h = theme.additionalData) === null || _h === void 0 ? void 0 : _h.chatBubbleTextColor, invertColor: ((_j = theme.additionalData) === null || _j === void 0 ? void 0 : _j.invertChatColor) || theme.invertColors, timestampUserWhite: !((_k = theme.additionalData) === null || _k === void 0 ? void 0 : _k.invertTimestampColor) })) : (React.createElement("div", { className: classnames({
                            [styles.messageBox]: true,
                            [styles.error]: message.errorReason,
                        }), style: { background }, onMouseEnter: () => this.setState({ hovered: true }), onMouseLeave: () => this.setState({ hovered: false }) },
                        this.allowReactionInteraction() && (React.createElement(SendMessageReaction, { onSendMessageReaction: onSendMessageReaction })),
                        !!message.context && (React.createElement(MessagePostback, { onClick: () => message.context && scrollToMessage(message.context._id), incoming: false, context: message.context, name: name, renderMessageAsHTML: renderMessagesAsHTML })),
                        isMediaMessage(message) && message.media.map((media, i) => {
                            var _a, _b;
                            if ([MEDIA_TYPE.AUDIO].includes(media.mediaType)) {
                                return (React.createElement(AudioMessage, { message: message, showCaption: message.media.length - 1 === i, key: media.fileId || i }));
                            }
                            if ([MEDIA_TYPE.DOC].includes(media.mediaType) &&
                                (media.url || media.filename)) {
                                return (React.createElement(DocumentMessage, { message: message, shareFile: store.files.shareFile, showCaption: message.media.length - 1 === i, customColor: (_a = theme.additionalData) === null || _a === void 0 ? void 0 : _a.chatBubbleTextColor, key: media.fileId || i }));
                            }
                            if (media.mediaType === MEDIA_TYPE.IMAGE) {
                                return (React.createElement(ImageMessage, { message: message, media: media, imageMessages: indexedImageMessages, customColor: (_b = theme.additionalData) === null || _b === void 0 ? void 0 : _b.chatBubbleTextColor, showCaption: message.media.length - 1 === i, key: `${i}-${media.fileId}` }));
                            }
                            if (media.mediaType === MEDIA_TYPE.VIDEO) {
                                return (React.createElement(VideoMessage, { message: message, shareFile: store.files.shareFile, showCaption: message.media.length - 1 === i, key: media.fileId || i }));
                            }
                            if (media.mediaType === MEDIA_TYPE.STICKER) {
                                return React.createElement(StickerMessage, { message: message, key: media.fileId || i });
                            }
                        }),
                        isLocationMessage(message) && message.location ? (React.createElement(LocationMessage, { longitude: message.location.longitude, latitude: message.location.latitude })) : null,
                        isContactsMessage(message) && message.contacts ? (React.createElement(ContactMessage, { contacts: message.contacts, invertColor: ((_l = theme.additionalData) === null || _l === void 0 ? void 0 : _l.invertChatColor) || theme.invertColors })) : null,
                        isListMessage(message) && (React.createElement(ListMessage, { list: message.list, text: message.text, status: message.status, invertColor: ((_m = theme.additionalData) === null || _m === void 0 ? void 0 : _m.invertChatColor) || theme.invertColors, customColor: (_o = theme.additionalData) === null || _o === void 0 ? void 0 : _o.chatBubbleTextColor })),
                        isSpecialMessage(message) && (React.createElement(SpecialMessage, { message: message, invertColor: ((_p = theme.additionalData) === null || _p === void 0 ? void 0 : _p.invertChatColor) || theme.invertColors, customColor: (_q = theme.additionalData) === null || _q === void 0 ? void 0 : _q.chatBubbleTextColor, background: background, timestamp: this.getTime(message.timestamp || message.received), timestampUserWhite: !((_r = theme.additionalData) === null || _r === void 0 ? void 0 : _r.invertTimestampColor), status: this.getMessageStatus(message.status) })),
                        isTextMessage(message) && (React.createElement(TextMessage, { invertColor: ((_s = theme.additionalData) === null || _s === void 0 ? void 0 : _s.invertChatColor) || theme.invertColors, customColor: (_t = theme.additionalData) === null || _t === void 0 ? void 0 : _t.chatBubbleTextColor, text: message.text, status: message.status })),
                        isPostbackMessage(message) &&
                            React.createElement(TextMessage, { text: message.postback.title || message.postback.payload, status: message.status }),
                        message.status === MESSAGE_STATUS.MESSAGE_DELETED ? React.createElement(DeletedMessage, null) : null,
                        !isSpecialMessage(message) && (React.createElement("div", { className: styles.receiver },
                            React.createElement("p", { className: classnames({
                                    [styles.timestampUser]: true,
                                    [styles.timestampUserWhite]: !((_u = theme.additionalData) === null || _u === void 0 ? void 0 : _u.invertTimestampColor),
                                }) }, this.getTime(message.timestamp || message.received)),
                            this.getMessageStatus(message.status))),
                        React.createElement(MessageContextMenu, { hovered: this.state.hovered, incoming: false, messageId: message._id, message: message, handleForwardMessage: handleForwardMessage, background: background }))),
                    message.errorReason && React.createElement(MessageErrorIndicator, { message: message.errorReason }),
                    message.reactions && (React.createElement(ReactionMessage, { reactions: message.reactions, history: message.reactionsHistory, direction: message.direction, loading: isReactionLoading })))),
            isSystemMessage(message) && (React.createElement(SystemMessage, { messageId: message._id, newChatStatus: message.newChatStatus, agent: message.agent, assignedToAgent: message.assignedToAgent, assignedToDepartment: message.assignedToDepartment, time: this.getTime(message.timestamp || message.received || 0) }))));
    }
}
export default memo(withStore(observer(Message)), (prevProps, nextProps) => {
    const propsToTrack = ['message', 'previousMessage', 'name', 'activeChat', 'isHighlighted', 'isReactionLoading'];
    const prevPropsToTrack = propsToTrack.map((prop) => prevProps[prop]);
    const nextPropsToTrack = propsToTrack.map((prop) => nextProps[prop]);
    if (!isEqual(prevPropsToTrack, nextPropsToTrack))
        return false;
    return true;
});
