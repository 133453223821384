import { isSpecialMessage } from '@whatsper/texterchat-common';
import React from 'react';
import classNames from 'classnames';
import WhatsAppMessage from './messages/WhatsAppMessage';
import s from './SpecialMessage.module.scss';
const SpecialMessage = ({ message, invertColor, customColor, background, timestamp, timestampUserWhite, status, }) => {
    if (isSpecialMessage(message)) {
        return (React.createElement("div", { dir: "auto", className: classNames(s.specialContainer, {
                [s.inverted]: invertColor,
            }) }, message.special.whatsapp && (React.createElement(WhatsAppMessage, { message: message.special.whatsapp, text: message.text, customColor: customColor, background: background, timestamp: timestamp, timestampUserWhite: timestampUserWhite, status: status }))));
    }
    return null;
};
export default SpecialMessage;
