import React from "react";
import { Trans } from "react-i18next";
import classnames from "classnames";
import s from "./PrettyDateBox.module.scss";
const PrettyDateBox = ({ messages, index, fixedDate }) => {
    const getPrettyDate = (messages, key) => {
        if (key === 0) {
            const messageDate = new Date(messages[key].received);
            return messageDate.toLocaleDateString();
        }
        else {
            const previousDate = new Date(messages[key - 1].received);
            const pastDate = new Date(messages[key].received);
            const currentDate = new Date();
            const diffTime = Math.abs(currentDate.getTime() - previousDate.getTime());
            const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
            if (diffDays < 7) {
                const weekDays = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
                if (pastDate.getDay() === currentDate.getDay()) {
                    return React.createElement(Trans, { i18nKey: "Today" });
                }
                else
                    return React.createElement(Trans, { i18nKey: `${weekDays[pastDate.getDay()]}` });
            }
            else {
                return pastDate.toLocaleDateString();
            }
        }
    };
    return (React.createElement("div", { className: classnames(s.timestampBoxContainer, "date-label") },
        React.createElement("div", { className: s.timestampBox },
            React.createElement("p", { className: s.timeDiffText }, fixedDate || getPrettyDate(messages, index)))));
};
export default PrettyDateBox;
