import React, {Component} from 'react';
import { getFileUrl } from '../../../../store/FilesStore';

import "./AudioMessage.css"

class AudioMessage extends Component {

    clickHandler = () => {
        this.audioRef.blur()
        let sounds = document.getElementsByTagName('audio');
        for(let i=0; i<sounds.length; i++) {
            if (sounds[i]!==this.audioRef){
                sounds[i].pause();
            }
        }
    }

    render() {
        return (
            <div className="audio-container">
                <audio
                    className="audio-controller"
                    src={this.getUrl()}
                    controls
                    controlsList="nodownload"
                    ref={(audioRef) => { this.audioRef = audioRef; }}
                    onPlay={()=>this.clickHandler()}
                />
            </div>
        );
    }

    getUrl = () => {
        const { media: mediaArray } = this.props.message;
        const media = mediaArray[0];

        if (media.fileId) {
            return getFileUrl(media.fileId);
        } else if (media.url) {
            return media.url;
        } else {
            console.error('Message have no file ID or URL');
        }
    }
}

export default AudioMessage;
