var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import { TEMPLATE_HEADER_TYPE } from '@whatsper/texterchat-common';
import { Button, Tooltip, Typography, message } from 'antd';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { getDirection } from '../../../../helpers';
import { has4ConsecutiveSpaces, hasNewLine } from '../../../../helpers/string';
import { useStore } from '../../../../store/rootStore';
import ErrorBoundary from '../../../ErrorBoundary';
import FullMessagePreview from '../../../WizardModal/WizardBody/FullMessagePreview/FullMessagePreview';
import AdvancedSettings from '../TemplateGenerator/AddOrEditTemplate/AdvancedSettings';
import FileUploadButton from '../TemplateGenerator/AddOrEditTemplate/FileUploadButton';
import GeneratorInput from '../TemplateGenerator/GeneratorInput';
import { getHeaderTypeSpecificMessages } from '../../../WizardModal/helpers';
import { updateLastSent } from '../../../Templates/TemplateListFilterable/helpers';
import './EditTemplateMessage.scss';
const { Text } = Typography;
const EditTemplateMessage = ({ hideModal }) => {
    const { templateMessagesStore, activeChatStore } = useStore();
    const [loading, setLoading] = useState(false);
    const rootRef = useRef(null);
    const { t } = useTranslation();
    const { richTemplateHeader, richTemplateHeaderType, richTemplateHeaderUrl, bodyExampleTemporaryParams, nextPlaceholderNumber, changeTempExampleParam, selectedAccountId, } = templateMessagesStore;
    const hasFile = richTemplateHeaderType && [TEMPLATE_HEADER_TYPE.IMAGE, TEMPLATE_HEADER_TYPE.VIDEO, TEMPLATE_HEADER_TYPE.DOCUMENT].includes(richTemplateHeaderType);
    const disabled = !!(hasFile && !richTemplateHeader && !richTemplateHeaderUrl);
    const { recommndedSizeMessage, requiredMessage, accepts, buttonText, } = getHeaderTypeSpecificMessages(richTemplateHeaderType, t);
    const onKeyPress = (event) => {
        if (event.key === 'Enter') {
            if (event.ctrlKey) {
                sendTemplate();
            }
        }
    };
    useEffect(() => {
        var _a;
        (_a = rootRef === null || rootRef === void 0 ? void 0 : rootRef.current) === null || _a === void 0 ? void 0 : _a.addEventListener('keydown', onKeyPress);
        return () => {
            var _a;
            (_a = rootRef === null || rootRef === void 0 ? void 0 : rootRef.current) === null || _a === void 0 ? void 0 : _a.removeEventListener('keydown', onKeyPress);
        };
    }, [rootRef]);
    function getActiveChatTitle() {
        var _a;
        return ((_a = activeChatStore.chat) === null || _a === void 0 ? void 0 : _a.title) || '';
    }
    function sendTemplate() {
        return __awaiter(this, void 0, void 0, function* () {
            setLoading(true);
            if (!templateMessagesStore.currentTemplate || !selectedAccountId) {
                message.error(t('templateSendError'), 5);
                setLoading(false);
                return;
            }
            ;
            const templateName = templateMessagesStore.currentTemplate.name;
            const accountId = selectedAccountId.id;
            const parametersFromText = templateMessagesStore.templateMessage.match(/{{(.*?)}}/g);
            let text = templateMessagesStore.templateMessage;
            let parameters = [...templateMessagesStore.bodyExampleTemporaryParams];
            if (parametersFromText === null || parametersFromText === void 0 ? void 0 : parametersFromText.length) {
                parametersFromText.forEach((word, i) => {
                    const parameter = parameters[i] || '';
                    text = text.replace(word, parameter);
                    parameters[i] = parameter;
                });
            }
            const hasExtraSpaceOrNewline = parameters.some((param) => has4ConsecutiveSpaces(param) || hasNewLine(param));
            if (hasExtraSpaceOrNewline) {
                message.error(t('extraSpaceOrNewlineMessage'), 5);
                setLoading(false);
                return;
            }
            parameters = parameters.map((param) => (!param ? ' ' : param));
            yield templateMessagesStore.sendTemplateMessage(templateMessagesStore.currentTemplate, text, parameters)
                .then(() => {
                updateLastSent(templateName, accountId);
                hideModal();
            })
                .catch((error) => {
                message.error(t('errorSendingTemplate', { values: { error: error.message } }), 5);
            })
                .finally(() => {
                setLoading(false);
            });
        });
    }
    return (React.createElement("div", { className: "edit-template-message", ref: rootRef },
        React.createElement("div", { className: "edit-template-message__header" },
            React.createElement(ChatOutlinedIcon, { className: "edit-template-message__icon" }),
            React.createElement(Trans, { i18nKey: "templateMessageNumber", values: { number: templateMessagesStore.templateTitle } })),
        React.createElement("div", { className: "edit-template-message__container" },
            React.createElement("div", { className: classNames('edit-template-content-left', {
                    isRtl: getDirection() === 'rtl',
                }) },
                React.createElement("div", { className: "edit-template-message__info" },
                    React.createElement(Trans, { i18nKey: "sendTemplate", values: { number: templateMessagesStore.templateTitle, phoneNumber: getActiveChatTitle() } })),
                hasFile && (React.createElement("div", { className: "upload-block" },
                    React.createElement(FileUploadButton, { blockField: false, handleSetHeader: templateMessagesStore.handleSetHeader, richTemplateHeader: templateMessagesStore.richTemplateHeader, className: "edit-template-message-upload-button", accepts: accepts, buttonText: buttonText }),
                    React.createElement("div", { className: "button-help-text" },
                        React.createElement(ExclamationCircleOutlined, { className: "info-circle-icon" }),
                        React.createElement("p", { className: "info-text" },
                            React.createElement(Trans, { i18nKey: recommndedSizeMessage }))))),
                bodyExampleTemporaryParams &&
                    [...Array(nextPlaceholderNumber - 1)].map((e, index) => (React.createElement("div", { key: index },
                        React.createElement(GeneratorInput, { text: bodyExampleTemporaryParams[index] || '', onChange: (value) => changeTempExampleParam(index, value), placeholder: "enterParam", placeholderParams: { number: index + 1 }, additionalStyles: "param-input", options: { maxLength: 1024, showCount: true }, disabled: disabled })))),
                React.createElement(Text, { className: "edit-template-message__advanced_settings_title" },
                    React.createElement(Trans, { i18nKey: "autoReplySettings" })),
                React.createElement("div", { className: "edit-template-message__advanced_settings" },
                    React.createElement(AdvancedSettings, { canAttachFile: true }))),
            React.createElement("div", { className: "edit-template-content-right" },
                React.createElement(ErrorBoundary, null,
                    React.createElement(FullMessagePreview, { type: "template", params: bodyExampleTemporaryParams })))),
        React.createElement("div", { className: "edit-template-message__footer" },
            React.createElement(Button, { type: "default", className: "big-button resolve-button white-button edit-template-message__button", onClick: hideModal },
                React.createElement(Trans, { i18nKey: "cancel" })),
            React.createElement(Tooltip, { title: disabled ? requiredMessage : '', placement: "top" },
                React.createElement(Button, { type: "primary", className: "big-button resolve-button edit-template-message__button", disabled: loading || disabled, onClick: () => {
                        sendTemplate();
                    } }, !loading ? React.createElement(Trans, { i18nKey: "send" }) : React.createElement(LoadingOutlined, null))))));
};
export default observer(EditTemplateMessage);
