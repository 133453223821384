import React, { useState, useRef, useEffect } from 'react';
import { observer } from 'mobx-react';
import { Typography, Radio } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { pick } from 'lodash';
import CommonButton from '../../common/CommonButton';
import ResolveChatsModal from '../ResolveChatsModal';
import { CHAT_STATUS, RESOLVE_HOURS } from '../../../../constants';
import { CHATS_GROUP } from '../../../../store/ChatsStore';
import { useStore } from '../../../../store/rootStore';
import { MyInput } from '../../../Base/Form';
import styles from './ResolveChatsMenu.module.scss';
const { Text } = Typography;
const ResolveChatsMenu = ({ visible, onHide, containerRef }) => {
    const { t } = useTranslation();
    const store = useStore();
    const [openModal, setOpenModal] = useState(false);
    const [hours, setHours] = useState();
    const [customHours, setCustomHours] = useState(RESOLVE_HOURS.CUSTOM);
    const menuRef = useRef(null);
    const { chatsStore } = store;
    useEffect(() => {
        if (visible) {
            document.addEventListener('click', closeMenuCheck);
            return () => {
                document.removeEventListener('click', closeMenuCheck);
            };
        }
    }, [visible]);
    useEffect(() => {
        if (chatsStore.currentGroup === CHATS_GROUP.PENDING) {
            setHours(RESOLVE_HOURS[36]);
        }
        if (chatsStore.currentGroup === CHATS_GROUP.ASSIGNED) {
            setHours(RESOLVE_HOURS.CUSTOM);
            setCustomHours(48);
        }
    }, [chatsStore.currentGroup]);
    const closeMenuCheck = (e) => {
        var _a;
        if (visible &&
            menuRef.current &&
            !menuRef.current.contains(e.target) &&
            !((_a = containerRef.current) === null || _a === void 0 ? void 0 : _a.contains(e.target))) {
            onHide();
        }
    };
    const getStatus = () => {
        const { currentGroup } = chatsStore;
        switch (currentGroup) {
            case CHATS_GROUP.PENDING:
                return CHAT_STATUS.PENDING;
            case CHATS_GROUP.ASSIGNED:
                return CHAT_STATUS.ASSIGNED;
        }
    };
    const dropdownHoursOptions = chatsStore.currentGroup === CHATS_GROUP.PENDING
        ? pick(RESOLVE_HOURS, ['24', '36', '48', '72'])
        : pick(RESOLVE_HOURS, ['12', '24', '36', '72']);
    return (React.createElement("div", { className: styles.wrapper },
        visible && (React.createElement("div", { ref: menuRef, className: styles.menu },
            React.createElement("div", { className: styles.title },
                React.createElement(Trans, { i18nKey: 'resolveAllOlderThan', values: {
                        status: chatsStore.currentGroup === CHATS_GROUP.PENDING ? t('pending') : t('taken'),
                    } })),
            React.createElement(Radio.Group, { onChange: (e) => setHours(e.target.value), value: hours },
                Object.keys(dropdownHoursOptions).map((key) => (React.createElement(Radio, { key: key, className: styles.radio, value: RESOLVE_HOURS[key] },
                    React.createElement(Text, null,
                        React.createElement(Trans, { i18nKey: RESOLVE_HOURS[key] === 1 ? 'hour' : 'hoursNumber', values: { number: RESOLVE_HOURS[key] } }))))),
                React.createElement(Radio, { className: styles.radio, value: RESOLVE_HOURS.CUSTOM },
                    React.createElement(Text, null,
                        React.createElement(Trans, { i18nKey: "custom" })))),
            hours === RESOLVE_HOURS.CUSTOM && (React.createElement("div", { className: styles.inputWrapper },
                React.createElement(MyInput, { value: customHours, onChangeHandler: (value) => setCustomHours(parseInt(value) || 0), className: styles.input, allowClear: true }),
                React.createElement(Text, null,
                    React.createElement(Trans, { i18nKey: "hours" })))),
            React.createElement("div", { className: styles.buttonsWrapper },
                React.createElement(CommonButton, { action: onHide },
                    React.createElement(Trans, { i18nKey: "cancel" })),
                React.createElement(CommonButton, { action: () => {
                        setOpenModal(!openModal);
                        onHide();
                    }, submit: true },
                    React.createElement(Trans, { i18nKey: "resolve" }))))),
        openModal && (React.createElement(ResolveChatsModal, { status: getStatus(), onClose: () => setOpenModal(false), hours: hours === RESOLVE_HOURS.CUSTOM ? customHours : hours || 0, t: t }))));
};
export default observer(ResolveChatsMenu);
