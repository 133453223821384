import React from 'react';
import { MessageContentsType } from '@whatsper/texterchat-common';
import { useTranslation } from 'react-i18next';
import './UserStatusMessage.scss';
import { CheckCircleOutlined, WarningOutlined } from '@ant-design/icons';
const UserStatusMessage = ({ message, color }) => {
    var _a, _b;
    const { t } = useTranslation();
    const isOnline = ((_b = (_a = message.special) === null || _a === void 0 ? void 0 : _a.website_chat) === null || _b === void 0 ? void 0 : _b.status) === 'ACTIVE';
    return (React.createElement("div", { className: "user-status-message", style: { color } },
        isOnline && React.createElement(CheckCircleOutlined, { className: "icon" }),
        !isOnline && React.createElement(WarningOutlined, { className: "icon" }),
        React.createElement("span", null, isOnline ? t('websiteChatUserOnlineMsg') : t('websiteChatUserOfflineMsg'))));
};
export default UserStatusMessage;
export function isUserStatusMessage(message) {
    var _a, _b;
    return (message === null || message === void 0 ? void 0 : message.type)
        && message.type === MessageContentsType.special
        && ((_b = (_a = message.special) === null || _a === void 0 ? void 0 : _a.website_chat) === null || _b === void 0 ? void 0 : _b.status);
}
