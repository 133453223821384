var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Trans, withTranslation } from 'react-i18next';
import { pick } from 'lodash';
import { EntityManagerRoute, Operation } from '../EntityManager';
import DepartmentForm from './DepartmentForm';
import { Permissions } from '@whatsper/texterchat-common';
import { withStore } from '../../store/rootStore';
import { ReactComponent as DashboardIcon } from '../../assets/icons/dashboard/Dashboard.svg';
import styles from './styles.module.scss';
class DepartmentsManager extends Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                loading: false,
            }
        });
        Object.defineProperty(this, "getOpSupported", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { hasPermissions } = this.props.store.agentsStore;
                const op = [];
                if (hasPermissions([Permissions.Permission.departments_view])) {
                    op.push(Operation.LIST);
                }
                if (hasPermissions([Permissions.Permission.departments_manage])) {
                    op.push(Operation.CREATE, Operation.EDIT, Operation.DELETE);
                }
                return op;
            }
        });
        Object.defineProperty(this, "renderOperationTitle", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (op, department) => {
                switch (op) {
                    case Operation.LIST:
                        return (React.createElement(Trans, { i18nKey: "departmentsManager" }));
                    case Operation.CREATE:
                        return (React.createElement(Trans, { i18nKey: "addDepartment" }));
                    case Operation.EDIT:
                        return (React.createElement(Trans, { i18nKey: "editDepartmentName", values: department }));
                    case Operation.DELETE:
                        return (React.createElement(Trans, { i18nKey: "deleteDepartmentName", values: department }));
                }
            }
        });
        Object.defineProperty(this, "renderDeleteQuestion", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (department) => {
                return (React.createElement(Trans, { i18nKey: "sureDeleteName", values: department }));
            }
        });
        Object.defineProperty(this, "departmentSave", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (formData, department) => __awaiter(this, void 0, void 0, function* () {
                this.setState({ loading: true });
                const sla = {
                    time: formData.isSlaEnabled && formData.slaTime
                        ? this.props.store.dashboard.parseSlaTimeFromMomentToMilliseconds(formData.slaTime)
                        : 0,
                };
                try {
                    yield this
                        .props
                        .store
                        .departmentsStore[department ? 'editDepartment' : 'createDepartment'](Object.assign(Object.assign(Object.assign({}, department), pick(formData, ['name', 'color', '_id'])), { sla }));
                }
                finally {
                    this.setState({ loading: false });
                }
            })
        });
        Object.defineProperty(this, "departmentDelete", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (department) => __awaiter(this, void 0, void 0, function* () {
                this.setState({ loading: true });
                try {
                    yield this
                        .props
                        .store
                        .departmentsStore
                        .deleteDepartment(department._id);
                }
                finally {
                    this.setState({ loading: false });
                }
            })
        });
        Object.defineProperty(this, "isDisabled", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (department) => {
                return department.disabled || false;
            }
        });
    }
    render() {
        return (React.createElement(EntityManagerRoute, { path: "/manage/departments", entities: this.props.store.departmentsStore.departments, entityIdKey: "_id", teaser: DepartmentTeaser, teaserProps: { parseSLATime: this.props.store.dashboard.parseSlaTimeFromMillisecondsToMoment }, form: DepartmentForm, renderOperationTitle: this.renderOperationTitle, renderDeleteQuestion: this.renderDeleteQuestion, onEntityDelete: (department) => this.departmentDelete(department), onEntitySave: (data, department) => this.departmentSave(data, department), disabled: (department) => this.isDisabled(department), opSupported: this.getOpSupported(), onClose: () => { }, loading: this.state.loading }));
    }
}
function DepartmentTeaser({ entity, parseSLATime, }) {
    var _a;
    const slaInMinutes = ((_a = entity.sla) === null || _a === void 0 ? void 0 : _a.time)
        ? parseSLATime(entity.sla.time).hours() * 60 + parseSLATime(entity.sla.time).minutes()
        : null;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: styles.departmentColor, style: { backgroundColor: entity.color } }),
        React.createElement("div", { className: styles.singleDepartmentInfo },
            React.createElement("div", { className: styles.singleDepartmentName }, entity.name),
            React.createElement("div", { className: styles.singleDepartmentAgentsNumber },
                React.createElement(Trans, { i18nKey: !!entity.agents && entity.agents.length === 1 ? "numberOfAgents" : "numberOfAgents_plural", values: { count: entity.agents ? entity.agents.length : 0 } }),
                slaInMinutes && (React.createElement("span", { className: styles.teaserSlaTimeContainer },
                    React.createElement(DashboardIcon, null),
                    React.createElement("span", null, "SLA"),
                    React.createElement("span", null,
                        slaInMinutes,
                        " min")))))));
}
export default withTranslation()(withStore(observer(DepartmentsManager)));
