import React, { useRef } from 'react';
import { Button, Tooltip } from "antd";
import classnames from "classnames";
import { Trans } from "react-i18next";
import crossRed from "../../../../../assets/messageStatuses/crossRed.svg";
import styles from "./ChatNotesHeader.module.scss";
const ChatNotesHeader = (props) => {
    const { isNoteFieldOpen, handleCloseField } = props;
    const buttonRef = useRef(null);
    return (React.createElement("div", { className: styles.header },
        React.createElement("div", { className: "activity-list-title" },
            React.createElement(Trans, { i18nKey: "activityList" })),
        React.createElement("div", { className: "close-field-button" },
            React.createElement(Tooltip, { placement: "bottomRight", title: isNoteFieldOpen ? "Close field" : "Add new note" },
                React.createElement(Button, { ref: buttonRef, shape: "circle", className: styles.closeFieldButtonCircle, onClick: () => {
                        var _a;
                        handleCloseField();
                        (_a = buttonRef.current) === null || _a === void 0 ? void 0 : _a.blur();
                    } },
                    React.createElement("img", { src: crossRed, className: classnames({
                            [styles.open]: isNoteFieldOpen,
                            [styles.closed]: !isNoteFieldOpen
                        }), alt: "Toggle button" }))))));
};
export default ChatNotesHeader;
