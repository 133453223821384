import React, { Component } from 'react';
import classnames from 'classnames';
import { observer } from 'mobx-react';
import { Trans, withTranslation } from 'react-i18next';
import { Redirect } from 'react-router-dom';
import { withStore } from '../../store/rootStore';
import { USER_STATES } from '../../constants';
import Logo from '../../assets/logos/texterLogos/texter-logo-square2-192x192.png';
import LanguageSelect from '../LeftDrawer/LanguageSelect';
import LoginMethod from './components/LoginMethod';
import LoginMethodSelect from './components/LoginMethodSelect';
import styles from './Login.module.scss';
class Login extends Component {
    constructor(props) {
        super(props);
        Object.defineProperty(this, "checkIfUserIsLoggedInIntervalId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        this.state = {
            partnerTheme: props.store.getPartnerTheme(),
        };
    }
    render() {
        const { store } = this.props;
        const { partnerTheme } = this.state;
        const { errorMessage, shouldUpdatePassword, userState, authMethods, setAuthMethods } = store.loginStore;
        if (userState === USER_STATES.LOGGED_IN) {
            return React.createElement(Redirect, { to: "/" });
        }
        return (React.createElement("div", { className: styles.container },
            React.createElement("div", { className: styles.content },
                React.createElement("div", { className: styles.topBar },
                    React.createElement("div", { className: styles.topBarContent },
                        React.createElement("img", { className: classnames(styles.logo, {
                                [styles.custom]: !!partnerTheme,
                            }), src: partnerTheme ? partnerTheme.icon : Logo }),
                        React.createElement("div", { className: styles.centralTextBlock },
                            React.createElement("div", { className: styles.mainText }, partnerTheme ? (React.createElement(Trans, { i18nKey: "loginTo", values: { name: partnerTheme.name } })) : (React.createElement(Trans, { i18nKey: "loginToTexterchat" })))),
                        React.createElement(LanguageSelect, { className: styles.languageSelect }))),
                authMethods.length > 1 ? (React.createElement(LoginMethodSelect, { authMethods: authMethods, setAuthMethods: setAuthMethods })) : (React.createElement(LoginMethod, { method: authMethods[0], shouldUpdatePassword: shouldUpdatePassword })),
                React.createElement("p", { className: styles.termsAndPrivacy },
                    React.createElement(Trans, { i18nKey: "termsAndPrivacy", components: {
                            1: React.createElement("a", { href: "https://texterchat.com/terms", target: "_blank" }),
                            2: React.createElement("a", { href: "https://texterchat.com/privacy", target: "_blank" }),
                        } })),
                React.createElement("div", { className: styles.messagesBlock }, errorMessage ? (React.createElement("p", { className: classnames(styles.notificationText, styles.loginError) }, errorMessage)) : null),
                window.ReactNativeWebView && (React.createElement("a", { className: styles.linkButton, onClick: () => {
                        window.ReactNativeWebView.postMessage(JSON.stringify({
                            event: 'goToProjectSelect',
                        }));
                    } },
                    React.createElement(Trans, { i18nKey: "changeProject" }))))));
    }
}
export default withTranslation()(withStore(observer(Login)));
