import React from 'react';
import { GlobalOutlined, InfoCircleOutlined, ReadOutlined, UnlockOutlined } from '@ant-design/icons';
import Collapsable from './Collapsable';
const routes = [
    {
        titleKey: 'visitWebsite',
        icon: React.createElement(GlobalOutlined, null),
        url: 'https://texterchat.com',
    },
    {
        titleKey: 'terms',
        icon: React.createElement(ReadOutlined, null),
        url: 'https://texterchat.com/terms',
    },
    {
        titleKey: 'privacyPolicy',
        icon: React.createElement(UnlockOutlined, null),
        url: 'https://texterchat.com/privacy',
    },
];
const AboutSettings = () => {
    return React.createElement(Collapsable, { title: "panelAbout", icon: React.createElement(InfoCircleOutlined, null), routes: routes });
};
export default AboutSettings;
