import React, { useEffect, useState } from 'react';
import { useStore } from '../../store/rootStore';
import InactiveTabModal from '../../components/Chat/common/InactiveTabModal';
import { TEXTERCHAT_HOMEPAGE_URL, USER_STATES } from '../../constants';
import useTabIsHidden from './useTabIsHidden';
const windowId = `${Date.now()}`;
const useHandleInactiveTab = () => {
    const { loginStore, tab, setTabInactive } = useStore();
    const [showModal, setShowModal] = useState(false);
    const tabHidden = useTabIsHidden();
    function close() {
        window.open(TEXTERCHAT_HOMEPAGE_URL, '_self');
    }
    function setActiveWindowId() {
        localStorage.setItem('activeWindowId', windowId);
        setTabInactive(false);
    }
    function storeEventHandler(event) {
        if (event.key === 'activeWindowId' && event.newValue !== windowId) {
            setTabInactive(true);
        }
    }
    useEffect(() => {
        window.addEventListener('storage', storeEventHandler);
        return () => {
            window.removeEventListener('storage', storeEventHandler);
        };
    }, []);
    useEffect(() => {
        if (loginStore.userState === USER_STATES.LOGGED_IN && !tabHidden) {
            setActiveWindowId();
        }
    }, [loginStore.userState]);
    useEffect(() => {
        if (tab.inactive) {
            loginStore.stopSession();
            setShowModal(true);
        }
        else {
            setShowModal(false);
        }
    }, [tab.inactive]);
    return (React.createElement(InactiveTabModal, { visible: showModal, setVisible: setShowModal, setActiveWindowId: setActiveWindowId, close: close }));
};
export default useHandleInactiveTab;
