var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { createContext, useContext } from 'react';
import { action, observable, runInAction, toJS, computed, reaction, makeObservable, } from 'mobx';
import { API_PATH } from '../../constants';
import { getAxios } from '../../backend';
import { useStore as useRootStore } from '../rootStore';
import { throttle, pick, omit } from 'lodash';
import yaml from 'yaml';
import { YAMLError } from 'yaml/util';
import BotTemplate from './newBotTemplate';
/**
 * Key in local storage to save editor state
 */
const localStorageEditorStateKey = 'botAdminEditorState';
/**
 * Properties of store related to editor, to save in local storage
 */
const editorStatePropsSave = [
    'editorMode',
    'editorYamlValue',
    'editorYamlValueInitial',
    'editorYamlValueSaved',
    'editorVersionComment',
    'editorSourceVersion',
    'flowVersionWarnings',
];
export default class BotAdminStore {
    constructor(rootStore) {
        Object.defineProperty(this, "rootStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        /**
         * Current selected bot identifier
         */
        Object.defineProperty(this, "currentBot", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        /**
         * Versions of current selected bot
         */
        Object.defineProperty(this, "flowVersions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: observable.array()
        });
        /**
         * Loading versions of current selected bot
         */
        Object.defineProperty(this, "flowVersionsLoading", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        /**
         * Bot versions pagination has more results
         */
        Object.defineProperty(this, "flowVersionsHasMore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: true
        });
        /**
         * Available bots information
         * @todo Pagination?
         */
        Object.defineProperty(this, "bots", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: new Map()
        });
        /**
         * Loading bots listing
         */
        Object.defineProperty(this, "botsLoading", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        /**
         * Identifier of bot currently updating
         */
        Object.defineProperty(this, "updatingBot", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        /**
         * Identifier of bot currently updating
         */
        Object.defineProperty(this, "settingDefaultBot", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        /**
         * Current mode of editor.
         * Different modes not implemented yet
         */
        Object.defineProperty(this, "editorMode", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: editorMode.yaml
        });
        /**
         * Information about version ediitor initial contents derived from
         */
        Object.defineProperty(this, "editorSourceVersion", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        /**
         * Editor YAML current contents
         */
        Object.defineProperty(this, "editorYamlValue", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ''
        });
        /**
         * Editor YAML initial contents
         */
        Object.defineProperty(this, "editorYamlValueInitial", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ''
        });
        /**
         * Is all changes done comparing to initial state are saved
         */
        Object.defineProperty(this, "editorYamlValueSaved", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: true
        });
        /**
         * Saving editor contents now
         */
        Object.defineProperty(this, "editorYamlValueSaving", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        /**
         * Loading into editor
         */
        Object.defineProperty(this, "editorValueLoading", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        /**
         * Flow version validation errors
         */
        Object.defineProperty(this, "flowVersionErrors", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: observable([])
        });
        /**
         * Flow version validation errors
         */
        Object.defineProperty(this, "flowVersionWarnings", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: observable([])
        });
        /**
         * Current comment to save with version
         */
        Object.defineProperty(this, "editorVersionComment", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ''
        });
        /**
         * Editor parsed flow value
         */
        Object.defineProperty(this, "editorValue", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {}
        });
        /**
         * Currently activating version of this bot (identifier)
         */
        Object.defineProperty(this, "activatingVersionOf", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        /**
         * Currently activating version of this bot (identifier)
         */
        Object.defineProperty(this, "deletingVersion", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        /** @todo Decide is this method need for future functionality */
        Object.defineProperty(this, "editorSetBotTemplate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (identifier, title) => {
                const bot = BotTemplate;
                bot.identifier = identifier;
                bot.title = title;
                const flow = yaml.stringify(bot);
                this.editorSourceVersion = null;
                this.flowVersionErrors.clear();
                this.editorYamlValueInitial = flow;
                this.editorYamlValue = flow;
                this.editorYamlValueSaved = false;
                this.editorVersionComment = `Create bot version`;
            }
        });
        Object.defineProperty(this, "clearErrors", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.flowVersionErrors.clear();
            }
        });
        Object.defineProperty(this, "clearWarnings", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.flowVersionWarnings.clear();
            }
        });
        /**
         * Create new bot version
         *
         * @param yaml
         * @param comment New version comment
         * @param create Create new bot
         */
        Object.defineProperty(this, "submitFlowVersion", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (yaml, comment, create) => __awaiter(this, void 0, void 0, function* () {
                return getAxios(false)
                    .then((axios) => {
                    return axios.post(API_PATH.BOTS_MANAGE + '/import', {
                        yaml,
                        comment,
                        create,
                    });
                })
                    .then(({ data }) => {
                    return data;
                });
            })
        });
        Object.defineProperty(this, "setEditorVersionComment", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (comment) => {
                this.editorVersionComment = comment;
                this.saveEditorStateLocal();
            }
        });
        /**
         * Create first bot version based on bot template
         *
         * @param identifier New bot identifier
         * @param title New bot title
         */
        Object.defineProperty(this, "createNewBot", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (identifier, title) => __awaiter(this, void 0, void 0, function* () {
                const botFlow = BotTemplate;
                botFlow.identifier = identifier;
                botFlow.title = title;
                const flow = yaml.stringify(botFlow);
                try {
                    const { bot } = yield this.submitFlowVersion(flow, 'Create new bot', true);
                    runInAction(() => {
                        this.bots.set(bot.identifier, bot);
                    });
                    this.getFlowVersion(bot.identifier, bot.latestVersion)
                        .then((version) => {
                        console.log(version);
                        this.setEditorYamlValueInitial(flow, version, true);
                    })
                        .catch((error) => {
                        console.error(error);
                    });
                    return bot;
                }
                catch (error) {
                    let errors = ['An unexpected error occured'];
                    if (isAxiosError(error) && error.response) {
                        errors = apiErrorRsponseToErrors(error.response);
                    }
                    runInAction(() => {
                        this.flowVersionErrors.replace(errors);
                    });
                    throw new Error(error);
                }
            })
        });
        /**
         * Save YAML mode editor contents as new bot version
         */
        Object.defineProperty(this, "saveYamlEditorVersion", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                var _a;
                if (this.editorYamlValueSaving)
                    return;
                runInAction(() => this.editorYamlValueSaving = true);
                try {
                    const flow = yaml.parse(this.editorYamlValue);
                    // Prevent in-editor bot identifier modification
                    if (((_a = this.editorSourceVersion) === null || _a === void 0 ? void 0 : _a.identifier) && flow.identifier !== this.editorSourceVersion.identifier) {
                        runInAction(() => {
                            var _a;
                            this.flowVersionErrors.replace([
                                `Bot identifier mismatch with source version: "${flow.identifier}" vs "${(_a = this.editorSourceVersion) === null || _a === void 0 ? void 0 : _a.identifier}"`
                            ]);
                        });
                        return;
                    }
                    const { bot, warnings } = yield this.submitFlowVersion(this.editorYamlValue, this.editorVersionComment);
                    runInAction(() => {
                        this.editorYamlValueSaving = false;
                        this.flowVersionWarnings.replace(warnings);
                        this.bots.set(bot.identifier, bot);
                    });
                    yield this.getFlowVersion(bot.identifier, bot.latestVersion)
                        .then((version) => {
                        this.setEditorYamlValueInitial(toJS(this.editorYamlValue), version);
                    })
                        .catch((error) => {
                        console.error(error);
                    });
                    return bot;
                }
                catch (error) {
                    runInAction(() => this.editorYamlValueSaving = false);
                    let errors = ['An unexpected error occured'];
                    console.error(error);
                    if (isAxiosError(error) && error.response) {
                        errors = apiErrorRsponseToErrors(error.response);
                    }
                    else if (error instanceof YAMLError) {
                        error.makePretty();
                        errors = [error.message];
                    }
                    runInAction(() => {
                        this.flowVersionErrors.replace(errors);
                    });
                }
            })
        });
        /**
         * Set/update current editing value
         *
         * @param value YAML string
         */
        Object.defineProperty(this, "setEditorYamlValue", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (value) => {
                if (value !== this.editorYamlValue) {
                    this.flowVersionErrors.clear();
                    this.editorYamlValue = value;
                    this.editorYamlValueSaved = value === this.editorYamlValueInitial;
                    this.saveEditorStateLocal();
                }
            }
        });
        /**
         * Set editor initial value
         *
         * @param value Editor initional yaml value
         * @param sourceVersion Information about version of initial value
         * @param clearWarnings Clear previous warnings
         */
        Object.defineProperty(this, "setEditorYamlValueInitial", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (value, sourceVersion, clearWarnings = false) => {
                if (clearWarnings || sourceVersion && this.editorSourceVersion && isSameFlowVersion(sourceVersion, this.editorSourceVersion)) {
                    this.flowVersionWarnings.clear();
                }
                this.editorSourceVersion = sourceVersion || null;
                this.flowVersionErrors.clear();
                this.editorYamlValueInitial = value;
                this.editorYamlValue = value;
                this.editorYamlValueSaved = true;
                this.editorVersionComment = '';
            }
        });
        /**
         * Load specified version into editor.
         *
         * IMPORTANT: Unsaved value not checked and overwritter,so call this method after checks only
         *
         * @param identifier Bot identifier to load
         * @param version Version number of bot to load
         */
        Object.defineProperty(this, "editorLoadVersion", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (identifier, version) => __awaiter(this, void 0, void 0, function* () {
                runInAction(() => this.editorValueLoading = true);
                try {
                    const ver = yield this.getFlowVersion(identifier, version);
                    this.setEditorYamlValueInitial(ver.flowYaml, omit(ver, 'flowYaml'), true);
                }
                catch (error) {
                    console.error(error);
                }
                runInAction(() => this.editorValueLoading = false);
            })
        });
        /**
         * Restore values saved to local storage
         */
        Object.defineProperty(this, "restoreEditorStateLocal", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                try {
                    const state = localStorage.getItem(localStorageEditorStateKey);
                    if (state && state.length) {
                        const stateData = JSON.parse(state);
                        runInAction(() => {
                            for (let i = 0; i < editorStatePropsSave.length; i++) {
                                if (typeof stateData[editorStatePropsSave[i]] !== 'undefined') {
                                    this[editorStatePropsSave[i]] = stateData[editorStatePropsSave[i]];
                                }
                            }
                        });
                    }
                }
                catch (error) {
                    console.error(error);
                }
            }
        });
        Object.defineProperty(this, "saveEditorStateLocal", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: throttle(() => {
                try {
                    localStorage
                        .setItem(localStorageEditorStateKey, JSON.stringify(pick(this, editorStatePropsSave)));
                }
                catch (error) {
                    console.error(error);
                }
            }, 3000, { trailing: true })
        });
        /**
         * Load available bots list to store.
         * @todo Pagination???
         */
        Object.defineProperty(this, "loadBots", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                this.botsLoading = true;
                getAxios()
                    .then((axios) => {
                    return axios.get(API_PATH.BOTS_MANAGE);
                })
                    .then(({ data }) => {
                    runInAction(() => {
                        for (let i = 0; i < data.length; i++) {
                            this.bots.set(data[i].identifier, data[i]);
                        }
                    });
                    // Set current bot if no selected
                    if (!this.currentBot && data.length) {
                        // Try to find default one
                        for (let i = 0; i < data.length; i++) {
                            if (data[i].default) {
                                this.setCurrentBot(data[i].identifier);
                                return;
                            }
                        }
                        // No default - use just first
                        this.setCurrentBot(data[0].identifier);
                    }
                })
                    .catch((error) => {
                    console.error(error);
                })
                    .finally(() => {
                    runInAction(() => this.botsLoading = false);
                });
            })
        });
        Object.defineProperty(this, "loadBot", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (identifier) => __awaiter(this, void 0, void 0, function* () {
                runInAction(() => this.botsLoading = true);
                const info = yield this.getBot(identifier);
                runInAction(() => {
                    this.bots.set(info.identifier, info);
                    this.botsLoading = false;
                });
                if (identifier === this.currentBot) {
                    this.loadCurrentBotVersions(true)
                        .catch((error) => {
                        console.error(error);
                    });
                }
                return info;
            })
        });
        Object.defineProperty(this, "getBot", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (identifier) => __awaiter(this, void 0, void 0, function* () {
                return getAxios()
                    .then((axios) => {
                    return axios.get(API_PATH.BOTS_MANAGE + '/' + identifier);
                })
                    .then(({ data }) => {
                    return data;
                });
            })
        });
        /**
         * Set current bot
         *
         * @param identifier Bot identifier
         */
        Object.defineProperty(this, "setCurrentBot", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (identifier) => __awaiter(this, void 0, void 0, function* () {
                if (!this.bots.has(identifier)) {
                    throw new Error(`Unknown bot "${identifier}"`);
                }
                this.currentBot = identifier;
            })
        });
        /**
         * Delete entire bot
         *
         * !!! Highly destructive action - Use with caution and add proper confirmations in UI !!!
         *
         * @param identifier Bot identifier
         */
        Object.defineProperty(this, "deleteBot", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (identifier) => __awaiter(this, void 0, void 0, function* () {
                const info = this.bots.get(identifier);
                if (info === null || info === void 0 ? void 0 : info.default) {
                    throw new Error(`Can not delete default bot "${identifier}"`);
                }
                runInAction(() => {
                    this.updatingBot = identifier;
                    if (this.currentBot === identifier) {
                        const [defaultBot] = Array.from(this.bots).find(([id, botInfo]) => {
                            if (botInfo.default) {
                                return true;
                            }
                        }) || [];
                        this.currentBot = defaultBot ? defaultBot : null;
                    }
                });
                yield getAxios()
                    .then((axios) => {
                    return axios.delete(API_PATH.BOTS_MANAGE + '/' + identifier);
                })
                    .then(() => {
                    runInAction(() => this.bots.delete(identifier));
                })
                    .catch((error) => console.error(error));
                runInAction(() => {
                    this.updatingBot = null;
                });
                yield this.loadBots();
            })
        });
        /**
         * Set bot active version
         *
         * @param identifier Bot identifier
         */
        Object.defineProperty(this, "setActiveVersion", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (identifier, version, makeDefaultBot = false) => __awaiter(this, void 0, void 0, function* () {
                this.activatingVersionOf = identifier;
                yield getAxios()
                    .then((axios) => {
                    return axios.post(API_PATH.BOTS_MANAGE + '/' + identifier + '/versions/' + version + '/activate', {
                        makeDefaultBot,
                    });
                });
                runInAction(() => this.activatingVersionOf = false);
                // secondary actions
                if (makeDefaultBot) {
                    this.loadBots()
                        .catch((error) => {
                        console.error(error);
                    });
                }
                else {
                    this.loadBot(identifier)
                        .catch((error) => {
                        console.error(error);
                    });
                }
            })
        });
        /**
         * Delete bot versions history
         *
         * @param identifier Bot identifier
         * @param version Version number to delete with all predecessors
         */
        Object.defineProperty(this, "deleteVersionsHistory", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (identifier, version) => __awaiter(this, void 0, void 0, function* () {
                this.deletingVersion = { identifier, version };
                yield getAxios()
                    .then((axios) => {
                    return axios.delete(API_PATH.BOTS_MANAGE + '/' + identifier + '/versions/' + version);
                });
                runInAction(() => this.deletingVersion = false);
                // secondary actions
                this.loadBot(identifier)
                    .then(() => {
                    if (identifier === this.currentBot) {
                        this.loadCurrentBotVersions(true)
                            .catch((error) => {
                            console.error(error);
                        });
                    }
                })
                    .catch((error) => {
                    console.error(error);
                });
            })
        });
        /**
         * Load current selected bot flow versions with lazy loading.
         * Each time called, loads next portion of versions, till available more.
         */
        Object.defineProperty(this, "loadCurrentBotVersions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (reload = false) => __awaiter(this, void 0, void 0, function* () {
                if (!this.currentBot)
                    return;
                try {
                    if (!this.flowVersions.length || reload) {
                        runInAction(() => {
                            this.flowVersionsLoading = true;
                            if (reload) {
                                this.flowVersionsHasMore = true;
                            }
                        });
                        const versions = yield this.getFlowVersions(this.currentBot);
                        runInAction(() => {
                            this.flowVersions.replace(versions);
                            if (this.flowVersions.length < 100) {
                                this.flowVersionsHasMore = false;
                            }
                        });
                    }
                    else if (this.flowVersionsHasMore) {
                        runInAction(() => this.flowVersionsLoading = true);
                        const versions = yield this.getFlowVersions(this.currentBot, this.flowVersions[this.flowVersions.length - 1].version);
                        runInAction(() => {
                            this.flowVersions.push(...versions);
                            if (versions.length < 100) {
                                this.flowVersionsHasMore = false;
                            }
                        });
                    }
                }
                catch (e) {
                    console.error(e);
                }
                finally {
                    runInAction(() => this.flowVersionsLoading = false);
                }
            })
        });
        /**
         * Get bot flow versions
         *
         * @param identifier Bot identifier
         * @param afterVersion Return only results after this version
         * @param limit How much results to load
         */
        Object.defineProperty(this, "getFlowVersions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (identifier, afterVersion, limit = 100) => __awaiter(this, void 0, void 0, function* () {
                return getAxios()
                    .then((axios) => {
                    return axios.get(API_PATH.BOTS_MANAGE + '/' + identifier + '/versions', {
                        params: {
                            afterVersion,
                            limit,
                        },
                    });
                })
                    .then(({ data }) => {
                    return data;
                });
            })
        });
        /**
         * Get bot flow specific version
         *
         * @param identifier Bot identifier
         * @param version Version number
         */
        Object.defineProperty(this, "getFlowVersion", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (identifier, version) => __awaiter(this, void 0, void 0, function* () {
                return getAxios()
                    .then((axios) => {
                    return axios.get(API_PATH.BOTS_MANAGE + '/' + identifier + '/versions/' + version);
                })
                    .then(({ data }) => {
                    return data;
                });
            })
        });
        /**
         * Set bot as default
         *
         * @param identifier Bot identifier
         */
        Object.defineProperty(this, "setDefaultBot", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (identifier) => __awaiter(this, void 0, void 0, function* () {
                runInAction(() => this.settingDefaultBot = true);
                yield getAxios()
                    .then((axios) => {
                    return axios.post(API_PATH.BOTS_MANAGE + '/' + identifier + '/set-default');
                })
                    .then(({ data: botInfo }) => {
                    runInAction(() => this.bots.set(botInfo.identifier, botInfo));
                    this.loadBots().catch((error) => console.error(error));
                })
                    .finally(() => runInAction(() => this.settingDefaultBot = false));
            })
        });
        makeObservable(this);
        this.rootStore = rootStore;
        // Restore values saved to local storage
        this.restoreEditorStateLocal();
        this.loadBots();
        // Load versions of current bot when current bot changed
        reaction(() => this.currentBot, (curBot) => {
            if (curBot) {
                this.loadCurrentBotVersions(true);
            }
            else {
                runInAction(() => this.flowVersions.clear());
            }
        });
    }
    get currentBotInfo() {
        if (!this.currentBot)
            return null;
        return this.bots.get(this.currentBot) || null;
    }
}
__decorate([
    observable
], BotAdminStore.prototype, "currentBot", void 0);
__decorate([
    observable
], BotAdminStore.prototype, "flowVersions", void 0);
__decorate([
    observable
], BotAdminStore.prototype, "flowVersionsLoading", void 0);
__decorate([
    observable
], BotAdminStore.prototype, "flowVersionsHasMore", void 0);
__decorate([
    observable
], BotAdminStore.prototype, "bots", void 0);
__decorate([
    observable
], BotAdminStore.prototype, "botsLoading", void 0);
__decorate([
    observable
], BotAdminStore.prototype, "updatingBot", void 0);
__decorate([
    observable
], BotAdminStore.prototype, "settingDefaultBot", void 0);
__decorate([
    observable
], BotAdminStore.prototype, "editorMode", void 0);
__decorate([
    observable
], BotAdminStore.prototype, "editorSourceVersion", void 0);
__decorate([
    observable
], BotAdminStore.prototype, "editorYamlValue", void 0);
__decorate([
    observable
], BotAdminStore.prototype, "editorYamlValueInitial", void 0);
__decorate([
    observable
], BotAdminStore.prototype, "editorYamlValueSaved", void 0);
__decorate([
    observable
], BotAdminStore.prototype, "editorYamlValueSaving", void 0);
__decorate([
    observable
], BotAdminStore.prototype, "editorValueLoading", void 0);
__decorate([
    observable
], BotAdminStore.prototype, "flowVersionErrors", void 0);
__decorate([
    observable
], BotAdminStore.prototype, "flowVersionWarnings", void 0);
__decorate([
    observable
], BotAdminStore.prototype, "editorVersionComment", void 0);
__decorate([
    observable
], BotAdminStore.prototype, "editorValue", void 0);
__decorate([
    observable
], BotAdminStore.prototype, "activatingVersionOf", void 0);
__decorate([
    observable
], BotAdminStore.prototype, "deletingVersion", void 0);
__decorate([
    action
], BotAdminStore.prototype, "editorSetBotTemplate", void 0);
__decorate([
    action
], BotAdminStore.prototype, "clearErrors", void 0);
__decorate([
    action
], BotAdminStore.prototype, "clearWarnings", void 0);
__decorate([
    computed
], BotAdminStore.prototype, "currentBotInfo", null);
__decorate([
    action
], BotAdminStore.prototype, "submitFlowVersion", void 0);
__decorate([
    action
], BotAdminStore.prototype, "setEditorVersionComment", void 0);
__decorate([
    action
], BotAdminStore.prototype, "createNewBot", void 0);
__decorate([
    action
], BotAdminStore.prototype, "saveYamlEditorVersion", void 0);
__decorate([
    action
], BotAdminStore.prototype, "setEditorYamlValue", void 0);
__decorate([
    action
], BotAdminStore.prototype, "setEditorYamlValueInitial", void 0);
__decorate([
    action
], BotAdminStore.prototype, "loadBots", void 0);
__decorate([
    action
], BotAdminStore.prototype, "loadBot", void 0);
__decorate([
    action
], BotAdminStore.prototype, "getBot", void 0);
__decorate([
    action
], BotAdminStore.prototype, "setCurrentBot", void 0);
__decorate([
    action
], BotAdminStore.prototype, "deleteBot", void 0);
__decorate([
    action
], BotAdminStore.prototype, "setActiveVersion", void 0);
__decorate([
    action
], BotAdminStore.prototype, "deleteVersionsHistory", void 0);
__decorate([
    action
], BotAdminStore.prototype, "loadCurrentBotVersions", void 0);
function isSameFlowVersion(version1, version2) {
    return version1.identifier === version2.identifier && version1.version === version2.version;
}
let store;
let context;
export const useStore = () => {
    const rootStore = useRootStore();
    if (!context) {
        store = new BotAdminStore(rootStore);
        context = createContext(store);
    }
    return useContext(context);
};
export function withStore(Component) {
    return function WrappedComponent(props) {
        const store = useStore();
        return React.createElement(Component, Object.assign({}, props, { store: store }));
    };
}
export var editorMode;
(function (editorMode) {
    editorMode["yaml"] = "yaml";
    // This two below is not implemented, just guessing on possible modes
    // form = 'form',
    // visual = 'visual',
})(editorMode || (editorMode = {}));
function isAxiosError(error) {
    return error && error.isAxiosError;
}
function apiErrorRsponseToErrors(response) {
    if (response.data && response.status >= 400 && response.status < 500) {
        if (typeof response.data === 'string') {
            return [response.data];
        }
        else if (typeof response.data.errors === 'object') {
            return response.data.errors;
        }
        else {
            return [JSON.stringify(response.data, null, 2)];
        }
    }
    return ['An unexpected error occured'];
}
