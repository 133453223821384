import React, { createRef, useEffect, useState } from 'react';
import { Button } from 'antd';
import { observer } from 'mobx-react';
import GoogleMapReact from 'google-map-react';
import Marker from './Marker';
import { getApiKey } from '../../../../settings';
import exportLogo from '../../../../assets/icons/arrows/export/exportLogo.svg';
import './LocationMessage.scss';
const LocationMessage = ({ longitude, latitude }) => {
    const [apiKey, setApiKey] = useState('');
    const mapRef = createRef();
    useEffect(() => {
        getApiKey().then(key => setApiKey(key || ''));
    }, []);
    const handleOpenGoogleMap = () => {
        var _a;
        const win = window.open(`https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`);
        (_a = mapRef.current) === null || _a === void 0 ? void 0 : _a.blur();
        win === null || win === void 0 ? void 0 : win.focus();
    };
    if (!apiKey)
        return null;
    return (React.createElement("div", { className: "map-wrapper" },
        React.createElement(Button, { ref: mapRef, shape: "default", className: "map-link-button", onClick: handleOpenGoogleMap },
            React.createElement("img", { src: exportLogo })),
        React.createElement("div", { className: "map" },
            React.createElement(GoogleMapReact, { bootstrapURLKeys: { key: apiKey }, center: [latitude, longitude], zoom: 15 },
                React.createElement(Marker, { lat: latitude, lng: longitude })))));
};
export default observer(LocationMessage);
