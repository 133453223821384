import React, { Component } from 'react';
import { observer } from 'mobx-react'
import {Trans} from 'react-i18next';
import {PoweroffOutlined} from "@ant-design/icons"
import Avatar from "../../../../Base/User/Avatar";
import styles from './LogOutSection.module.scss';
import {USER_STATES} from '../../../../../constants'
import { withStore } from '../../../../../store/rootStore';
import { Tooltip, Button } from 'antd';
import CommonButton from "../../../../Chat/common/CommonButton";

class LogOutSection extends Component {

  logOut = (event) => {
    event.preventDefault();
    console.log("LOGOUT");
    this.props.store.loginStore.logOut()
      .then(() => {
        if (this.props.store.loginStore.userState === USER_STATES.LOGGED_OUT) {
          this.props.history.push("/login");
        }
      }).catch(error => {
        // this.setState({ error: error.messages })
        // setTimeout(() => this.setState({ error: null }), 3000)
      })
  }



  render() {
    const {userDetails}= this.props.store.loginStore;
      return (
      <div className={styles.logOutSection}>
        <div className={styles.logOutSectionUser}>
          <div>
            {userDetails?.displayName}
          </div>
        </div>

        <Avatar
          displayName={userDetails?.displayName}
          src="https://cdn.iconscout.com/icon/free/png-256/avatar-370-456322.png"
          status={this.props.store.statusStore.status}
        />

        <Tooltip placement="bottomRight" title={<Trans i18nKey="logout"/>}>
            <Button shape="circle" className={styles.logoutButtonCircle} onClick={this.logOut}>
                <PoweroffOutlined className={styles.logOutSectionLogout} />
            </Button>
        </Tooltip>
      </div>
    );
  }
}

export default withStore(observer(LogOutSection));
