var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { Modal, Tooltip } from 'antd';
import { Trans } from "react-i18next";
import { LoadingOutlined } from "@ant-design/icons";
import CommonButton from "../CommonButton/CommonButton";
import styles from "./SubmitModal.module.scss";
import classNames from 'classnames';
const SubmitModal = (props) => {
    const { visible, onCancel, onSubmit, children, alert, submitTextKey, cancelTextKey, buttonsClassName } = props;
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const handleAsync = () => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        setError(false);
        return onSubmit();
    });
    const handleSubmit = () => {
        return handleAsync()
            .catch(() => setError(true))
            .finally(() => setLoading(false));
    };
    return (React.createElement(Modal, { width: 800, open: visible, closable: false, onCancel: onCancel, footer: null, centered: true },
        React.createElement("div", { className: styles.content },
            React.createElement("div", null, children),
            React.createElement("div", { className: classNames(styles.buttons, buttonsClassName) },
                React.createElement(CommonButton, { action: onCancel },
                    React.createElement(Trans, { i18nKey: cancelTextKey || "cancel" })),
                React.createElement(Tooltip, { open: error, arrowPointAtCenter: true, placement: "top", title: React.createElement(Trans, { i18nKey: "unexpectedError" }) },
                    React.createElement(CommonButton, { action: handleSubmit, alert: alert, submit: !alert }, loading ? React.createElement(LoadingOutlined, null)
                        : React.createElement(Trans, { i18nKey: submitTextKey })))))));
};
export default SubmitModal;
