import React, { useState, useEffect, useRef } from 'react';
import classnames from 'classnames';
import { Input } from 'antd';
import { SmileOutlined } from '@ant-design/icons';
import pickerData from '@emoji-mart/data';
import Picker from '@emoji-mart/react';
import { withTranslation } from 'react-i18next';
import { getDirection } from '../../../../helpers';
import styles from './TextareaWithEmoji.module.scss';
const { TextArea } = Input;
const TextareaWithEmoji = ({ placeholderText, text, t, setText, additionalStyles, align, textareaProps }) => {
    const [openEmoji, setOpenEmoji] = useState(false);
    const [selectionStart, setSelectionStart] = useState(text.length);
    const [selectionEnd, setSelectionEnd] = useState(text.length);
    const inputRef = useRef(null);
    const emojiRef = useRef(null);
    const smileRef = useRef(null);
    useEffect(() => {
        document.addEventListener('click', (e) => closeEmojiFromOutside(e));
        return document.removeEventListener('click', (e) => closeEmojiFromOutside(e));
    });
    const closeEmojiFromOutside = (event) => {
        var _a;
        if (openEmoji &&
            emojiRef.current &&
            !emojiRef.current.contains(event.target) &&
            !((_a = smileRef.current) === null || _a === void 0 ? void 0 : _a.contains(event.target))) {
            setOpenEmoji(false);
        }
    };
    const handleAddEmoji = (emoji, start, end) => {
        const stringEnd = text.length;
        const newText = `${text.slice(0, start)}${emoji}${text.slice(end, stringEnd)}`;
        setText(newText);
    };
    const onEmojiSelect = (emojiObject) => {
        var _a, _b;
        handleAddEmoji(emojiObject.native, selectionStart, selectionEnd);
        setSelectionStart(selectionStart + 2);
        setSelectionEnd(selectionEnd + 2);
        (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
        (_b = inputRef.current) === null || _b === void 0 ? void 0 : _b.resizableTextArea.textArea.setSelectionRange(selectionStart + 2, selectionEnd + 2);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: classnames(styles.textbox, additionalStyles), onClick: () => {
                var _a;
                (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
            } },
            React.createElement(TextArea, Object.assign({ dir: getDirection(), ref: inputRef, className: styles.textarea, placeholder: t(`${placeholderText}`), onChange: (e) => {
                    setSelectionStart(e.target.selectionStart);
                    setSelectionEnd(e.target.selectionEnd);
                    setText(e.target.value);
                }, autoSize: { minRows: 5 }, value: text, bordered: false }, textareaProps)),
            React.createElement(SmileOutlined, { ref: smileRef, className: styles.emojiPickerTrigger, onClick: () => {
                    setOpenEmoji(!openEmoji);
                } })),
        openEmoji && (React.createElement("div", { ref: emojiRef, className: classnames(styles.emojiPicker, {
                [styles.top]: align === 'top',
                [styles.bottom]: align === 'bottom',
            }) },
            React.createElement(Picker, { data: pickerData, className: styles.emojiPickerInner, onClickOutside: closeEmojiFromOutside, onEmojiSelect: onEmojiSelect, theme: "light" })))));
};
export default withTranslation()(TextareaWithEmoji);
