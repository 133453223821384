/**
 * Format value of seconds since day started in hh:mm(:ss) format
 *
 * @param seconds
 * @returns
 */
export function formatDaySecondsAsTime(seconds, showSecondsRemainder = false) {
    const secondsRemainder = seconds % 60, minutes = Math.floor(seconds / 60), minutesRemainder = minutes % 60, hours = Math.floor(minutes / 60);
    let time = `${hours.toString().padStart(2, '0')}:${minutesRemainder.toString().padStart(2, '0')}`;
    if (showSecondsRemainder) {
        time += `:${secondsRemainder.toString().padStart(2, '0')}`;
    }
    return time;
}
