import React from "react";
import { Link } from "react-router-dom";
import { observer } from "mobx-react";
import ClickableIcon from "./ClickableIcon";
import texterBigLogo from "../../../assets/logos/texterLogos/texterBigLogo.svg";
import { ReactComponent as Dashboard } from "../../../assets/icons/leftMenuIcons/statistics.svg";
import { ReactComponent as Bot } from "../../../assets/icons/leftMenuIcons/bot.svg";
import { ReactComponent as ForumIcon } from "../../../assets/icons/leftMenuIcons/ForumIcon.svg";
import { ReactComponent as SettingsOutlinedIcon } from "../../../assets/icons/leftMenuIcons/SettingsOutlinedIcon.svg";
import { ReactComponent as ChatOutlinedIcon } from "../../../assets/icons/leftMenuIcons/ChatOutlinedIcon.svg";
import { ReactComponent as Question } from "../../../assets/icons/leftMenuIcons/question.svg";
import { Permissions } from "@whatsper/texterchat-common";
import styles from "./LeftSideBar.module.scss";
const LeftSideBar = ({ drawer, messageDrawer, settingsAction, toggleMessageDrawer, toggleDashboardDrawer, toggleTemplateMessageDrawer, dashboardOpen, templateMessageDrawer, openCompanySupport, hideIcons, hasPermissions, hasScheduledReports, partnerTheme, }) => {
    return (React.createElement("div", { className: styles.leftSidebar },
        React.createElement("div", { className: styles.leftSidebarMenuTop },
            React.createElement("img", { src: partnerTheme ? partnerTheme.iconSmall : texterBigLogo, alt: "Logo", className: styles.leftSidebarIcon })),
        React.createElement("div", { className: styles.leftSidebarMenu },
            !hideIcons && (React.createElement(React.Fragment, null,
                React.createElement(ClickableIcon, { tooltipTransKey: "templateMessages", component: ForumIcon, action: toggleTemplateMessageDrawer, status: templateMessageDrawer, notification: hasScheduledReports }),
                React.createElement(ClickableIcon, { tooltipTransKey: "tooltipNewMessage", component: ChatOutlinedIcon, action: toggleMessageDrawer, status: messageDrawer }),
                hasPermissions([Permissions.Permission.stats_read]) && (React.createElement(ClickableIcon, { tooltipTransKey: "liveDashboard", component: Dashboard, action: toggleDashboardDrawer, status: dashboardOpen })),
                hasPermissions([Permissions.Permission.bots_view, Permissions.Permission.bots_edit]) && (React.createElement(Link, { to: "/bot-admin" },
                    React.createElement(ClickableIcon, { tooltipTransKey: "openBotAdmin", component: Bot }))),
                React.createElement(ClickableIcon, { tooltipTransKey: "tooltipHelp", component: Question, action: openCompanySupport, status: false }))),
            hideIcons && (React.createElement(Link, { to: "/" },
                React.createElement(ClickableIcon, { tooltipTransKey: "openChats", component: ForumIcon }))),
            !!settingsAction &&
                React.createElement(ClickableIcon, { tooltipTransKey: "settingsDrawer", component: SettingsOutlinedIcon, action: settingsAction, status: drawer }))));
};
export default observer(LeftSideBar);
