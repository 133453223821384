import React, {Component} from 'react';
import {StopOutlined} from "@ant-design/icons"
import {Trans} from "react-i18next"

import "./DeletedMessage.css"

class DeletedMessage extends Component {
    render() {
        return (
            <div className="deleted-message-container">
                <StopOutlined className="deleted-sign"/>
                <p className="deleted-message-text">
                    <Trans i18nKey="messageWasDeleted"/>
                </p>
            </div>
        );
    }
}

export default DeletedMessage;
