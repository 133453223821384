import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import Template from '../Template';
import styles from './styles.module.scss';
import { Trans } from 'react-i18next';
import ErrorBoundary from '../../ErrorBoundary';
export default function TemplatesList({ templates, selected, onClicked, loading, isInbox, isRTL }) {
    if (loading)
        return (React.createElement("div", { className: styles.empty },
            React.createElement(LoadingOutlined, { className: styles.preloader })));
    if (templates.length === 0)
        return (React.createElement("div", { className: styles.empty },
            React.createElement(Trans, { i18nKey: "noTemplates" })));
    return (React.createElement("div", { className: styles.listContainer }, templates.map((item, i) => {
        return (React.createElement(ErrorBoundary, { key: item.name },
            React.createElement(Template, { onClicked: () => onClicked ? onClicked(item) : null, template: item, highlighted: item.name === selected, isInbox: isInbox, isRTL: isRTL }),
            React.createElement("div", { className: styles.divider })));
    })));
}
