import React from 'react';
import { useStore } from '../../store/rootStore';
const FeaturesGate = ({ features, customAdapter, children }) => {
    const { channels } = useStore();
    const permitted = channels.hasFeatures(features, customAdapter);
    if (!permitted) {
        return null;
    }
    return React.createElement(React.Fragment, null, children);
};
export default FeaturesGate;
