import React from 'react';
import { Typography } from 'antd';
import s from './TemplateTitle.module.scss';
import classNames from 'classnames';
const { Text } = Typography;
const TemplateTitle = ({ title, name, isRtl }) => {
    return (React.createElement("div", { className: classNames(s.title, {
            [s.rtl]: isRtl,
        }) },
        title,
        React.createElement(Text, null, name)));
};
export default TemplateTitle;
