import React from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import Icon from '@ant-design/icons';
import { ReactComponent as EditIcon } from '../../assets/icons/pencil/penIcon.svg';
import { ReactComponent as DeleteIcon } from '../../assets/icons/deleteIcon/deleteIcon.svg';
import ErrorBoundary from '../ErrorBoundary';
import { getDirection } from '../../helpers';
import styles from './EntityManager.module.scss';
const EntityListItem = observer(function ({ entity, children, entityIdKey, disabled, onEdit, onDelete, customListActions, customActionIcons, className, }) {
    const isDisabled = disabled ? disabled(entity) : false;
    const isRTL = getDirection() === 'rtl';
    return (React.createElement(ErrorBoundary, { key: entity[entityIdKey] },
        React.createElement("li", { className: classNames(styles.entityListItem, className, {
                [styles.disabled]: isDisabled,
            }) },
            React.createElement("div", { className: styles.entityTeaser, onClick: () => {
                    if (typeof onEdit === 'function') {
                        onEdit(entity);
                    }
                } }, children),
            React.createElement("div", { className: styles.entityListItemActions },
                !isDisabled && customListActions && customListActions(entity),
                typeof onEdit === 'function' && !isDisabled && (React.createElement(Icon, { className: classNames(styles.entityListItemBtn, styles.entityListItemEditBtn, {
                        [styles.rtl]: isRTL,
                    }), component: (customActionIcons === null || customActionIcons === void 0 ? void 0 : customActionIcons.edit) || EditIcon, onClick: () => onEdit && onEdit(entity) })),
                typeof onDelete === 'function' && !isDisabled && (React.createElement(Icon, { className: classNames(styles.entityListItemBtn, styles.entityListItemDeleteBtn), component: (customActionIcons === null || customActionIcons === void 0 ? void 0 : customActionIcons.delete) || DeleteIcon, onClick: () => !isDisabled && onDelete(entity) }))))));
});
export default EntityListItem;
