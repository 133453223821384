import React from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import { Trans } from 'react-i18next';
import { observer } from 'mobx-react';
import { PHONE_FORMAT } from '../../../constants';
import { useStore } from '../../../store/rootStore';
import { getDirection } from '../../../helpers';
import s from './PhoneFormatSelect.module.scss';
const PhoneFormatSelect = () => {
    const { phoneFormat, setPhoneFormat } = useStore();
    const handleChange = (event) => {
        setPhoneFormat(event.target.value);
    };
    return (React.createElement(FormControl, { className: s.formControl },
        React.createElement(Select, { labelId: "phone-format-select-label", id: "phone-format-select", onChange: handleChange, value: phoneFormat, dir: getDirection(), MenuProps: {
                classes: {
                    paper: s.paper,
                },
            } }, Object.values(PHONE_FORMAT).map((format) => {
            return (React.createElement(MenuItem, { key: format, value: format },
                React.createElement(Trans, { i18nKey: format })));
        }))));
};
export default observer(PhoneFormatSelect);
