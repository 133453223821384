import React from 'react';
import classNames from 'classnames';
import { MESSAGE_DIRECTION } from '@whatsper/texterchat-common';
import { BsStickyFill } from 'react-icons/bs';
import { getFilePreviewUrl } from '../../../../../../store/FilesStore';
import styles from './StickerPostback.module.scss';
const StickerPostback = ({ context, name, type, incoming, invertChatColor }) => {
    let { fileId, url, caption } = context.media[0];
    if (fileId) {
        url = getFilePreviewUrl(fileId);
    }
    return (React.createElement("div", { className: classNames(styles.main, {
            [styles.reply]: type === 'reply',
        }) },
        React.createElement("div", { className: styles.info },
            React.createElement("p", { className: classNames(styles.name, {
                    [styles.incoming]: incoming,
                    [styles.invertColors]: !incoming && invertChatColor && type === 'message',
                }) }, context.direction === MESSAGE_DIRECTION.IN ? name : 'You'),
            React.createElement("div", { className: classNames(styles.text, {
                    [styles.reply]: type === 'reply',
                    [styles.invertColors]: !incoming && type === 'message',
                }) },
                React.createElement(BsStickyFill, null),
                caption || 'Sticker')),
        React.createElement("img", { className: styles.picture, src: url, alt: 'sticker' })));
};
export default StickerPostback;
