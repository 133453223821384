var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from 'react';
import { Button, Empty, Popconfirm, Table, Tag } from 'antd';
import moment from 'moment';
import { Exports } from '@whatsper/texterchat-common';
import { observer } from 'mobx-react';
import { LoadingOutlined } from '@ant-design/icons';
import { readableFileSize } from '../../../../../../helpers';
import { Trans } from 'react-i18next';
import s from '../ExportChats.module.scss';
const statusColors = {
    [Exports.Status.done]: '#0FB878',
    [Exports.Status.failed]: '#EB584E',
    [Exports.Status.in_progress]: '#546BD6',
    [Exports.Status.requested]: '#E69700', // $$primary_yellow
};
const ExportsList = ({ exports, loading, onDelete, onDownload, isMobile, accounts }) => {
    return (React.createElement(Table, Object.assign({ className: s.table, style: { height: '100%' }, sortDirections: ['descend', 'ascend', 'descend'], loading: loading, columns: [
            {
                title: React.createElement(Trans, { i18nKey: "startedAt" }),
                dataIndex: 'startedAt',
                key: 'startedAt',
                render: (text) => moment(text).format('LLL'),
                sorter: (a, b) => moment(a.startedAt).unix() - moment(b.startedAt).unix(),
            },
            {
                title: React.createElement(Trans, { i18nKey: "finishedAt" }),
                dataIndex: 'finishedAt',
                key: 'finishedAt',
                defaultSortOrder: 'descend',
                render: (text) => text && moment(text).format('LLL'),
                sorter: (a, b) => moment(a.finishedAt).unix() - moment(b.finishedAt).unix(),
            },
            {
                title: React.createElement(Trans, { i18nKey: "status" }),
                dataIndex: 'status',
                key: 'status',
                sorter: (a, b) => {
                    const statusOrder = ['done', 'in_progress', 'requested', 'failed'];
                    return statusOrder.indexOf(a.status) - statusOrder.indexOf(b.status);
                },
                defaultSortOrder: 'descend',
                render: (status) => (React.createElement(Tag, { color: statusColors[status], key: status },
                    React.createElement(Trans, { i18nKey: 'exportStatus__' + status }))),
            },
            {
                title: React.createElement(Trans, { i18nKey: "channelsAccounts" }),
                key: 'channelsAccounts',
                render: (text, record) => {
                    var _a;
                    if ((_a = record.params) === null || _a === void 0 ? void 0 : _a.channelsAccounts) {
                        if (accounts.length === 0) {
                            return React.createElement(LoadingOutlined, { className: s.loading });
                        }
                        else {
                            const accountNames = record.params.channelsAccounts
                                .map((acc) => {
                                const account = accounts.find((a) => a.accountId === acc.accountId && a.channel === acc.name);
                                return account
                                    ? account.title || account.metadata.accountTitle || account.accountId
                                    : null;
                            })
                                .filter(Boolean);
                            return accountNames.join(', ');
                        }
                    }
                    return null;
                },
            },
            ...(exports.some((e) => { var _a; return (_a = e.params) === null || _a === void 0 ? void 0 : _a.dateRange; })
                ? [
                    {
                        title: React.createElement(Trans, { i18nKey: "dateRange" }),
                        key: 'dateRange',
                        render: (text, record) => {
                            const { dateRange } = record.params;
                            if (!dateRange) {
                                return 'All Time';
                            }
                            const { start, end } = dateRange;
                            return (React.createElement("span", { style: { whiteSpace: 'nowrap' } },
                                moment(start).format('MMM Do YY'),
                                " - ",
                                moment(end).format('MMM Do YY')));
                        },
                    },
                ]
                : []),
            ...(exports.some((e) => e.scheduled)
                ? [
                    {
                        title: React.createElement(Trans, { i18nKey: "scheduled" }),
                        key: 'scheduled',
                        sorter: (a, b) => {
                            if (a.scheduled && b.scheduled) {
                                return moment(a.scheduled).unix() - moment(b.scheduled).unix();
                            }
                            return 1;
                        },
                        render: (text, record) => {
                            if (record.scheduled) {
                                return moment(record.scheduled).format('LLL');
                            }
                            return null;
                        },
                    },
                ]
                : []),
            {
                title: React.createElement(Trans, { i18nKey: "attachments" }),
                key: 'attachments',
                align: 'right',
                sorter: (a, b) => { var _a; return (((_a = a.params) === null || _a === void 0 ? void 0 : _a.includeAttachments) ? 1 : -1); },
                render: (text, record) => { var _a; return (((_a = record.params) === null || _a === void 0 ? void 0 : _a.includeAttachments) ? React.createElement("span", null, "\u2705") : null); },
            },
            {
                title: React.createElement(Trans, { i18nKey: "emptyChats" }),
                key: 'emptyChats',
                align: 'right',
                sorter: (a, b) => { var _a; return (((_a = a.params) === null || _a === void 0 ? void 0 : _a.includeEmptyChats) ? 1 : -1); },
                render: (text, record) => { var _a; return (((_a = record.params) === null || _a === void 0 ? void 0 : _a.includeEmptyChats) ? React.createElement("span", null, "\u2705") : null); },
            },
            {
                title: '',
                key: 'actions',
                render: (text, record) => (React.createElement("div", { className: s.actionsWrapper },
                    typeof onDelete === 'function' &&
                        React.createElement(Popconfirm, { title: 'Are you sure you want to delete this export?', okText: 'Delete', onConfirm: () => __awaiter(void 0, void 0, void 0, function* () { return yield onDelete(record._id.toString()); }), okButtonProps: { danger: true }, zIndex: 1200 },
                            React.createElement(Button, { size: "small", danger: true },
                                React.createElement(Trans, { i18nKey: "delete" }))),
                    typeof onDownload === 'function' && record.status === 'done' && record.fileID && (React.createElement(Button, { size: "small", type: "primary", onClick: () => record.fileID && onDownload(record.fileID), className: s.downloadButton },
                        React.createElement(Trans, { i18nKey: "download" }),
                        record.size && (React.createElement("span", { className: s.downloadSize },
                            "(",
                            readableFileSize(record.size),
                            ")")))))),
            },
        ], dataSource: [...exports], pagination: false, bordered: true, rowKey: "_id", locale: {
            emptyText: React.createElement(Empty, { image: Empty.PRESENTED_IMAGE_SIMPLE, description: "No exports found" }),
        } }, (exports.length > 0 && isMobile && { scroll: { x: '100%' } }))));
};
export default observer(ExportsList);
