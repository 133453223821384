var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
export function getConfig() {
    return new Promise((resolve, reject) => {
        if (typeof window.env.initial_settings === 'object') {
            return resolve(window.env.initial_settings);
        }
        const i = setInterval(() => {
            const env = window.env;
            if (typeof env.initial_settings === 'object') {
                resolve(env.initial_settings);
                clearInterval(i);
            }
            else if (env.initial_settings_error || !env.initial_settings_loading) {
                reject(new Error('Can`t load initial settings'));
                clearInterval(i);
            }
        }, 50);
    });
}
export function getProjectId() {
    return __awaiter(this, void 0, void 0, function* () {
        const { projectId } = yield getConfig();
        return projectId;
    });
}
export function getApiKey() {
    return __awaiter(this, void 0, void 0, function* () {
        const { googleServicesApiKey } = yield getConfig();
        return googleServicesApiKey;
    });
}
