import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Modal } from 'antd';
import classNames from 'classnames';
import { useStore } from '../../../../store/rootStore';
import CommonButton from '../CommonButton';
import s from './InactiveTabModal.module.scss';
const InactiveTabModal = ({ visible, setVisible, setActiveWindowId, close, withTimeout }) => {
    const { loginStore, setTabInactive } = useStore();
    const { i18n } = useTranslation();
    const [time, setTime] = useState(59);
    const onClose = () => {
        close();
    };
    const onUseHere = () => {
        loginStore.startInactiveTabSession();
        setActiveWindowId();
        setVisible(false);
        setTabInactive(false);
    };
    const parseTime = (time) => {
        return time < 10 ? `0:0${time}` : `0:${time}`;
    };
    useEffect(() => {
        if (visible) {
            const interval = setInterval(() => {
                setTime((prev) => prev - 1);
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [visible]);
    useEffect(() => {
        if (withTimeout) {
            if (time === 0) {
                onClose();
            }
        }
    }, [time, withTimeout]);
    return (React.createElement(Modal, { open: visible, onCancel: () => { }, maskStyle: { backgroundColor: 'rgba(255, 255, 255, 1)', zIndex: 1500 }, wrapClassName: s.modalWrapper, width: 512, maskTransitionName: "", transitionName: "", footer: null, closable: false, centered: true },
        React.createElement("div", { className: classNames(s.container, {
                [s.rtl]: i18n.dir() === 'rtl',
            }) },
            React.createElement("div", { className: s.title },
                React.createElement(Trans, { i18nKey: "inactiveTabModalTitle" })),
            React.createElement("div", { className: s.title },
                React.createElement(Trans, { i18nKey: "inactiveTabModalSubtitle" })),
            withTimeout && (React.createElement("span", { className: s.subtitle },
                React.createElement(Trans, { i18nKey: "inactiveTabModalAction", values: { time: parseTime(time) } }))),
            React.createElement("div", { className: s.buttons },
                React.createElement(CommonButton, { action: onClose },
                    React.createElement(Trans, { i18nKey: "close" })),
                React.createElement(CommonButton, { action: onUseHere, submit: true },
                    React.createElement(Trans, { i18nKey: "useHere" }))))));
};
export default InactiveTabModal;
