import React, { useRef } from 'react';
import { Trans } from "react-i18next";
import { Button, Typography } from "antd";
import classnames from "classnames";
import { WEBSITE_LINK } from "../../../../../../../constants";
import crmNotConnected from "../../../../../../../assets/icons/warnings/crmNotConnected.svg";
import styles from "./ErrorBlock.module.scss";
const { Text } = Typography;
const ErrorBlock = () => {
    const failRef = useRef(null);
    const contactUs = () => {
        var _a;
        const win = window.open(WEBSITE_LINK, 'crm');
        win === null || win === void 0 ? void 0 : win.focus();
        (_a = failRef.current) === null || _a === void 0 ? void 0 : _a.blur();
    };
    return (React.createElement("div", { className: styles.wrapper },
        React.createElement("div", { className: styles.logo },
            React.createElement("img", { src: crmNotConnected })),
        React.createElement(Text, { className: styles.title },
            React.createElement(Trans, { i18nKey: "crmNotConnected" })),
        React.createElement(Text, { className: styles.text },
            React.createElement(Trans, { i18nKey: "contactToConnectCrm" })),
        React.createElement(Button, { ref: failRef, className: classnames("white-button", styles.button), onClick: contactUs },
            React.createElement(Trans, { i18nKey: "contactUs" }))));
};
export default ErrorBlock;
