import React, { memo } from 'react';
import { isEqual } from 'lodash';
import { MESSAGE_DIRECTION } from '@whatsper/texterchat-common';
import classNames from 'classnames';
import moment from 'moment';
import { Tooltip } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import s from './ReactionMessage.module.scss';
const ReactionMessage = ({ reactions, history, direction, loading, onRemoveMessageReaction }) => {
    if (reactions && reactions.length === 0 && !loading)
        return null;
    const latestTimestamp = history === null || history === void 0 ? void 0 : history[history.length - 1].timestamp;
    return (React.createElement("div", { className: classNames(s.container, {
            [s.in]: direction === MESSAGE_DIRECTION.IN,
        }) },
        React.createElement("div", { className: s.content, onClick: () => !loading && (onRemoveMessageReaction === null || onRemoveMessageReaction === void 0 ? void 0 : onRemoveMessageReaction()) }, loading ? (React.createElement(LoadingOutlined, null)) : (React.createElement(Tooltip, { title: moment(latestTimestamp).fromNow() },
            React.createElement("div", { className: s.reactions }, reactions === null || reactions === void 0 ? void 0 :
                reactions.reverse().slice(0, 3).map((r) => React.createElement("span", { key: r }, r)),
                (reactions === null || reactions === void 0 ? void 0 : reactions.length) && reactions.length > 1 && (React.createElement("span", { className: s.totalAmount }, reactions.length))))))));
};
export default memo(ReactionMessage, (prevProps, nextProps) => {
    const propsToTrack = ['reactions', 'loading'];
    const prevPropsToTrack = propsToTrack.map((prop) => prevProps[prop]);
    const nextPropsToTrack = propsToTrack.map((prop) => nextProps[prop]);
    if (!isEqual(prevPropsToTrack, nextPropsToTrack))
        return false;
    return true;
});
