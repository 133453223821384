import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import ChatStatusTitle from '../../../../Chat/common/ChatStatusTitle';
import styles from './TopBarButton.module.scss';
import { LoadingOutlined } from '@ant-design/icons';
const TopBarButton = ({ status, active, handleClick, numberOfChats = 0, loading }) => {
    const { t } = useTranslation();
    return (React.createElement("div", { className: classnames(styles.topBarButton, { [styles.active]: active }), onClick: () => handleClick() },
        React.createElement(ChatStatusTitle, { className: classnames(styles.topBarButtonTitle, { [styles.bold]: active }), active: active, status: status, tabs: true }),
        loading ? (React.createElement(LoadingOutlined, { className: styles.loading })) : (React.createElement("div", { className: classnames(styles.topBarButtonChats, { [styles.active]: active }) },
            React.createElement(Trans, { i18nKey: "numberOfChats", values: { number: numberOfChats, chats: numberOfChats % 10 === 1 ? t('chat') : t('chats') } })))));
};
export default observer(TopBarButton);
