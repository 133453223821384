import React from 'react';
import { observer } from 'mobx-react';
import { Select } from 'antd';
import { Trans } from 'react-i18next';
import { useStore } from '../../../../store/rootStore';
import styles from './DepartmentsSelector.module.scss';
import { MySelect } from '../../../Base/Form';
const { Option } = Select;
const DepartmentsSelector = ({ disabled, onChange, value, borderColor, style, mode, allDepOption = true, notAssignedDepOption = true, size = "large", }) => {
    const { departmentsStore } = useStore();
    return (React.createElement(MySelect, { disabled: disabled, mode: mode, size: size, style: { borderColor }, className: style, onChange: onChange, value: value },
        allDepOption && (React.createElement(Option, { value: 'allDepartments' },
            React.createElement("div", { style: { backgroundColor: 'lightgrey' }, className: styles.color }),
            React.createElement(Trans, { i18nKey: "allDepartments" }))),
        notAssignedDepOption && (React.createElement(Option, { value: 'notAssigned' },
            React.createElement("div", { style: { border: '2px solid lightgrey' }, className: styles.color }),
            React.createElement(Trans, { i18nKey: "notAssigned" }))),
        departmentsStore.departments.map((department, i) => (React.createElement(Option, { key: i, value: department._id },
            React.createElement("div", { style: { backgroundColor: department.color ? department.color : 'lightgrey' }, className: styles.color }),
            department.name)))));
};
export default observer(DepartmentsSelector);
