import React from 'react';
import { Trans } from "react-i18next";
import styles from "./ClientInfo.module.scss";
const ClientInfo = (props) => {
    const { name, phone, id } = props;
    return (React.createElement("div", { className: styles.clientInfo },
        React.createElement("div", null,
            React.createElement("div", { className: styles.clientInfoSubheader },
                React.createElement(Trans, { i18nKey: "name" }),
                ":"),
            React.createElement("div", { className: styles.clientInfoDetails }, name)),
        React.createElement("div", null,
            React.createElement("div", { className: styles.clientInfoSubheader },
                React.createElement(Trans, { i18nKey: "phone" }),
                ":"),
            React.createElement("div", { className: styles.clientInfoDetails }, phone)),
        React.createElement("div", null,
            React.createElement("div", { className: styles.clientInfoSubheader },
                React.createElement(Trans, { i18nKey: "id" }),
                ":"),
            React.createElement("div", { className: styles.clientInfoDetails }, id))));
};
export default ClientInfo;
