var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import classNames from 'classnames';
import PasswordUpdate from './PasswordUpdate';
import PasswordLogin from './PasswordLogin';
import s from '../../../Login.module.scss';
const Password = ({ shouldUpdatePassword, showPasswordReset, resetPassword, onSubmit }) => {
    const [formData, setFormData] = useState({ email: '', password: '', newPassword: '' });
    const [checkEmail, setCheckEmail] = useState(false);
    const [loading, setLoading] = useState(false);
    const disabled = loading ||
        Object.keys(formData)
            // Ignore newPassword if shouldUpdatePassword is not true
            .filter((key) => shouldUpdatePassword || key !== 'newPassword')
            .some((key) => !formData[key]);
    const handleResetPassword = () => {
        if (formData.email) {
            resetPassword(formData.email)
                .then(() => {
                setCheckEmail(true);
            })
                .catch((e) => {
                console.error(e.error.message);
            });
        }
        setTimeout(() => setCheckEmail(false), 3000);
    };
    const onSubmitHandler = (e) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            e.preventDefault();
            setLoading(true);
            yield onSubmit(formData.email, formData.password, shouldUpdatePassword ? formData.newPassword : undefined);
        }
        catch (e) {
            console.error(e.error.message);
        }
        finally {
            setLoading(false);
        }
    });
    return (React.createElement("div", { className: s.pageWrapper },
        shouldUpdatePassword ? (React.createElement(PasswordUpdate, { onSubmitHandler: onSubmitHandler, formData: formData, setFormData: setFormData, loading: loading, disabled: disabled })) : (React.createElement(PasswordLogin, { onSubmitHandler: onSubmitHandler, formData: formData, setFormData: setFormData, loading: loading, disabled: disabled })),
        checkEmail ? (React.createElement("p", { className: classNames(s.notificationText, s.loginEmailCheck) },
            React.createElement(Trans, { i18nKey: "checkEmail" }))) : null,
        showPasswordReset && (React.createElement("a", { className: s.linkButton, onClick: handleResetPassword },
            React.createElement(Trans, { i18nKey: "resetPassword" })))));
};
export default Password;
