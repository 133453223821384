import React from 'react';
import { Tooltip } from "antd";
import Icon from "@ant-design/icons";
import { ReactComponent as ErrorExclamation } from "../../../../../assets/icons/warnings/errorExclamation.svg";
import styles from "./MessageErrorIndicator.module.scss";
const MessageErrorIndicator = (props) => {
    const { message } = props;
    return (React.createElement("div", null,
        React.createElement(Tooltip, { placement: "topRight", arrowPointAtCenter: true, title: message, trigger: ["click", "hover"] },
            React.createElement(Icon, { className: styles.icon, component: ErrorExclamation }))));
};
export default MessageErrorIndicator;
