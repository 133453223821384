var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { omit } from 'lodash';
import { observer } from 'mobx-react';
import React, { useRef } from 'react';
import Icon, { LinkOutlined, SettingOutlined } from '@ant-design/icons';
import { Tooltip, message } from 'antd';
import { Trans, useTranslation } from 'react-i18next';
import { useStore } from '../../store/rootStore';
import { EntityManagerRoute, Operation } from '../EntityManager';
import ChannelsForm from './ChannelsForm/ChannelsForm';
import { Permissions } from '@whatsper/texterchat-common';
import classNames from 'classnames';
import { getChannelSharableLink, getDirection } from '../../helpers';
import entityManagerStyles from '../EntityManager/EntityManager.module.scss';
import s from './ChannelsManager.module.scss';
const ChannelsManager = () => {
    const { channels, agentsStore } = useStore();
    const formRef = useRef(null);
    const { t } = useTranslation();
    const { accounts, fetchChannelsAccounts, createChannelAccount, getChannelAccount, editChannelAccount, deleteChannelAccount, loading, setLoading, themes, } = channels;
    const renderOperationTitle = (op, channel) => {
        switch (op) {
            case Operation.LIST:
                return React.createElement(Trans, { i18nKey: "channelManager" });
            case Operation.CREATE:
                return React.createElement(Trans, { i18nKey: "addChannel" });
            case Operation.EDIT:
                return React.createElement(Trans, { i18nKey: "editChannelName", values: { name: channel === null || channel === void 0 ? void 0 : channel.title } });
            case Operation.DELETE:
                return React.createElement(Trans, { i18nKey: "deleteChannelName", values: { name: channel === null || channel === void 0 ? void 0 : channel.title } });
        }
    };
    const renderDeleteQuestion = (channel) => React.createElement(Trans, { i18nKey: "sureDeleteName", values: { name: channel.title } });
    const errorHandler = (formData) => {
        if (!formData.adapterName) {
            throw new Error(t('adapterRequired'));
        }
        if (formRef.current && !formRef.current.validateForm()) {
            throw new Error(t('credentialsNotValid'));
        }
    };
    const channelSave = (formData, channel) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            setLoading(true);
            errorHandler(formData);
            if (channel) {
                yield editChannelAccount(Object.assign(Object.assign({}, channel), omit(formData, 'credentials')), formData.credentials);
            }
            else {
                const { adapterName } = formData, account = __rest(formData, ["adapterName"]);
                if (adapterName) {
                    yield createChannelAccount(adapterName, 
                    // @ts-expect-error // We check that credentials valid in errorHandle
                    account);
                }
            }
        }
        finally {
            setLoading(false);
        }
    });
    const channelDelete = (channel) => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        try {
            yield deleteChannelAccount(channel);
        }
        finally {
            setLoading(false);
        }
    });
    const customListActions = (channel) => {
        const onClick = () => {
            if (window.isSecureContext) {
                const url = getChannelSharableLink({
                    name: channel.channel,
                    id: channel.accountId,
                });
                navigator.clipboard.writeText(url);
                message.success(t('channelLinkCopiedToClipboard'));
            }
            else {
                message.error(t('clipboardNotSupported'));
            }
        };
        return (React.createElement(Tooltip, { title: t('copyLinkToChat') },
            React.createElement(Icon, { className: classNames(s.copyIconBtn, entityManagerStyles.entityListItemBtn, {
                    [entityManagerStyles.rtl]: getDirection() === 'rtl',
                }), 
                // @ts-expect-error Couldn't figure out what the problem was
                component: LinkOutlined, onClick: onClick })));
    };
    const isDisabled = (channel) => false;
    const Teaser = ({ entity: { title, channel, metadata, accountId } }) => {
        var _a;
        return (React.createElement("div", { className: s.teaserContainer },
            React.createElement("img", { src: (_a = themes[accountId]) === null || _a === void 0 ? void 0 : _a.icon, alt: "icon", className: s.teaserIcon }),
            React.createElement("div", { className: s.teaserContent },
                React.createElement("div", { className: s.teaserName },
                    channel,
                    " (",
                    title || metadata.accountTitle || metadata.pageName || metadata.businessName || accountId,
                    ")"),
                React.createElement("div", { className: s.teaserNumber }, accountId))));
    };
    const getOpSupported = () => {
        const { hasPermissions } = agentsStore;
        const op = [];
        if (hasPermissions([Permissions.Permission.channels_view])) {
            op.push(Operation.LIST);
        }
        if (hasPermissions([Permissions.Permission.channels_manage])) {
            op.push(Operation.CREATE, Operation.EDIT);
        }
        return op;
    };
    return (React.createElement(EntityManagerRoute, { path: "/manage/channels", entities: accounts, entityIdKey: "_id", teaser: Teaser, form: ChannelsForm, formProps: { getChannelAccount, formRef }, renderOperationTitle: renderOperationTitle, renderDeleteQuestion: renderDeleteQuestion, onMounted: fetchChannelsAccounts, onEntityDelete: channelDelete, onEntitySave: channelSave, disabled: isDisabled, onClose: () => { }, loading: loading, opSupported: getOpSupported(), customListActions: customListActions, customActionIcons: {
            edit: SettingOutlined
        } }));
};
export default observer(ChannelsManager);
