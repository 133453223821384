var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React from "react";
import { observer } from "mobx-react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import FileSaver from "file-saver";
import { Trans } from "react-i18next";
import testCsv from "../../../../assets/csvFilesAndIcons/exampleCSVFile.csv";
import FileUploadError from "./FileUploadBlock/FileUploadError";
import FileUploadSuccess from "./FileUploadBlock/FileUploadSuccess";
import FileUploadButton from "./FileUploadBlock/FileUploadButton";
import TemplateTitle from "../TemplateTitle/TemplateTitle";
import classNames from "classnames";
import { getDirection } from "../../../../helpers";
import { withStore } from "../../../../store/rootStore";
import s from "./ThirdPage.module.scss";
const ThirdPage = ({ store: { wizardStore } }) => {
    var _a, _b;
    const downloadCsvHandle = () => __awaiter(void 0, void 0, void 0, function* () {
        const newUrl = yield fetch(testCsv).then((resp) => resp.blob());
        FileSaver.saveAs(newUrl, "testCsv.csv");
    });
    const { uploadedFile } = wizardStore;
    return (React.createElement("div", { className: classNames(s["upload-csv-page-main"], {
            [s.rtl]: getDirection() === 'rtl',
        }) },
        React.createElement(TemplateTitle, { title: ((_a = wizardStore.chosenTemplate) === null || _a === void 0 ? void 0 : _a.title) || '', name: ((_b = wizardStore.chosenTemplate) === null || _b === void 0 ? void 0 : _b.name) || '', isRtl: getDirection() === 'rtl' }),
        React.createElement("div", { className: s["file-upload-block"] },
            !uploadedFile || (uploadedFile && !uploadedFile.id && !uploadedFile.error) ? React.createElement(FileUploadButton, null) : null,
            uploadedFile && uploadedFile.id && !uploadedFile.error ? React.createElement(FileUploadSuccess, null) : null,
            uploadedFile && uploadedFile.error ? React.createElement(FileUploadError, null) : null),
        React.createElement("div", { className: s["csv-wizard-warning-block"] },
            React.createElement(ExclamationCircleOutlined, { className: s["wizard-info-circle"] }),
            React.createElement("p", { className: s["wizard-text"] },
                React.createElement(Trans, { i18nKey: "checkAmountOfParams", components: { 1: React.createElement("a", { className: s["csv-example"], onClick: downloadCsvHandle }) } })))));
};
export default withStore(observer(ThirdPage));
