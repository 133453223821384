import React, { Component } from 'react';
import { Alert } from 'antd';
import { MySelect, MyInput } from '../../Base/Form';
import styles from './TokenForm.module.css';
import { Trans, withTranslation } from 'react-i18next';
class TokenForm extends Component {
    constructor(props) {
        super(props);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                title: '',
                token: '',
                scopes: [],
            }
        });
        Object.defineProperty(this, "componentDidMount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.props.onInput(this.state, this.canBeSavedState());
            }
        });
        Object.defineProperty(this, "renderScopesSelectOptions", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                return Object.entries(this.props.scopesInfo).map(([scopeName, scopeInfo], i) => ({
                    label: scopeInfo.title,
                    value: scopeName,
                }));
            }
        });
        Object.defineProperty(this, "onInput", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (formData) => {
                this.setState(Object.assign({}, formData), () => this.props.onInput(this.state, this.canBeSavedState()));
            }
        });
        Object.defineProperty(this, "canBeSavedState", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                return this.state.title.trim().length > 0 && this.state.scopes.length > 0;
            }
        });
        if (props.entity) {
            this.state = Object.assign(Object.assign({}, this.state), props.entity);
        }
    }
    render() {
        const { title, scopes } = this.state, { error } = this.props;
        return (React.createElement(React.Fragment, null,
            error
                ? React.createElement(Alert, { message: "Error", description: error, type: "error", showIcon: true, style: { marginBottom: 30 } })
                : null,
            this.props.entity
                ? React.createElement("div", { className: styles.inputWrap },
                    React.createElement("label", null,
                        React.createElement(Trans, { i18nKey: "apiToken" })),
                    React.createElement(MyInput.TextArea, { value: this.props.entity.token, disabled: true, autoSize: true }))
                : null,
            React.createElement("div", { className: styles.inputWrap },
                React.createElement("label", null,
                    React.createElement(Trans, { i18nKey: "title" })),
                React.createElement(MyInput, { value: title, onChangeHandler: (value) => this.onInput({ title: value }) })),
            React.createElement("div", { className: styles.inputWrap },
                React.createElement("label", null,
                    React.createElement(Trans, { i18nKey: "chooseScopes" })),
                React.createElement(MySelect, { value: scopes, mode: "multiple", allowClear: true, onChange: (scopes) => this.onInput({ scopes }), className: styles.scopesSelect, style: { width: '100%' }, options: this.renderScopesSelectOptions() }))));
    }
}
export default withTranslation()(TokenForm);
