import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Trans } from 'react-i18next';
import CommonModal from '../../../../common/CommonModal';
import MessagePreview from '../MessagePreview';
import GeneratorInput from '../../GeneratorInput/GeneratorInput';
import InfoBlock from './InfoBlock';
import { ReactComponent as GreenPlus } from '../../../../../../assets/icons/plus/greenPlus.svg';
import { withStore } from '../../../../../../store/rootStore';
import UploadButton from '../../../../common/UploadButton';
import { ExclamationCircleOutlined, UploadOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { getDirection } from '../../../../../../helpers';
import { TEMPLATE_HEADER_TYPE, WhatsApp } from '@whatsper/texterchat-common';
import { RICH_TEMPLATE_BUTTON_TYPE } from '../../../../../../constants';
import styles from './MessagePreviewModal.module.scss';
const MessagePreviewModal = ({ visible, setModalVisible, disabled, store }) => {
    const { bodyExampleTemporaryParams, changeTempExampleParam, setTemplateExamples, nextPlaceholderNumber, richTemplateHeaderType, richTemplateHeader, richTemplateHeaderUrl, richTemplateFooter, bodyExampleParams, handleSetHeader, quickReplyButtons, callToActionButtons, templateMessage, } = store.templateMessagesStore;
    const [imagePreviewSrc, setImagePreviewSrc] = useState('');
    const [imagePreviewName, setImagePreviewName] = useState('');
    const [hadInitialFile, setHadInitialFile] = useState(false);
    const [error, setError] = useState('');
    const isImage = richTemplateHeaderType === TEMPLATE_HEADER_TYPE.IMAGE;
    const onFileOrImageLoad = (files) => {
        if (files instanceof Error) {
            setError(files.message);
        }
        else {
            handleSetHeader(files);
            setError('');
            if (richTemplateHeaderType && [TEMPLATE_HEADER_TYPE.IMAGE, TEMPLATE_HEADER_TYPE.VIDEO].includes(richTemplateHeaderType)) {
                setImagePreviewSrc(URL.createObjectURL(files[0]));
            }
        }
    };
    const onCancel = () => {
        setImagePreviewSrc('');
        setImagePreviewName('');
        if (!hadInitialFile) {
            handleSetHeader('');
        }
    };
    useEffect(() => {
        if (visible) {
            if (richTemplateHeaderType && [TEMPLATE_HEADER_TYPE.IMAGE, TEMPLATE_HEADER_TYPE.DOCUMENT, TEMPLATE_HEADER_TYPE.VIDEO].includes(richTemplateHeaderType)) {
                if (typeof richTemplateHeader !== 'string' && richTemplateHeader !== null) {
                    if (richTemplateHeaderType && [TEMPLATE_HEADER_TYPE.IMAGE, TEMPLATE_HEADER_TYPE.VIDEO].includes(richTemplateHeaderType)) {
                        const src = URL.createObjectURL(richTemplateHeader);
                        setImagePreviewSrc(src);
                    }
                    setImagePreviewName(richTemplateHeader.name);
                }
            }
            else {
                setImagePreviewSrc('');
                setImagePreviewName('');
            }
        }
    }, [visible]);
    useEffect(() => {
        if (visible) {
            if (richTemplateHeaderType && [TEMPLATE_HEADER_TYPE.IMAGE, TEMPLATE_HEADER_TYPE.VIDEO].includes(richTemplateHeaderType) &&
                typeof richTemplateHeader !== 'string' &&
                richTemplateHeader !== null) {
                setHadInitialFile(true);
            }
        }
    }, [visible]);
    return (React.createElement(CommonModal, { visible: visible, headerIcon: GreenPlus, titleTextKey: 'addSampleData', modalActionText: 'setData', setModalVisible: setModalVisible, modalAction: () => {
            setTemplateExamples(bodyExampleTemporaryParams);
            setModalVisible();
        }, onCancel: onCancel, centerContent: false, destroyOnClose: true },
        React.createElement("div", { className: classNames(styles.modalContent, {
                [styles.rtl]: getDirection() === 'rtl',
            }) },
            React.createElement("div", { className: styles.inputWrapper },
                React.createElement(InfoBlock, null),
                richTemplateHeaderType && [TEMPLATE_HEADER_TYPE.IMAGE, TEMPLATE_HEADER_TYPE.VIDEO].includes(richTemplateHeaderType) && (React.createElement("div", { className: styles.uploadButtonContainer },
                    React.createElement(UploadButton, { onFileSelected: onFileOrImageLoad, text: imagePreviewName || (isImage ? 'uploadSampleImage' : 'uploadSampleVideo'), types: isImage ? ['image/*'] : ['video/*'], additionalStyles: styles.uploadButton, icon: React.createElement(UploadOutlined, null), disabled: disabled, fluid: true }),
                    React.createElement("div", { className: styles.buttonHelpText },
                        React.createElement(ExclamationCircleOutlined, { className: styles.infoCircle }),
                        React.createElement("p", { className: styles.infoText },
                            React.createElement(Trans, { i18nKey: isImage ? "recommendedImageSize" : "recommendedVideoSize" }))))),
                richTemplateHeaderType === TEMPLATE_HEADER_TYPE.DOCUMENT && (React.createElement("div", { className: styles.uploadButtonContainer },
                    React.createElement(UploadButton, { onFileSelected: onFileOrImageLoad, text: imagePreviewName || 'uploadSampleFile', types: ['.pdf', '.doc', '.docx', '.csv'], additionalStyles: styles.uploadButton, icon: React.createElement(UploadOutlined, null), disabled: disabled, fluid: true }),
                    React.createElement("div", { className: styles.buttonHelpText },
                        React.createElement(ExclamationCircleOutlined, { className: styles.infoCircle }),
                        React.createElement("p", { className: styles.infoText },
                            React.createElement(Trans, { i18nKey: "recommendedFileSize" }))))),
                bodyExampleTemporaryParams &&
                    [...Array(nextPlaceholderNumber - 1)].map((e, index) => (React.createElement("div", { key: index },
                        React.createElement(GeneratorInput, { text: bodyExampleTemporaryParams[index] || '', onChange: (value) => changeTempExampleParam(index, value), placeholder: "enterParamExample", placeholderParams: { number: index + 1 }, additionalStyles: styles.input, disabled: disabled, options: {
                                maxLength: 1024,
                                showCount: true,
                            } }))))),
            React.createElement("div", { className: styles.previewWrapper },
                React.createElement(MessagePreview, { header: {
                        type: richTemplateHeaderType,
                        text: richTemplateHeader,
                        file: richTemplateHeader,
                        url: richTemplateHeaderUrl,
                        previewUrl: imagePreviewSrc,
                    }, body: {
                        text: templateMessage,
                        params: bodyExampleTemporaryParams || bodyExampleParams,
                    }, footer: {
                        type: WhatsApp.TemplateComponentType.FOOTER,
                        text: richTemplateFooter || '',
                    }, buttons: quickReplyButtons
                        ? {
                            buttons: quickReplyButtons,
                            type: RICH_TEMPLATE_BUTTON_TYPE.QUICK_REPLY,
                        }
                        : callToActionButtons
                            ? {
                                buttons: callToActionButtons,
                                type: RICH_TEMPLATE_BUTTON_TYPE.CALL_TO_ACTION,
                            }
                            : undefined })))));
};
export default withStore(observer(MessagePreviewModal));
