import React, { Component, createRef } from "react";
import { observer } from "mobx-react";
import { Trans, withTranslation } from "react-i18next";
import { Button, Tooltip, Typography, Radio } from "antd";
import Icon, { QuestionCircleOutlined, RightOutlined, LoadingOutlined } from "@ant-design/icons";
import classnames from "classnames";
import styles from "./AdvancedSettings.module.scss";
import { TEMPLATE_RADIO_STATUSES, CHAT_STATUS, ADDITIONAL_CHAT_STATUS, TEMPLATE_USAGE, } from "../../../../../../constants";
import { withStore } from "../../../../../../store/rootStore";
import AssignToModal from "../../../../../MainPage/modals/AssignToModal";
import { ReactComponent as QuickRepliesPlus } from "../../../../../../assets/icons/plus/quickRepliesPlus.svg";
import blackPicture from "../../../../../../assets/avatars/blankProfilePicture.png";
import CustomReply from "./CustomReply";
import { MySelect } from "../../../../../Base/Form";
import { getDirection } from "../../../../../../helpers";
const { Text } = Typography;
class AdvancedSettings extends Component {
    constructor(props) {
        super(props);
        Object.defineProperty(this, "chooseButtonRef", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "agentButtonRef", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "departmentButtonRef", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "attachFileButtonRef", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "getAgent", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (agentId) => {
                const { users } = this.props.store.agentsStore;
                const chosenAgent = users.find((agent) => agent.uid === agentId);
                return chosenAgent ? chosenAgent.displayName : null;
            }
        });
        Object.defineProperty(this, "getDepartment", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (departmentId) => {
                const { departments } = this.props.store.departmentsStore;
                return departments.find((department) => department._id === departmentId);
            }
        });
        Object.defineProperty(this, "toggleAssignModal", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setState({ isAssignModalVisible: !this.state.isAssignModalVisible });
            }
        });
        this.state = {
            isAssignModalVisible: false,
        };
        this.chooseButtonRef = createRef();
        this.agentButtonRef = createRef();
        this.departmentButtonRef = createRef();
        this.attachFileButtonRef = createRef();
    }
    componentDidMount() {
        const { selectedChannelBot, fetchBotNodeList } = this.props.store.templateMessagesStore;
        fetchBotNodeList(selectedChannelBot || '');
    }
    render() {
        const { canAttachFile, canChangeMode, editMode, store } = this.props;
        const { responsibleAgent, responsibleDepartment: departmentId, getBotNodeList, botNodeListLoading, templateChatStatus, handleSetStatus, setBotNode, handleSetBotNode, handleSetRadioStatus, templateChatRadioStatus, templateUsage, templateFieldMissing, selectedChannelBot, } = store.templateMessagesStore;
        const { chosenTemplate } = store.wizardStore;
        const responsibleDepartment = departmentId ? this.getDepartment(departmentId) : null;
        return (React.createElement("div", { className: classnames(styles.advancedSettingsBlock, {
                [styles.noMargin]: !canChangeMode || editMode,
            }) },
            canChangeMode && (React.createElement(Radio.Group, { onChange: (e) => handleSetRadioStatus(e.target.value), value: templateChatRadioStatus },
                React.createElement(Radio, { value: TEMPLATE_RADIO_STATUSES.TAKEN, disabled: templateUsage === TEMPLATE_USAGE.BULK || Object.keys(chosenTemplate || {}).length > 0 },
                    React.createElement(Trans, { i18nKey: "keepChatAssigned" })),
                React.createElement(Radio, { value: TEMPLATE_RADIO_STATUSES.OTHER },
                    React.createElement(Trans, { i18nKey: "moveToBulkAndReply" })))),
            templateChatRadioStatus === TEMPLATE_RADIO_STATUSES.OTHER && (React.createElement("div", { className: styles.generalBlock },
                React.createElement("label", { className: styles.label },
                    React.createElement(Trans, { i18nKey: "chatStatus" }),
                    React.createElement(Tooltip, { title: React.createElement(Trans, { i18nKey: "chatStatusHelp" }), placement: getDirection() === "rtl" ? "bottomLeft" : "bottomRight", arrowPointAtCenter: true },
                        React.createElement(QuestionCircleOutlined, { className: styles.labelIcon }))),
                React.createElement(MySelect, { dropdownStyle: { padding: "0" }, value: templateChatStatus, onChange: handleSetStatus, size: "large", options: [
                        {
                            label: React.createElement(Trans, { i18nKey: "withBot" }),
                            value: CHAT_STATUS.BOT,
                        },
                        {
                            label: React.createElement(Trans, { i18nKey: "pendingTo" }),
                            value: CHAT_STATUS.ASSIGNED,
                        },
                        {
                            label: React.createElement(Trans, { i18nKey: "pendingToSendingAgent" }),
                            value: ADDITIONAL_CHAT_STATUS.PENDING_SELF,
                        },
                        {
                            label: React.createElement(Trans, { i18nKey: "resolved" }),
                            value: CHAT_STATUS.RESOLVED,
                        }
                    ] }),
                templateChatStatus === CHAT_STATUS.BOT && (React.createElement(React.Fragment, null,
                    React.createElement("label", { className: styles.label },
                        React.createElement(Trans, { i18nKey: "botNode" }),
                        React.createElement(Tooltip, { title: React.createElement(Trans, { i18nKey: "botNodeHelp" }), placement: getDirection() === "rtl" ? "bottomLeft" : "bottomRight", arrowPointAtCenter: true },
                            React.createElement(QuestionCircleOutlined, { className: styles.labelIcon }))),
                    React.createElement(MySelect, { value: setBotNode || "start", onChange: handleSetBotNode, virtual: false, options: getBotNodeList(selectedChannelBot || ''), loading: botNodeListLoading, notFoundContent: botNodeListLoading ? React.createElement(LoadingOutlined, { style: { fontSize: 16 } }) : null, size: "large" }))),
                templateChatStatus === CHAT_STATUS.ASSIGNED && (React.createElement(React.Fragment, null,
                    React.createElement("label", { className: styles.label },
                        React.createElement(Trans, { i18nKey: "agentOrDepartment" }),
                        templateFieldMissing && (React.createElement(Text, { className: styles.fieldRequired },
                            React.createElement(Trans, { i18nKey: "fieldRequired" })))),
                    !responsibleAgent && !responsibleDepartment && (React.createElement(Button, { ref: this.chooseButtonRef, className: styles.advancedButton, onClick: () => {
                            var _a;
                            (_a = this.chooseButtonRef.current) === null || _a === void 0 ? void 0 : _a.blur();
                            this.toggleAssignModal();
                        } },
                        React.createElement(Icon, { className: styles.quickReplyIcon, component: QuickRepliesPlus }),
                        React.createElement(Text, { className: styles.createNewTemplateButtonText, strong: true },
                            React.createElement(Trans, { i18nKey: "assignResponsible" })))),
                    !!responsibleAgent && !responsibleDepartment && (React.createElement(Button, { ref: this.agentButtonRef, className: classnames(styles.advancedButton, styles.advancedChosenButton), onClick: () => {
                            var _a;
                            (_a = this.agentButtonRef.current) === null || _a === void 0 ? void 0 : _a.blur();
                            this.toggleAssignModal();
                        } },
                        React.createElement("div", { className: styles.chosenResponsibleBlock },
                            React.createElement("img", { className: styles.chosenAgentAvatar, src: blackPicture }),
                            React.createElement(Text, null, this.getAgent(responsibleAgent))),
                        React.createElement("div", { className: styles.chosenResponsibleBlock },
                            React.createElement(Text, { className: styles.chosenResponsibleText },
                                React.createElement(Trans, { i18nKey: "change" })),
                            React.createElement(RightOutlined, { style: { color: "#02CA79" } })))),
                    !responsibleAgent && !!responsibleDepartment && (React.createElement(Button, { ref: this.departmentButtonRef, className: classnames(styles.advancedButton, styles.advancedChosenButton), onClick: () => {
                            var _a;
                            (_a = this.departmentButtonRef.current) === null || _a === void 0 ? void 0 : _a.blur();
                            this.toggleAssignModal();
                        } },
                        React.createElement("div", { className: styles.chosenResponsibleBlock },
                            React.createElement("div", { className: styles.chosenDepartmentColor, style: { backgroundColor: responsibleDepartment.color } }),
                            React.createElement(Text, null, responsibleDepartment.name)),
                        React.createElement("div", { className: styles.chosenResponsibleBlock },
                            React.createElement(Text, { className: styles.chosenResponsibleText },
                                React.createElement(Trans, { i18nKey: "change" })),
                            React.createElement(RightOutlined, { style: { color: "#02CA79" } })))),
                    React.createElement(AssignToModal, { isAssignModalVisible: this.state.isAssignModalVisible, toggleModal: this.toggleAssignModal, assignFunction: (chatId, uid, depId) => {
                            this.props.store.templateMessagesStore.setResponsibleAgentOrDepartment(uid, depId);
                            return Promise.resolve();
                        } }))))),
            templateChatStatus !== CHAT_STATUS.BOT && templateChatRadioStatus !== TEMPLATE_RADIO_STATUSES.TAKEN && (React.createElement(CustomReply, { canAttachFile: canAttachFile }))));
    }
}
Object.defineProperty(AdvancedSettings, "defaultProps", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: {
        canChangeMode: true,
        editMode: false,
    }
});
export default withTranslation()(withStore(observer(AdvancedSettings)));
