import React, { useEffect, useState } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Checkbox, Tooltip } from 'antd';
import { observer } from 'mobx-react';
import { Trans, useTranslation } from 'react-i18next';
import { RICH_TEMPLATE_HEADER_TYPE } from '../../../../../../constants';
import { getDirection } from '../../../../../../helpers';
import s from './SetDefault.module.scss';
const SetDefault = ({ isDefault, isApproved, onChange, currentDefault, headerType, body }) => {
    const { t } = useTranslation();
    const [violation, setViolation] = useState('');
    /**
     *
     * @param type RICH_TEMPLATE_HEADER_TYPE
     * @returns {boolean} true if template has no header file/image
     */
    const validateHeader = (type) => {
        const hasHeader = [RICH_TEMPLATE_HEADER_TYPE.IMAGE, RICH_TEMPLATE_HEADER_TYPE.DOCUMENT].includes(type);
        return !hasHeader;
    };
    /**
     *
     * @param message Template message
     * @returns {boolean} true if message has only one placeholder and no more placeholders
     */
    const validateParam = (message) => {
        const pattern = /^(?!.*\{\{2\}})(?=.*\{\{1\}})[\s\S]*\{\{1\}}(?!.*\{\{1\}})[\s\S]*$/;
        const match = message.match(pattern);
        return match;
    };
    /**
     *
     * @param message Template message
     * @returns {boolean} true if left part of the message is less than 15 char and right part of the message is less than 15 char
     */
    const validateLength = (message) => {
        const pattern = /^.{0,15}\{\{1\}}.{0,15}$/;
        const match = message.match(pattern);
        return match;
    };
    useEffect(() => {
        let v = '';
        if (!isApproved) {
            v = 'Template must be approved';
        }
        else if (!validateHeader(headerType)) {
            v = 'Template cannot have header';
        }
        else if (!validateParam(body)) {
            v = 'Template must have only one placeholder';
        }
        else if (!validateLength(body)) {
            v = 'Template must have left and right part of the message shorter than 15 char';
        }
        setViolation(v);
        // We need to unselect it when it becomes invalid
        if (v && isDefault)
            onChange(false);
    }, [isDefault, isApproved, headerType, body]);
    const renderCheckbox = () => (React.createElement(Checkbox, { onChange: (e) => onChange(e.target.checked), checked: isDefault, disabled: !!violation }, t('setAsDefaultTemplate')));
    return (React.createElement("div", { className: s.wrapper },
        React.createElement(Tooltip, { title: violation || '', placement: getDirection() === 'rtl' ? 'topRight' : 'topLeft' }, renderCheckbox()),
        !!currentDefault &&
            React.createElement("div", { className: s.warningBlock },
                React.createElement(ExclamationCircleOutlined, { className: s.warningCircleIcon }),
                React.createElement("p", { className: s.warningText },
                    React.createElement(Trans, { i18nKey: "currentDefaultTemplate", values: { name: currentDefault }, components: { bold: React.createElement("strong", null) } })))));
};
export default observer(SetDefault);
