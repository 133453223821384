import React from 'react';
import classnames from 'classnames';
import { DoubleRightOutlined } from '@ant-design/icons';
import { getBackgroundColor, getDirection } from '../../../helpers/functions';
import { useStore } from '../../../store/rootStore';
import './ScrollToUnreadButton.scss';
const ScrollToUnreadButton = ({ unreadCount, handleClick }) => {
    var _a;
    const { activeChatStore: { theme }, } = useStore();
    const background = getBackgroundColor(theme, { forcePrimaryColor: true });
    const invertColors = theme.invertColors || ((_a = theme.additionalData) === null || _a === void 0 ? void 0 : _a.invertChatColor);
    return (React.createElement("div", { className: classnames('scroll-button-container', {
            ['rtl']: getDirection() === 'rtl',
        }) },
        unreadCount > 0 ? (React.createElement("div", { className: classnames('unread-counter', { invertColors }), style: { background } },
            React.createElement("p", { className: "unread-counter-number" }, unreadCount))) : null,
        React.createElement("div", { className: classnames('scroll-button', { invertColors }), onClick: () => handleClick(), style: { background } },
            React.createElement(DoubleRightOutlined, { className: "scroll-down-arrow" }))));
};
export default ScrollToUnreadButton;
