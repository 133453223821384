import React, { useRef } from 'react';
import { Switch } from 'antd';
import classnames from "classnames";
import styles from "./CommonSwitch.module.scss";
const CommonSwitch = (props) => {
    const { checked, onChange, disabled } = props;
    const switchRef = useRef(null);
    return (React.createElement(Switch, { className: classnames(styles.switch, { [styles.checked]: checked }), checked: checked, disabled: disabled, ref: switchRef, onChange: () => {
            var _a;
            onChange();
            (_a = switchRef.current) === null || _a === void 0 ? void 0 : _a.blur();
        } }));
};
export default CommonSwitch;
