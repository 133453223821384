import React from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import { Trans, withTranslation } from 'react-i18next';
import { Button } from 'antd';
import { useStore } from '../../../store/rootStore';
import blockerImage from '../../../assets/avatars/blockerImage.svg';
import styles from './NoActiveChatSelected.module.scss';
const NoActiveChatSelected = ({ bottomBlock }) => {
    const { navigation } = useStore();
    return (React.createElement("div", { className: styles.blockerContainer },
        React.createElement("div", { className: styles.blockerInnerContainer },
            React.createElement("img", { className: styles.blockerImage, src: blockerImage }),
            React.createElement("p", { className: styles.blockerTitle },
                React.createElement(Trans, { i18nKey: "noContactSelected" })),
            bottomBlock && (React.createElement(React.Fragment, null,
                React.createElement("p", { className: styles.blockerText },
                    React.createElement(Trans, { i18nKey: "selectContact" })),
                React.createElement(Button, { className: classnames(styles.blockerButton, 'white-button'), onClick: navigation.toggleMessageDrawer },
                    React.createElement(Trans, { i18nKey: "sendNewMessage" })))))));
};
export default withTranslation()(observer(NoActiveChatSelected));
