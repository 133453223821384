import React, {Component} from 'react';
import {observer} from "mobx-react";
import { withStore } from '../../../../store/rootStore';
import styles from "./ChatDepartmentTitle.module.scss";


class ChatDepartmentTitle extends Component {

    getDepartment = () => {
        const departmentId = this.props.departmentId;
        // var store = this.props.store;
        let department = {name:"",color:"#fff"};
        if (this.props.store.departmentsStore.departments.length) {
            const department2 = this.props.store.departmentsStore.departments.find(d => d._id === departmentId);
            if (!!department2) {
                // console.log(department2);
                department = department2;
            }
        }
        return department;
    }

    render() {
        const department = this.getDepartment();
        return (
            <span
                className={styles.text}
                style={{backgroundColor: department.color}}
            >
                {department.name}
            </span>
        );
    }
}

export default withStore(observer(ChatDepartmentTitle));
