import { ArrowLeftOutlined } from '@ant-design/icons';
import React from 'react';
import LoadingIcon from '../../../../../Chat/common/LoadingIcon';
import classNames from 'classnames';
import { AiOutlineDownload } from 'react-icons/ai';
import { Tooltip } from 'antd';
import { Trans } from 'react-i18next';
import s from '../FilesMediaLinks.module.scss';
const FilesMediaLinksHeader = ({ background, onBack, loading, inverted, disabled, onDownload }) => {
    return (React.createElement("div", { className: s.header, style: { background } },
        React.createElement(ArrowLeftOutlined, { className: classNames(s.icon, { [s.inverted]: inverted }), onClick: onBack }),
        loading ? (React.createElement(LoadingIcon, { additionalStyles: classNames(s['loading-icon'], s.downloadLoader) })) : (React.createElement(Tooltip, { title: disabled ? undefined : React.createElement(Trans, { i18nKey: "downloadMedia" }), placement: "left" },
            React.createElement(AiOutlineDownload, { className: classNames(s.icon, {
                    [s.inverted]: inverted,
                    [s.disabled]: disabled,
                }), onClick: onDownload })))));
};
export default FilesMediaLinksHeader;
