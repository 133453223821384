var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import classnames from 'classnames';
import axios from 'axios';
import { isValidPhoneNumber } from "libphonenumber-js";
import { message } from 'antd';
import { MyInput } from '../../../../Base/Form';
import Button from 'antd/es/button';
import { SEND_APP_LINKS_URL } from '../../../../../constants';
import { useStore } from '../../../../../store/rootStore';
import { getDirection } from '../../../../../helpers';
import s from './FormSendAppLinks.module.scss';
const FormSendAppLinks = ({ closeModal }) => {
    const store = useStore();
    const { t } = useTranslation();
    const isRTL = getDirection() === 'rtl';
    const [phoneNumber, setPhoneNumber] = useState('');
    const [isValidPhone, setIsValidPhone] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isErrorSending, setIsErrorSending] = useState(false);
    const handleChange = (value) => {
        setPhoneNumber(value);
    };
    useEffect(() => {
        const isPhoneNumberCorrect = !phoneNumber.trim().length || isValidPhoneNumber(phoneNumber, store.defaultCountry);
        setIsValidPhone(isPhoneNumberCorrect);
    }, [phoneNumber]);
    const handleSubmit = (e) => __awaiter(void 0, void 0, void 0, function* () {
        e.preventDefault();
        if (isValidPhone) {
            try {
                setLoading(true);
                yield axios.post(`${SEND_APP_LINKS_URL}?phoneNumber=${phoneNumber}`);
                setIsErrorSending(false);
                setPhoneNumber('');
                message.success(React.createElement(Trans, { i18nKey: "appLinksSentSuccess", values: { number: phoneNumber } }), 5);
                closeModal();
            }
            catch (error) {
                setIsErrorSending(true);
                console.error('Failed to send message:', error);
                message.error(React.createElement(Trans, { i18nKey: "appLinksSentError", values: { number: phoneNumber } }), 5);
            }
            finally {
                setLoading(false);
            }
        }
    });
    const handleEnter = (e) => {
        if (e.key === 'Enter') {
            handleSubmit(e);
        }
    };
    return (React.createElement("div", { className: classnames(s.sendAppLinksWrapper, { [s.rtl]: isRTL }) },
        React.createElement(Trans, { i18nKey: "enterPhoneNumberToReceiveLinks" }),
        React.createElement("div", { className: classnames(s.sendAppLinksFormWrapper, { [s.rtl]: isRTL }) },
            React.createElement(MyInput, { value: phoneNumber, onChangeHandler: handleChange, onKeyDown: handleEnter, placeholder: t("phoneNumber"), size: "middle" }),
            React.createElement(Button, { type: "primary", size: "large", loading: loading, disabled: !isValidPhone || loading || !phoneNumber.trim().length, onClick: handleSubmit, className: s.sendAppLinksButton },
                React.createElement(Trans, { i18nKey: "send" }))),
        !isValidPhone ? (React.createElement("div", { className: classnames(s.phoneInputError) },
            React.createElement(Trans, { i18nKey: "incorrectNumber" }))) : null,
        isErrorSending ? (React.createElement("div", { className: classnames(s.errorMessage) },
            React.createElement(Trans, { i18nKey: "unexpectedErrorTryAgain" }))) : null));
};
export default FormSendAppLinks;
