import React, { useEffect } from "react";
import { observer } from "mobx-react";
import classNames from "classnames";
import AccountPage from "./AccountPage";
import FirstPage from "./FirstPage";
import SecondPage from "./SecondPage";
import SecondPageFile from "./SecondPageFile";
import ThirdPage from "./ThirdPage";
import FourthPage from "./FourthPage";
import { WIZARD_STEPS } from "../../../constants";
import { withStore } from "../../../store/rootStore";
import FullMessagePreview from "./FullMessagePreview/FullMessagePreview";
import ErrorBoundary from "../../ErrorBoundary";
import s from "./WizardBody.module.scss";
const WizardBody = ({ store: { wizardStore: { wizardStep, templateHasFile, setWizardStep }, channels: { templateAccounts }, }, selectedAccountId, setSelectedAccountId, }) => {
    const renderSteps = (step) => {
        switch (step) {
            case WIZARD_STEPS.ACCOUNT_STEP:
                return (React.createElement(AccountPage, { selectedAccountId: selectedAccountId, setSelectedAccountId: setSelectedAccountId }));
            case WIZARD_STEPS.FIRST_STEP:
                return React.createElement(FirstPage, { selectedAccountId: selectedAccountId });
            case WIZARD_STEPS.SECOND_STEP:
                return templateHasFile ? React.createElement(SecondPageFile, null) : React.createElement(SecondPage, null);
            case WIZARD_STEPS.THIRD_STEP:
                return templateHasFile ? React.createElement(SecondPage, null) : React.createElement(ThirdPage, null);
            case WIZARD_STEPS.FOURTH_STEP:
                return templateHasFile ? React.createElement(ThirdPage, null) : React.createElement(FourthPage, null);
            case WIZARD_STEPS.FIFTH_STEP:
                return templateHasFile ? React.createElement(FourthPage, null) : null;
            default:
                return null;
        }
    };
    const shouldRenderMessagePreview = ![WIZARD_STEPS.ACCOUNT_STEP, WIZARD_STEPS.FIRST_STEP].includes(wizardStep);
    const renderMessagePreview = () => {
        if (!shouldRenderMessagePreview)
            return null;
        return (React.createElement(ErrorBoundary, null,
            React.createElement(FullMessagePreview, { type: "wizard" })));
    };
    useEffect(() => {
        /** Skip channel account select step, buy allow user to navigate back */
        if (templateAccounts.length === 1) {
            setSelectedAccountId({
                name: templateAccounts[0].channel,
                id: templateAccounts[0].accountId,
            });
            setWizardStep(1);
        }
    }, []);
    return (React.createElement("div", { className: classNames(s["wizard-body-main"], {
            [s["wizard-body-main--with-preview"]]: shouldRenderMessagePreview,
        }) },
        renderSteps(wizardStep),
        renderMessagePreview()));
};
export default withStore(observer(WizardBody));
