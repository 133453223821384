import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import ChatsListItem from '../ChatsListItem';
import ItemSkeleton from '../ChatsListItem/ItemSkeleton';
import { ChatsLoading } from '../../../store/ChatsStore';
import LazyLoadList from '../../Base/Utils/LazyLoadList';
import ErrorBoundary from '../../ErrorBoundary';
class List extends Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "renderHeader", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (chat, header, isAfter) => {
                const insert = isAfter ? header.isAfter && header.isAfter(chat) : header.isBefore && header.isBefore(chat);
                if (insert) {
                    return header.content;
                }
                else {
                    return null;
                }
            }
        });
        Object.defineProperty(this, "renderItems", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { search, onClickChat, chats, skeletonCountMax, skeletonCountDefault, loading, favorites } = this.props, sectionHeaders = [...(this.props.sectionHeaders || [])], skeletonCount = chats.length ? Math.min(chats.length, skeletonCountMax) : skeletonCountDefault;
                const renderHeaders = (chat, isAfter) => {
                    const r = [];
                    for (let i = 0; i < sectionHeaders.length; i++) {
                        const h = sectionHeaders[i];
                        if (isAfter ? h.isAfter && h.isAfter(chat) : h.isBefore && h.isBefore(chat)) {
                            if (h.once) {
                                sectionHeaders.splice(i, 1);
                            }
                            r.push(h.content);
                        }
                    }
                    return r.length ? r : null;
                };
                if (loading === ChatsLoading.ALL) {
                    const res = [];
                    for (let i = 0; i < skeletonCount; i++) {
                        res.push(React.createElement(ItemSkeleton, { key: `skeleton-${i}` }));
                    }
                    return res;
                }
                return chats.map((chat, i) => {
                    return (React.createElement(ErrorBoundary, { key: chat._id },
                        renderHeaders(chat, false),
                        React.createElement(Link, { to: () => {
                                const { name, accountId, id } = chat.channelInfo;
                                return `/contact/${name}/${accountId}/${id}/`;
                            }, className: (favorites === null || favorites === void 0 ? void 0 : favorites.includes(chat._id)) ? 'favorite' : '', replace: true },
                            React.createElement(ChatsListItem, { chat: chat, highlighted: search || '', handleActive: () => onClickChat ? onClickChat(chat) : undefined })),
                        renderHeaders(chat, true)));
                });
            }
        });
    }
    render() {
        const { id, className, loading, hasMore, onLoadMore } = this.props;
        return (React.createElement(LazyLoadList, { id: id, className: className, loadingNext: loading === ChatsLoading.MORE, canLoad: loading === ChatsLoading.NONE, hasNext: hasMore, onLoadNext: () => onLoadMore === null || onLoadMore === void 0 ? void 0 : onLoadMore(), keepScroll: false },
            this.props.header,
            this.renderItems()));
    }
}
Object.defineProperty(List, "defaultProps", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: {
        skeletonCountDefault: 10,
        skeletonCountMax: 20,
        headers: [],
    }
});
export default observer(List);
