import React from 'react';
import classNames from 'classnames';
import { LoadingOutlined } from '@ant-design/icons';
import { Trans } from 'react-i18next';
import s from './MyForm.module.scss';
const MyForm = ({ onSubmit, children, loading, disabled }) => {
    return (React.createElement("form", { onSubmit: onSubmit, className: s.formWrapper },
        children,
        React.createElement("div", { className: s.submitWrapper },
            React.createElement("button", { type: "submit", disabled: disabled, className: classNames(s.submitButton, {
                    [s.disabled]: disabled,
                }) }, loading ? React.createElement(LoadingOutlined, null) : React.createElement(Trans, { i18nKey: "login" })))));
};
export default MyForm;
