var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { Component, createRef } from "react";
import { observer } from "mobx-react";
import { Trans, withTranslation } from "react-i18next";
import { Input, Select, Divider, Tooltip, Typography } from "antd";
import Button from "antd/es/button";
import Icon, { LoadingOutlined, SmileOutlined, QuestionCircleOutlined, ExclamationCircleOutlined } from "@ant-design/icons";
import classnames from "classnames";
import { WhatsApp } from "@whatsper/texterchat-common";
import { withStore } from "../../../../../store/rootStore";
import DepartmentsSelector from "../../../common/DepartmentsSelector";
import RichParams from "./RichParams";
import AdvancedSettings from "./AdvancedSettings";
import CommonButton from "../../../common/CommonButton";
import SubmitCancelModal from "./SubmintCancelModal";
import EmojiPalette from "../../../EmojiPalette";
import { TEMPLATE_USAGE, LANGUAGES_TYPES } from "../../../../../constants";
import { ReactComponent as QuickRepliesPlus } from "../../../../../assets/icons/plus/quickRepliesPlus.svg";
import HebrewFlag from "../../../../../assets/icons/flags/hebrewFlag.svg";
import EnglishFlag from "../../../../../assets/icons/flags/englishFlag.svg";
import RussianFlag from "../../../../../assets/icons/flags/RussianFlag.png";
import ItalianFlag from "../../../../../assets/icons/flags/italianFlag.svg";
import { MyInput, MySelect } from "../../../../Base/Form";
import { getDirection } from "../../../../../helpers";
import styles from "./AddOrEditTemplate.module.scss";
import SetDefault from "./SetDefault";
const { TextArea } = Input;
const { Option } = Select;
const { Text } = Typography;
class AddOrEditTemplate extends Component {
    constructor(props) {
        super(props);
        Object.defineProperty(this, "templateInputRef", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "blockField", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { currentTemplate } = this.props.store.templateMessagesStore;
                return !!(currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.status) && currentTemplate.status !== 'NEW';
            }
        });
        Object.defineProperty(this, "addEmoji", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (emojiObject) => {
                const { selectionStart, selectionEnd } = this.state, { handleAddEmoji } = this.props.store.templateMessagesStore, emojiLength = 2, newStart = selectionStart + emojiLength, newEnd = selectionEnd + emojiLength;
                handleAddEmoji(emojiObject.native, selectionStart, selectionEnd);
                this.setState({ selectionStart: newStart, selectionEnd: newEnd }, () => {
                    var _a, _b;
                    (_a = this.templateInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
                    (_b = this.templateInputRef.current) === null || _b === void 0 ? void 0 : _b.resizableTextArea.textArea.setSelectionRange(newStart, newEnd);
                });
            }
        });
        Object.defineProperty(this, "hideEmojiPicker", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setState({ openEmoji: false });
            }
        });
        Object.defineProperty(this, "addPlaceholder", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a, _b;
                const { nextPlaceholderNumber, handleAddPlaceholder, showCancelNotifyModal } = this.props.store.templateMessagesStore;
                const caretPosStart = (_a = this.templateInputRef.current) === null || _a === void 0 ? void 0 : _a.resizableTextArea.textArea.selectionStart;
                const caretPosEnd = (_b = this.templateInputRef.current) === null || _b === void 0 ? void 0 : _b.resizableTextArea.textArea.selectionEnd;
                const placeholderLength = nextPlaceholderNumber.toString().length;
                const bracketsLength = 4;
                const newStart = caretPosStart + bracketsLength + placeholderLength;
                const newEnd = caretPosEnd + bracketsLength + placeholderLength;
                handleAddPlaceholder(caretPosStart, caretPosEnd);
                this.setState({ selectionStart: newStart, selectionEnd: newEnd }, () => {
                    var _a, _b;
                    (_a = this.templateInputRef.current) === null || _a === void 0 ? void 0 : _a.resizableTextArea.textArea.setSelectionRange(newStart, newEnd);
                    (_b = this.templateInputRef.current) === null || _b === void 0 ? void 0 : _b.focus();
                });
                showCancelNotifyModal(true);
            }
        });
        const { templateMessage, } = props.store.templateMessagesStore;
        this.state = {
            openEmoji: false,
            textboxActive: false,
            selectionStart: templateMessage.length,
            selectionEnd: templateMessage.length,
            showCancelModal: false,
        };
        this.templateInputRef = createRef();
    }
    render() {
        var _a;
        const { handleSetUsage, handleSetTemplateLanguage, handleSetCategory, handleSetDepartment, handleSetMessage, handleSetTitle, setStoreToDefault, createTemplate, handleEditTemplate, handleSubmitToWhatsApp, setTemplateName, templateName, templateTitle, templateUsage, templateDepartments, templateLanguage, templateCategory, templateMessage, submitTemplateLoading, submitTemplateError, submitWhatsAppLoading, submitWhatsAppError, templateCreating, templateEditing, currentTemplate, showCancelNotifyModal, showManagerCancelModal, isDefaultTemplate, setIsDefaultTemplate, defaultTemplate, richTemplateHeaderType, } = this.props.store.templateMessagesStore;
        const { t, disableSendButton } = this.props;
        const { textboxActive, showCancelModal, openEmoji } = this.state;
        const categories = Object.values(WhatsApp.TemplateCategory), categoryOptions = categories.map((category) => {
            return (React.createElement(Option, { value: category, key: category, className: classnames(styles.templateSelectOption, {
                    [styles.rtl]: getDirection() === 'rtl',
                }) },
                React.createElement(Trans, { i18nKey: category })));
        });
        // Older legacy category on already approved template
        if (templateCategory && !categories.includes(templateCategory)) {
            categoryOptions.push(React.createElement(Option, { value: templateCategory, key: templateCategory, disabled: true },
                templateCategory,
                " (legacy)"));
        }
        const saveTemplate = () => {
            (() => __awaiter(this, void 0, void 0, function* () {
                try {
                    if (templateCreating && !submitTemplateLoading) {
                        yield createTemplate();
                    }
                    else if (templateEditing && !submitTemplateLoading) {
                        yield handleEditTemplate();
                    }
                    showCancelNotifyModal(false);
                }
                catch (error) {
                    console.error('Error saving template', error);
                }
            }))();
        };
        const disableSubmitTemplate = disableSendButton() || submitTemplateLoading || !!submitTemplateError;
        const disableSubmitToWhatsApp = disableSendButton() ||
            !currentTemplate ||
            (!!(currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.status) && currentTemplate.status !== 'NEW') ||
            submitWhatsAppLoading ||
            !!submitWhatsAppError;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: styles.context },
                React.createElement("div", { className: styles.block },
                    React.createElement("label", { className: styles.label },
                        React.createElement(Trans, { i18nKey: "name" })),
                    React.createElement(MyInput, { value: templateTitle, onChangeHandler: (value) => {
                            handleSetTitle(value);
                            showCancelNotifyModal(true);
                        }, placeholder: t("addTemplateNamePlaceholder"), size: "large" })),
                React.createElement("div", { className: styles.block },
                    React.createElement("label", { className: styles.label },
                        React.createElement(Trans, { i18nKey: "departments" })),
                    React.createElement(DepartmentsSelector, { style: classnames(styles.templateGeneratorSelect, {
                            [styles.templateDepartmentSelectHover]: templateUsage === "inbox",
                        }), borderColor: templateUsage === "inbox" ? "#02CA79" : undefined, onChange: (value) => {
                            handleSetDepartment(value);
                            showCancelNotifyModal(true);
                        }, value: templateDepartments, mode: "multiple", allDepOption: false, notAssignedDepOption: false })),
                React.createElement("div", { className: styles.templateGeneratorDoubleBlock },
                    React.createElement("div", { className: styles.templateGeneratorSingleBlock },
                        React.createElement("label", { className: styles.label },
                            React.createElement(Trans, { i18nKey: "language" })),
                        React.createElement(Tooltip, { arrowPointAtCenter: true, placement: "bottom", title: this.blockField() && React.createElement(Trans, { i18nKey: "You can't change this field" }) },
                            React.createElement(MySelect, { disabled: this.blockField(), defaultValue: LANGUAGES_TYPES.HE, className: classnames({
                                    [styles.templateGeneratorSelectBlocked]: this.blockField(),
                                    [styles.rtl]: getDirection() === 'rtl',
                                }), onChange: (value) => {
                                    handleSetTemplateLanguage(value);
                                    showCancelNotifyModal(true);
                                }, value: templateLanguage, size: "large", options: [
                                    {
                                        label: (React.createElement(React.Fragment, null,
                                            React.createElement("img", { className: styles.templateSelectIcon, src: HebrewFlag }),
                                            React.createElement(Trans, { i18nKey: "hebrew" }))),
                                        value: LANGUAGES_TYPES.HE,
                                    },
                                    {
                                        label: (React.createElement(React.Fragment, null,
                                            React.createElement("img", { className: styles.templateSelectIcon, src: EnglishFlag }),
                                            React.createElement(Trans, { i18nKey: "english" }))),
                                        value: LANGUAGES_TYPES.EN,
                                    },
                                    {
                                        label: (React.createElement(React.Fragment, null,
                                            React.createElement("div", { style: { backgroundColor: "#02CA79" }, className: styles.templateSelectIcon }),
                                            React.createElement(Trans, { i18nKey: "arabic" }))),
                                        value: LANGUAGES_TYPES.AR,
                                    },
                                    {
                                        label: (React.createElement(React.Fragment, null,
                                            React.createElement("img", { className: styles.templateSelectIcon, src: RussianFlag }),
                                            React.createElement(Trans, { i18nKey: "russian" }))),
                                        value: LANGUAGES_TYPES.RU,
                                    },
                                    {
                                        label: (React.createElement(React.Fragment, null,
                                            React.createElement("img", { className: styles.templateSelectIcon, src: ItalianFlag }),
                                            React.createElement(Trans, { i18nKey: "italian" }))),
                                        value: LANGUAGES_TYPES.IT,
                                    }
                                ] }))),
                    React.createElement("div", { className: styles.templateGeneratorSingleBlock },
                        React.createElement("label", { className: styles.label },
                            React.createElement(Trans, { i18nKey: "category" })),
                        React.createElement(Tooltip, { arrowPointAtCenter: true, placement: "bottom", title: this.blockField() && React.createElement(Trans, { i18nKey: "You can't change this field" }) },
                            React.createElement(MySelect, { disabled: this.blockField(), className: classnames({
                                    [styles.templateGeneratorSelectBlocked]: this.blockField(),
                                    [styles.rtl]: getDirection() === 'rtl',
                                }), onChange: (value) => {
                                    handleSetCategory(value);
                                    showCancelNotifyModal(true);
                                }, value: templateCategory, size: "large" }, categoryOptions)))),
                React.createElement("div", { className: styles.block },
                    React.createElement("label", { className: styles.label },
                        React.createElement(Trans, { i18nKey: "message" }),
                        React.createElement("div", { className: styles.templateGeneratorMessageCounterWrapper },
                            React.createElement(Text, { className: styles.templateGeneratorMessageCounter },
                                React.createElement(Trans, { i18nKey: "templateMessageLength", values: { length: templateMessage.length } })),
                            React.createElement(Tooltip, { title: React.createElement(Trans, { i18nKey: "messageHelp" }), placement: getDirection() === 'rtl' ? 'bottomLeft' : 'bottomRight', arrowPointAtCenter: true },
                                React.createElement(QuestionCircleOutlined, { className: styles.templateGeneratorLabelIcon })))),
                    React.createElement(Tooltip, { arrowPointAtCenter: true, placement: "bottom", title: this.blockField() && React.createElement(Trans, { i18nKey: "You can't change this field" }) },
                        React.createElement("div", { className: classnames(styles.templateTextboxBlock, {
                                [styles.templateTextboxActive]: textboxActive && !this.blockField(),
                            }) },
                            React.createElement("div", { className: classnames(styles.templateTextbox, {
                                    [styles.templateGeneratorTextareaBlocked]: this.blockField(),
                                }), onMouseOver: () => {
                                    if (!this.blockField()) {
                                        this.setState({ textboxActive: true });
                                    }
                                }, onMouseLeave: () => this.setState({ textboxActive: false }), onClick: () => {
                                    var _a;
                                    if (!this.blockField()) {
                                        (_a = this.templateInputRef.current) === null || _a === void 0 ? void 0 : _a.focus();
                                    }
                                } },
                                React.createElement(TextArea, { dir: getDirection(), disabled: this.blockField(), maxLength: 1024, ref: this.templateInputRef, className: classnames({ [styles.templateGeneratorTextareaBlocked]: this.blockField() }), placeholder: t("addTemplateMessagePlaceholder"), onChange: (e) => {
                                        this.setState({ selectionStart: e.target.selectionStart, selectionEnd: e.target.selectionEnd });
                                        handleSetMessage(e);
                                        showCancelNotifyModal(true);
                                    }, onClick: (e) => {
                                        const target = e.target;
                                        this.setState({ selectionStart: target.selectionStart, selectionEnd: target.selectionEnd });
                                    }, autoSize: { minRows: 5, maxRows: 10 }, value: templateMessage, bordered: false, onFocus: () => this.setState({ textboxActive: true }), onBlur: () => this.setState({ textboxActive: false }) }),
                                React.createElement(SmileOutlined, { className: classnames(styles.templateEmojiPickerTrigger, {
                                        [styles.templateEmojiBlocked]: this.blockField(),
                                        [styles.rtl]: getDirection() === 'rtl',
                                    }), onClick: (e) => {
                                        e.stopPropagation();
                                        if (!this.blockField()) {
                                            this.setState({ openEmoji: !openEmoji });
                                        }
                                    } })),
                            openEmoji && React.createElement(EmojiPalette, { onClickOutside: this.hideEmojiPicker, onEmojiSelectHandle: this.addEmoji, className: styles.templateEmojiPicker }),
                            React.createElement(Button, { className: styles.templateGeneratorTextareaButton, onClick: this.addPlaceholder, disabled: this.blockField() },
                                React.createElement(Icon, { className: styles.templateGeneratorAddPlaceholderIcon, component: QuickRepliesPlus }),
                                React.createElement(Text, { className: styles.createNewTemplateButtonText, strong: true },
                                    React.createElement(Trans, { i18nKey: "addPlaceholder" })))))),
                React.createElement("div", { className: styles.templateGeneratorDoubleBlock },
                    React.createElement("div", { className: styles.templateGeneratorSingleBlock },
                        React.createElement("label", { className: styles.label },
                            React.createElement(Trans, { i18nKey: "usage" })),
                        React.createElement(MySelect, { onChange: (value) => {
                                handleSetUsage(value);
                                showCancelNotifyModal(true);
                            }, value: templateUsage, size: "large", options: [
                                {
                                    label: React.createElement(Trans, { i18nKey: "bulk" }),
                                    value: TEMPLATE_USAGE.BULK,
                                },
                                {
                                    label: React.createElement(Trans, { i18nKey: "inbox" }),
                                    value: TEMPLATE_USAGE.INBOX,
                                }
                            ] })),
                    React.createElement("div", { className: styles.templateGeneratorSingleBlock },
                        React.createElement("label", { className: styles.label },
                            React.createElement(Trans, { i18nKey: "whatsappTemplateId" }),
                            React.createElement(Tooltip, { title: React.createElement(Trans, { i18nKey: "templateIdHelp" }), placement: getDirection() === 'rtl' ? 'bottomLeft' : 'bottomRight', arrowPointAtCenter: true },
                                React.createElement(QuestionCircleOutlined, { className: styles.templateGeneratorLabelIcon }))),
                        React.createElement(MyInput, { value: currentTemplate ? currentTemplate.name : templateName, onChangeHandler: (value) => {
                                setTemplateName(value);
                            }, size: "large", disabled: !!currentTemplate, className: styles.templateGeneratorInput, placeholder: t('templateIdPlaceholder') }))),
                React.createElement(Divider, { className: styles.templateAdvancedSettingsDivider }),
                React.createElement("label", { className: classnames(styles.templateGeneratorLabelTitle, {
                        [styles.rtl]: getDirection() === 'rtl',
                    }) },
                    React.createElement(Trans, { i18nKey: "richTemplateParams" })),
                React.createElement(RichParams, null),
                React.createElement(Divider, { className: styles.templateAdvancedSettingsDivider }),
                React.createElement("label", { className: classnames(styles.templateGeneratorLabelTitle, {
                        [styles.rtl]: getDirection() === 'rtl',
                    }) },
                    React.createElement(Trans, { i18nKey: "autoReplySettings" })),
                React.createElement(AdvancedSettings, { editMode: true }),
                React.createElement(Divider, { className: styles.templateAdvancedSettingsDivider }),
                React.createElement("label", { className: classnames(styles.templateGeneratorLabelTitle, {
                        [styles.rtl]: getDirection() === 'rtl',
                    }) },
                    React.createElement(Trans, { i18nKey: "defaultTemplateSettings" }),
                    React.createElement(Tooltip, { overlayClassName: styles.defaultTemplateTooltipOverlay, title: (React.createElement("div", { className: styles.defaultTemplateTooltipWrapper },
                            React.createElement("div", null,
                                React.createElement(Trans, { i18nKey: "defaultTemplateTitle" })),
                            React.createElement("ul", null,
                                React.createElement("li", null,
                                    React.createElement(Trans, { i18nKey: "defaultTemplateRequirement1" })),
                                React.createElement("li", null,
                                    React.createElement(Trans, { i18nKey: "defaultTemplateRequirement2" }),
                                    " ",
                                    '{{1}}'),
                                React.createElement("li", null,
                                    React.createElement(Trans, { i18nKey: "defaultTemplateRequirement3" }))))) },
                        React.createElement(ExclamationCircleOutlined, { className: styles.defaultTemplateMoreInfo }))),
                React.createElement(SetDefault, { headerType: richTemplateHeaderType, body: templateMessage, isDefault: isDefaultTemplate, isApproved: (currentTemplate === null || currentTemplate === void 0 ? void 0 : currentTemplate.status) === WhatsApp.TemplateLocalizationStatus.APPROVED, onChange: setIsDefaultTemplate, currentDefault: defaultTemplate ? (_a = defaultTemplate.title) !== null && _a !== void 0 ? _a : defaultTemplate.name : undefined })),
            React.createElement("div", { className: classnames(styles.templateGeneratorBottom, styles.templateEditButtons) },
                React.createElement(Button, { type: "default", className: classnames("big-button resolve-button white-button", styles.editTemplateMessageButton), onClick: () => {
                        if (showManagerCancelModal) {
                            this.setState({ showCancelModal: true });
                        }
                        else {
                            setStoreToDefault();
                        }
                    } },
                    React.createElement(Trans, { i18nKey: "cancel" })),
                React.createElement(Tooltip, { arrowPointAtCenter: true, placement: "top", overlayClassName: styles.tooltip, title: submitTemplateError ? React.createElement(Trans, { i18nKey: submitTemplateError }) : undefined },
                    React.createElement("div", null,
                        React.createElement(CommonButton, { submit: true, additionalStyles: classnames(styles.editTemplateMessageButton, {
                                [styles.disabled]: disableSubmitTemplate,
                            }), disabled: disableSubmitTemplate, action: saveTemplate }, !submitTemplateLoading ? (React.createElement(Trans, { i18nKey: templateCreating ? "createTemplate" : "saveTemplate" })) : (React.createElement(LoadingOutlined, null))))),
                React.createElement(Tooltip, { arrowPointAtCenter: true, placement: "top", overlayClassName: styles.tooltip, title: submitWhatsAppError ? React.createElement(Trans, { i18nKey: submitWhatsAppError }) : undefined },
                    React.createElement("div", null,
                        React.createElement(CommonButton, { submit: true, additionalStyles: classnames(styles.editTemplateMessageButton, {
                                [styles.disabled]: disableSubmitToWhatsApp,
                            }), disabled: disableSubmitToWhatsApp, action: () => {
                                handleSubmitToWhatsApp();
                                showCancelNotifyModal(false);
                            } }, !submitWhatsAppLoading ? React.createElement(Trans, { i18nKey: "sendToTyntec" }) : React.createElement(LoadingOutlined, null)))),
                showManagerCancelModal && (React.createElement(SubmitCancelModal, { visible: showCancelModal, onSubmit: () => {
                        setStoreToDefault();
                        showCancelNotifyModal(false);
                    }, onCancel: () => showCancelNotifyModal(false), i18key: "sureToCloseTemplates" })))));
    }
}
export default withTranslation()(withStore(observer(AddOrEditTemplate)));
