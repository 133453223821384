import React from 'react';
import { useStore } from '../../../../store/rootStore';
import { observer } from 'mobx-react';
import MessagePreview from '../../../Chat/QuickReplies/TemplateGenerator/AddOrEditTemplate/MessagePreview';
import { RICH_TEMPLATE_BUTTON_TYPE, TEMPLATE_PARTS } from '../../../../constants';
import s from './FullMessagePreview.module.scss';
const FullMessagePreview = ({ type, params }) => {
    var _a, _b;
    const { wizardStore, templateMessagesStore } = useStore();
    const { uploadedImageOrFileStep, chosenTemplate, templateHasFile, exampleTemplates, exampleTemplatesStep, wizardStep, } = wizardStore;
    const { richTemplateHeader, richTemplateHeaderType, richTemplateHeaderUrl, richTemplateFooter, templateMessage, bodyExampleParams, quickReplyButtons, callToActionButtons, } = templateMessagesStore;
    let header;
    let body;
    let footer;
    let buttons;
    if (type === 'wizard') {
        header = Object.assign(Object.assign({}, chosenTemplate === null || chosenTemplate === void 0 ? void 0 : chosenTemplate.header), { previewUrl: (_a = uploadedImageOrFileStep === null || uploadedImageOrFileStep === void 0 ? void 0 : uploadedImageOrFileStep.preview) === null || _a === void 0 ? void 0 : _a.url, previewFile: uploadedImageOrFileStep });
        body = Object.assign({ text: chosenTemplate === null || chosenTemplate === void 0 ? void 0 : chosenTemplate.text, params }, (wizardStep >= (templateHasFile ? 4 : 3) && {
            exampleText: (_b = exampleTemplates[exampleTemplatesStep]) === null || _b === void 0 ? void 0 : _b.text,
        }));
        footer = chosenTemplate === null || chosenTemplate === void 0 ? void 0 : chosenTemplate.footer;
        buttons = chosenTemplate === null || chosenTemplate === void 0 ? void 0 : chosenTemplate.buttons;
    }
    if (type === 'template') {
        header = {
            type: richTemplateHeaderType,
            text: richTemplateHeader,
            file: richTemplateHeader,
            url: richTemplateHeaderUrl,
        };
        body = {
            text: templateMessage,
            params: params || bodyExampleParams,
        };
        footer = {
            type: TEMPLATE_PARTS.FOOTER,
            text: richTemplateFooter,
        };
        buttons = Object.assign(Object.assign({}, (quickReplyButtons && {
            type: RICH_TEMPLATE_BUTTON_TYPE.QUICK_REPLY,
            buttons: quickReplyButtons,
        })), (callToActionButtons && {
            type: RICH_TEMPLATE_BUTTON_TYPE.CALL_TO_ACTION,
            buttons: callToActionButtons,
        }));
    }
    return (React.createElement("div", { className: s.wrapper },
        React.createElement(MessagePreview, { header: header, body: body, footer: footer, buttons: buttons })));
};
export default observer(FullMessagePreview);
