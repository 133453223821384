import React from 'react';
import classNames from 'classnames';
import { Facebook, MESSAGE_DIRECTION } from '@whatsper/texterchat-common';
import { RiAdvertisementLine } from 'react-icons/ri';
import { getFilePreviewUrl, getFileUrl } from '../../../../../../store/FilesStore';
import { isAdMessage } from '../../../../../../helpers';
import styles from './AdPostback.module.scss';
const AdPostback = ({ context, name, type, incoming, invertChatColor }) => {
    const { referral } = isAdMessage(context) && context.special.facebook;
    const renderAdPreview = () => {
        if (referral.media_type === Facebook.ReferralMediaType.image) {
            return React.createElement("img", { src: getFilePreviewUrl(referral.image), className: styles.media, alt: "ad" });
        }
        if (referral.media_type === Facebook.ReferralMediaType.video) {
            return React.createElement("video", { src: getFileUrl(referral.video), className: styles.media });
        }
    };
    return (React.createElement("div", { className: classNames(styles.main, {
            [styles.reply]: type === 'reply',
        }) },
        React.createElement("div", { className: styles.info },
            React.createElement("p", { className: classNames(styles.name, {
                    [styles.incoming]: incoming,
                    [styles.invertColors]: !incoming && invertChatColor && type === 'message',
                }) }, context.direction === MESSAGE_DIRECTION.IN ? name : 'You'),
            React.createElement("div", { className: classNames(styles.text, {
                    [styles.reply]: type === 'reply',
                    [styles.invertColors]: !incoming && type === 'message',
                }) },
                React.createElement(RiAdvertisementLine, null),
                context.text || 'Ad')),
        renderAdPreview()));
};
export default AdPostback;
