import { makeStyles } from "@material-ui/core/styles";
export const useDrawerStyles = makeStyles((theme) => ({
    root: (props) => ({
        direction: props.direction,
        "& .MuiDrawer-paperAnchorLeft": {
            left: 65,
            width: 292,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            [theme.breakpoints.down(1026)]: {
                left: 0,
                width: "100%",
            },
        },
        "& .MuiDrawer-paperAnchorRight": {
            right: 65,
            width: 292,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            [theme.breakpoints.down(1026)]: {
                right: 0,
                width: "100%",
            },
        },
        "& .MuiDivider-root": {
            width: 'calc(100% - 32px)',
            marginLeft: "0px !important",
        },
        "& .MuiTypography-body1": {
            fontStyle: "normal",
            fontWeight: "normal",
            fontFamily: "Helvetica",
            fontSize: 14,
            letterSpacing: "1px",
            lineHeight: "26px",
        },
        "& .MuiList-padding": {
            width: "100%",
        },
        "& .MuiListItemIcon-root": {
            minWidth: 40,
            '@media(max-width: 780px)': {
                minWidth: 56,
            }
        },
        "& .MuiListItemText-root": {
            textAlign: props.direction === "rtl" ? "right" : "left",
        },
        "& .MuiInputBase-input": {
            fontFamily: 'Helvetica',
        }
    }),
    backIcon: (props) => ({
        transform: props.direction === "rtl" ? "rotate(180deg)" : "rotate(0deg)",
    }),
    paragraph: {
        fontFamily: "SF UI Text",
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: 14,
        lineHeight: "16px",
        width: "85%",
    },
    customSection: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        marginBottom: 25,
    },
    span: {
        fontStyle: "normal",
        fontWeight: 600,
        fontSize: 16,
        lineHeight: "45px",
    },
    sectionTitle: (props) => ({
        display: 'flex',
        flexDirection: "row",
        alignItems: 'center',
        lineHeight: '20px',
        "& .anticon": {
            marginLeft: props.direction === "rtl" ? '0' : '0.5rem',
            marginRight: props.direction === "rtl" ? '0.5rem' : '0',
        },
    }),
}));
