var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { action, observable, runInAction, computed, makeObservable } from 'mobx';
import { API_PATH, SERVER_URL } from '../../constants';
import { getAxios } from '../../backend';
const categories = [
    'Automotive',
    'Beauty, Spa and Salon',
    'Clothing and Apparel',
    'Education',
    'Entertainment',
    'Event Planning and Service',
    'Finance and Banking',
    'Food and Grocery',
    'Public Service',
    'Hotel and Lodging',
    'Medical and Health',
    'Non-profit',
    'Professional Services',
    'Shopping and Retail',
    'Travel and Transportation',
    'Restaurant',
    'Other',
];
export default class ProfileStore {
    constructor(rootStore) {
        Object.defineProperty(this, "rootStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "profileLoading", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "profileData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "profilePhoto", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "profilePhotoInfo", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "errorMessage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "schema", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "categories", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: categories
        });
        Object.defineProperty(this, "fetchProfile", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                try {
                    runInAction(() => (this.profileLoading = true));
                    yield Promise.all([this.fetchProfileSettings(), this.fetchProfileSchema(), this.fetchProfilePhotoInfo()]);
                    runInAction(() => (this.profileLoading = false));
                }
                catch (error) {
                    console.error('Error at fetching profile', error);
                    runInAction(() => (this.profileLoading = false));
                }
            })
        });
        Object.defineProperty(this, "fetchProfileSettings", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                try {
                    return getAxios()
                        .then((axios) => {
                        var _a, _b;
                        return axios.get(`${API_PATH.CHANNELS}/accounts/${(_a = this.account) === null || _a === void 0 ? void 0 : _a.channel}/${(_b = this.account) === null || _b === void 0 ? void 0 : _b.accountId}/profile/settings`);
                    })
                        .then(({ data }) => {
                        runInAction(() => {
                            this.profileData = observable(data);
                        });
                    });
                }
                catch (error) {
                    console.error('Error at fetching profile', error);
                }
            })
        });
        Object.defineProperty(this, "fetchProfileSchema", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                try {
                    return getAxios()
                        .then((axios) => {
                        var _a, _b;
                        return axios.get(`${API_PATH.CHANNELS}/accounts/${(_a = this.account) === null || _a === void 0 ? void 0 : _a.channel}/${(_b = this.account) === null || _b === void 0 ? void 0 : _b.accountId}/profile/settings/schema`);
                    })
                        .then(({ data }) => {
                        runInAction(() => {
                            this.schema = observable(data);
                        });
                    });
                }
                catch (error) {
                    console.error('Error at fetching schema', error);
                }
            })
        });
        Object.defineProperty(this, "fetchProfilePhotoInfo", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                try {
                    return getAxios()
                        .then((axios) => {
                        var _a, _b;
                        return axios.get(`${API_PATH.CHANNELS}/accounts/${(_a = this.account) === null || _a === void 0 ? void 0 : _a.channel}/${(_b = this.account) === null || _b === void 0 ? void 0 : _b.accountId}/profile/picture/info`);
                    })
                        .then(({ data }) => {
                        runInAction(() => {
                            this.profilePhotoInfo = observable(data);
                        });
                    });
                }
                catch (error) {
                    console.error('Error at fetching profile photo info', error);
                }
            })
        });
        Object.defineProperty(this, "updateProfilePhoto", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (pictureFile) => __awaiter(this, void 0, void 0, function* () {
                runInAction(() => {
                    this.errorMessage = '';
                });
                try {
                    return getAxios()
                        .then((axios) => {
                        var _a, _b;
                        const formData = new FormData();
                        formData.append('picture', pictureFile);
                        return axios.post(`${API_PATH.CHANNELS}/accounts/${(_a = this.account) === null || _a === void 0 ? void 0 : _a.channel}/${(_b = this.account) === null || _b === void 0 ? void 0 : _b.accountId}/profile/picture`, formData, { headers: { 'content-type': 'multipart/form-data' } });
                    })
                        .then(({ data }) => { })
                        .catch((err) => {
                        runInAction(() => {
                            this.errorMessage = err.message;
                            this.profileLoading = false;
                        });
                        throw new Error(err.message);
                    });
                }
                catch (error) {
                    console.error('Error at fetching profile', error);
                }
            })
        });
        Object.defineProperty(this, "updateProfile", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (query) => __awaiter(this, void 0, void 0, function* () {
                try {
                    runInAction(() => {
                        this.profileLoading = true;
                        this.errorMessage = '';
                    });
                    return getAxios()
                        .then((axios) => {
                        var _a, _b;
                        return axios.patch(`${API_PATH.CHANNELS}/accounts/${(_a = this.account) === null || _a === void 0 ? void 0 : _a.channel}/${(_b = this.account) === null || _b === void 0 ? void 0 : _b.accountId}/profile/settings`, Object.assign({}, query));
                    })
                        .then(() => {
                        runInAction(() => {
                            this.profileData = Object.assign(Object.assign({}, this.profileData), query);
                            this.profileLoading = false;
                        });
                    })
                        .catch((err) => {
                        runInAction(() => {
                            this.errorMessage = err.message;
                            this.profileLoading = false;
                        });
                        throw new Error(err.message);
                    });
                }
                catch (error) {
                    console.error('Error at updating profile', error);
                    runInAction(() => (this.profileLoading = false));
                }
            })
        });
        makeObservable(this);
        this.rootStore = rootStore;
    }
    get account() {
        return this.rootStore.channels.currentAccount;
    }
    get photoUrl() {
        if (!this.account)
            return;
        return `${SERVER_URL}${API_PATH.CHANNELS}/accounts/${this.account.channel}/${this.account.accountId}/profile/picture`;
    }
}
__decorate([
    observable
], ProfileStore.prototype, "profileLoading", void 0);
__decorate([
    observable
], ProfileStore.prototype, "profileData", void 0);
__decorate([
    observable
], ProfileStore.prototype, "profilePhoto", void 0);
__decorate([
    observable
], ProfileStore.prototype, "profilePhotoInfo", void 0);
__decorate([
    observable
], ProfileStore.prototype, "errorMessage", void 0);
__decorate([
    observable
], ProfileStore.prototype, "schema", void 0);
__decorate([
    computed
], ProfileStore.prototype, "account", null);
__decorate([
    computed
], ProfileStore.prototype, "photoUrl", null);
__decorate([
    action
], ProfileStore.prototype, "fetchProfile", void 0);
__decorate([
    action
], ProfileStore.prototype, "fetchProfileSettings", void 0);
__decorate([
    action
], ProfileStore.prototype, "fetchProfileSchema", void 0);
__decorate([
    action
], ProfileStore.prototype, "fetchProfilePhotoInfo", void 0);
__decorate([
    action
], ProfileStore.prototype, "updateProfilePhoto", void 0);
__decorate([
    action
], ProfileStore.prototype, "updateProfile", void 0);
