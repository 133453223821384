import React from 'react';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { TIME_FORMAT } from '../../../constants';
import { useStore } from '../../../store/rootStore';
import { observer } from 'mobx-react';
import { Trans } from 'react-i18next';
import { getDirection } from '../../../helpers';
import s from './TimeFormatSelect.module.scss';
const TimeFormatSelect = () => {
    const { timeFormat, setTimeFormat } = useStore();
    const handleChange = (event) => {
        setTimeFormat(event.target.value);
    };
    return (React.createElement(FormControl, { className: s.formControl },
        React.createElement(InputLabel, { id: "demo-simple-select-label" }),
        React.createElement(Select, { labelId: "demo-simple-select-label", id: "demo-simple-select", defaultValue: TIME_FORMAT.TWELVE_HOURS, value: timeFormat, onChange: handleChange, dir: getDirection(), MenuProps: {
                classes: {
                    paper: s.paper,
                },
            } },
            React.createElement(MenuItem, { value: TIME_FORMAT.TWELVE_HOURS },
                React.createElement(Trans, { i18nKey: "twelveHours" })),
            React.createElement(MenuItem, { value: TIME_FORMAT.TWENTY_FOUR_HOURS },
                React.createElement(Trans, { i18nKey: "twentyFourHours" })))));
};
export default observer(TimeFormatSelect);
