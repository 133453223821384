import React from 'react';
import { observer } from 'mobx-react';
import LogOutSection from './atoms/LogOutSection';
import ChatStatusButtons from './atoms/ChatStatusButtons';
import { useStore } from '../../../store/rootStore';
import ErrorBoundary from '../../ErrorBoundary';
import Module from './modules';
import { PortalHost, PortalName } from '../../portals';
import './RightBar.css';
const RightBar = () => {
    const store = useStore();
    const { userDetails } = store.loginStore;
    return (React.createElement("div", { className: "right-bar" },
        React.createElement(LogOutSection, { name: userDetails === null || userDetails === void 0 ? void 0 : userDetails.displayName }),
        React.createElement(ErrorBoundary, null,
            React.createElement(PortalHost, { name: PortalName.rightBarContents, className: "right-bar-contents" },
                React.createElement(Module, null))),
        React.createElement(ChatStatusButtons, { visible: true })));
};
export default observer(RightBar);
