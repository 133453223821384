import React from "react";
import ReactTextTransition, { presets } from "react-text-transition";
const TextTransition = ({ text, className }) => {
    if (typeof text !== "string") {
        return text;
    }
    return text
        .split("")
        .map((n, i) => (React.createElement(ReactTextTransition, { key: i, text: n, springConfig: presets.stiff, className: className, direction: "down", inline: true })));
};
export default TextTransition;
