import { observer } from 'mobx-react';
import React from 'react';
import { Trans } from 'react-i18next';
import ContactListItem from '../ContactListItem';
import styles from './ContactList.module.scss';
const ContactList = ({ chats, selectedContacts, addToSelectedList }) => {
    if (chats.length === 0) {
        return (React.createElement("div", { className: styles.noContactsFound },
            React.createElement(Trans, { i18nKey: "noContactsFound" })));
    }
    return (React.createElement("div", { className: styles.contactList }, chats.map((chat) => (React.createElement(ContactListItem, { key: chat._id, chat: chat, addToSelectedList: () => addToSelectedList({
            _id: chat._id,
            status: chat.status,
            agentUid: chat.agent.uid,
        }), selected: selectedContacts && !!selectedContacts.find((selectedChat) => selectedChat._id === chat._id) })))));
};
export default observer(ContactList);
