var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';
import { Drawer } from '@material-ui/core';
import { RiMapPinFill, RiLink, RiGlobalFill, RiBriefcase2Fill, RiMailFill } from 'react-icons/ri';
import { Upload, Button, Col, Row, message, Divider } from 'antd';
import Icon, { LoadingOutlined, PlusOutlined, CameraFilled } from '@ant-design/icons';
import LoadingIcon from '../Chat/common/LoadingIcon';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import KeyboardBackspaceOutlinedIcon from '@material-ui/icons/KeyboardBackspaceOutlined';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { MessagingChannelFeature } from '@whatsper/texterchat-common';
import validator from '@rjsf/validator-ajv8';
import { Form as RJSFForm } from '@rjsf/antd';
import { isArray, omit, pick } from 'lodash';
import { withStore } from '../../store/rootStore';
import { ReactComponent as VerifiedIcon } from '../../assets/icons/chatStatuses/verified-badge-icon.svg';
import { VERIFY_BADGE_URL } from '../../constants';
import s from './EditProfileDrawer.module.scss';
const icons = {
    description: React.createElement(RiBriefcase2Fill, null),
    address: React.createElement(RiMapPinFill, null),
    email: React.createElement(RiMailFill, null),
    websites: React.createElement(RiLink, null),
    about: React.createElement(RiGlobalFill, null),
};
const EditProfileDrawer = ({ store: { profile, channels }, toggleEditProfileDrawer, isOpen }) => {
    var _a, _b;
    const { t } = useTranslation();
    const [formData = {}, setFormData] = useState(profile.profileData);
    const [imageUrl, setImageUrl] = useState('');
    const [imageFile, setImageFile] = useState(null);
    const formRef = useRef();
    const [tempImage, setTempImage] = useState(null);
    const photoUrl = tempImage ? tempImage : (imageUrl || imageUrl === null) ? imageUrl : profile.photoUrl;
    function getBase64(img, callback) {
        if (!img)
            return;
        const reader = new FileReader();
        reader.addEventListener('load', () => {
            if (profile.profilePhotoInfo) {
                const { minHeight, minWidth } = profile.profilePhotoInfo;
                const image = new Image();
                image.src = reader.result;
                setTempImage(image.src);
                image.onload = function () {
                    const width = image.width;
                    const height = image.height;
                    if (width < minWidth || height < minHeight) {
                        if (image.src === tempImage)
                            return;
                        message.error(`Image must be at least ${minWidth}x${minHeight}px`);
                    }
                };
            }
            else {
                callback(reader.result);
            }
        });
        reader.readAsDataURL(img);
    }
    function beforeUpload(file) {
        var _a;
        const maxSize = (_a = profile.profilePhotoInfo) === null || _a === void 0 ? void 0 : _a.maxSize;
        const isJpgOrPng = file.type.startsWith('image/');
        if (!isJpgOrPng) {
            message.error('You can only upload JPG/PNG file!');
        }
        const isTooLarge = file.size > maxSize;
        if (isTooLarge) {
            const prettySize = (maxSize / 1024 / 1024).toFixed(2);
            message.error(`Image must smaller than ${prettySize}MB`);
        }
        return isJpgOrPng && !isTooLarge;
    }
    const handlePreview = (file) => {
        setImageUrl(window.URL.createObjectURL(file));
    };
    const handleUpload = (info) => {
        setImageFile(info.file.originFileObj);
        getBase64(info.file.originFileObj, setImageUrl);
    };
    const handlePhotoLoadedError = (e) => {
        setImageUrl(null);
    };
    const onUpdateProfile = () => __awaiter(void 0, void 0, void 0, function* () {
        var _c;
        if (!((_c = formRef.current) === null || _c === void 0 ? void 0 : _c.validateForm())) {
            return;
        }
        try {
            const promises = [profile.updateProfile(formData || {})];
            if (imageFile) {
                promises.push(profile.updateProfilePhoto(imageFile));
            }
            yield Promise.all(promises);
            toggleEditProfileDrawer();
            message.success(t('profileUpdatedSuccess'));
        }
        catch (e) {
            const { errorMessage } = profile;
            message.error(errorMessage || t('profileUpdatedError'));
        }
    });
    const onError = (e) => {
        message.error(e[0].message);
    };
    const onVerifyProfileClick = () => {
        var _a;
        (_a = window.open(VERIFY_BADGE_URL, '_blank', 'noopener,noreferrer')) === null || _a === void 0 ? void 0 : _a.focus();
    };
    useEffect(() => {
        const adapter = channels.adapters.find((adapter) => { var _a; return adapter.name === ((_a = channels.currentAccount) === null || _a === void 0 ? void 0 : _a.adapterName); });
        const editProfileSupported = channels.hasFeatures([MessagingChannelFeature.profileInfoEdit], adapter);
        if (channels.currentAccount && editProfileSupported) {
            profile.fetchProfile();
        }
    }, [channels.currentAccount]);
    useEffect(() => {
        const { profileData, schema } = profile;
        if (profileData) {
            const schemaFields = Object.keys(schema === null || schema === void 0 ? void 0 : schema.properties);
            const formData = pick(profileData, schemaFields);
            setFormData(formData);
        }
    }, [profile.profileData]);
    useEffect(() => {
        if (!isOpen) {
            setImageUrl('');
            setTempImage('');
        }
    }, [isOpen]);
    const { profileLoading } = profile;
    const uploadButton = (React.createElement("div", null,
        profileLoading ? React.createElement(LoadingOutlined, null) : React.createElement(PlusOutlined, null),
        React.createElement("div", { style: { marginTop: 8 } }, t('upload'))));
    return (React.createElement(Drawer, { open: isOpen, onClose: toggleEditProfileDrawer, style: { zIndex: 100 }, classes: {
            root: s['message-drawer__root'],
            paperAnchorLeft: s['message-drawer__paperAnchorLeft'],
        } },
        React.createElement("div", { className: s.editProfileContainer },
            React.createElement("div", { className: classnames(s.gridItem, s.formCol) },
                React.createElement(Row, { className: s.headerRow },
                    React.createElement("div", { className: s.editProfileHeader },
                        React.createElement("div", { className: s.editProfileBack },
                            React.createElement(ListItemIcon, { onClick: toggleEditProfileDrawer },
                                React.createElement(KeyboardBackspaceOutlinedIcon, { fontSize: "small" })),
                            React.createElement("span", { className: s.editProfileTitle }, t('editProfileTitle'))))),
                React.createElement(Row, { className: s.contentRow },
                    React.createElement(Col, { span: 21, offset: 3 }, profileLoading ? (React.createElement("div", { className: s.editProfileLoadingContainer },
                        React.createElement(LoadingIcon, null))) : (React.createElement(React.Fragment, null,
                        React.createElement(Upload, { name: "avatar", listType: "picture-card", className: s['avatar-uploader'], showUploadList: false, beforeUpload: beforeUpload, onPreview: handlePreview, onChange: handleUpload, accept: "image/*" }, photoUrl ? (React.createElement("img", { src: photoUrl, className: s.avatarContainer, onError: handlePhotoLoadedError, alt: "avatar" })) : (uploadButton)),
                        React.createElement("div", { className: s.comment }, (_a = profile.profilePhotoInfo) === null || _a === void 0 ? void 0 : _a.notes),
                        React.createElement(RJSFForm, { validator: validator, schema: profile.schema, formData: formData, className: s.formContainer, onChange: (e) => setFormData(e.formData), onError: onError, ref: formRef })))))),
            React.createElement("div", { className: classnames(s.gridItem, s.summaryCol) },
                React.createElement("div", { className: s.summaryHeader },
                    photoUrl ? (React.createElement("div", { className: s.imagePreview, style: { backgroundImage: "url('" + photoUrl + "')" } })) : (React.createElement("div", { className: s.emptyImage },
                        React.createElement(CameraFilled, null),
                        ' ')),
                    (formData === null || formData === void 0 ? void 0 : formData.name) ? React.createElement("span", { className: s.title }, formData.name) : null,
                    (formData === null || formData === void 0 ? void 0 : formData.vertical) ? React.createElement("span", { className: s.category }, formData.vertical) : null),
                React.createElement("div", { className: s.valuesPreview }, Object.keys(omit((_b = profile.schema) === null || _b === void 0 ? void 0 : _b.properties, ['name', 'vertical'])).map((key) => {
                    const value = formData && formData[key];
                    if (!value) {
                        return (React.createElement("div", { className: s.emptyValue, key: key },
                            icons[key],
                            React.createElement("span", null, t('notAdded'))));
                    }
                    if (isArray(value)) {
                        return value.filter(Boolean).map((v, i) => {
                            if (v.startsWith('http')) {
                                return (React.createElement("div", { key: v, className: s.filledValue },
                                    icons[key],
                                    React.createElement("span", null,
                                        React.createElement("a", { href: v, target: "_blank", rel: "noopener noreferrer" }, v))));
                            }
                            return (React.createElement("div", { key: i, className: s.filledValue },
                                icons[key],
                                React.createElement("span", null, v)));
                        });
                    }
                    return (React.createElement("div", { key: key, className: s.filledValue },
                        icons[key],
                        React.createElement("span", null, value)));
                })),
                React.createElement("div", { className: s.verifiedBadgeContainer },
                    React.createElement(Divider, null),
                    React.createElement(Icon, { component: VerifiedIcon, className: s.verifiedBadgeIcon }),
                    React.createElement("div", { className: s.verifiedBadgeMessage },
                        t('verificationBadgeDescription'),
                        ":"),
                    React.createElement(Button, { type: "default", className: classnames(s.editProfileButton, s.defaultButton, s.verifiedBadgeButton, 'comment-button'), onClick: onVerifyProfileClick },
                        React.createElement(VerifiedIcon, null),
                        React.createElement("span", null, t('applyForVerificationBadge')))),
                React.createElement("div", { className: s.editProfileButtons },
                    React.createElement(Button, { type: "default", className: classnames(s.editProfileButton, s.defaultButton, 'comment-button'), onClick: toggleEditProfileDrawer }, t('cancel')),
                    React.createElement(Button, { type: "primary", className: classnames('big-green-button', s.editProfileButton, 'comment-button'), onClick: onUpdateProfile, disabled: profileLoading }, t('save')))))));
};
export default withStore(observer(EditProfileDrawer));
