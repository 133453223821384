var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { Auth } from '@whatsper/texterchat-common';
import TOTP from './TOTP';
import WhatsappOTP from './WhatsappOTP';
import s from '../../../Login.module.scss';
const OTP = ({ onSubmit, method }) => {
    const [formData, setFormData] = useState({ code: '' });
    const [loading, setLoading] = useState(false);
    const disabled = loading || Object.keys(formData).some((key) => !formData[key]);
    useEffect(() => {
        onSubmit();
    }, []);
    const onSubmitHandler = (e) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            e.preventDefault();
            setLoading(true);
            const { code } = formData;
            if (code) {
                yield onSubmit(code);
            }
            else {
                yield onSubmit();
            }
        }
        catch (e) {
            console.error(e.error.message);
        }
        finally {
            setLoading(false);
        }
    });
    const renderOTPForm = () => {
        switch (method) {
            case Auth.Methods.Method.whatsapp_otp:
                return (React.createElement(WhatsappOTP, { onSubmitHandler: onSubmitHandler, formData: formData, setFormData: setFormData, loading: loading, disabled: disabled }));
            case Auth.Methods.Method.totp:
                return (React.createElement(TOTP, { onSubmitHandler: onSubmitHandler, formData: formData, setFormData: setFormData, loading: loading, disabled: disabled }));
            default:
                return;
        }
    };
    return React.createElement("div", { className: s.pageWrapper }, renderOTPForm());
};
export default OTP;
