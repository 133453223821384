import React from 'react';
import classnames from 'classnames';
import { isButtonsMessage, isContactsMessage, isListMessage, isMediaMessage, isPostbackMessage, isSpecialMessage, isTextMessage, } from '@whatsper/texterchat-common';
import { useStore } from '../../../../store/rootStore';
import { isAdMessage, shadeColor } from '../../../../helpers';
import AudioPostback from './PostbackTypes/AudioPostback';
import TextPostback from './PostbackTypes/TextPostback';
import DocumentPostback from './PostbackTypes/DocumentPostback';
import ImagePostback from './PostbackTypes/ImagePostback';
import ContactPostback from './PostbackTypes/ContactPostback';
import VideoPostback from './PostbackTypes/VideoPostback';
import AdPostback from './PostbackTypes/AdPostback';
import StickerPostback from './PostbackTypes/StickerPostback';
import { MEDIA_TYPE } from '../../../../constants';
import styles from './MessagePostback.module.scss';
const MessagePostback = ({ context, name, incoming, onClick, renderMessageAsHTML }) => {
    var _a;
    const store = useStore();
    const { theme } = store.activeChatStore;
    const backgroundColor = incoming ? '#f3f3f3' : shadeColor(theme.color, 10);
    const postbackProps = {
        context,
        name,
        incoming,
        invertChatColor: (_a = theme.additionalData) === null || _a === void 0 ? void 0 : _a.invertChatColor,
        renderMessageAsHTML,
    };
    return (React.createElement("div", { className: styles.container, onClick: onClick, style: { backgroundColor } },
        React.createElement("div", { className: classnames(styles.coloredLine, {
                [styles.incoming]: incoming,
                [styles.invertColors]: !incoming && postbackProps.invertChatColor,
            }) }),
        isMediaMessage(context) && context.media && context.media[0].mediaType === MEDIA_TYPE.DOC ? (React.createElement(DocumentPostback, Object.assign({}, postbackProps, { type: "message" }))) : null,
        isMediaMessage(context) && context.media && context.media[0].mediaType === MEDIA_TYPE.IMAGE ? (React.createElement(ImagePostback, Object.assign({}, postbackProps, { type: "message" }))) : null,
        isMediaMessage(context) && context.media && context.media[0].mediaType === MEDIA_TYPE.AUDIO ? (React.createElement(AudioPostback, Object.assign({}, postbackProps, { type: "message" }))) : null,
        isMediaMessage(context) && context.media && context.media[0].mediaType === MEDIA_TYPE.VIDEO ? (React.createElement(VideoPostback, Object.assign({}, postbackProps, { type: "message" }))) : null,
        isMediaMessage(context) && context.media && context.media[0].mediaType === MEDIA_TYPE.STICKER ? (React.createElement(StickerPostback, Object.assign({}, postbackProps, { type: "message" }))) : null,
        isButtonsMessage(context) || isSpecialMessage(context) || isListMessage(context) ? (React.createElement(TextPostback, Object.assign({}, postbackProps, { type: "message" }))) : null,
        isAdMessage(context) ? React.createElement(AdPostback, Object.assign({}, postbackProps, { type: "message" })) : null,
        isPostbackMessage(context) ? React.createElement(TextPostback, Object.assign({}, postbackProps, { type: "message", isPostback: true })) : null,
        isTextMessage(context) && !isMediaMessage(context) && !isAdMessage(context) && context.text ? (React.createElement(TextPostback, Object.assign({}, postbackProps, { type: "message" }))) : null,
        isContactsMessage(context) ? React.createElement(ContactPostback, Object.assign({}, postbackProps)) : null));
};
export default MessagePostback;
