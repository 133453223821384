import moment from 'moment';
export const getHoursAndMinutes = (timestamp, noSeconds = false) => {
    if (!timestamp)
        return undefined;
    const duration = moment.duration(timestamp);
    let hours = duration.hours();
    let minutes = duration.minutes();
    let seconds = duration.seconds();
    if (hours < 10 && hours > 0)
        hours = `0${hours}`;
    if (seconds < 10)
        seconds = `0${seconds}`;
    if (minutes < 10)
        minutes = `0${minutes}`;
    if (hours === 0) {
        if (noSeconds)
            return `${minutes}m`;
        return `${minutes}m:${seconds}s`;
    }
    else {
        return `${hours}h:${minutes}m`;
    }
};
