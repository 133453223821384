export function objectMap(obj, mapper) {
    const mapped = {};
    Object.entries(obj).map(([k, v], i) => {
        mapped[k] = mapper(v, k, i);
    });
    return mapped;
}
export function objectFill(keys, value) {
    const obj = {};
    keys.map(k => {
        obj[k] = value;
    });
    return obj;
}
export function strEnum(o) {
    return Object.freeze(o.reduce((res, key) => {
        res[key] = key;
        return res;
    }, Object.create(null)));
}
export function lowercaseKeys(obj, deep = false) {
    return Object.keys(obj).reduce((acc, key) => {
        acc[key.toLowerCase()] =
            deep && typeof obj[key] === "object" ? lowercaseKeys(obj[key]) : obj[key];
        return acc;
    }, {});
}
export function trimKeys(obj, deep = false) {
    return Object.keys(obj).reduce((acc, key) => {
        acc[key.trim()] =
            deep && typeof obj[key] === "object" ? trimKeys(obj[key]) : obj[key];
        return acc;
    }, {});
}
