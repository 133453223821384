import React from 'react';
import {Button, Modal, Typography} from 'antd';
import Icon from '@ant-design/icons';
import { Trans } from 'react-i18next';

import {ReactComponent as Bug} from "../../../../../assets/icons/warnings/bug.svg";

import styles from "./InfectedModal.module.scss"
import classnames from "classnames";

const {Text} = Typography

const InfectedModal = ({visible, onClose, onDownload}) => {
    return (
        <Modal
            width={600}
            open={visible}
            closable={false}
            onCancel={onClose}
            footer={null}
            centered
            bodyStyle={{
                padding: '0',
                boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)',
                borderRadius: '2px',
            }}
        >
            <div className={styles.infectedModalContainer}>
                <Icon className={styles.bugIcon} component={Bug} />
                <Text className={styles.warningText}>
                    <Trans i18nKey="warningExclamation"/>
                </Text>
                <Text className={styles.warningMainText}>
                    <Trans i18nKey="definedViruses"/>
                </Text>
                <div className={styles.buttonsBlock}>
                    <Button className={classnames('big-button',styles.infectedModalButton, styles.infectedModalButtonWhite)}
                            onClick={onClose}>
                        <Text className={styles.infectedModalButtonText}>
                            <Trans i18nKey="cancel"/>
                        </Text>
                    </Button>
                    <Button className={classnames('big-button',styles.infectedModalButton, styles.infectedModalButtonRed)}
                            onClick={()=> {
                                onDownload()
                                onClose()
                            }}>
                        <Text className={classnames(styles.infectedModalButtonText, styles.infectedModalButtonTextWhite)}>
                            <Trans i18nKey="download"/>
                        </Text>
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default InfectedModal;
