import React from 'react';
import classnames from 'classnames';
import ChatsListItem from '../../../../Chat/ChatsListItem';
import { ReactComponent as SingleCheck } from '../../../../../assets/icons/chatStatuses/singleCheck.svg';
import Icon from '@ant-design/icons';
import styles from './ContactListItem.module.scss';
const ContactListItem = ({ chat, selected, addToSelectedList }) => {
    return (React.createElement("div", { className: classnames(styles.contactListItem, { [styles.contactListItemSelected]: selected }), onClick: addToSelectedList },
        React.createElement(ChatsListItem, { chat: chat, highlighted: "", className: styles.chatItem, showUnreadCount: false, divider: false }),
        React.createElement("div", { className: styles.contactChosenWrapper }, selected && React.createElement(Icon, { className: styles.contactItemChosenCheck, component: SingleCheck }))));
};
export default ContactListItem;
