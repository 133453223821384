import * as React from "react";
import turnOffIcon from '../../../assets/icons/logout/turnOff.svg';
import styles from './AutoDisconnectModalContent.module.scss';
import { Trans } from "react-i18next";
const AutoDisconnectModalContent = (props) => {
    const [timer, setTimer] = React.useState(props.countdownLength / 1000);
    React.useEffect(() => {
        let timeout;
        if (timer > 0) {
            timeout = setTimeout(() => setTimer(timer - 1), 1000);
        }
        else {
            console.debug('AutoDisconnectModalContent: onLogout');
            props.onLogout();
        }
        return () => {
            if (timeout) {
                clearTimeout(timeout);
            }
        };
    }, [timer]);
    return (React.createElement("div", { className: styles.container },
        React.createElement("div", { className: styles.descriptionContainer },
            React.createElement("img", { className: styles.turnOffIcon, src: turnOffIcon, alt: "turn-off" }),
            React.createElement("h3", null, React.createElement(Trans, { i18nKey: "autoDisconnecting" })),
            React.createElement("p", { className: styles.descriptionText },
                React.createElement(Trans, { i18nKey: "noActivity" }),
                " ",
                Math.floor(props.inactivityPeriod / 1000 / 60),
                " ",
                React.createElement(Trans, { i18nKey: "minutes" }),
                ".",
                React.createElement("br", null),
                React.createElement(Trans, { i18nKey: "disconnecting" })),
            React.createElement("p", { className: styles.timer }, formatTime(timer)))));
};
function formatTime(seconds) {
    return Math.floor(seconds / 60).toString().padStart(2, '0')
        + ':'
        + (seconds % 60).toString().padStart(2, '0');
}
export default AutoDisconnectModalContent;
