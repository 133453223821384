import React from "react";
import { observer } from "mobx-react";
import Files from "react-files";
import Icon from "@ant-design/icons";
import { Trans } from "react-i18next";
import { ReactComponent as CsvLoadFail } from "../../../../../../assets/csvFilesAndIcons/csvLoadFail.svg";
import { ReactComponent as CloseCross } from "../../../../../../assets/messageStatuses/closeCross.svg";
import { withStore } from "../../../../../../store/rootStore";
import "./FileUploadError.css";
const FileUploadError = ({ store: { wizardStore } }) => {
    const { errorMessage, errorValue } = wizardStore.uploadedFile || {};
    const deleteCsvHandle = () => {
        wizardStore.setUploadedFile({});
    };
    const onFileSelected = (files) => {
        wizardStore.onFileSelected(files);
    };
    const onFilesError = () => {
        wizardStore.onFilesError("uploadedFileError");
    };
    return (React.createElement("div", { className: "csv-upload-failed-wrapper" },
        React.createElement("div", { className: "file-load-error-main" },
            React.createElement("div", { className: "csv-upload-failed-info" },
                React.createElement(Icon, { component: CsvLoadFail }),
                React.createElement("p", { className: "csv-upload-failed-text" },
                    React.createElement(Trans, { i18nKey: `${errorMessage}`, values: errorValue, components: { bold: React.createElement("strong", null) } }))),
            React.createElement("div", { className: "csv-upload-failed-info" },
                React.createElement(Files, { multiple: false, onChange: onFileSelected, onError: onFilesError, accepts: [".csv"], clickable: true },
                    React.createElement("p", { className: "csv-try-again-text" },
                        React.createElement(Trans, { i18nKey: "tryAgain" }))),
                React.createElement("div", { className: "remove-csv" },
                    React.createElement(Icon, { onClick: deleteCsvHandle, component: CloseCross }))))));
};
export default withStore(observer(FileUploadError));
