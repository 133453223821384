import React from 'react';
import { Button, Col, Progress, Row, Statistic, Tooltip } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { AiFillPauseCircle, AiFillPlayCircle } from 'react-icons/ai';
import { ImStop } from 'react-icons/im';
import i18n from '../../i18n';
const styles = {
    button: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 4,
        height: '38 !important',
        backgroundColor: '#F5F5F5',
    },
    icon: {
        width: 20,
        height: 20,
    },
    iconResume: {
        // $primary_green
        fill: '#0FB878',
    },
};
const BulkProgressDisplay = ({ data, onStop, onPause, onResume, messageStopped }) => {
    const { sent = 0, skipped = 0, failed = 0, totalClients, stopped, paused } = data;
    const percentage = Math.round(((sent + skipped + failed) / totalClients) * 100);
    const onPauseResume = () => {
        if (paused) {
            onResume();
        }
        else {
            onPause();
        }
    };
    const resumePauseIcon = paused ? (React.createElement(AiFillPlayCircle, { style: Object.assign(Object.assign({}, styles.icon), styles.iconResume) })) : (React.createElement(AiFillPauseCircle, { style: styles.icon }));
    return (React.createElement("div", null,
        React.createElement(Row, { gutter: 16 },
            React.createElement(Col, { flex: "120px" },
                React.createElement(Progress, { percent: percentage, type: "circle" })),
            React.createElement(Col, { flex: "auto" },
                React.createElement(Statistic, { title: i18n.t('bulkModalTotal'), value: totalClients }),
                React.createElement(Statistic, { title: i18n.t('bulkModalSent'), prefix: React.createElement(CheckCircleOutlined, null), value: sent || 0 })),
            React.createElement(Col, { flex: "auto" },
                React.createElement(Statistic, { title: i18n.t('bulkModalSkipped'), prefix: React.createElement(ExclamationCircleOutlined, null), value: skipped || 0 }),
                React.createElement(Statistic, { title: i18n.t('bulkModalFailed'), prefix: React.createElement(CloseCircleOutlined, null), value: failed || 0 }))),
        React.createElement(Row, { gutter: 12, style: { marginTop: 12 } },
            React.createElement(Col, { span: 12 },
                React.createElement(Tooltip, { title: stopped ? messageStopped : '' },
                    React.createElement("div", null,
                        React.createElement(Button, { type: "default", style: styles.button, onClick: onPauseResume, disabled: stopped, block: true },
                            resumePauseIcon,
                            paused ? i18n.t('bulkModalResume') : i18n.t('bulkModalPause'))))),
            React.createElement(Col, { span: 12 },
                React.createElement(Tooltip, { title: stopped ? messageStopped : '' },
                    React.createElement("div", null,
                        React.createElement(Button, { type: "default", style: styles.button, onClick: onStop, disabled: stopped, block: true },
                            React.createElement(ImStop, { style: styles.icon }),
                            stopped ? i18n.t('bulkModalStopped') : i18n.t('bulkModalStop'))))))));
};
export default BulkProgressDisplay;
