import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import { Modal, Typography } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Trans } from "react-i18next";
import AddOrEditTemplate from "./AddOrEditTemplate";
import DeleteTemplate from "./DeleteTemplate";
import TemplatesList from "./TemplatesList";
import TemplateGeneratorTop from "./TemplateGeneratorTop";
import SubmitModal from "../../common/SubmitModal";
import { CALL_TO_ACTION_TYPES } from "../../../../constants";
import ErrorBoundary from "../../../ErrorBoundary";
import ChannelSelect from "../../ChatsList/ChannelSelect";
import useCheckIfMobile from "../../../../helpers/hooks/useCheckIfMobile";
import { useStore } from "../../../../store/rootStore";
import classNames from "classnames";
import { getDirection } from "../../../../helpers";
import styles from "./TemplateGenerator.module.scss";
const { Text } = Typography;
const TemplateGenerator = () => {
    const [showCancelModal, setShowCancelModal] = useState(false);
    const [templateAnchor, setTemplateAnchor] = useState(null);
    const { templateMessagesStore, channels } = useStore();
    const modalRef = useRef(null);
    const isMobile = useCheckIfMobile();
    const { isNewTemplateDrawer, setStoreToDefault, toggleNewTemplateDrawer, templateUsage, templateLanguage, templateCategory, templateMessage, templateTitle, callToActionButtons, templateEditing, templateCreating, templateDeleting, templateMessagesLoading, showManagerCancelModal, showCancelNotifyModal, selectedAccountId, setSelectedAccount, } = templateMessagesStore;
    useEffect(() => {
        if (templateAnchor && !templateEditing && !templateCreating && !templateDeleting) {
            const element = document.getElementById(`#${templateAnchor}`);
            if (element) {
                element.scrollIntoView({ block: 'center' });
            }
        }
    }, [templateAnchor, templateEditing, templateCreating, templateDeleting, modalRef.current]);
    const disableSendButton = () => {
        let buttonsChecker = [];
        if (callToActionButtons) {
            buttonsChecker = callToActionButtons.map((button) => {
                var _a, _b;
                return ((_a = button.text) === null || _a === void 0 ? void 0 : _a.length) === 0 ||
                    (button.type === CALL_TO_ACTION_TYPES.URL && !button.url.includes('https://')) ||
                    (button.type === CALL_TO_ACTION_TYPES.PHONE_NUMBER && !((_b = button.phone_number) === null || _b === void 0 ? void 0 : _b.startsWith('+')));
            });
        }
        return (!templateUsage ||
            !templateLanguage ||
            !templateCategory ||
            !templateMessage ||
            !templateTitle ||
            (callToActionButtons && buttonsChecker.includes(true)));
    };
    const renderBody = () => {
        if (templateMessagesLoading) {
            return (React.createElement("div", { className: styles.loadingWrapper },
                React.createElement(LoadingOutlined, { style: { fontSize: 50, marginBottom: 15 } }),
                React.createElement("p", null, "Loading templates...")));
        }
        if (!templateEditing && !templateCreating && !templateDeleting) {
            return (React.createElement(ErrorBoundary, null,
                React.createElement(TemplatesList, { forwardRef: modalRef, header: isMobile ? (React.createElement(ChannelSelect, { accounts: channels.templateAccounts, activeChannel: selectedAccountId, onChannelClick: setSelectedAccount, className: styles.channelSelector, showAll: false })) : null, setTemplateAnchor: setTemplateAnchor })));
        }
        if (templateEditing || templateCreating) {
            return (React.createElement(ErrorBoundary, null,
                React.createElement(AddOrEditTemplate, { disableSendButton: disableSendButton })));
        }
        if (templateDeleting) {
            return React.createElement(DeleteTemplate, null);
        }
        return null;
    };
    return (React.createElement(Modal, { open: isNewTemplateDrawer, footer: null, onCancel: () => {
            if (showManagerCancelModal) {
                setShowCancelModal(true);
            }
            else {
                setStoreToDefault();
                toggleNewTemplateDrawer();
            }
        }, width: 720, bodyStyle: {
            padding: "0",
            boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.2)",
            borderRadius: "2px",
        }, closable: false, centered: true, destroyOnClose: true },
        React.createElement("div", { className: classNames(styles.wrapper, {
                [styles.rtl]: getDirection() === 'rtl',
            }) },
            React.createElement(TemplateGeneratorTop, null),
            renderBody()),
        React.createElement(SubmitModal, { visible: showCancelModal, onCancel: () => setShowCancelModal(false), onSubmit: () => {
                setShowCancelModal(false);
                setStoreToDefault();
                showCancelNotifyModal(false);
            }, submitTextKey: "yes", alert: true },
            React.createElement("div", { className: styles.modalText },
                React.createElement(Text, { className: styles.modalText },
                    React.createElement(Trans, { i18nKey: "sureToCloseTemplates" }))))));
};
export default observer(TemplateGenerator);
