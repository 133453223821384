import React, { useState } from 'react';
import { Divider, Select, Typography } from 'antd';
import { Trans } from 'react-i18next';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import CommonButton from '../../../common/CommonButton';
import PhoneFormatter from '../../../../Base/Phone/Formatter';
import s from './SingleContact.module.scss';
const { Text } = Typography;
const SingleContact = ({ contact, action, avatarSrc }) => {
    var _a, _b, _c;
    const [selectedPhone, setSelectedPhone] = useState((_b = (_a = contact.phones) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.phone);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: s.contact },
            React.createElement("div", { className: s.nameWrapper },
                React.createElement("img", { className: s.avatar, src: avatarSrc }),
                React.createElement("div", { className: s.info },
                    contact.phones && contact.phones.length > 1 ? (React.createElement(Select, { value: selectedPhone, onChange: setSelectedPhone, className: s.phoneSelector }, contact.phones.map((phone, i) => (React.createElement(Select.Option, { key: i, value: phone === null || phone === void 0 ? void 0 : phone.phone },
                        React.createElement(PhoneFormatter, { phone: phone === null || phone === void 0 ? void 0 : phone.phone })))))) : (React.createElement("div", null,
                        React.createElement(PhoneFormatter, { phone: selectedPhone || '' }))),
                    React.createElement("div", { className: s.name }, contact.name.formatted_name), (_c = contact.emails) === null || _c === void 0 ? void 0 :
                    _c.map((email, i) => (React.createElement("div", { key: i, className: s.email }, email === null || email === void 0 ? void 0 : email.email))))),
            React.createElement(CommonButton, { action: () => selectedPhone && action(selectedPhone), disabled: !selectedPhone, submit: true },
                React.createElement(Text, { className: classNames(s.buttonText, { [s.disabled]: !selectedPhone }) },
                    React.createElement(Trans, { i18nKey: "openChat" })))),
        React.createElement(Divider, { className: s.divider })));
};
export default observer(SingleContact);
