import classNames from 'classnames';
import React from 'react';
import { getDirection } from '../../../../helpers';
import s from './ButtonsMessage.module.scss';
const ButtonsMessage = ({ text, customColor, invertColor, background, buttons, timestamp, timestampUserWhite, status, }) => {
    return (React.createElement("div", { className: s.container },
        React.createElement("div", { className: s.buttonsWrapper, style: { background } },
            React.createElement("p", { dir: "auto", className: classNames(s.buttonsBody, { [s.rtl]: getDirection() === 'rtl', [s.whiteText]: invertColor }), style: { color: customColor } }, text),
            React.createElement("div", { className: classNames(s.receiver, s.timestampContainer) },
                React.createElement("p", { className: classNames({
                        [s.timestampUser]: true,
                        [s.timestampUserWhite]: timestampUserWhite,
                    }) }, timestamp),
                status)),
        buttons.map(({ title, payload }, i) => (React.createElement("button", { key: i, className: s.button, style: { background }, disabled: true },
            React.createElement("p", { dir: "auto", className: classNames(s.text, {
                    [s.whiteText]: invertColor,
                }), style: { color: customColor } }, title || payload))))));
};
export default ButtonsMessage;
