import React from 'react';
import Icon from '@ant-design/icons';
import { Divider, Typography } from 'antd';
import classNames from 'classnames';
import { ReactComponent as DeleteIcon } from '../../../../../assets/icons/deleteIcon/deleteIcon.svg';
import { ReactComponent as EditIcon } from '../../../../../assets/icons/pencil/penIcon.svg';
import UserItemLabel from "../../../../Base/Items/UserItem/UserItemLabel";
import '../style.css';
import styles from "./QuickRepliesField.module.scss";
const { Text } = Typography;
const QuickRepliesField = ({ reply, departmentsList, setReplyDeleting, isEditing, setReplyEditted, }) => {
    const handleReplyDelete = () => {
        setReplyDeleting === null || setReplyDeleting === void 0 ? void 0 : setReplyDeleting(reply, !isEditing);
    };
    const handleReplyEditted = () => {
        setReplyEditted === null || setReplyEditted === void 0 ? void 0 : setReplyEditted(reply, !isEditing);
    };
    return (React.createElement("div", { className: classNames(styles.replyParent, styles.quickRepliesField, {
            [styles.managerReply]: !!setReplyEditted,
            [styles.quickRepliesFieldActive]: isEditing
        }) },
        React.createElement("div", { className: styles.replyNameSpaceLeft, onClick: () => handleReplyEditted() },
            React.createElement(Text, { dir: "auto", className: styles.replyText }, reply.text),
            React.createElement("div", { className: styles.replyDepartmentsWrapper }, reply.departments && departmentsList.filter(department => { var _a; return (_a = reply.departments) === null || _a === void 0 ? void 0 : _a.includes(department._id); }).map((department, i) => (React.createElement(React.Fragment, null,
                React.createElement(UserItemLabel, { key: i, department: department }),
                React.createElement(Divider, { type: "vertical" })))))),
        React.createElement("div", { className: styles.replyNameSpaceRight },
            React.createElement(Icon, { id: "replyEditChild", className: styles.replyNameButtonEdit, component: EditIcon, onClick: () => handleReplyEditted() }),
            React.createElement(Icon, { className: styles.replyNameButtonDelete, component: DeleteIcon, onClick: () => handleReplyDelete() }))));
};
export default QuickRepliesField;
