var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { API_PATH } from "../../constants";
import { action, observable, runInAction, makeObservable } from "mobx";
import { getAxios } from '../../backend';
import { EventName } from "@whatsper/texterchat-common";
import { pick } from "lodash";
class DepartmentsStore {
    constructor(rootStore) {
        Object.defineProperty(this, "rootStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "departments", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: observable([])
        });
        Object.defineProperty(this, "fetchDepartments", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                yield getAxios()
                    .then((axios) => {
                    return axios.get(API_PATH.DEPARTMENTS);
                })
                    .then(({ data }) => {
                    runInAction(() => this.departments.replace(data.departments));
                })
                    .catch(error => console.error("ERROR: getting departments failed", error));
            })
        });
        Object.defineProperty(this, "createDepartment", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (department) => __awaiter(this, void 0, void 0, function* () {
                yield getAxios()
                    .then((axios) => {
                    return axios.post(API_PATH.DEPARTMENTS, department);
                });
            })
        });
        Object.defineProperty(this, "editDepartment", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (department) => __awaiter(this, void 0, void 0, function* () {
                yield getAxios()
                    .then((axios) => {
                    return axios.patch(`${API_PATH.DEPARTMENTS}/${department._id}`, pick(department, ['color', 'name', 'sla']));
                });
            })
        });
        Object.defineProperty(this, "deleteDepartment", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (id) => __awaiter(this, void 0, void 0, function* () {
                yield getAxios()
                    .then((axios) => {
                    return axios.delete(`${API_PATH.DEPARTMENTS}/${id}`);
                });
            })
        });
        makeObservable(this);
        this.rootStore = rootStore;
        this.rootStore.events
            .addListener(EventName.department_created, (data) => this.onDepartmentCreatedEvent(data))
            .addListener(EventName.department_updated, (data) => this.onDepartmentUpdatedEvent(data))
            .addListener(EventName.department_deleted, (data) => this.onDepartmentDeletedEvent(data));
    }
    getDepartmentData(id) {
        return this.departments.find(dep => dep._id === id);
    }
    onDepartmentCreatedEvent(dep) {
        this.departments.push(dep);
    }
    onDepartmentUpdatedEvent(depUpdated) {
        this.departments.replace(this.departments.map((dep) => {
            return depUpdated._id === dep._id ? depUpdated : dep;
        }));
    }
    onDepartmentDeletedEvent(data) {
        this.departments.replace(this.departments.filter((dep) => dep._id !== data._id));
    }
}
__decorate([
    action
], DepartmentsStore.prototype, "onDepartmentCreatedEvent", null);
__decorate([
    action
], DepartmentsStore.prototype, "onDepartmentUpdatedEvent", null);
__decorate([
    action
], DepartmentsStore.prototype, "onDepartmentDeletedEvent", null);
export default DepartmentsStore;
