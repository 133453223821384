import React from 'react';
import { observer } from 'mobx-react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Trans, useTranslation } from 'react-i18next';
import UploadButton from '../../../Chat/common/UploadButton';
import { useStore } from '../../../../store/rootStore';
import TemplateTitle from '../TemplateTitle/TemplateTitle';
import { getHeaderTypeSpecificMessages } from '../../helpers';
import s from './SecondPageFile.module.scss';
const SecondPageFile = () => {
    var _a, _b;
    const { wizardStore } = useStore();
    const { t } = useTranslation();
    const { chosenTemplate, onImageOrFileStepLoad, uploadedImageOrFileStep } = wizardStore;
    const { recommndedSizeMessage, accepts, buttonText, } = getHeaderTypeSpecificMessages((chosenTemplate === null || chosenTemplate === void 0 ? void 0 : chosenTemplate.header) && chosenTemplate.header.type || null, t);
    return (React.createElement("div", { className: s.secondPageWrapper },
        React.createElement(TemplateTitle, { title: ((_a = wizardStore.chosenTemplate) === null || _a === void 0 ? void 0 : _a.title) || '', name: ((_b = wizardStore.chosenTemplate) === null || _b === void 0 ? void 0 : _b.name) || '' }),
        chosenTemplate && (React.createElement("div", { className: s.uploadButton },
            React.createElement(UploadButton, { onFileSelected: onImageOrFileStepLoad, text: (uploadedImageOrFileStep === null || uploadedImageOrFileStep === void 0 ? void 0 : uploadedImageOrFileStep.name) || buttonText, types: accepts }),
            React.createElement("div", { className: s['button-help-text'] },
                React.createElement(ExclamationCircleOutlined, { className: s['info-circle'] }),
                React.createElement("p", { className: s['info-text'] },
                    React.createElement(Trans, { i18nKey: recommndedSizeMessage })))))));
};
export default observer(SecondPageFile);
