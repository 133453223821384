import React from 'react';
import { observer } from 'mobx-react';
import { Select, Typography } from 'antd';
import { Trans } from 'react-i18next';
import { FILTER_AGENTS } from '../../../constants';
import { CHATS_GROUP } from '../../../store/ChatsStore';
import { MySelect } from '../../Base/Form';
import { useStore } from '../../../store/rootStore';
import singleCheck from '../../../assets/icons/chatStatuses/singleCheck.svg';
import styles from './AgentsFilter.module.scss';
const { Option } = Select;
const { Text } = Typography;
const AgentsFilter = ({ fullwidth }) => {
    const { agentsStore, chatsStore } = useStore();
    const handleChange = (value) => {
        chatsStore.setAgentFilter(value);
    };
    const renderCheckmark = (visible) => visible && React.createElement("img", { src: singleCheck });
    return (React.createElement(MySelect, { className: styles.filterAgentsSelect, defaultValue: FILTER_AGENTS.ALL_AGENTS, value: chatsStore.searchFilter.agent || FILTER_AGENTS.ALL_AGENTS, onChange: handleChange, virtual: false, fullWidth: fullwidth, size: "small", onClick: e => {
            /* Allow filter to be open within a modal */
            e.stopPropagation();
        } },
        React.createElement(Option, { id: FILTER_AGENTS.ALL_AGENTS, value: FILTER_AGENTS.ALL_AGENTS },
            React.createElement("div", { className: styles.filterAgentsContent },
                React.createElement("div", { style: { backgroundColor: 'lightgrey' }, className: styles.filterAgentColor }),
                React.createElement(Trans, { i18nKey: "allAgents" })),
            renderCheckmark(!chatsStore.searchFilter.agent)),
        React.createElement(Option, { id: FILTER_AGENTS.NOT_ASSIGNED, value: FILTER_AGENTS.NOT_ASSIGNED },
            React.createElement("div", { className: styles.filterAgentsContent },
                React.createElement("div", { style: { border: '2px solid lightgrey' }, className: styles.filterAgentColor }),
                React.createElement(Trans, { i18nKey: "notAssigned" })),
            renderCheckmark(!chatsStore.searchFilter.agent)),
        chatsStore.currentGroup === CHATS_GROUP.RESOLVED ? (React.createElement(Option, { id: FILTER_AGENTS.WITH_BOT, key: "with-bot", value: FILTER_AGENTS.WITH_BOT },
            React.createElement(Trans, { i18nKey: "withBot" }),
            renderCheckmark(FILTER_AGENTS.WITH_BOT === chatsStore.searchFilter.agent))) : null,
        agentsStore.users
            .filter((agent) => !agent.disabled)
            .map((agent) => (React.createElement(Option, { key: agent._id, id: agent._id, value: agent._id, className: styles.filterAgentsOption },
            React.createElement("div", { className: styles.filterAgentsContent },
                React.createElement("div", { style: { backgroundColor: agent.color || 'lightgrey' }, className: styles.filterAgentColor }),
                React.createElement(Text, { className: styles.filterAgentsOptionText }, agent.displayName || agent.email)),
            renderCheckmark(agent._id === chatsStore.searchFilter.agent))))));
};
export default observer(AgentsFilter);
