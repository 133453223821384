import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Trans } from "react-i18next";
import { Tooltip, Typography } from 'antd';
import classnames from "classnames";
import Icon, { InfoCircleOutlined, LoadingOutlined } from "@ant-design/icons";
import styles from "./BlockedChatBar.module.scss";
import CommonButton from "../../common/CommonButton";
import { ReactComponent as LockOpen } from "../../../../assets/icons/lock/lockOpen.svg";
import { withStore } from '../../../../store/rootStore';
const { Text } = Typography;
const BlockedChatBar = withStore(observer((props) => {
    const { activeChatStore, agentsStore } = props.store;
    const [fillButton, setFillButton] = useState(false);
    const getAgent = () => {
        var _a;
        if ((_a = activeChatStore.chat) === null || _a === void 0 ? void 0 : _a.blockedChat) {
            const agent = agentsStore.getAgentData(activeChatStore.chat.blockedChat.agent);
            return (agent === null || agent === void 0 ? void 0 : agent.displayName) || (agent === null || agent === void 0 ? void 0 : agent.email);
        }
        return '';
    };
    const unblockChat = () => {
        activeChatStore.unblockChat();
    };
    return (React.createElement("div", { className: styles.wrapper },
        React.createElement("div", null,
            React.createElement(InfoCircleOutlined, { className: styles.warningIcon }),
            React.createElement(Text, null,
                React.createElement(Trans, { i18nKey: "blockedInfo", components: { span: React.createElement("span", { className: styles.errorText }) }, values: { agent: getAgent() } }))),
        React.createElement(Tooltip, { open: activeChatStore.blockUserError, arrowPointAtCenter: true, placement: "top", title: React.createElement(Trans, { i18nKey: "unexpectedError" }) },
            React.createElement(CommonButton, { action: unblockChat, additionalStyles: styles.unblockButton, onMouseEnter: () => setFillButton(true), onMouseLeave: () => setFillButton(false) }, activeChatStore.blockChatLoading ? React.createElement(LoadingOutlined, { className: styles.preloader }) :
                React.createElement("div", { className: styles.buttonContent },
                    React.createElement(Icon, { className: classnames({ [styles.fillButton]: fillButton }), component: LockOpen }),
                    React.createElement(Text, { className: classnames(styles.buttonText, { [styles.fillButton]: fillButton }) },
                        React.createElement(Trans, { i18nKey: "unblock" })))))));
}));
export default BlockedChatBar;
