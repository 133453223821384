import React from 'react';
import { Auth } from '@whatsper/texterchat-common';
import { Button } from 'antd';
import { Trans } from 'react-i18next';
import styles from '../Login.module.scss';
const MethodSelect = ({ authMethods, setAuthMethods }) => {
    const renderMethodTitle = (method) => {
        switch (method) {
            case Auth.Methods.Method.password:
                return React.createElement(Trans, { i18nKey: "loginPasswordMethod" });
            case Auth.Methods.Method.whatsapp_otp:
                return React.createElement(Trans, { i18nKey: "loginWhatsAppOTPMethod" });
            case Auth.Methods.Method.totp:
                return React.createElement(Trans, { i18nKey: "loginTOTPMethod" });
        }
    };
    return (React.createElement("div", { className: styles.loginMethods }, authMethods.map((method) => (React.createElement(Button, { key: method, type: "primary", className: styles.loginFormButton, onClick: () => setAuthMethods([method]) }, renderMethodTitle(method))))));
};
export default MethodSelect;
