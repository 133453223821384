import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import classNames from 'classnames';
import { setMomentLocaleOnLanguageChange } from './helpers';
import { getDirection, setDirection } from '../../../helpers';
import { RiTextDirectionL, RiTextDirectionR } from 'react-icons/ri';
import s from './LanguageSelect.module.scss';
const LanguageSelect = ({ className }) => {
    const { i18n } = useTranslation();
    const dir = getDirection();
    const [language, setLanguage] = useState(i18n.language + '-' + dir);
    const defaultLanguage = localStorage.getItem('currentLanguage') || language.split('-')[0];
    const defaultDirection = localStorage.getItem('currentDirection') || dir;
    const handleChange = (event) => {
        const [language, dir] = event.target.value.split('-');
        setLanguage(event.target.value);
        setDirection(dir);
        localStorage.setItem('currentLanguage', language);
        localStorage.setItem('currentDirection', dir);
        i18n.changeLanguage(language);
    };
    useEffect(() => {
        setMomentLocaleOnLanguageChange(i18n.language);
    }, [i18n.language]);
    return (React.createElement(FormControl, { className: classNames(s.formControl, className) },
        React.createElement(InputLabel, { id: "demo-simple-select-label" }),
        React.createElement(Select, { MenuProps: {
                classes: {
                    paper: s.paper,
                },
            }, labelId: "demo-simple-select-label", id: "demo-simple-select", onChange: handleChange, value: `${defaultLanguage}-${defaultDirection}` },
            React.createElement(MenuItem, { value: "he-rtl" },
                "\u05E2\u05D1\u05E8 ",
                React.createElement(RiTextDirectionR, null)),
            React.createElement(MenuItem, { value: "he-ltr" },
                React.createElement(RiTextDirectionL, null),
                " \u05E2\u05D1\u05E8"),
            React.createElement(MenuItem, { value: "en-ltr" }, "ENG"))));
};
export default LanguageSelect;
