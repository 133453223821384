import React from 'react';
import VpnKeyOutlinedIcon from '@material-ui/icons/VpnKeyOutlined';
import AccountTreeOutlinedIcon from '@material-ui/icons/AccountTreeOutlined';
import { ReactComponent as ZapierIcon } from '../../../assets/icons/leftMenuIcons/zapier.svg';
import { ReactComponent as MakeIcon } from '../../../assets/icons/leftMenuIcons/make.svg';
import { GrConfigure } from 'react-icons/gr';
import Collapsable from './Collapsable';
const routes = [
    {
        titleKey: 'apiTokensManager',
        icon: React.createElement(VpnKeyOutlinedIcon, null),
        path: '/manage/api-tokens',
    },
    {
        titleKey: 'oauthManager',
        icon: React.createElement(AccountTreeOutlinedIcon, null),
        path: '/manage/oauth',
    },
    {
        titleKey: 'panelZapier',
        icon: React.createElement(ZapierIcon, null),
        url: 'https://zapier.com/developer/public-invite/188308/637c625ce8e4d609634e8b16540f27ce',
    },
    {
        titleKey: 'panelMake',
        icon: React.createElement(MakeIcon, null),
        url: 'https://www.make.com/en/hq/app-invitation/2aec0eb04e88d1fb86a5ec560692d365',
    },
];
const DevelopersSettings = ({ goToAppPath }) => {
    return React.createElement(Collapsable, { title: "panelDevelopers", icon: React.createElement(GrConfigure, null), routes: routes, goToAppPath: goToAppPath });
};
export default DevelopersSettings;
