import React, { useEffect, useState } from 'react';
import { RxCaretDown, RxCaretUp } from 'react-icons/rx';
import { Input } from 'antd';
import Icon, { LeftOutlined, LoadingOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { observer } from 'mobx-react';
import { useStore } from '../../../store/rootStore';
import { MessagesSearchLoading } from '../../../store/MessagesStore';
import s from './ChatSearch.module.scss';
const ChatSearchMobile = ({ onClose, onMessageSelected }) => {
    var _a, _b;
    const [currentMessage, setCurrentMessage] = useState(0);
    const { activeChatStore } = useStore();
    const { inChatSearchQuery, setInChatSearchQuery, inChatSearchResults, resetInChatSearch, theme, inChatSearchLoading, } = activeChatStore;
    const loading = inChatSearchLoading !== MessagesSearchLoading.NONE;
    useEffect(() => {
        if (!inChatSearchResults.totalHits)
            return;
        setCurrentMessage(0);
    }, [inChatSearchResults]);
    useEffect(() => {
        var _a;
        if (!inChatSearchResults.totalHits)
            return;
        if (typeof onMessageSelected === 'function') {
            onMessageSelected((_a = inChatSearchResults.messages[currentMessage]) === null || _a === void 0 ? void 0 : _a.id);
        }
    }, [currentMessage]);
    useEffect(() => {
        if (!inChatSearchQuery) {
            resetInChatSearch();
        }
    }, [inChatSearchQuery]);
    return (React.createElement("div", { className: s.mobileWrapper },
        React.createElement(LeftOutlined, { className: classNames(s.arrowBack, {
                [s.inverted]: (_a = theme.additionalData) === null || _a === void 0 ? void 0 : _a.invertDotsColor,
            }), onClick: onClose }),
        React.createElement("div", { className: s.mobileInputWrapper },
            React.createElement(Input, { value: inChatSearchQuery, onChange: (e) => setInChatSearchQuery(e.target.value), className: classNames(s.searchInput, s.mobileSearchInput, {
                    [s.inverted]: theme.invertColors,
                }), placeholder: "Search...", bordered: false, allowClear: !loading, suffix: loading && React.createElement(LoadingOutlined, null) }),
            React.createElement("div", { className: s.mobileInputBackground })),
        inChatSearchResults.totalHits !== 0 && (React.createElement("div", { className: classNames(s.resultsCount, {
                [s.inverted]: theme.invertColors,
            }) },
            currentMessage + 1,
            " of ",
            inChatSearchResults.totalHits)),
        React.createElement("div", { className: classNames(s.controls, {
                [s.inverted]: (_b = theme.additionalData) === null || _b === void 0 ? void 0 : _b.invertDotsColor,
            }) },
            React.createElement(Icon, { component: RxCaretUp, onClick: () => setCurrentMessage(currentMessage + 1), disabled: currentMessage >= inChatSearchResults.totalHits }),
            React.createElement(Icon, { component: RxCaretDown, onClick: () => setCurrentMessage(currentMessage - 1), disabled: currentMessage === 0 }))));
};
export default observer(ChatSearchMobile);
