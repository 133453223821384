import React from 'react';
import { Modal, Button, Typography } from 'antd';
import { Trans } from "react-i18next";
import Icon from "@ant-design/icons";
import styles from "./NotificationModal.module.scss";
import { ReactComponent as QuickRepliesCross } from "../../../../assets/messageStatuses/closeCross.svg";
const { Text } = Typography;
const NotificationModal = (props) => {
    const { visible, onCancel, contentText } = props;
    return (React.createElement(Modal, { width: 600, open: visible, closable: false, onCancel: onCancel, footer: null, centered: true, bodyStyle: {
            padding: '0',
            boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)',
            borderRadius: '2px',
        } },
        React.createElement("div", { className: styles.modalContainer },
            React.createElement("div", { className: styles.modalHeader },
                React.createElement("div", { className: styles.closeIconWrapper },
                    React.createElement(Icon, { component: QuickRepliesCross }))),
            React.createElement(Text, { className: styles.modalContentText },
                React.createElement(Trans, { i18nKey: `${contentText}` })),
            React.createElement("div", { className: styles.modalBottom },
                React.createElement(Button, { className: styles.modalButton, onClick: onCancel },
                    React.createElement(Trans, { i18nKey: "ok" }))))));
};
export default NotificationModal;
