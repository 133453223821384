var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import Drawer from '@material-ui/core/Drawer';
import { CHAT_STATUS, CHAT_STATUSES_NAMES } from '@whatsper/texterchat-common';
import { withStore } from '../../store/rootStore';
import Header from './views/Header';
import Overview from './views/Overview';
import Departments from './views/Departments';
import Agents from './views/Agents';
import SLAModal from './components/SLAModal';
import DownloadDataModal from './components/DownloadDataModal';
import ErrorBoundary from '../ErrorBoundary';
import classNames from 'classnames';
import { getDirection } from '../../helpers';
import s from './DashboardDrawer.module.scss';
const DashboardDrawer = ({ toggleDashboardDrawer, isOpen, store: { dashboard } }) => {
    const [isSLAModalVisible, setSLAModalVisible] = useState(false);
    const [isDownloadDataModalVisible, setDownloadDataModalVisible] = useState(false);
    const fetchData = () => __awaiter(void 0, void 0, void 0, function* () {
        if (!dashboard.latestTimestamp)
            return;
        const { starts, ends } = dashboard.latestTimestamp;
        if (!dashboard.hasData) {
            dashboard.setLoading(true);
        }
        if (dashboard.isLive || !dashboard.historicalModeEnabled) {
            dashboard.getLiveStats({ after: starts.toString() });
        }
        else {
            dashboard.getHistoricalStats({ after: starts.toString(), before: ends.toString() });
        }
        yield Promise.allSettled([
            dashboard.getResolvedStats({ after: starts.toString() }),
            dashboard.getSettings(),
            dashboard.getSettingsWorkingHours(),
            dashboard.getSLACalculations(),
            dashboard.getHistoricalStats({
                after: starts.toString(),
                before: ends.toString(),
                statuses: [CHAT_STATUSES_NAMES[CHAT_STATUS.ASSIGNED]],
            }),
        ]);
        dashboard.setLoading(false);
    });
    useEffect(() => {
        if (isOpen) {
            dashboard.getSettingsWorkingHoursTimestamps();
        }
    }, [isOpen]);
    useEffect(() => {
        if (isOpen && dashboard.latestTimestamp) {
            fetchData();
            dashboard.startPollingData();
        }
        if (!isOpen) {
            dashboard.stopPollingData();
        }
    }, [isOpen, dashboard.latestTimestamp]);
    return (React.createElement(Drawer, { open: isOpen, onClose: () => {
            toggleDashboardDrawer(false);
            setSLAModalVisible(false);
            setDownloadDataModalVisible(false);
        }, style: { zIndex: 10 }, anchor: getDirection() === 'rtl' ? 'right' : 'left', dir: getDirection(), classes: {
            root: s['dashboard__root'],
            paperAnchorLeft: s['dashboard__paperAnchorLeft'],
            paperAnchorRight: s['dashboard__paperAnchorRight'],
        } },
        React.createElement("div", { className: classNames(s['dashboard__container'], {
                [s.rtl]: getDirection() === 'rtl',
            }) },
            React.createElement(ErrorBoundary, null,
                React.createElement(Header, { toggleDashboardDrawer: toggleDashboardDrawer, latestTimestamp: dashboard.latestTimestamp, setSLAModalVisible: setSLAModalVisible, setDownloadDataModalVisible: setDownloadDataModalVisible, isLive: dashboard.isLive })),
            React.createElement(ErrorBoundary, null,
                React.createElement(Overview, null)),
            React.createElement(ErrorBoundary, null,
                React.createElement(Departments, null)),
            React.createElement(ErrorBoundary, null,
                React.createElement(Agents, null))),
        React.createElement(DownloadDataModal, { visible: isDownloadDataModalVisible, onCancel: () => setDownloadDataModalVisible(false), toggleDashboardDrawer: toggleDashboardDrawer }),
        React.createElement(SLAModal, { visible: isSLAModalVisible, onCancel: () => setSLAModalVisible(false), toggleDashboardDrawer: toggleDashboardDrawer, updateSettings: dashboard.updateSettings })));
};
export default withStore(observer(DashboardDrawer));
