import classNames from "classnames";
import React, { useEffect, useState } from "react";
import Files from "react-files";
import { Trans } from "react-i18next";
import CommonButton from "../CommonButton";
import s from "./UploadButton.module.scss";
const UploadButton = ({ onFileSelected, text, types, multiple, icon, fluid, disabled, additionalStyles }) => {
    const [innerText, setInnerText] = useState("");
    useEffect(() => {
        return () => {
            setInnerText('');
        };
    }, []);
    return (React.createElement("div", { className: classNames(s.uploadButton, { [s.fluid]: fluid }) },
        React.createElement(Files, { multiple: multiple || false, onChange: (files) => {
                if (disabled || files.length === 0)
                    return;
                if (files.length > 1) {
                    setInnerText("filesLoaded");
                }
                else {
                    setInnerText(files[0].name);
                }
                onFileSelected(files);
            }, onError: () => {
                setInnerText("loadingError");
            }, accepts: types, clickable: true },
            React.createElement(CommonButton, { action: () => { }, additionalStyles: additionalStyles, disabled: disabled, submit: true },
                React.createElement("div", { className: s.buttonText },
                    React.createElement(Trans, { i18nKey: innerText || text })),
                icon))));
};
export default UploadButton;
