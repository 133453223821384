var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { API_PATH, SERVER_URL } from '../../constants';
import { getAxios } from '../../backend';
import FileSaver from 'file-saver';
import moment from 'moment';
export default class FilesStore {
    constructor(rootStore) {
        Object.defineProperty(this, "rootStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "uploadFiles", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (files, directory) => __awaiter(this, void 0, void 0, function* () {
                const formData = new FormData();
                formData.append('directory', directory);
                if (isFileList(files)) {
                    let i = 0;
                    for (const file of files) {
                        formData.append(`file_${i}`, file);
                        i++;
                    }
                }
                else {
                    Object.keys(files).map((name) => {
                        formData.append(name, files[name]);
                    });
                }
                return getAxios()
                    .then((axios) => {
                    return axios.post(API_PATH.FILES, formData, {
                        headers: {
                            'content-type': 'multipart/form-data'
                        }
                    });
                })
                    .then(({ data }) => data)
                    .catch(err => {
                    console.error("Error uploading files", err);
                });
            })
        });
        Object.defineProperty(this, "shareFile", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (fileId) => __awaiter(this, void 0, void 0, function* () {
                return getAxios()
                    .then((axios) => {
                    return axios.post(`${API_PATH.FILES}/share/${fileId}`);
                })
                    .then(({ data }) => {
                    return data.token;
                })
                    .then((token) => {
                    return getFileSharedUrl(fileId, token);
                });
            })
        });
        Object.defineProperty(this, "uploadFileAndShare", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (file, directory) => __awaiter(this, void 0, void 0, function* () {
                const uploadedFiles = yield this.uploadFiles([file], directory);
                if (uploadedFiles) {
                    const [fileSaved] = uploadedFiles;
                    return this.shareFile(fileSaved.fileId);
                }
            })
        });
        Object.defineProperty(this, "getAgentUploadDirectory", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a;
                const date = new Date().toDateString().replace(/[^a-z0-9_-]/ig, '-');
                return `${STORAGE_AGENT_DIR}/${(_a = this.rootStore.loginStore.userDetails) === null || _a === void 0 ? void 0 : _a._id}/${date}`;
            }
        });
        /**
         * Download files by their IDs. It will be a downloaded as a ZIP archive.
         *
         * @param ids Array of file IDs to be downloaded
         */
        Object.defineProperty(this, "downloadFilesArchive", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (ids) => __awaiter(this, void 0, void 0, function* () {
                const params = { ids };
                return getAxios()
                    .then((axios) => {
                    return axios.post(`${API_PATH.FILES}/multi-download`, params, { responseType: 'blob' });
                })
                    .then((res) => {
                    FileSaver.saveAs(res.data, `files-${moment().format('d-M-Y')}.zip`);
                })
                    .catch((err) => {
                    console.error('Error downloading files', err);
                });
            })
        });
        this.rootStore = rootStore;
    }
}
/**
 * Get URL to file
 *
 * @param fileId Target file ID
 * @param download Add to response headers which will force browser to download file instead of opening it
 * @param attachment Name of the file which will be used as attachment name when downloading
 * @returns
 */
export function getFileUrl(fileId, download = false, attachment) {
    let url = `${SERVER_URL}${API_PATH.FILES}/${fileId}`;
    if (download) {
        url += '/download';
        if (attachment) {
            url += `?attachment=${encodeURIComponent(attachment)}`;
        }
    }
    return url;
}
/**
 * Get URL to file
 *
 * @param fileId Target file ID
 * @param download Add to response headers which will force browser to download file instead of opening it
 * @param attachment Name of the file which will be used as attachment name when downloading
 * @returns url to file
 */
export function getDirectFileUrlToStorage(fileId) {
    return getAxios()
        .then((axios) => {
        return axios.get(`${API_PATH.FILES}/${fileId}/direct-storage-link`);
    })
        .then((res) => {
        return res.data.url;
    })
        .catch((err) => {
        console.error('Error downloading files', err);
    });
}
export function getFileSharedUrl(fileId, token, noPipe = true) {
    let url = `${SERVER_URL}${API_PATH.FILES}/shared/${fileId}?t=${encodeURIComponent(token)}`;
    if (noPipe) {
        url += '&noPipe=1';
    }
    // Sometimes server URL might be not absolute full URL but just path,
    // but we need to have full URL always when sharing with external services
    if (url.startsWith('http')) {
        return url;
    }
    return window.location.origin + url;
}
export function getFilePreviewUrl(fileId) {
    return `${SERVER_URL}${API_PATH.FILES}/preview/${fileId}`;
}
export const STORAGE_AGENT_DIR = 'agent_uploads';
function isFileList(files) {
    return typeof files.length === 'number';
}
