import React from 'react';
import { getFilePreviewUrl } from '../../../../store/FilesStore';
import styles from './StickerMessage.module.scss';
const StickerMessage = ({ message: { media: mediaArray } }) => {
    const media = mediaArray[0];
    let url;
    if (media.fileId) {
        url = getFilePreviewUrl(media.fileId);
    }
    else if (media.url) {
        url = media.url;
    }
    else {
        console.error('Message have no file ID or URL');
        return null;
    }
    return React.createElement("img", { className: styles.image, src: url, alt: 'sticker' });
};
export default StickerMessage;
