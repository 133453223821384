var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { observable, runInAction } from 'mobx';
import axios from 'axios';
import { SERVER_URL, VERSION } from '../constants';
/**
 * Extend prepared Axios instance with optionally publicErrors
 *
 * @param publicErrors Overwrite thrown errors messages with server-provided errors or generic text for "non-tech" users
 */
export function getAxios(publicErrors = true) {
    return __awaiter(this, void 0, void 0, function* () {
        // DONT MOVE INSTANCE CREATION OUTSIDE OF THIS FUNCTION!!!!!!!!!
        // Every time interceptors.response.use() called it registers new interceptors!
        // After error altered by createApiPublicError() it will not contain original properties like "response" or "__CANCEL__" anymore,
        // which will cause false detection of no internet connection and other problems!
        /**
         * Prepare Axios instance with server base URL
         */
        const axiosInstance = axios.create({
            baseURL: SERVER_URL,
            headers: {
                'x-inbox-version': VERSION,
            },
            withCredentials: true,
        });
        axiosInstance.interceptors.response.use((value) => {
            setHasResponseAttempt();
            return value;
        }, (error) => {
            // Return cancelation error as is and not log
            if (error.__CANCEL__) {
                return Promise.reject(error);
            }
            console.error(error);
            if (error.response) {
                console.error('Response data', error.response.data);
            }
            if (!error.response) {
                setNoResponseAttempt();
            }
            else {
                setHasResponseAttempt();
            }
            return Promise.reject(publicErrors ? createApiPublicError(error) : error);
        });
        return axiosInstance;
    });
}
/**
 * After this count or more consecutive requests
 * to backend without response consider we have no internet connection
 */
const noInternetFailsThreshold = 2;
let consecutiveFailedRequests = 0;
const onlineStatus = observable({ online: true });
export { onlineStatus };
function setNoResponseAttempt() {
    consecutiveFailedRequests++;
    runInAction(() => {
        onlineStatus.online = consecutiveFailedRequests < noInternetFailsThreshold;
    });
}
function setHasResponseAttempt() {
    consecutiveFailedRequests = 0;
    runInAction(() => {
        onlineStatus.online = true;
    });
}
/**
 * Create error with text to show to users
 */
function createApiPublicError(error) {
    let text = 'An unexpected error occured';
    if (typeof error.response === 'object') {
        const { data, status } = error.response;
        if (status >= 400 && status < 500) {
            if (typeof data === 'string') {
                text = data;
            }
            else if (data && typeof data.error === 'string') {
                text = data.error;
            }
        }
        else if (status >= 500) {
            text = 'Internal server error';
        }
    }
    return new Error(text);
}
