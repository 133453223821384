import React, { useRef } from "react";
import classnames from "classnames";
import Icon from "@ant-design/icons";
import { Tooltip } from "antd";
import { Trans } from "react-i18next";
import { ReactComponent as NotificationCircle } from "../../../../assets/icons/chatStatuses/green-notification-circle.svg";
import styles from "./ClickableIcon.module.scss";
;
const ClickableIcon = ({ component: Component, action, status, tooltipTransKey, notification }) => {
    const clickableIconRef = useRef(null);
    const onClick = () => {
        var _a;
        const mouseLeaveEvent = new Event("mouseout", { bubbles: true });
        (_a = clickableIconRef.current) === null || _a === void 0 ? void 0 : _a.dispatchEvent(mouseLeaveEvent);
        action === null || action === void 0 ? void 0 : action();
    };
    return (React.createElement(Tooltip, { placement: "right", title: React.createElement(Trans, { i18nKey: tooltipTransKey }) },
        React.createElement("div", { className: classnames(styles.button, {
                [styles.buttonColorSelect]: status,
                [styles.buttonColor]: !status,
            }), onClick: onClick, ref: clickableIconRef },
            React.createElement(Icon, { className: classnames(styles.buttonSmooth, {
                    [styles.menuButtonColorSelect]: status,
                    [styles.menuButtonColor]: !status,
                }), component: () => React.createElement(Component, null) }),
            notification && React.createElement(Icon, { className: styles.notificationIcon, component: NotificationCircle }))));
};
export default ClickableIcon;
