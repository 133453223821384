var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Select } from 'antd';
import _ from 'lodash';
import classNames from 'classnames';
import { getDirection } from '../../../../helpers';
import './MySelect.scss';
const { Option } = Select;
const MySelect = (_a) => {
    var { className, options = [], fullWidth, children, size = 'middle', forwardRef } = _a, rest = __rest(_a, ["className", "options", "fullWidth", "children", "size", "forwardRef"]);
    /* If options are strings, convert them to objects */
    if (_.every(options, _.isString)) {
        options = _.map(options, (option) => ({
            label: option,
            value: option,
        }));
    }
    const renderOptions = () => {
        if (children) {
            return children;
        }
        return options.map((option) => (React.createElement(Option, { key: option.value, value: option.value, className: 'option' }, option.label)));
    };
    return (React.createElement(Select, Object.assign({ className: classNames('myselect-wrapper', className, {
            rtl: getDirection() === 'rtl',
            fullWidth,
            small: size === 'small',
            middle: size === 'middle',
            large: size === 'large',
        }), popupClassName: classNames('myselect-popup', {
            rtl: getDirection() === 'rtl',
        }), direction: getDirection(), ref: forwardRef }, rest), renderOptions()));
};
MySelect.Option = Option;
export default MySelect;
