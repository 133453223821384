var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { DownloadOutlined } from '@ant-design/icons';
import { Button, DatePicker, Modal } from 'antd';
import classNames from 'classnames';
import FileSaver from "file-saver";
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { API_PATH } from '../../../constants';
import CommonButton from '../../Chat/common/CommonButton';
import { getAxios } from '../../../backend';
import s from '../DashboardDrawer.module.scss';
;
const DownloadDataModal = ({ visible, onCancel, toggleDashboardDrawer }) => {
    const { i18n, t } = useTranslation();
    const [loadingFullReport, setLoadingFullReport] = useState(false);
    const [loadingRawReport, setLoadingRawReport] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [isErrorDownloading, setErrorDownloading] = useState(false);
    const [dateRange, setDateRange] = useState([null, null]);
    const onChooseYesterday = () => {
        const yesterdayStart = moment().subtract(1, 'days').startOf('day').set({ hour: 9, minute: 0, second: 0 });
        const yesterdayEnd = moment().subtract(1, 'days').endOf('day').set({ hour: 23, minute: 59, second: 59 });
        setDateRange([yesterdayStart, yesterdayEnd]);
    };
    const onChooseLastWeek = () => {
        const lastWeekStart = moment().subtract(1, 'weeks').startOf('week').set({ hour: 9, minute: 0, second: 0 });
        const lastWeekEnd = lastWeekStart.clone().endOf('week').subtract(1, 'days').set({ hour: 23, minute: 59, second: 59 });
        setDateRange([lastWeekStart, lastWeekEnd]);
    };
    const handleDownloadFullReportData = () => __awaiter(void 0, void 0, void 0, function* () {
        var _a, _b;
        setLoadingFullReport(true);
        setDisabled(true);
        try {
            if (dateRange[0] && dateRange[1]) {
                const afterTimestamp = ((_a = dateRange[0]) === null || _a === void 0 ? void 0 : _a.unix()) * 1000;
                const beforeTimestamp = ((_b = dateRange[1]) === null || _b === void 0 ? void 0 : _b.unix()) * 1000;
                yield getDataAndGenerateCSVFile(true, afterTimestamp, beforeTimestamp);
            }
            setLoadingFullReport(false);
            setDisabled(false);
            setErrorDownloading(false);
        }
        catch (error) {
            console.error('Error downloading full report CSV file', error);
            setErrorDownloading(true);
            setLoadingFullReport(false);
            setDisabled(false);
        }
    });
    const handleDownloadRawData = () => __awaiter(void 0, void 0, void 0, function* () {
        var _c;
        setLoadingRawReport(true);
        setDisabled(true);
        const inboxLanguage = ((_c = localStorage.getItem('currentLanguage')) === null || _c === void 0 ? void 0 : _c.toString()) || 'en';
        try {
            if (dateRange[0] && dateRange[1]) {
                const afterTimestamp = dateRange[0].unix() * 1000;
                const beforeTimestamp = dateRange[1].unix() * 1000;
                yield getDataAndGenerateCSVFile(false, afterTimestamp, beforeTimestamp, inboxLanguage);
            }
            setErrorDownloading(false);
            setDisabled(false);
            setLoadingRawReport(false);
        }
        catch (error) {
            console.error('Error downloading raw report CSV file', error);
            setErrorDownloading(true);
            setDisabled(false);
            setLoadingRawReport(false);
        }
    });
    const getDataAndGenerateCSVFile = (isFullReport, afterTimestamp, beforeTimestamp, language) => __awaiter(void 0, void 0, void 0, function* () {
        let csvData;
        const reportTypeForAPIPath = isFullReport ? 'full-report' : 'full-report-raw';
        const reportTypeForFileName = isFullReport ? 'Full' : 'Chats';
        if (isFullReport) {
            const { data: response } = yield getAxios()
                .then((axios) => {
                return axios.get(`${API_PATH.STATS}/chats/status/${reportTypeForAPIPath}?after=${afterTimestamp}&before=${beforeTimestamp}`);
            });
            csvData = generateFullReportCSV(response, afterTimestamp, beforeTimestamp);
        }
        else {
            const { data: response } = yield getAxios()
                .then((axios) => {
                return axios.get(`${API_PATH.STATS}/chats/status/${reportTypeForAPIPath}?after=${afterTimestamp}&before=${beforeTimestamp}&language=${language}`);
            });
            csvData = response.csv;
        }
        const csvFile = new Blob([csvData], { type: 'text/csv;charset=utf-8;' });
        const fileName = generateFileName(afterTimestamp, beforeTimestamp, reportTypeForFileName);
        FileSaver.saveAs(csvFile, fileName);
    });
    const generateFullReportCSV = (data, afterTimestamp, beforeTimestamp) => {
        let csv = '';
        const TOTAL_DATA = data.totalChatsCounter;
        const BOT_DATA = data.chatsStartedFromBotCounter;
        const afterDate = new Date(afterTimestamp);
        afterDate.setHours(9, 0, 0, 0);
        const beforeDate = new Date(beforeTimestamp);
        beforeDate.setHours(23, 59, 59, 999);
        const afterDateStr = formatDateTime(afterDate);
        const beforeDateStr = formatDateTime(beforeDate);
        const dateRange = `${afterDateStr} - ${beforeDateStr}`;
        csv += `"${dateRange}"\n`;
        csv += `Category,Amount chats in Pending,Total Taken,Avg time to Resolve,Total times chats Resolved, Amount chats resolved, Amount bulk messsages delivered\n`;
        if (TOTAL_DATA.totalResolved !== 0) {
            csv += `Total numbers,${TOTAL_DATA.total},${TOTAL_DATA.totalTaken},${formatSeconds(TOTAL_DATA.avgTimeToResolve)},${TOTAL_DATA.totalResolved},${TOTAL_DATA.totalUniqueResolved},${TOTAL_DATA.amountBulksDelivered}\n`;
        }
        else {
            csv += `Total numbers,${TOTAL_DATA.total},${TOTAL_DATA.totalTaken},0,${TOTAL_DATA.totalResolved},0,${TOTAL_DATA.amountBulksDelivered}\n`;
        }
        if (BOT_DATA.resolved !== 0) {
            csv += `Chats started with bot start node,${BOT_DATA.count},${BOT_DATA.taken},${formatSeconds(BOT_DATA.avgTimeToResolve)},${BOT_DATA.resolved}\n`;
        }
        else {
            csv += `Chats started with bot start node,${BOT_DATA.count},${BOT_DATA.taken},0,${BOT_DATA.resolved}\n`;
        }
        csv += '\nBy Department';
        for (const [department, values] of Object.entries(data.totalCountsDepartments)) {
            csv += `\n${department},${values.count},${values.taken},,${values.resolved}`;
        }
        ;
        csv += '\n\nBy Agent';
        for (const [agent, values] of Object.entries(data.totalCountsAgents)) {
            if (values.resolved !== 0) {
                csv += `\n${agent},,${values.taken},${formatSeconds(values.avgTimeToResolve)},${values.resolved}`;
            }
            else {
                csv += `\n${agent},,${values.taken},0,${values.resolved}`;
            }
        }
        ;
        return csv;
    };
    const formatSeconds = (seconds) => {
        const hours = Math.floor(seconds / 3600);
        const minutes = Math.floor((seconds % 3600) / 60);
        if (hours === 0) {
            return `${minutes} m`;
        }
        else if (minutes === 0) {
            return `${hours} h`;
        }
        else {
            return `${hours} h ${minutes} m`;
        }
    };
    const formatDateTime = (date) => {
        const dateOptions = {
            day: 'numeric',
            month: 'short',
            year: 'numeric',
        };
        const timeOptions = {
            hour: '2-digit',
            minute: '2-digit',
        };
        const formattedDate = date.toLocaleDateString(undefined, dateOptions);
        const formattedTime = date.toLocaleTimeString(undefined, timeOptions);
        return `${formattedDate} ${formattedTime}`;
    };
    const generateFileName = (afterTimestamp, beforeTimestamp, reportType) => {
        const afterDate = new Date(afterTimestamp);
        const beforeDate = new Date(beforeTimestamp);
        const afterFormattedDate = afterDate.toLocaleDateString('he', { day: '2-digit', month: '2-digit', year: 'numeric' }); // 22.07.2024
        const beforeFormattedDate = beforeDate.toLocaleDateString('he', { day: '2-digit', month: '2-digit', year: 'numeric' });
        let fileName = '';
        if (afterFormattedDate === beforeFormattedDate) {
            fileName = `${reportType} report ${afterFormattedDate}.csv`;
        }
        else {
            fileName = `${reportType} report ${afterFormattedDate}-${beforeFormattedDate}.csv`;
        }
        return fileName;
    };
    return (React.createElement(Modal, { open: visible, onCancel: onCancel, className: s['download-data-modal__container'], width: 622, footer: null },
        React.createElement("div", { className: classNames(s['download-data-modal__header'], {
                [s['download-data-modal__header--rtl']]: i18n.dir() === 'rtl'
            }) },
            React.createElement("div", { className: s['download-data-modal__header--title'] },
                React.createElement(Trans, { i18nKey: "downloadDataToCSV" })),
            React.createElement("div", { className: classNames(s['download-data-modal__greenBorder'], {
                    [s['download-data-modal__greenBorder--rtl']]: i18n.dir() === 'rtl'
                }) })),
        React.createElement("div", { className: classNames(s['download-data-modal__body']) },
            React.createElement("div", { className: classNames(s['download-data-modal__pickDateWrapper']) },
                React.createElement("div", { className: classNames(s['download-data-modal__buttonsGroup'], {
                        [s['download-data-modal__buttonsGroup--rtl']]: i18n.dir() === 'rtl'
                    }) },
                    React.createElement(CommonButton, { action: onChooseYesterday, disabled: disabled },
                        React.createElement(Trans, { i18nKey: "yesterdayTime" })),
                    React.createElement(CommonButton, { action: onChooseLastWeek, disabled: disabled },
                        React.createElement(Trans, { i18nKey: "lastWeek" }))),
                React.createElement("div", { className: classNames(s['download-data-modal_datePickersContainer'], {
                        [s['download-data-modal_datepickersContainer--rtl']]: i18n.dir() === 'rtl'
                    }) },
                    React.createElement(DatePicker, { format: "DD MMMM YYYY", placeholder: !dateRange[0] ? t("chooseStartDate") : dateRange[0].format('DD MMMM YYYY'), style: {
                            height: "48px",
                            width: "168px"
                        }, onChange: (date) => {
                            const newDateRange = [...dateRange];
                            newDateRange[0] = moment(date).set({ hour: 9, minute: 0, second: 0 });
                            setDateRange(newDateRange);
                        }, disabled: disabled }),
                    React.createElement(DatePicker, { format: "DD MMMM YYYY", placeholder: !dateRange[1] ? t("chooseEndDate") : dateRange[1].format('DD MMMM YYYY'), style: {
                            height: "48px",
                            width: "168px"
                        }, disabledDate: (current) => {
                            // disable dates that are before the chosen start date or after one week from the chosen start date
                            if (dateRange[0]) {
                                const oneWeekAfterStartDate = dateRange[0].clone().add(1, 'week');
                                return current && (current < dateRange[0] || current > oneWeekAfterStartDate);
                            }
                            return true; // disable all dates if start date was not chosen
                        }, onChange: (date) => {
                            // Update the end date in dateRange
                            const newDateRange = [...dateRange];
                            newDateRange[1] = moment(date).set({ hour: 23, minute: 59, second: 59 });
                            setDateRange(newDateRange);
                        }, disabled: disabled }))),
            React.createElement("div", { className: classNames(s['download-data-modal__downloadButtonsContainer']) },
                React.createElement(Button, { block: true, icon: React.createElement(DownloadOutlined, null), size: 'large', onClick: handleDownloadFullReportData, className: classNames(s['download-data-modal__downloadButton']), loading: loadingFullReport },
                    React.createElement(Trans, { i18nKey: "downloadData" })),
                React.createElement(Button, { block: true, icon: React.createElement(DownloadOutlined, null), size: 'large', onClick: handleDownloadRawData, type: 'dashed', className: classNames(s['download-data-modal__downloadButton']), loading: loadingRawReport },
                    React.createElement(Trans, { i18nKey: "downloadRawData" })),
                isErrorDownloading ? (React.createElement("div", { className: classNames(s['download-data-modal__error']) },
                    React.createElement(Trans, { i18nKey: "unexpectedErrorTryAgain" }))) : null))));
};
export default observer(DownloadDataModal);
