import React from 'react';
import { Divider, Typography } from 'antd';
import UserItemLabel from '../../Base/Items/UserItem/UserItemLabel';
import styles from './styles.module.scss';
const { Text } = Typography;
const QuickReply = ({ reply, departmentsList, onClicked }) => {
    return (React.createElement("div", { className: styles.item, onClick: () => (onClicked ? onClicked() : null) },
        React.createElement("div", { className: styles.itemText },
            React.createElement(Text, null, reply.text)),
        React.createElement("div", { className: styles.replyDepartmentsWrapper }, reply.departments && departmentsList &&
            departmentsList
                .filter((department) => { var _a; return (_a = reply.departments) === null || _a === void 0 ? void 0 : _a.includes(department._id); })
                .map((department, i) => (React.createElement(React.Fragment, { key: i },
                React.createElement(UserItemLabel, { department: department }),
                React.createElement(Divider, { type: "vertical" })))))));
};
export default QuickReply;
