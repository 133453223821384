var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { API_PATH } from '../../constants';
import { action, observable, runInAction, makeObservable } from 'mobx';
import { getAxios } from '../../backend';
import { EventName } from '@whatsper/texterchat-common';
import { message } from 'antd';
class ExportsStore {
    constructor(rootStore) {
        Object.defineProperty(this, "rootStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "exports", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: observable([])
        });
        Object.defineProperty(this, "loading", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "validationProblems", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "getChatExports", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                runInAction(() => {
                    this.loading = true;
                });
                yield getAxios()
                    .then((axios) => {
                    return axios.get(`${API_PATH.EXPORT}`);
                })
                    .then(({ data }) => {
                    runInAction(() => {
                        this.exports.replace(data.exports);
                    });
                })
                    .catch((error) => {
                    console.error('Error on fetching chat exports', error);
                })
                    .finally(() => {
                    runInAction(() => {
                        this.loading = false;
                    });
                });
            })
        });
        Object.defineProperty(this, "validateExport", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (request) => __awaiter(this, void 0, void 0, function* () {
                return yield getAxios()
                    .then((axios) => {
                    return axios.post(`${API_PATH.EXPORT}/validate`, request);
                })
                    .then(({ data }) => {
                    if ('problems' in data) {
                        this.setValidationError(data.problems);
                        return false;
                    }
                    this.setValidationError([]);
                    return true;
                })
                    .catch((error) => {
                    console.error('Error on validating chat export', error);
                    message.error(error.message);
                    return false;
                });
            })
        });
        Object.defineProperty(this, "createChatExport", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (request) => __awaiter(this, void 0, void 0, function* () {
                runInAction(() => {
                    this.loading = true;
                });
                return getAxios()
                    .then((axios) => {
                    return axios.post(`${API_PATH.EXPORT}`, request);
                })
                    .then(({ data }) => {
                    if ('problems' in data) {
                        this.setValidationError(data.problems);
                        return false;
                    }
                    if ('exportEntityId' in data) {
                        return true;
                    }
                })
                    .catch((error) => {
                    console.error('Error on creating chat export', error);
                    message.error(error.message);
                })
                    .finally(() => {
                    runInAction(() => {
                        this.loading = false;
                    });
                });
            })
        });
        Object.defineProperty(this, "getChatExport", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (exportId) => __awaiter(this, void 0, void 0, function* () {
                runInAction(() => {
                    this.loading = true;
                });
                return getAxios()
                    .then((axios) => {
                    return axios.get(`${API_PATH.EXPORT}/${exportId}`);
                })
                    .then(({ data }) => {
                    return data;
                })
                    .catch((error) => {
                    console.error('Error on fetching chat export', error);
                })
                    .finally(() => {
                    runInAction(() => {
                        this.loading = false;
                    });
                });
            })
        });
        Object.defineProperty(this, "deleteChatExport", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (exportId) => __awaiter(this, void 0, void 0, function* () {
                runInAction(() => {
                    this.loading = true;
                });
                return getAxios()
                    .then((axios) => {
                    return axios.delete(`${API_PATH.EXPORT}/${exportId}`);
                })
                    .then(({ data }) => {
                    return data.success;
                })
                    .catch((error) => {
                    console.error('Error on deleting chat export', error);
                    message.error(error.message);
                })
                    .finally(() => {
                    runInAction(() => {
                        this.loading = false;
                    });
                });
            })
        });
        Object.defineProperty(this, "setValidationError", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (problems) => {
                this.validationProblems = problems;
            }
        });
        makeObservable(this);
        this.rootStore = rootStore;
        this.rootStore.events
            .addListener(EventName.export_created, (data) => this.onExportCreatedEvent(data))
            .addListener(EventName.export_deleted, (data) => this.onExportDeletedEvent(data))
            .addListener(EventName.export_updated, (data) => this.onExportUpdatedEvent(data));
    }
    onExportCreatedEvent(data) {
        this.exports.unshift(data);
    }
    onExportDeletedEvent(data) {
        const item = this.exports.find((e) => e._id === data._id);
        if (item) {
            this.exports.remove(item);
        }
    }
    onExportUpdatedEvent(data) {
        this.exports.replace(this.exports.map((e) => {
            return e._id === data._id ? data : e;
        }));
    }
}
__decorate([
    observable
], ExportsStore.prototype, "exports", void 0);
__decorate([
    observable
], ExportsStore.prototype, "loading", void 0);
__decorate([
    observable
], ExportsStore.prototype, "validationProblems", void 0);
__decorate([
    action
], ExportsStore.prototype, "getChatExports", void 0);
__decorate([
    action
], ExportsStore.prototype, "validateExport", void 0);
__decorate([
    action
], ExportsStore.prototype, "createChatExport", void 0);
__decorate([
    action
], ExportsStore.prototype, "getChatExport", void 0);
__decorate([
    action
], ExportsStore.prototype, "deleteChatExport", void 0);
__decorate([
    action
], ExportsStore.prototype, "setValidationError", void 0);
__decorate([
    action
], ExportsStore.prototype, "onExportCreatedEvent", null);
__decorate([
    action
], ExportsStore.prototype, "onExportDeletedEvent", null);
__decorate([
    action
], ExportsStore.prototype, "onExportUpdatedEvent", null);
export default ExportsStore;
