import { ExclamationCircleOutlined } from "@ant-design/icons";
import Drawer from "@material-ui/core/Drawer";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ChatOutlinedIcon from "@material-ui/icons/ChatOutlined";
import KeyboardBackspaceOutlinedIcon from "@material-ui/icons/KeyboardBackspaceOutlined";
import { MessagingChannelFeature as Feature } from "@whatsper/texterchat-common";
import Button from "antd/es/button";
import classnames from "classnames";
import { formatNumber, isValidPhoneNumber, parseNumber } from "libphonenumber-js";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Trans } from "react-i18next";
import { useHistory } from "react-router-dom";
import { MyInput } from "../Base/Form";
import { useStore } from "../../store/rootStore";
import ChannelSelect from "../Chat/ChatsList/ChannelSelect";
import { getDirection } from "../../helpers";
import "./MessageDrawer.scss";
const MessageDrawer = ({ setChat, toggleMessageDrawer, isOpen }) => {
    const store = useStore();
    const history = useHistory();
    const [accounts, setAccounts] = useState([]);
    const [account, setAccount] = useState(null);
    const [chatId, setChatId] = useState('');
    const [phoneIdSupported, setPhoneIdSupported] = useState(false);
    const [validPhone, setValidPhone] = useState(false);
    const onChannelIdSelected = (channel) => {
        setAccount(accounts.find(({ accountId, channel: c }) => {
            return c === (channel === null || channel === void 0 ? void 0 : channel.name) && accountId === (channel === null || channel === void 0 ? void 0 : channel.id);
        }) || null);
    };
    // Populate accounts list with accounts that support contactGlobalId feature
    // additionally mapping with adapter features,
    // when accounts or adapters change
    useEffect(() => {
        setAccounts(store.channels.accounts.map((account) => {
            const adapter = store.channels.adaptersByName[account.adapterName];
            return Object.assign(Object.assign({}, account), { features: adapter ? adapter.features : [] });
        })
            .filter(({ features }) => {
            return features.includes(Feature.contactGlobalId);
        }));
    }, [store.channels.accounts.length, store.channels.adaptersByName]);
    // Set current account to value selected in search filter,
    // or otherwise set to default one or first account in list
    useEffect(() => {
        let a = null;
        if (store.chatsStore.searchFilter.channel) {
            const { id, name } = store.chatsStore.searchFilter.channel;
            a = accounts.find(({ accountId, channel }) => {
                return id === accountId && channel === name;
            }) || null;
        }
        if (!a && accounts.length) {
            a = accounts.find(({ isDefault }) => isDefault) || accounts[0];
        }
        setAccount(a);
    }, [store.chatsStore.searchFilter.channel, accounts]);
    useEffect(() => {
        if (account) {
            setPhoneIdSupported(account.features.includes(Feature.contactPhoneNumber));
        }
        else {
            setPhoneIdSupported(false);
        }
    }, [account]);
    useEffect(() => {
        if (phoneIdSupported) {
            const correct = !chatId.trim().length || isValidPhoneNumber(chatId, store.defaultCountry);
            setValidPhone(correct);
        }
        else {
            setValidPhone(true);
        }
    }, [chatId, phoneIdSupported]);
    const handleChange = (value) => {
        const serilizedNumber = value.replace(/[^\x20-\x7E]/g, "");
        setChatId(serilizedNumber);
    };
    const handleEnter = (e) => {
        if (e.key === 'Enter') {
            onSend();
        }
    };
    const onSend = () => {
        if (chatId && validPhone) {
            let id = chatId;
            if (phoneIdSupported) {
                const phonenumber = parseNumber(chatId, store.defaultCountry);
                id = formatNumber(phonenumber, "E.164").replace(/[^\d]/g, '');
            }
            if (account) {
                setChat(id, { id: account.accountId, name: account.channel });
                history.push(`/contact/${account.channel}/${account.accountId}/${id}`);
                toggleMessageDrawer(false);
            }
        }
    };
    return (React.createElement(Drawer, { className: classnames("message-drawer", {
            "rtl": getDirection() === "rtl",
        }), open: isOpen, onClose: () => toggleMessageDrawer(false), dir: getDirection(), anchor: getDirection() === "rtl" ? "right" : "left" },
        React.createElement("div", { className: classnames("message-drawer__back", {
                "rtl": getDirection() === "rtl",
            }), onClick: () => toggleMessageDrawer(false) },
            React.createElement(ListItemIcon, null,
                React.createElement(KeyboardBackspaceOutlinedIcon, { fontSize: "small" })),
            React.createElement("span", null,
                React.createElement(Trans, { i18nKey: "sendNewMessage" }))),
        React.createElement("div", { className: "message-drawer__container" },
            React.createElement("div", null,
                React.createElement("div", { className: "message-drawer__header" },
                    React.createElement(ChatOutlinedIcon, { className: "message-drawer__icon", fontSize: "large" })),
                React.createElement("div", { className: "message-drawer__content" },
                    store.channels.accounts.length > 1 &&
                        React.createElement("div", { className: "message-drawer__box" },
                            React.createElement("div", { className: "message-drawer__input-title" },
                                React.createElement(Trans, { i18nKey: "sendFrom" })),
                            React.createElement(ChannelSelect, { activeChannel: account ? { name: account.channel, id: account.accountId } : null, onChannelClick: onChannelIdSelected, className: "message-drawer__channelSelect", accounts: accounts, initSearch: false, showAll: false })),
                    React.createElement("div", { className: "message-drawer__box" },
                        React.createElement("div", { className: "message-drawer__input-title" },
                            React.createElement("div", null,
                                React.createElement(Trans, { i18nKey: "sendNewMessageTo" })),
                            !validPhone ? (React.createElement("div", { className: "message-drawer__error" },
                                React.createElement(Trans, { i18nKey: "incorrectNumber" }))) : null),
                        React.createElement(MyInput, { onChangeHandler: handleChange, className: "message-drawer__input", onKeyDown: handleEnter, size: "middle", autoFocus: true })),
                    React.createElement(Button, { type: "primary", className: "message-drawer__button big-button resolve-button", onClick: onSend, disabled: !validPhone || chatId.trim().length === 0 },
                        React.createElement(Trans, { i18nKey: "send" })),
                    store.channels.accounts.length !== accounts.length ? (React.createElement("div", { className: classnames("message-drawer__moreInfo", { rtl: getDirection() === "rtl" }) },
                        React.createElement(ExclamationCircleOutlined, { className: "info-circle" }),
                        React.createElement("p", { className: "info-text" },
                            React.createElement(Trans, { i18nKey: "nonGlobalIdChannelsHidden" })))) : (React.createElement("div", { className: "message-placeholder" })))))));
};
export default observer(MessageDrawer);
