import { readableFileSize } from '../../../helpers';
export const checkFileMaxSize = (file, mediaLimits) => {
    var _a;
    if (file.type.startsWith('image') && (mediaLimits === null || mediaLimits === void 0 ? void 0 : mediaLimits.image)) {
        if (file.size > mediaLimits.image.maxSize) {
            return { key: 'tooLargeUploadMessage', size: readableFileSize(mediaLimits.image.maxSize) };
        }
    }
    if (file.type.startsWith('video') && (mediaLimits === null || mediaLimits === void 0 ? void 0 : mediaLimits.video)) {
        if (file.size > mediaLimits.video.maxSize) {
            return { key: 'tooLargeUploadMessage', size: readableFileSize(mediaLimits.video.maxSize) };
        }
    }
    if (file.type.startsWith('audio') && (mediaLimits === null || mediaLimits === void 0 ? void 0 : mediaLimits.audio)) {
        if (file.size > mediaLimits.audio.maxSize) {
            return { key: 'tooLargeUploadMessage', size: readableFileSize(mediaLimits.audio.maxSize) };
        }
    }
    if (mediaLimits === null || mediaLimits === void 0 ? void 0 : mediaLimits.document) {
        if (file.size > ((_a = mediaLimits.document) === null || _a === void 0 ? void 0 : _a.maxSize)) {
            return { key: 'tooLargeUploadMessage', size: readableFileSize(mediaLimits.document.maxSize) };
        }
    }
    return false;
};
