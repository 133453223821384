import * as React from 'react';
import { createPortal } from 'react-dom';
import favicon from './assets/favicon/favicon.ico';
import faviconUnread from './assets/favicon/favicon-unread.ico';
import faviconPause from './assets/favicon/favicon-pause.png';
import { useStore } from './store/rootStore';
import { observer } from 'mobx-react';
class HeadPortal extends React.Component {
    componentDidMount() {
        const head = document.getElementsByTagName('head')[0];
        head.querySelectorAll('.static-only').forEach((el) => {
            head.removeChild(el);
        });
    }
    render() {
        return createPortal(this.props.children, document.getElementsByTagName('head')[0]);
    }
}
const Favicon = ({ unread, inactive }) => {
    return (React.createElement("link", { rel: "icon", className: "dynamic", href: inactive ? faviconPause : unread ? faviconUnread : favicon }));
};
const Title = (props) => {
    return (React.createElement("title", { className: "dynamic" },
        props.unreadCount ? '(' + props.unreadCount + ') ' : '',
        props.title));
};
const Head = () => {
    const { chatsStore, tab } = useStore();
    return (React.createElement(HeadPortal, null,
        React.createElement(Title, { title: "TexterChat", unreadCount: chatsStore.count.waiting }),
        React.createElement(Favicon, { unread: chatsStore.count.waiting > 0, inactive: tab.inactive })));
};
export default observer(Head);
