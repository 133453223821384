var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { observer } from 'mobx-react';
import moment from 'moment';
import React, { Component } from 'react';
import { Trans, useTranslation, withTranslation } from 'react-i18next';
import { API_PATH, SERVER_URL } from '../../constants';
import { getAxios } from '../../backend';
import { useStore, withStore } from '../../store/rootStore';
import { EntityManagerRoute, Operation } from '../EntityManager';
import NewAuthorization from './NewAuthorization';
import { SERVICE_ADDITIONAL_QUERY, SERVICES_REDIRECT_ONLY } from './servicesCustom';
import EntityListItem from '../EntityManager/EntityListItem';
import s from './OAuthManager.module.scss';
const datetimeFormat = 'Do MMM YY, ddd, h:mm a';
const CredentialsExcerpt = ({ entity }) => {
    const { agentsStore } = useStore();
    const { t } = useTranslation();
    let agent;
    if (entity.user) {
        const userInfo = agentsStore.getAgentData(entity.user);
        agent = userInfo ? userInfo.displayName || userInfo.email || userInfo._id : entity.user;
    }
    return (React.createElement("div", null,
        React.createElement("div", null,
            React.createElement(Trans, { i18nKey: "oauthService", values: { service: entity.service } })),
        React.createElement("div", null,
            React.createElement(Trans, { i18nKey: "createdOn", values: { datetime: moment.unix(entity.created).format(datetimeFormat) } })),
        React.createElement("div", null, entity.updated && (React.createElement(Trans, { i18nKey: "updatedOn", values: { datetime: moment.unix(entity.updated).format(datetimeFormat) } }))),
        React.createElement("div", null,
            React.createElement(Trans, { i18nKey: "oauthUsage", values: {
                    usage: !!entity.user
                        ? t('oauthTokenUsingForUser', { values: { agent } })
                        : t('oauthTokenUsageApp'),
                } }))));
};
class OAuthManager extends Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                loading: false,
                error: null,
                credentials: [],
            }
        });
        Object.defineProperty(this, "renderOperationTitle", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (op, cred) => {
                switch (op) {
                    case Operation.LIST:
                        return (React.createElement(Trans, { i18nKey: "oauthManager" }));
                    case Operation.DELETE:
                        return (React.createElement(Trans, { i18nKey: "deleteOAuthCred", values: { service: cred === null || cred === void 0 ? void 0 : cred.service } }));
                    case Operation.CREATE:
                        return (React.createElement(Trans, { i18nKey: "OAuthAuthorize" }));
                    default:
                        return React.createElement("div", null);
                }
            }
        });
        Object.defineProperty(this, "renderDeleteQuestion", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (cred) => {
                return (React.createElement(Trans, { i18nKey: "sureDeleteOAuthCred", values: { service: cred.service } }));
            }
        });
        Object.defineProperty(this, "credentialsLoad", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                this.setState({ loading: true });
                getAxios()
                    .then((axios) => {
                    return axios.get(`${API_PATH.AUTH}/oauth/credentials`);
                })
                    .then(({ data }) => {
                    this.setState({ credentials: data });
                })
                    .catch((error) => {
                    console.error(error);
                })
                    .finally(() => {
                    this.setState({ loading: false });
                });
            })
        });
        Object.defineProperty(this, "credentialsDelete", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (cred) => __awaiter(this, void 0, void 0, function* () {
                this.setState({ loading: true });
                getAxios()
                    .then((axios) => {
                    return axios.delete(`${API_PATH.AUTH}/oauth/credentials/${cred._id}`);
                })
                    .catch((error) => {
                    console.error(error);
                })
                    .finally(() => {
                    this.credentialsLoad();
                });
            })
        });
        Object.defineProperty(this, "startAuthorize", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ({ service, scope, usage }) => {
                return new Promise((resolve, reject) => {
                    const query = new URLSearchParams();
                    scope.forEach((name, i) => {
                        query.set(`scope[${i}]`, name);
                    });
                    if (typeof SERVICE_ADDITIONAL_QUERY[service.name] === 'object') {
                        for (const key in SERVICE_ADDITIONAL_QUERY[service.name]) {
                            query.set(key, SERVICE_ADDITIONAL_QUERY[service.name][key]);
                        }
                    }
                    const url = `${SERVER_URL}${API_PATH.AUTH}/oauth/v2/authorize/${service.name}/${usage}?${query.toString()}`;
                    if (SERVICES_REDIRECT_ONLY.includes(service.name)) {
                        window.location.assign(url);
                        return resolve();
                    }
                    const width = 500;
                    const height = 700;
                    const left = Math.round((window.innerWidth - width) / 2);
                    const top = Math.round((window.innerHeight - height) / 2);
                    console.log(`width=${width},height=${height},left=${left > 0 ? left : 0},top=${top > 0 ? top : 0},popup,status,scrollbars`);
                    const authWindow = window.open(url, `_blank`, `width=${width},height=${height},left=${left > 0 ? left : 0},top=${top > 0 ? top : 0},popup,status,scrollbars`);
                    if (authWindow) {
                        // Unfortunatelly this not works for some reason
                        authWindow.onclose = () => {
                            resolve();
                        };
                    }
                });
            }
        });
    }
    render() {
        return (React.createElement(EntityManagerRoute, { path: "/manage/oauth", entities: this.state.credentials, entityIdKey: "_id", teaser: CredentialsExcerpt, form: NewAuthorization, renderOperationTitle: this.renderOperationTitle, renderDeleteQuestion: this.renderDeleteQuestion, onMounted: this.credentialsLoad, onEntityDelete: (cred) => this.credentialsDelete(cred), onEntitySave: (data) => this.startAuthorize(data), onEntityCancel: () => {
                this.credentialsLoad();
            }, onClose: () => { }, loading: this.state.loading, disabled: () => { return false; }, opSupported: [Operation.LIST, Operation.DELETE, Operation.CREATE], renderListItem: (entity, props) => {
                return (React.createElement(EntityListItem, Object.assign({ key: entity[props.entityIdKey], entity: entity, className: s.oauthListItem }, props),
                    React.createElement(CredentialsExcerpt, { entity: entity })));
            } }));
    }
}
export default withTranslation()(withStore(observer(OAuthManager)));
