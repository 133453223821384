import React, { memo } from 'react';
import { MEDIA_TYPE } from '@whatsper/texterchat-common';
import classNames from 'classnames';
import { Document, Page, pdfjs } from 'react-pdf';
import Icon, { FileFilled } from '@ant-design/icons';
import { Trans } from 'react-i18next';
import LoadingIcon from '../common/LoadingIcon';
pdfjs.GlobalWorkerOptions.workerSrc = new URL('pdfjs-dist/build/pdf.worker.min.mjs', import.meta.url).toString();
import s from './FilePreview.module.scss';
const Preview = ({ file, type, pageHeight, onPDFLoadSuccess, compact }) => {
    var _a;
    if (!file)
        return null;
    const size = file.size / 1000 > 1000 ? `${(file.size / 1000000).toFixed(2)} MB` : `${(file.size / 1000).toFixed(2)} KB`;
    const ext = (_a = file.name) === null || _a === void 0 ? void 0 : _a.split('.').pop();
    const onPageLoadSuccess = () => {
        const textLayers = document.querySelectorAll('.react-pdf__Page__textContent');
        const annotationsLayers = document.querySelectorAll('.react-pdf__Page__annotations');
        [...textLayers, ...annotationsLayers].forEach((layer) => {
            const { style } = layer;
            style.display = 'none';
        });
    };
    switch (type) {
        case MEDIA_TYPE.IMAGE:
            return React.createElement("img", { src: URL.createObjectURL(file), alt: file.name, className: s.innerContent });
        case MEDIA_TYPE.VIDEO:
            return (React.createElement("video", { className: classNames(s.innerContent, {
                    [s.compactVideo]: compact,
                }), controls: !compact },
                React.createElement("source", { src: URL.createObjectURL(file) })));
        case MEDIA_TYPE.AUDIO:
            if (compact) {
                return (React.createElement("div", { className: classNames(s.compactContainer, s.innerContent) },
                    React.createElement(Icon, { className: s.compactIcon, component: FileFilled }),
                    React.createElement("span", null, ext)));
            }
            return (React.createElement("audio", { src: URL.createObjectURL(file), className: classNames(s.innerContent, s.audioPlayer), controls: true }));
        case MEDIA_TYPE.DOC:
            if (compact) {
                return (React.createElement("div", { className: classNames(s.compactContainer, s.innerContent) },
                    React.createElement(Icon, { className: s.compactIcon, component: FileFilled }),
                    React.createElement("span", null, ext)));
            }
            if (ext === 'pdf') {
                return (React.createElement("div", { className: s.innerContent },
                    React.createElement(Document, { file: file, className: s.pdfDocument, onLoadSuccess: onPDFLoadSuccess, loading: React.createElement("div", { className: s.loadingIcon },
                            React.createElement(LoadingIcon, null)) },
                        React.createElement(Page, { pageNumber: 1, height: pageHeight, className: s.pdfPage, onLoadSuccess: onPageLoadSuccess }))));
            }
            else {
                return (React.createElement("div", { className: s.noPreviewContainer },
                    React.createElement(Icon, { className: s.innerIcon, component: FileFilled }),
                    React.createElement("div", { className: s.innerTitle },
                        React.createElement(Trans, { i18nKey: "previewNotAvailable" })),
                    React.createElement("div", { className: s.innerSubtitle },
                        size,
                        " - ",
                        ext)));
            }
        default:
            return (React.createElement("div", { className: s.noPreviewContainer },
                React.createElement(Icon, { className: s.innerIcon, component: FileFilled }),
                React.createElement("div", { className: s.innerTitle },
                    React.createElement(Trans, { i18nKey: "previewNotAvailable" })),
                React.createElement("div", { className: s.innerSubtitle },
                    size,
                    " - ",
                    ext)));
    }
};
export default memo(Preview, (prevProps, nextProps) => {
    // props change that should trigger re-render
    if (prevProps.file.uid !== nextProps.file.uid)
        return false;
    if (prevProps.pageHeight !== nextProps.pageHeight)
        return false;
    return true;
});
