import React from 'react';
import { observer } from 'mobx-react';
import ChatNote from "../ChatNote";
import ListPreloader from '../../../../Base/Utils/ListPreloader';
import styles from "./ChatNotesList.module.scss";
const ChatNotesList = (props) => {
    const { notesList, isLoading, handleDeleteNote, getAgent, hasPermission, } = props;
    const renderNotes = () => {
        if (isLoading) {
            return React.createElement(ListPreloader, null);
        }
        if (!notesList.length) {
            return null;
        }
        return notesList.map((note) => {
            var _a;
            return (React.createElement(ChatNote, { key: String(note._id), avatar: null, text: note.text, agentEmail: ((_a = getAgent(note)) === null || _a === void 0 ? void 0 : _a.email) || '', createdAt: note.createdAt, handleDeleteNote: () => handleDeleteNote(note), hasPermission: hasPermission(note) }));
        });
    };
    return (React.createElement("div", { className: styles.notesListBlock }, renderNotes()));
};
export default observer(ChatNotesList);
