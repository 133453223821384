import React from 'react';
import classNames from 'classnames';
import { Trans, withTranslation } from 'react-i18next';
import { CHAT_STATUS } from '../../../../constants';
import { getDirection } from '../../../../helpers';
import styles from './ChatStatusTitle.module.scss';
const ChatStatusTitle = ({ agentName, agentColor, tabs, status, active, listItem, className, }) => {
    const shouldTruncate = agentName && agentName.length > 10;
    const agentNameTruncated = shouldTruncate ?
        `${agentName.substring(0, 10)}...` : agentName;
    const getStatus = () => {
        if (status === CHAT_STATUS.BOT) {
            return React.createElement(Trans, { i18nKey: 'withBot' });
        }
        else if (status === CHAT_STATUS.PENDING) {
            if (listItem && agentNameTruncated) {
                return React.createElement(Trans, { values: { agent: `-${agentNameTruncated}` }, i18nKey: 'pendingForAgent' });
            }
            else {
                return React.createElement(Trans, { i18nKey: 'pending' });
            }
        }
        else if (status === CHAT_STATUS.ASSIGNED) {
            if (listItem) {
                return React.createElement(Trans, { values: { agent: agentNameTruncated }, i18nKey: 'takenBy' });
            }
            else {
                return React.createElement(Trans, { i18nKey: 'taken' });
            }
        }
        else if (status === CHAT_STATUS.RESOLVED) {
            return agentNameTruncated ? (React.createElement(Trans, { i18nKey: 'handledBy', values: { agent: agentNameTruncated } })) : (React.createElement(Trans, { i18nKey: 'solved' }));
        }
        else if (status === CHAT_STATUS.BULK) {
            return React.createElement(Trans, { i18nKey: 'bulk' });
        }
        else {
            return React.createElement(Trans, { i18nKey: 'allChats' });
        }
    };
    return (React.createElement("p", { className: classNames({
            [styles.statusText]: true,
            [styles.tabTitle]: tabs,
            [styles.activeTab]: active,
            [styles.blue]: status === CHAT_STATUS.BOT,
            [styles.yellow]: status === CHAT_STATUS.PENDING,
            [styles.green]: status === CHAT_STATUS.ASSIGNED,
            [styles.grey]: status === CHAT_STATUS.RESOLVED,
            [styles.listItemText]: listItem,
            [styles.longText]: shouldTruncate,
            [styles.reversed]: getDirection() === 'rtl',
            className,
        }), style: agentColor ? { color: agentColor } : {}, title: agentName || undefined }, getStatus()));
};
export default withTranslation()(ChatStatusTitle);
