import { TEMPLATE_HEADER_TYPE } from '@whatsper/texterchat-common';
export const getHeaderTypeSpecificMessages = (templateHeaderType, t) => {
    const messages = {
        [TEMPLATE_HEADER_TYPE.IMAGE]: {
            recommndedSizeMessage: t('recommendedImageSize'),
            requiredMessage: t('imageRequired'),
            accepts: ['image/*'],
            buttonText: t('uploadImage'),
            wizardHeaderText: 'uploadImageStep',
        },
        [TEMPLATE_HEADER_TYPE.VIDEO]: {
            recommndedSizeMessage: t('recommendedVideoSize'),
            requiredMessage: t('videoRequired'),
            accepts: ['video/*'],
            buttonText: t('uploadVideo'),
            wizardHeaderText: 'uploadVideoStep',
        },
        [TEMPLATE_HEADER_TYPE.DOCUMENT]: {
            recommndedSizeMessage: t('recommendedFileSize'),
            requiredMessage: t('documentRequired'),
            accepts: ['.pdf', '.doc', '.docx', '.csv'],
            buttonText: t('uploadFile'),
            wizardHeaderText: 'uploadDocumentStep',
        },
    };
    if (!templateHeaderType || !messages[templateHeaderType])
        return {};
    return messages[templateHeaderType];
};
