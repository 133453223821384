export var PortalName;
(function (PortalName) {
    PortalName["rightBarContents"] = "rightBarContents";
    PortalName["mobileDrawer"] = "mobileDrawer";
})(PortalName || (PortalName = {}));
export function getPortalDOMId(name) {
    return `portal-${name}`;
}
export function getPortalKey(name) {
    // Yes right now it is same as getPortalDOMId, but it is used in different context and may be changed in future
    return `portal-${name}`;
}
