import React from 'react';
import { observer } from 'mobx-react';
import { Trans } from 'react-i18next';
import { useStore } from '../../../../store/rootStore';
import ChannelSelect from '../../../Chat/ChatsList/ChannelSelect';
import classNames from 'classnames';
import { getDirection } from '../../../../helpers';
import s from './AccountPage.module.scss';
const AccountPage = ({ selectedAccountId, setSelectedAccountId, }) => {
    const { channels, wizardStore } = useStore();
    const onChannelSelected = (channelId) => {
        setSelectedAccountId(channelId);
        wizardStore.setWizardStep(1);
    };
    return (React.createElement("div", { className: s.container },
        React.createElement("div", { className: s.title },
            React.createElement(Trans, { i18nKey: "selectChannel" })),
        React.createElement(ChannelSelect, { accounts: channels.templateAccounts, activeChannel: selectedAccountId, onChannelClick: onChannelSelected, className: classNames(s.channelSelect, {
                [s.rtl]: getDirection() === 'rtl',
            }), showAll: false, showBorders: true, renderAsList: true })));
};
export default observer(AccountPage);
