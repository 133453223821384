import React, { Component } from 'react';
import { observer } from 'mobx-react';
import LeftSideBar from './LeftSideBar/LeftSideBar';
import CenterContent from './CenterContent/CenterContent';
import RightBar from './RightBar/RightBar';
import LeftDrawer from '../LeftDrawer/LeftDrawer';
import WizardModal from '../WizardModal';
import NoInternetMessage from '../NoInternetMessage';
import { APP_STATE } from '../../constants';
import IsReadyErrorMessage from '../IsReadyErrorMessage';
import TopBar from './TopBar/TopBar';
import TopMobileDrawer from '../TopMobileDrawer/TopMobileDrawer';
import MessageDrawer from '../MessageDrawer/MessageDrawer';
import BulkReportsDrawer from '../BulkReportsDrawer/BulkReportsDrawer';
import DashboardDrawer from '../DashboardDrawer/DashboardDrawer';
import AgentsManager from '../AgentsManager';
import DepartmentsManager from '../DepartmentsManager';
import ChannelsManager from '../ChannelsManager';
import ApiTokensManager from '../ApiTokensManager';
import OAuthManager from '../OAuthManager';
import NotificationSubscribeExample from '../NotificationSubscribeExample';
import TemplateMessageDrawer from './TemplateMessageDrawer';
import TemplateGenerator from '../Chat/QuickReplies/TemplateGenerator';
import AutoDisconnectModalContent from '../ModalContents/AutoDisconnectModalContent';
import SECTION from '../../store/NavigationStore/Section';
import { onlineStatus } from '../../backend';
import SupportModal from './modals/SupportModal';
import EmptyModal from '../Chat/common/EmplyModal';
import { withStore } from '../../store/rootStore';
import EditProfileDrawer from '../EditProfileDrawer/EditProfileDrawer';
import { withTranslation } from 'react-i18next';
import classNames from 'classnames';
import { getDirection } from '../../helpers';
import ChatSettingsRoute from '../../routes/settings/ChatSettingsRoute';
import './MainPageLayout.scss';
class MainPageLayout extends Component {
    constructor(props) {
        super(props);
        Object.defineProperty(this, "componentDidMount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.init();
            }
        });
        Object.defineProperty(this, "componentWillUnmount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if (this.state.midnightInterval) {
                    clearInterval(this.state.midnightInterval);
                }
                window.removeEventListener('popstate', this.handleBackButton);
                window.removeEventListener('resize', this.setAppHeight);
            }
        });
        /**
         * @todo
         *  Find better ways to do same this method does.
         *  Current approach is extremely problematic - this method was rewritten few times already,
         *  due bugs appear after new functionality.
         *
         */
        Object.defineProperty(this, "handleBackButton", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (e) => {
                const { history, location, store } = this.props;
                history.push(location.pathname);
                let closedSomething = false;
                if (store.navigation.sectionOpen[SECTION.settings]) {
                    store.navigation.toggleSection(SECTION.settings, false);
                    closedSomething = true;
                }
                if (typeof store.activeChatStore.chat === 'object') {
                    store.activeChatStore.setActiveChatId();
                    closedSomething = true;
                }
                if (!closedSomething) {
                    if (window.ReactNativeWebView) {
                        window.ReactNativeWebView.postMessage(JSON.stringify({
                            event: 'hitHistoryRoot',
                        }));
                    }
                }
                return false;
            }
        });
        Object.defineProperty(this, "setInitSectionsOpen", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { match, store } = this.props;
                const routeSection = match.params.section || null;
                const sectionOpen = {};
                for (const sName in SECTION) {
                    sectionOpen[sName] = routeSection === SECTION[sName];
                }
                store.navigation.initSectionOpen(sectionOpen);
            }
        });
        Object.defineProperty(this, "setAppHeight", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const vh = window.innerHeight * 0.01;
                document.documentElement.style.setProperty('--vh', `${vh}px`);
            }
        });
        Object.defineProperty(this, "midnightReloadHandle", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if (new Date().getHours() === 4) {
                    window.location.reload();
                }
            }
        });
        Object.defineProperty(this, "createChat", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (chatId, account) => {
                this.props.store.activeChatStore.setActiveChatByChannelId(chatId, account.name, account.id);
            }
        });
        this.state = {
            midnightInterval: null,
        };
    }
    init() {
        window.addEventListener('popstate', this.handleBackButton);
        window.addEventListener('resize', this.setAppHeight);
        const midnightInterval = setInterval(() => this.midnightReloadHandle(), 3600000);
        this.setState({ midnightInterval });
        this.setInitSectionsOpen();
        this.setAppHeight();
    }
    render() {
        var _a;
        const { statusStore, appState, navigation, dashboard, wizardStore, loginStore, agentsStore, templateMessagesStore, getPartnerTheme, bulkReports, } = this.props.store;
        const { logoutCountdown } = statusStore;
        const { logoutCountdownStart, logoutCountdownLength } = (_a = statusStore.settings) !== null && _a !== void 0 ? _a : {};
        const { sectionOpen } = navigation;
        return (React.createElement("div", { className: "main-page-container" },
            appState === APP_STATE.ERROR ? React.createElement(IsReadyErrorMessage, null) : null,
            React.createElement(ShowOffline, null),
            React.createElement(TopBar, { toggleMobileDrawer: () => navigation.toggleSection(SECTION.mobileDrawer), isDrawerOpen: !!sectionOpen.mobileDrawer, partnerTheme: getPartnerTheme() }),
            React.createElement("div", { className: classNames("main-page-layout", {
                    "rtl": getDirection() === "rtl",
                }) },
                React.createElement(LeftSideBar, { settingsAction: () => {
                        navigation.toggleSection(SECTION.settings);
                    }, toggleMessageDrawer: () => {
                        navigation.toggleMessageDrawer();
                        navigation.toggleSection(SECTION.mobileDrawer, false);
                    }, toggleWizardModal: () => wizardStore.setWizardIsOpen(), toggleTemplateMessageDrawer: () => {
                        navigation.toggleTemplateMessageDrawer();
                    }, templateMessageDrawer: navigation.isTemplateMessageDrawerOpen, drawer: sectionOpen.settings, botAdmin: sectionOpen.botAdmin, messageDrawer: navigation.isMessageDrawerOpen, dashboardOpen: navigation.isDashboardDrawerOpen, toggleDashboardDrawer: navigation.toggleDashboardDrawer, openCompanySupport: navigation.toggleSupportModal, hasPermissions: agentsStore.hasPermissions, hasScheduledReports: bulkReports.hasScheduledReports, partnerTheme: getPartnerTheme() }),
                React.createElement(LeftDrawer, { className: "settings-drawer", onOpenSection: (name) => navigation.toggleSection(name, true), onCloseDrawer: () => navigation.toggleSection(SECTION.settings, false), isOpen: !!sectionOpen.settings, toggleTemplateDrawer: () => {
                        templateMessagesStore.toggleNewTemplateDrawer();
                        navigation.toggleSection(SECTION.settings, false);
                    } }),
                React.createElement(WizardModal, { closeModal: wizardStore.setWizardIsOpen, visible: wizardStore.isWizardOpen }),
                React.createElement(TemplateGenerator, null),
                React.createElement(AgentsManager, null),
                React.createElement(DepartmentsManager, null),
                React.createElement(ChannelsManager, null),
                React.createElement(ApiTokensManager, null),
                React.createElement(OAuthManager, null),
                sectionOpen.mobileDrawer ? (React.createElement(TopMobileDrawer, { toggleDrawer: () => navigation.toggleSection(SECTION.settings), toggleMessageDrawer: () => {
                        navigation.toggleMessageDrawer();
                        navigation.toggleSection(SECTION.mobileDrawer, false);
                    } })) : null,
                React.createElement(TemplateMessageDrawer, { isOpen: navigation.isTemplateMessageDrawerOpen, toggleTemplateMessageDrawer: () => navigation.toggleTemplateMessageDrawer(), toggleBulkMessageDrawer: () => {
                        wizardStore.setWizardIsOpen();
                        navigation.toggleTemplateMessageDrawer();
                    }, toggleNewTemplateDrawer: () => {
                        templateMessagesStore.toggleNewTemplateDrawer();
                        navigation.toggleTemplateMessageDrawer();
                    }, toggleBulkReportsDrawer: () => {
                        navigation.toggleBulkReportsDrawer();
                        navigation.toggleTemplateMessageDrawer();
                    } }),
                React.createElement(MessageDrawer, { toggleMessageDrawer: () => navigation.toggleMessageDrawer(), isOpen: navigation.isMessageDrawerOpen, setChat: this.createChat }),
                React.createElement(BulkReportsDrawer, { toggleBulkReportsDrawer: () => navigation.toggleBulkReportsDrawer(), toggleBulkMessageDrawer: () => wizardStore.setWizardIsOpen(), isOpen: navigation.isBulkReportsDrawerOpen }),
                React.createElement(EditProfileDrawer, { toggleEditProfileDrawer: () => navigation.toggleEditProfileDrawer(), isOpen: navigation.isEditProfileDrawerOpen }),
                React.createElement(DashboardDrawer, { toggleDashboardDrawer: () => navigation.toggleDashboardDrawer(), isOpen: navigation.isDashboardDrawerOpen }),
                React.createElement(NotificationSubscribeExample, { isOpen: !!sectionOpen.notificationExample, setNotificationExampleOpen: () => navigation.toggleSection(SECTION.notificationExample) }),
                React.createElement(SupportModal, { visible: navigation.isSupportModalOpen, setModalVisible: navigation.toggleSupportModal }),
                React.createElement(ChatSettingsRoute, null),
                React.createElement(CenterContent, null),
                React.createElement(RightBar, null)),
            (!!logoutCountdownLength && !!logoutCountdownStart && logoutCountdown) &&
                // Don't use "visible" property to hide modal and unmount always,
                // because otherwise it continues to count down in the background and
                // eventually logs out the user even if the modal is not visible.
                React.createElement(EmptyModal, { visible: true },
                    React.createElement(AutoDisconnectModalContent, { countdownLength: logoutCountdownLength, inactivityPeriod: logoutCountdownStart, onLogout: loginStore.logOut }))));
    }
}
const ShowOffline = observer(function () {
    if (onlineStatus.online) {
        return null;
    }
    else {
        return React.createElement(NoInternetMessage, null);
    }
});
export default withTranslation()(withStore(observer(MainPageLayout)));
