import React from 'react';
import { Typography } from 'antd';
import { Trans } from 'react-i18next';
import { ReactComponent as QuickRepliesCross } from '../../../assets/messageStatuses/closeCross.svg';
import ActiveIcon from '../../Chat/ActiveIcon';
import s from './Header.module.scss';
const { Text } = Typography;
const Header = ({ onClose }) => {
    return (React.createElement("div", { className: s.titleWrapper },
        React.createElement("div", { className: s.title },
            React.createElement(Text, { className: s.titleText },
                React.createElement(Trans, { i18nKey: "quickReplies" }))),
        React.createElement("div", { className: s.closeModal },
            React.createElement(ActiveIcon, { component: QuickRepliesCross, action: () => {
                    onClose === null || onClose === void 0 ? void 0 : onClose();
                } }))));
};
export default Header;
