import React from "react";
import { Trans } from "react-i18next";
import { useHistory } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import LocalPhoneIcon from "@material-ui/icons/LocalPhone";
import LanguageOutlinedIcon from "@material-ui/icons/LanguageOutlined";
import PeopleAltOutlinedIcon from "@material-ui/icons/PeopleAltOutlined";
import KeyboardBackspaceOutlinedIcon from "@material-ui/icons/KeyboardBackspaceOutlined";
import ForumIcon from "@material-ui/icons/Forum";
import Tooltip from "@material-ui/core/Tooltip";
import { withStyles } from "@material-ui/core/styles";
import { BankOutlined, QuestionCircleOutlined, NotificationOutlined } from "@ant-design/icons";
import { useDrawerStyles } from "./style";
import TimeFormatSelect from "./TimeFormatSelect";
import LanguageSelect from "./LanguageSelect";
import PhoneFormatSelect from "./PhoneFormatSelect";
import classnames from "classnames";
import { PHONE_FORMAT } from "../../constants";
import { getDirection } from "../../helpers";
import AboutSettings from "./menu/About";
import DevelopersSettings from "./menu/Developers";
import { useStore } from "../../store/rootStore";
import { Permissions } from "@whatsper/texterchat-common";
import { observer } from "mobx-react";
import ChatSettings from "./menu/ChatSettings";
const PhoneFormatTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: "#f5f5f9",
        color: "rgba(0, 0, 0, 0.87)",
        maxWidth: 220,
        fontSize: theme.typography.pxToRem(15),
        border: "1px solid #dadde9",
    },
}))(Tooltip);
const LeftDrawer = ({ onOpenSection, onCloseDrawer, isOpen, className, toggleTemplateDrawer, }) => {
    const classes = useDrawerStyles({ direction: getDirection() });
    const history = useHistory();
    const { agentsStore } = useStore();
    const goToAppPath = (path) => {
        onCloseDrawer();
        history.push(path, { prevPath: history.location.pathname });
    };
    const isMobile = () => {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    };
    const list = () => (React.createElement("section", { className: classes.customSection },
        React.createElement(List, null,
            React.createElement(ListItem, { button: true, onClick: onCloseDrawer },
                React.createElement(ListItemIcon, null,
                    React.createElement(KeyboardBackspaceOutlinedIcon, { fontSize: "small", className: classes.backIcon })),
                React.createElement("span", { className: classes.span },
                    React.createElement(Trans, { i18nKey: "Settings" })))),
        React.createElement(List, null,
            React.createElement(ListItem, null,
                React.createElement(ListItemIcon, null,
                    React.createElement(LanguageOutlinedIcon, { fontSize: "small" })),
                React.createElement(ListItemText, { primary: React.createElement(Trans, { i18nKey: "Languages" }) }),
                React.createElement(LanguageSelect, null)),
            React.createElement(ListItem, null,
                React.createElement(ListItemIcon, null,
                    React.createElement(AccessTimeIcon, { fontSize: "small" })),
                React.createElement(ListItemText, { primary: React.createElement(Trans, { i18nKey: "timeFormat" }) }),
                React.createElement(TimeFormatSelect, null)),
            React.createElement(ListItem, null,
                React.createElement(ListItemIcon, null,
                    React.createElement(LocalPhoneIcon, { fontSize: "small" })),
                React.createElement(ListItemText, { primary: React.createElement(PhoneFormatTooltip, { title: React.createElement(React.Fragment, null,
                            React.createElement("div", null,
                                React.createElement("strong", null,
                                    React.createElement(Trans, { i18nKey: PHONE_FORMAT.SMART }),
                                    ":"),
                                React.createElement("br", null),
                                React.createElement(Trans, { i18nKey: "PF_SMART_ABOUT" })),
                            React.createElement("div", null,
                                React.createElement("strong", null,
                                    React.createElement(Trans, { i18nKey: PHONE_FORMAT.INTERNATIONAL }),
                                    ":"),
                                React.createElement("br", null),
                                React.createElement(Trans, { i18nKey: "PF_INTERNATIONAL_ABOUT" })),
                            React.createElement("div", null,
                                React.createElement("strong", null,
                                    React.createElement(Trans, { i18nKey: PHONE_FORMAT.NATIONAL }),
                                    ":"),
                                React.createElement("br", null),
                                React.createElement(Trans, { i18nKey: "PF_NATIONAL_ABOUT" }))) },
                        React.createElement("div", { className: classes.sectionTitle },
                            React.createElement(Trans, { i18nKey: "phoneFormat" }),
                            React.createElement(QuestionCircleOutlined, null))) }),
                React.createElement(PhoneFormatSelect, null))),
        React.createElement(Divider, null),
        agentsStore.hasPermissions([Permissions.Permission.users_view]) ? (React.createElement(List, null,
            React.createElement(ListItem, { button: true, onClick: () => goToAppPath("/manage/agents") },
                React.createElement(ListItemIcon, null,
                    React.createElement(PeopleAltOutlinedIcon, { fontSize: "small" })),
                React.createElement(ListItemText, { primary: React.createElement(Trans, { i18nKey: "agentsManager" }) })))) : null,
        agentsStore.hasPermissions([Permissions.Permission.departments_view]) ? (React.createElement(List, null,
            React.createElement(ListItem, { button: true, onClick: () => goToAppPath("/manage/departments") },
                React.createElement(ListItemIcon, null,
                    React.createElement(BankOutlined, { style: { fontSize: "16px" } })),
                React.createElement(ListItemText, { primary: React.createElement(Trans, { i18nKey: "departmentsManager" }) })))) : null,
        agentsStore.hasPermissions([Permissions.Permission.channels_view]) ? (React.createElement(List, null,
            React.createElement(ListItem, { button: true, onClick: () => goToAppPath("/manage/channels") },
                React.createElement(ListItemIcon, null,
                    React.createElement(NotificationOutlined, { style: { fontSize: "16px" } })),
                React.createElement(ListItemText, { primary: React.createElement(Trans, { i18nKey: "channelManager" }) })))) : null,
        agentsStore.hasPermissions([Permissions.Permission.export_list]) && (React.createElement(ChatSettings, { goToAppPath: goToAppPath })),
        isMobile() && agentsStore.hasPermissions([Permissions.Permission.template_messages_view]) ? (React.createElement(List, null,
            React.createElement(ListItem, { button: true, onClick: toggleTemplateDrawer },
                React.createElement(ListItemIcon, null,
                    React.createElement(ForumIcon, { style: { fontSize: "16px" } })),
                React.createElement(ListItemText, { primary: React.createElement(Trans, { i18nKey: "templateManager" }) })))) : null,
        React.createElement(Divider, null),
        React.createElement(AboutSettings, null),
        React.createElement(Divider, null),
        agentsStore.hasPermissions([
            Permissions.Permission.oauth_manage_all,
            Permissions.Permission.api_tokens_view,
        ]) && (React.createElement(DevelopersSettings, { goToAppPath: goToAppPath }))));
    return (React.createElement(Drawer, { className: classnames(classes.root, className), onClose: onCloseDrawer, anchor: getDirection() === "rtl" ? "right" : "left", dir: getDirection(), open: isOpen }, list()));
};
export default observer(LeftDrawer);
