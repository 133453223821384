var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { PING_MSG } from '@whatsper/texterchat-common';
import { observable, runInAction, makeObservable } from 'mobx';
import { API_PATH, SERVER_URL } from '../constants';
import EventEmitter from 'events';
/** Period to check last event time */
const CHECK_STUCK_INTERVAL_MSEC = 60000;
/** If last event was more than this period ago, consider stream failed */
const MAX_EVENTS_PERIOD_MSEC = 60000; // Twice server ping period
export class Events extends EventEmitter {
    constructor() {
        // TODO: [mobx-undecorate] verify the constructor arguments and the arguments of this automatically generated super call
        super();
        Object.defineProperty(this, "source", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        /** Timestamp of latest event received */
        Object.defineProperty(this, "lastEventTime", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        /** Handle of interval to check last event time */
        Object.defineProperty(this, "checkStuckInterval", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        /** Is stream connection connected and alive */
        Object.defineProperty(this, "connected", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        makeObservable(this);
    }
    /**
     * Connect to events stream.
     * Important: Ensure to have authentication cookie set before calling this method!
     */
    connect() {
        this.disconnect();
        this.setupEventSource();
        this.checkStuckInterval = setInterval(this.checkStuck.bind(this), CHECK_STUCK_INTERVAL_MSEC);
    }
    setupEventSource() {
        this.source = new EventSource(`${SERVER_URL}${API_PATH.EVENTS_STREAM}`, { withCredentials: true });
        this.source.addEventListener('open', this.onSourceOpen.bind(this));
        this.source.addEventListener('error', this.onSourceError.bind(this));
        this.source.addEventListener('message', this.onSourceEvent.bind(this));
    }
    disconnect() {
        if (null !== this.checkStuckInterval) {
            clearInterval(this.checkStuckInterval);
            this.checkStuckInterval = null;
        }
        this.destroyEventSource();
    }
    destroyEventSource() {
        if (this.source) {
            this.source.close();
            this.source.removeEventListener('open', this.onSourceEvent.bind(this));
            this.source.removeEventListener('error', this.onSourceError.bind(this));
            this.source.removeEventListener('message', this.onSourceEvent.bind(this));
            this.source = null;
            runInAction(() => this.connected = false);
        }
    }
    /** Simple helper to add one listener to more than one event */
    addMultiListener(events, listener) {
        for (const event of events) {
            this.addListener(event, listener);
        }
        return this;
    }
    /** Opposite to addMultiListener() */
    removeMultiListener(events, listener) {
        for (const event of events) {
            this.removeListener(event, listener);
        }
        return this;
    }
    onSourceOpen(openEvent) {
        runInAction(() => this.connected = true);
        this.emit(LifecycleEvent.STREAM_OPEN);
        console.debug('EventSource open:', openEvent);
    }
    onSourceError(failEvent) {
        runInAction(() => this.connected = false);
        this.emit(LifecycleEvent.STREAM_FAILED);
        console.warn(`EventSource failed:`, failEvent);
    }
    onSourceEvent(serverEvent) {
        this.lastEventTime = Date.now();
        if (!this.connected) {
            runInAction(() => this.connected = true);
        }
        if (PING_MSG === serverEvent.data) {
            return;
        }
        try {
            const { event, data } = JSON.parse(serverEvent.data);
            if (typeof event === 'string') {
                console.debug('Received server-sent event:', event, data);
                this.emit(event, data);
            }
            else {
                console.error('Malformed event: No "event" field inside event data or it not a string type');
            }
        }
        catch (error) {
            console.error('Error parsing event data JSON:', error);
        }
    }
    checkStuck() {
        if (Date.now() - this.lastEventTime > MAX_EVENTS_PERIOD_MSEC) {
            runInAction(() => this.connected = false);
            this.emit(LifecycleEvent.STREAM_FAILED);
            console.warn('EventSource failed: Detected too long no events period. Re-creating event source');
            this.destroyEventSource();
            this.setupEventSource();
        }
    }
}
__decorate([
    observable
], Events.prototype, "connected", void 0);
export var LifecycleEvent;
(function (LifecycleEvent) {
    LifecycleEvent["STREAM_OPEN"] = "stream_open";
    LifecycleEvent["STREAM_FAILED"] = "stream_failed";
})(LifecycleEvent || (LifecycleEvent = {}));
