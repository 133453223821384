import React from 'react';
import { observer } from 'mobx-react';
import Box from './Box';
import { ReactComponent as ClockCircle } from '../../../assets/icons/dashboard/ClockCircle.svg';
import { ReactComponent as CheckSquare } from '../../../assets/icons/dashboard/CheckSquare.svg';
import { ReactComponent as Hourglass } from '../../../assets/icons/dashboard/Hourglass.svg';
import { ReactComponent as IssuesClose } from '../../../assets/icons/dashboard/IssuesClose.svg';
import { ReactComponent as UserAdd } from '../../../assets/icons/dashboard/UserAdd.svg';
import { ReactComponent as Dashboard } from '../../../assets/icons/dashboard/Dashboard.svg';
const boxIcons = [React.createElement(ClockCircle, null), React.createElement(Hourglass, null), React.createElement(UserAdd, null), React.createElement(IssuesClose, null), React.createElement(CheckSquare, null), React.createElement(Dashboard, null)];
const Row = ({ boxData, loading, inverted }) => {
    return (React.createElement(React.Fragment, null, boxData.map(({ title, icon, data, color }, index) => (React.createElement(Box, { key: index, title: title, icon: icon || boxIcons[index], data: data, loading: loading, inverted: inverted, color: color })))));
};
export default observer(Row);
