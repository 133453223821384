import React from 'react';
import { Divider } from 'antd';
import classnames from 'classnames';
import HighlightedText from '../../Base/Utils/HighlightedText';
import './MessagesSearchHit.scss';
const MessagesSearchHit = ({ hit, onClick, className, highlightedClassName, search, renderHeader, }) => {
    highlightedClassName = classnames(highlightedClassName, 'highlighted');
    let text;
    if (hit.highlighted) {
        text = FormatHighlighted({ highlighted: hit.highlighted, highlightedClassName });
    }
    else if (search) {
        text = React.createElement(HighlightedText, { text: hit.text, highlighted: search, className: highlightedClassName });
    }
    else {
        text = hit.text;
    }
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: classnames('messages-search-item', className), onClick: () => (onClick ? onClick() : '') },
            renderHeader
                ? renderHeader(hit)
                : hit.chatTitle && React.createElement("div", { className: "conversation-title" }, hit.chatTitle),
            React.createElement("div", { className: "text" }, text)),
        React.createElement(Divider, { className: "messages-search-item-divider" })));
};
function FormatHighlighted({ highlighted, highlightedClassName, }) {
    const textParts = highlighted.split('|||'), textWrapped = [];
    for (let i = 0; i < textParts.length; i++) {
        if (i % 2) {
            // Odd number iteration
            textWrapped.push(React.createElement("span", { key: textParts[i] + '-' + i, className: highlightedClassName }, textParts[i]));
        }
        else {
            // Even number iteration
            textWrapped.push(textParts[i]);
        }
    }
    return textWrapped;
}
export default MessagesSearchHit;
