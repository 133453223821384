import React from 'react';
import { Typography } from 'antd';
import { Trans } from 'react-i18next';
import classnames from 'classnames';
import ModalWithoutBottom from '../../../Chat/common/ModalWithoutBottom';
import CommonButton from '../../../Chat/common/CommonButton';
import WhatsappLogo from '../../../../assets/logos/MessengersBigLogos/WhatsappBigLogo.png';
import FormSendAppLinks from './FormSendAppLinks/FormSendAppLinks';
import { MOBILE_APP_URLS, SUPPORT_LINK, YOUTUBE_TUTORIALS_URLS } from '../../../../constants';
import { ReactComponent as YoutubeLogo } from '../../../../assets/crmLogos/youtube-logo.svg';
import SECTION from '../../../../store/NavigationStore/Section';
import { AiFillAndroid, AiFillApple, AiFillBell } from 'react-icons/ai';
import { useStore } from '../../../../store/rootStore';
import { getDirection } from '../../../../helpers';
import s from './SupportModal.module.scss';
const { Text } = Typography;
const SupportModal = ({ visible, setModalVisible }) => {
    const isRTL = getDirection() === 'rtl';
    const { navigation } = useStore();
    const onLinkClick = (url) => {
        var _a;
        (_a = window.open(url, '_blank', 'noopener noreferrer')) === null || _a === void 0 ? void 0 : _a.focus();
    };
    const onNotificationExampleClick = () => {
        navigation.toggleSection(SECTION.notificationExample);
        setModalVisible();
    };
    return (React.createElement(ModalWithoutBottom, { visible: visible, setModalVisible: setModalVisible, titleTextKey: 'contactTexterSupport', height: "650px" },
        React.createElement("div", { className: s.content },
            React.createElement("div", { className: classnames(s.contactWrapper, { [s.rtl]: isRTL }) },
                React.createElement(Text, { className: classnames(s.text, { [s.rtl]: isRTL }) },
                    React.createElement(Trans, { i18nKey: "contactSupportText" })),
                React.createElement(CommonButton, { action: () => onLinkClick(SUPPORT_LINK) },
                    React.createElement("img", { src: WhatsappLogo, alt: "whatsapp" }),
                    React.createElement(Trans, { i18nKey: "contactSupport" }))),
            React.createElement("div", { className: classnames(s.notificationWrapper, { [s.rtl]: isRTL }) },
                React.createElement("div", { className: s.tutorialsLink, onClick: onNotificationExampleClick },
                    React.createElement(AiFillBell, null),
                    React.createElement(Trans, { i18nKey: "howToEnableNotifications" }))),
            React.createElement("div", { className: classnames(s.tutorialsWrapper, { [s.rtl]: isRTL }) },
                React.createElement("div", { className: classnames(s.tutorialsTitle, { [s.rtl]: isRTL }), onClick: () => onLinkClick(YOUTUBE_TUTORIALS_URLS.PLAYLIST) },
                    React.createElement(YoutubeLogo, null),
                    React.createElement(Trans, { i18nKey: "youtubeTutorials" }),
                    ":"),
                React.createElement("div", { className: s.tutorialsLink, onClick: () => onLinkClick(YOUTUBE_TUTORIALS_URLS.INBOX_TUTORIAL) },
                    React.createElement(Trans, { i18nKey: "inboxTutorial" })),
                React.createElement("div", { className: s.tutorialsLink, onClick: () => onLinkClick(YOUTUBE_TUTORIALS_URLS.NEW_TEMPLATE) },
                    React.createElement(Trans, { i18nKey: "creatingNewTemplate" })),
                React.createElement("div", { className: s.tutorialsLink, onClick: () => onLinkClick(YOUTUBE_TUTORIALS_URLS.RICH_TEMPLATE_PARAMETERS) },
                    React.createElement(Trans, { i18nKey: "richTemplateParameters" })),
                React.createElement("div", { className: s.tutorialsLink, onClick: () => onLinkClick(YOUTUBE_TUTORIALS_URLS.SENDING_BULK_MESSAGES) },
                    React.createElement(Trans, { i18nKey: "sendingBulkMessages" })),
                React.createElement("div", { className: s.tutorialsLink, onClick: () => onLinkClick(YOUTUBE_TUTORIALS_URLS.ADMIN_TUTORIAL) },
                    React.createElement(Trans, { i18nKey: "adminsTutorial" }))),
            React.createElement("div", { className: classnames(s.appsWrapper, { [s.rtl]: isRTL }) },
                React.createElement("div", { className: classnames(s.tutorialsTitle, { [s.rtl]: isRTL }) },
                    React.createElement(Trans, { i18nKey: "downloadTheTexterChatApp" }),
                    ":"),
                React.createElement("div", { className: classnames(s.tutorialsLink, { [s.rtl]: isRTL }), onClick: () => onLinkClick(MOBILE_APP_URLS.ANDROID) },
                    React.createElement(AiFillAndroid, null),
                    React.createElement(Trans, { i18nKey: "downloadForAndroid" })),
                React.createElement("div", { className: classnames(s.tutorialsLink, { [s.rtl]: isRTL }), onClick: () => onLinkClick(MOBILE_APP_URLS.IOS) },
                    React.createElement(AiFillApple, null),
                    React.createElement(Trans, { i18nKey: "downloadForIos" })),
                React.createElement(FormSendAppLinks, { closeModal: setModalVisible })))));
};
export default SupportModal;
