import React, { Component } from 'react';
import { Skeleton, Divider } from 'antd';
import styles from './ChatsListItem.module.scss';
export default class ItemSkeleton extends Component {
    render() {
        return (React.createElement(React.Fragment, null,
            React.createElement(Skeleton, { active: true, avatar: {
                    shape: 'circle',
                    size: 50,
                }, paragraph: {
                    rows: 1,
                }, className: styles.skeleton }),
            this.props.divider &&
                React.createElement(Divider, { className: styles.divider })));
    }
}
Object.defineProperty(ItemSkeleton, "defaultProps", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: {
        divider: true,
    }
});
