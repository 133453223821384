var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { action, observable, runInAction, makeObservable } from "mobx";
import { API_PATH } from "../../constants";
import { getAxios } from '../../backend';
import { EventName } from "@whatsper/texterchat-common";
class QuickRepliesStore {
    constructor(rootStore) {
        Object.defineProperty(this, "rootStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "quickRepliesList", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: observable.array()
        });
        Object.defineProperty(this, "quickRepliesLoading", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "loadQuickReplies", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                try {
                    const { data: quickReplies } = yield getAxios()
                        .then((axios) => {
                        return axios.get(API_PATH.QUICK_REPLIES);
                    });
                    runInAction(() => {
                        this.quickRepliesList.replace(
                        // legacy
                        quickReplies.reverse().map((reply) => (Object.assign({ type: 'QuickReply' }, reply))));
                    });
                }
                catch (error) {
                    console.error('Error loading quick replies', error);
                }
            })
        });
        Object.defineProperty(this, "addQuickReply", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (reply) => __awaiter(this, void 0, void 0, function* () {
                yield getAxios()
                    .then((axios) => {
                    return axios.post(API_PATH.QUICK_REPLIES, reply);
                })
                    .catch(function (error) {
                    console.error("ERROR: adding quick reply failed", error);
                });
            })
        });
        Object.defineProperty(this, "editQuickReply", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (id, newReply) => __awaiter(this, void 0, void 0, function* () {
                if (!id) {
                    console.error(`Can not find quick reply id: "${id}"`);
                }
                yield getAxios()
                    .then((axios) => {
                    return axios.patch(`${API_PATH.QUICK_REPLIES}/${id}`, newReply);
                })
                    .catch(function (error) {
                    console.error("ERROR: editing quick reply failed", error);
                });
            })
        });
        Object.defineProperty(this, "deleteQuickReply", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (id) => __awaiter(this, void 0, void 0, function* () {
                yield getAxios()
                    .then((axios) => {
                    return axios.delete(`${API_PATH.QUICK_REPLIES}/${id}`);
                })
                    .catch(function (error) {
                    console.error("ERROR: deleting quick reply failed", error);
                });
            })
        });
        makeObservable(this);
        this.rootStore = rootStore;
        this.rootStore.events
            .addListener(EventName.quick_reply_created, (data) => this.onQuickReplyCreatedEvent(data))
            .addListener(EventName.quick_reply_updated, (data) => this.onQuickReplyUpdatedEvent(data))
            .addListener(EventName.quick_reply_deleted, (data) => this.onQuickReplyDeletedEvent(data));
    }
    onQuickReplyCreatedEvent(reply) {
        this.quickRepliesList.push(Object.assign({ type: 'QuickReply' }, reply));
    }
    onQuickReplyUpdatedEvent(replyUpdated) {
        this.quickRepliesList.replace(this.quickRepliesList.map((reply) => {
            return replyUpdated._id === reply._id ? Object.assign({ type: 'QuickReply' }, replyUpdated) : reply;
        }));
    }
    onQuickReplyDeletedEvent(data) {
        this.quickRepliesList.replace(this.quickRepliesList.filter((reply) => reply._id !== data._id));
    }
}
__decorate([
    observable
], QuickRepliesStore.prototype, "quickRepliesLoading", void 0);
__decorate([
    action
], QuickRepliesStore.prototype, "onQuickReplyCreatedEvent", null);
__decorate([
    action
], QuickRepliesStore.prototype, "onQuickReplyUpdatedEvent", null);
__decorate([
    action
], QuickRepliesStore.prototype, "onQuickReplyDeletedEvent", null);
export default QuickRepliesStore;
