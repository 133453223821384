import React from 'react';
import { SearchOutlined } from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import DepartmentsFilter from '../DepFilter';
import { MyInput } from '../../../../Base/Form';
import { getDirection } from '../../../../../helpers';
import s from './ContactFilters.module.scss';
const ContactFilters = ({ currentDepartmentId, text, loading, setSearchFilter, setDepartmentFilter, }) => {
    const { t } = useTranslation();
    return (React.createElement("div", { className: classNames(s.contactFilterHeader, {
            [s.rtl]: getDirection() === 'rtl',
        }) },
        React.createElement("div", { className: s.contactFilterItem },
            React.createElement(MyInput, { type: "search", size: "large", placeholder: t('searchChat'), value: text, onChangeHandler: setSearchFilter, prefix: React.createElement(SearchOutlined, { className: s.contactFilterIcon }), disabled: loading, allowClear: true })),
        React.createElement("div", { className: classNames(s.contactFilterItem, s.departmentItem) },
            React.createElement(DepartmentsFilter, { currentDepartmentId: currentDepartmentId, setDepartmentFilter: setDepartmentFilter, size: 'large', disabled: loading }))));
};
export default observer(ContactFilters);
