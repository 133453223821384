var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { APP_STATE } from '../constants';
import LoadingScreen from '../components/LoadingScreen';
const PrivateRoute = (_a) => {
    var { component: Component, isLoggedIn, appState } = _a, rest = __rest(_a, ["component", "isLoggedIn", "appState"]);
    return (React.createElement(Route, Object.assign({}, rest, { render: (props) => {
            return (appState === APP_STATE.INIT && !isLoggedIn)
                ? React.createElement(LoadingScreen, null)
                : (isLoggedIn
                    ? React.createElement(Component, Object.assign({}, props))
                    : React.createElement(Redirect, { to: '/login' }));
        } })));
};
export default PrivateRoute;
