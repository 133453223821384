var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { action, observable, runInAction, makeObservable } from "mobx";
import { getAxios } from '../../backend';
import { API_PATH } from "../../constants";
class CrmStore {
    constructor(rootStore) {
        Object.defineProperty(this, "rootStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "crmStatus", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ""
        });
        Object.defineProperty(this, "crmStatusCode", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(this, "crmData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "crmDataName", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: "-"
        });
        Object.defineProperty(this, "crmDataPhone", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: "-"
        });
        Object.defineProperty(this, "crmDataID", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: "-"
        });
        Object.defineProperty(this, "crmDataLink", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "crmDataLoading", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "crmError", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "crmStatuses", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "crmStatusesLoading", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "crmId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ""
        });
        Object.defineProperty(this, "activityListLoading", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "activityList", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: observable.array()
        });
        Object.defineProperty(this, "syncCrmActivities", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (chatId) => __awaiter(this, void 0, void 0, function* () {
                this.activityList.clear();
                yield this.request({
                    "op": "syncActivityData",
                    chatId: chatId,
                })
                    .catch((error) => {
                    console.error(`ERROR syncCrmActivities ${error.message}`);
                })
                    .finally(() => this.getCrmActivities());
            })
        });
        Object.defineProperty(this, "getCrmActivities", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                var _a;
                try {
                    const chatid = (_a = this.rootStore.activeChatStore.chat) === null || _a === void 0 ? void 0 : _a._id;
                    if (chatid) {
                        runInAction(() => this.activityListLoading = true);
                        yield getAxios()
                            .then((axios) => {
                            return axios.get(`${API_PATH.CRM}/activities`, {
                                params: { chatid }
                            });
                        })
                            .then(({ data }) => {
                            runInAction(() => {
                                this.activityList.replace(data);
                            });
                        });
                    }
                }
                catch (error) {
                    console.error('Error loading CRM activities', error);
                }
                finally {
                    runInAction(() => this.activityListLoading = false);
                }
            })
        });
        Object.defineProperty(this, "changeStatus", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (status) => __awaiter(this, void 0, void 0, function* () {
                var _b;
                this.crmStatusCode = status;
                const chosenStatus = this.crmStatuses.find(crmStatus => crmStatus.value === status);
                if (chosenStatus) {
                    this.crmStatus = chosenStatus.name;
                }
                const chatId = (_b = this.rootStore.activeChatStore.chat) === null || _b === void 0 ? void 0 : _b._id;
                if (chatId) {
                    // Happens when active chat is changes:
                    this.crmDataID = "-";
                    yield this.request({
                        "op": "changeStatus",
                        chatId: chatId,
                        params: {
                            crmStatusCode: status
                        }
                    })
                        .catch((error) => {
                        console.error(error.message);
                    });
                }
                console.log("Status Changed");
            })
        });
        Object.defineProperty(this, "addActivity", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (text) => __awaiter(this, void 0, void 0, function* () {
                var _c;
                const date = new Date();
                const chatid = (_c = this.rootStore.activeChatStore.chat) === null || _c === void 0 ? void 0 : _c._id;
                const { userDetails } = this.rootStore.loginStore;
                if (chatid && userDetails) {
                    this.activityList.push({
                        chatid,
                        createdby: userDetails.email || userDetails._id,
                        text: text,
                        createdon: date.getTime()
                    });
                    yield this.request({
                        "op": "addActivity",
                        chatId: chatid,
                        params: {
                            email: userDetails.email || '',
                            date,
                            text,
                        }
                    })
                        .catch((error) => {
                        console.error(`ERROR addActivity ${error.message}`);
                    });
                }
            })
        });
        Object.defineProperty(this, "assignToAgentInCrm", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                var _d, _e;
                const chatId = (_d = this.rootStore.activeChatStore.chat) === null || _d === void 0 ? void 0 : _d._id;
                const email = (_e = this.rootStore.loginStore.userDetails) === null || _e === void 0 ? void 0 : _e.email;
                yield this.request({
                    "op": "assignChat",
                    chatId: chatId,
                    params: {
                        email,
                    }
                })
                    .catch((error) => {
                    console.error(`ERROR assignToAgentInCrm ${error.message}`);
                });
            })
        });
        Object.defineProperty(this, "getCustomerDetails", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (chatId) => __awaiter(this, void 0, void 0, function* () {
                if (chatId) {
                    this.crmDataLoading = true;
                    this.resetCustomerDetails();
                    yield this.request({
                        "op": "getCustomerDetails",
                        chatId: chatId
                    })
                        .then((data) => {
                        runInAction(() => {
                            var _a;
                            if (data.success &&
                                !!data.crmData &&
                                ((_a = this.rootStore.activeChatStore.chat) === null || _a === void 0 ? void 0 : _a._id) == chatId) {
                                this.crmData = data;
                                if (!!data.crmData.name) {
                                    this.crmDataName = data.crmData.name;
                                }
                                if (!!data.crmData.statuscode) {
                                    this.crmStatusCode = data.crmData.statuscode;
                                }
                                if (!!data.crmData.statusCode) {
                                    this.crmStatusCode = data.crmData.statusCode;
                                }
                                if (!!data.crmData.status) {
                                    this.crmStatus = data.crmData.status;
                                }
                                if (!!data.crmData.id) {
                                    this.crmDataID = data.crmData.id;
                                }
                                this.crmDataPhone = data.crmData.phone;
                                if (data.crmData.deepLink) {
                                    this.crmDataLink = data.crmData.deepLink;
                                }
                            }
                            this.crmDataLoading = false;
                        });
                    })
                        .catch((error) => {
                        runInAction(() => {
                            this.crmDataLoading = false;
                            this.crmError = true;
                        });
                        console.error("CRM Error", error);
                    });
                }
            })
        });
        Object.defineProperty(this, "resetCustomerDetails", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                runInAction(() => {
                    this.crmData = null;
                    this.crmDataLink = null;
                    this.crmError = false;
                    this.crmDataName = "-";
                    this.crmDataPhone = "-";
                    this.crmDataID = "-";
                    this.crmStatus = "";
                    this.crmStatusCode = 0;
                });
            }
        });
        Object.defineProperty(this, "crmGetFields", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                // Happens when active chat is changes:
                this.crmDataID = "-";
                this.crmStatusesLoading = true;
                yield this.request({
                    "op": "crmGetFields"
                })
                    .then((data) => {
                    runInAction(() => {
                        if (data.crmId) {
                            this.crmId = data.crmId;
                        }
                        if (data.data && data.data.statuses) {
                            this.crmStatuses = data.data.statuses;
                        }
                    });
                })
                    .catch((error) => {
                    runInAction(() => this.crmError = true);
                    console.error("CRM Error", error.message);
                })
                    .finally(() => {
                    runInAction(() => this.crmStatusesLoading = false);
                });
            })
        });
        Object.defineProperty(this, "request", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (data) => __awaiter(this, void 0, void 0, function* () {
                return getAxios()
                    .then((axios) => {
                    return axios.post(API_PATH.CRM, data);
                })
                    .then(({ data }) => data);
            })
        });
        makeObservable(this);
        this.rootStore = rootStore;
    }
}
__decorate([
    observable
], CrmStore.prototype, "crmStatus", void 0);
__decorate([
    observable
], CrmStore.prototype, "crmStatusCode", void 0);
__decorate([
    observable
], CrmStore.prototype, "crmData", void 0);
__decorate([
    observable
], CrmStore.prototype, "crmDataName", void 0);
__decorate([
    observable
], CrmStore.prototype, "crmDataPhone", void 0);
__decorate([
    observable
], CrmStore.prototype, "crmDataID", void 0);
__decorate([
    observable
], CrmStore.prototype, "crmDataLink", void 0);
__decorate([
    observable
], CrmStore.prototype, "crmDataLoading", void 0);
__decorate([
    observable
], CrmStore.prototype, "crmError", void 0);
__decorate([
    observable
], CrmStore.prototype, "crmStatuses", void 0);
__decorate([
    observable
], CrmStore.prototype, "crmStatusesLoading", void 0);
__decorate([
    observable
], CrmStore.prototype, "crmId", void 0);
__decorate([
    observable
], CrmStore.prototype, "activityListLoading", void 0);
__decorate([
    action
], CrmStore.prototype, "syncCrmActivities", void 0);
__decorate([
    action
], CrmStore.prototype, "getCrmActivities", void 0);
__decorate([
    action
], CrmStore.prototype, "changeStatus", void 0);
__decorate([
    action
], CrmStore.prototype, "addActivity", void 0);
__decorate([
    action
], CrmStore.prototype, "assignToAgentInCrm", void 0);
__decorate([
    action
], CrmStore.prototype, "getCustomerDetails", void 0);
__decorate([
    action
], CrmStore.prototype, "crmGetFields", void 0);
export default CrmStore;
