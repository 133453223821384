import { Skeleton } from 'antd';
import classnames from 'classnames';
import React from 'react';
import { useTranslation } from 'react-i18next';
import s from '../DashboardDrawer.module.scss';
const PanelSummary = ({ panels, color, loading, type }) => {
    const { t } = useTranslation();
    const renderValue = (value) => {
        if (loading)
            return React.createElement(Skeleton.Input, { className: s['box__summary--skeleton'], active: true });
        if (value !== undefined)
            return value;
        return t('noData');
    };
    return (React.createElement("div", { className: classnames(s['box__summary--container'], s[`box__summary--container__${type}`]) },
        React.createElement("div", null),
        React.createElement("div", { className: classnames(s['box__summary--header'], 'header-summary', {
                [s['box__summary--header__agents']]: type === 'agents',
            }), style: { borderColor: color } }, panels.map(({ title, icon, value, compact }) => (React.createElement("div", { key: title, className: classnames(s['box__summary--item']), style: { borderRightColor: color } },
            React.createElement("div", { className: s['box__summary--item-icon'] }, icon),
            React.createElement("div", { className: s['box__summary--item-title'] },
                title,
                ":"),
            React.createElement("div", { className: classnames(s['box__summary--item-value'], {
                    [s['box__summary--item-value--compact']]: compact,
                }) }, renderValue(value))))))));
};
export default PanelSummary;
