import React, { Component } from "react";
import { observer } from "mobx-react";
import { Trans, withTranslation } from "react-i18next";
import Drawer from "@material-ui/core/Drawer";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import { BsArrowLeft } from 'react-icons/bs';
import ReportsSelector from "./ReportsSelector";
import SingleReport from "./SingleReport";
import { sortReportsByDate } from "./helpers";
import { withStore } from "../../store/rootStore";
import ErrorBoundary from "../ErrorBoundary";
import classNames from "classnames";
import { getDirection } from "../../helpers";
import s from "./BulkReportsDrawer.module.scss";
class BulkReportsDrawer extends Component {
    constructor(props) {
        super(props);
        Object.defineProperty(this, "setSearchValue", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (value) => {
                this.setState({ searchValue: value });
            }
        });
        Object.defineProperty(this, "setDateRangeValue", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (value) => {
                if (value === null) {
                    this.setState({ dateRangeValue: [null, null] });
                }
                else {
                    this.setState({ dateRangeValue: value });
                }
            }
        });
        Object.defineProperty(this, "selectReport", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (id) => {
                this.setState({ selectedReportId: id });
                this.props.store.bulkReports.fetchSingleBulkReport(id);
            }
        });
        Object.defineProperty(this, "onUpdateComment", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (comment) => {
                if (this.state.selectedReportId) {
                    this.props.store.bulkReports.updateSingleBulkReport(this.state.selectedReportId, { comment });
                }
            }
        });
        Object.defineProperty(this, "onUpdateTitle", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (title) => {
                if (this.state.selectedReportId) {
                    this.props.store.bulkReports.updateSingleBulkReport(this.state.selectedReportId, { title });
                }
            }
        });
        Object.defineProperty(this, "onDownloadContacts", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (type) => {
                const { selectedReportId } = this.state;
                if (selectedReportId) {
                    this.props.store.bulkReports.downloadListOfContacts(selectedReportId, type);
                }
            }
        });
        Object.defineProperty(this, "onDownloadAllData", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { selectedReportId } = this.state;
                if (selectedReportId) {
                    this.props.store.bulkReports.downloadAllData(selectedReportId);
                }
            }
        });
        Object.defineProperty(this, "onLoadMore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const query = this._createQuery();
                this.props.store.bulkReports.fetchBulkReports(query, true);
            }
        });
        Object.defineProperty(this, "onSendNewBulkMessage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.props.toggleBulkMessageDrawer();
            }
        });
        Object.defineProperty(this, "_createQuery", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a;
                const { searchValue, dateRangeValue } = this.state;
                const beforeTimestamp = dateRangeValue[1] !== null && (((_a = dateRangeValue === null || dateRangeValue === void 0 ? void 0 : dateRangeValue[1]) === null || _a === void 0 ? void 0 : _a.unix()) * 1000).toString();
                const afterTimestamp = dateRangeValue[0] !== null && (dateRangeValue[0].unix() * 1000).toString();
                const search = searchValue.length > 2 ? searchValue : null;
                return Object.assign(Object.assign(Object.assign({}, (beforeTimestamp && { beforeTimestamp })), (afterTimestamp && { afterTimestamp })), (search && { search }));
            }
        });
        this.state = {
            searchValue: "",
            dateRangeValue: [null, null],
            selectedReportId: null,
        };
    }
    componentDidMount() {
        this.props.store.bulkReports.fetchScheduledReports();
    }
    componentDidUpdate(prevProps, prevState) {
        const { searchValue, dateRangeValue } = this.state;
        const query = this._createQuery();
        const search = searchValue.length > 2 ? searchValue : null;
        if (prevState.dateRangeValue !== dateRangeValue || prevState.searchValue !== searchValue) {
            if (prevState.searchValue !== searchValue && !search && searchValue !== "")
                return;
            this.props.store.bulkReports.fetchBulkReports(query);
        }
        if (!prevProps.isOpen && this.props.isOpen) {
            this.props.store.bulkReports.fetchBulkReports();
        }
    }
    render() {
        const { toggleBulkReportsDrawer, isOpen, store, i18n } = this.props;
        const { searchValue, dateRangeValue, selectedReportId } = this.state;
        return (React.createElement(Drawer, { open: isOpen, onClose: () => toggleBulkReportsDrawer(), anchor: getDirection() === "rtl" ? "right" : "left", dir: getDirection(), style: { zIndex: 100 }, classes: {
                root: s["message-drawer__root"],
                paperAnchorLeft: s["message-drawer__paperAnchorLeft"],
                paperAnchorRight: s["message-drawer__paperAnchorRight"],
            } },
            React.createElement("div", { className: classNames(s["message-drawer__container"], {
                    [s.rtl]: getDirection() === "rtl",
                }) },
                React.createElement("div", { className: s["message-drawer__header"] },
                    React.createElement("div", { className: classNames(s["message-drawer__back"], {
                            [s.rtl]: getDirection() === "rtl",
                        }) },
                        React.createElement(ListItemIcon, { onClick: () => toggleBulkReportsDrawer() },
                            React.createElement(BsArrowLeft, null)),
                        React.createElement("span", { className: s["message-drawer__title"] },
                            React.createElement(Trans, { i18nKey: "bulkMessagesReports" })))),
                React.createElement("div", { className: s["message-drawer__selector"] },
                    React.createElement(ErrorBoundary, null,
                        React.createElement(ReportsSelector, { reportsList: [
                                ...store.bulkReports.reportsList,
                                ...store.bulkReports.scheduledReportsList
                            ].sort(sortReportsByDate), searchValue: searchValue, setSearchValue: this.setSearchValue, dateRangeValue: dateRangeValue, setDateRangeValue: this.setDateRangeValue, selectedReportId: selectedReportId, selectReport: this.selectReport, onLoadMore: this.onLoadMore, onSendNewBulkMessage: this.onSendNewBulkMessage, hasMore: store.bulkReports.searchHasMore || false, loading: store.bulkReports.bulkReportsLoading }))),
                React.createElement("div", { className: s["message-drawer__main"] },
                    React.createElement(ErrorBoundary, null,
                        React.createElement(SingleReport, { report: store.bulkReports.singleReport, loading: store.bulkReports.singleBulkReportLoading, onUpdateComment: this.onUpdateComment, onUpdateTitle: this.onUpdateTitle, onDownloadContacts: this.onDownloadContacts, onDownloadAllData: this.onDownloadAllData }))))));
    }
}
export default withTranslation()(withStore(observer(BulkReportsDrawer)));
