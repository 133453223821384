import React from "react";
import { Trans } from "react-i18next";
import { Typography } from "antd";
import SubmitModal from "../../../../common/SubmitModal";
import styles from "./SubmitCancelModal.module.scss";
const { Text } = Typography;
;
const SubmitCancelModal = ({ visible, onCancel, onSubmit, i18key }) => {
    return (React.createElement(SubmitModal, { visible: visible, onCancel: onCancel, onSubmit: onSubmit, submitTextKey: "yes", alert: true },
        React.createElement("div", { className: styles.modalText },
            React.createElement(Text, { className: styles.modalText },
                React.createElement(Trans, { i18nKey: i18key })))));
};
export default SubmitCancelModal;
