var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useRef } from 'react';
import { Button } from "antd";
import classnames from "classnames";
import styles from "./CommonButton.module.scss";
const CommonButton = (props) => {
    const { action, type, submit, alert, children, disabled, loading, additionalStyles, style, onMouseEnter, onMouseLeave } = props, rest = __rest(props, ["action", "type", "submit", "alert", "children", "disabled", "loading", "additionalStyles", "style", "onMouseEnter", "onMouseLeave"]);
    const buttonRef = useRef(null);
    return (React.createElement(Button, Object.assign({}, rest, { ref: buttonRef, className: classnames(styles.button, additionalStyles, { [styles.white]: !submit && !alert && !disabled, [styles.green]: submit && !disabled, [styles.red]: alert && !disabled, [styles.disabled]: disabled }), style: style, disabled: disabled, htmlType: type, loading: loading, onMouseEnter: onMouseEnter, onMouseLeave: onMouseLeave, onClick: (e) => {
            var _a;
            (_a = buttonRef.current) === null || _a === void 0 ? void 0 : _a.blur();
            action(e);
        } }), children));
};
export default CommonButton;
