import React from 'react';
import { Typography } from "antd";
import { QuestionCircleOutlined } from "@ant-design/icons";
import { Trans } from "react-i18next";
import styles from "./InfoBlock.module.scss";
const { Text } = Typography;
const InfoBlock = () => {
    return (React.createElement("div", { className: styles.container },
        React.createElement(QuestionCircleOutlined, { className: styles.icon }),
        React.createElement(Text, null,
            React.createElement(Trans, { i18nKey: "templatePreviewInfo", components: { span: React.createElement("span", { className: styles.whatsText }) } }))));
};
export default InfoBlock;
