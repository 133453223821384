import { Button, Upload, message } from 'antd';
import { Trans } from 'react-i18next';
import { debounce, pick } from 'lodash';
import Icon from '@ant-design/icons';
import React from 'react';
import { MEDIA_TYPE } from '@whatsper/texterchat-common';
import classNames from 'classnames';
import { ReactComponent as DeleteIcon } from '../../../assets/icons/deleteIcon/deleteIcon.svg';
import { ReactComponent as QuickRepliesPlus } from '../../../assets/icons/plus/quickRepliesPlus.svg';
import Preview from './Preview';
import { checkFileMaxSize } from '../ChatWindow/helpers';
import { fileEnsureUid } from '../../../helpers/file';
import s from './FilePreview.module.scss';
const Controls = ({ files, selectedFileUid, onRemove, onAdd, onSelect, pageHeight, onPDFLoadSuccess, mediaLimits, }) => {
    var _a;
    if (files && files.length < 1)
        return null;
    let accept = Object.values(pick(mediaLimits, ['video', 'image'])).map(({ types }) => types);
    if (mediaLimits === null || mediaLimits === void 0 ? void 0 : mediaLimits.audio)
        accept.push(mediaLimits.audio.types);
    if ((_a = mediaLimits === null || mediaLimits === void 0 ? void 0 : mediaLimits.document) === null || _a === void 0 ? void 0 : _a.types) {
        accept.push(mediaLimits.document.types);
    }
    else {
        accept = ['*'];
    }
    const onAddFiles = debounce((info) => {
        const rawFiles = info.fileList.map((f) => f.originFileObj);
        if (!beforeUpload(rawFiles))
            return;
        const files = rawFiles.map((file) => {
            let type = file.type.split('/')[0];
            if (!Object.values(MEDIA_TYPE).includes(type)) {
                type = MEDIA_TYPE.DOC;
            }
            return { file: fileEnsureUid(file), type };
        });
        onAdd(files);
        /** onChange called once per file for multi select, this is a solution until fix/update */
    }, 0);
    const beforeUpload = (files) => {
        const checkStatuses = files.map((file) => {
            if (mediaLimits) {
                const maxSizeError = checkFileMaxSize(file, mediaLimits);
                if (maxSizeError) {
                    message.error(React.createElement(Trans, { i18nKey: maxSizeError.key, values: { size: maxSizeError.size } }));
                    return false;
                }
            }
            return true;
        });
        return checkStatuses.every((check) => check);
    };
    return (React.createElement("div", { className: s.controlsContainer },
        files.map(({ file, type }) => {
            const selected = selectedFileUid === file.uid;
            return (React.createElement("div", { className: classNames(s.controlsBoxPreview, {
                    [s.selected]: selected,
                }), onClick: () => {
                    if (selected) {
                        onRemove(file.uid);
                    }
                    else {
                        onSelect(file, type);
                    }
                }, key: file.uid },
                React.createElement(Preview, { file: file, type: type, pageHeight: pageHeight, onPDFLoadSuccess: onPDFLoadSuccess, compact: true }),
                React.createElement(Icon, { className: s.deleteIcon, component: DeleteIcon })));
        }),
        React.createElement(Upload, { showUploadList: false, beforeUpload: () => false, onChange: onAddFiles, accept: accept.join(','), multiple: true },
            React.createElement(Button, { className: s.controlsAdd, icon: React.createElement(QuickRepliesPlus, null) }))));
};
export default Controls;
