var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { throttle } from "lodash";
import { action, makeObservable, observable, reaction } from "mobx";
import { getAxios } from '../../backend';
import { API_PATH } from "../../constants";
const windowActivityEvents = ['mousedown', 'mousemove', 'keypress', 'scroll', 'touchstart'];
class StatusStore {
    constructor(rootStore) {
        Object.defineProperty(this, "rootStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "awayTimeoutId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "beforeCountdownTimeoutId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "heartbeatIntervalId", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        // to minimize writes count write status
        // not more often than heartbeat
        Object.defineProperty(this, "statusLatestSave", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: 0
        });
        Object.defineProperty(this, "settings", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "status", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ACTIVITY_STATUS.ONLINE
        });
        Object.defineProperty(this, "logoutCountdown", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "setup", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                console.debug('Setting up status store listeners and timers');
                this.onActivity();
                this.setupHeartbeat();
                this.attachActivityListeners();
            }
        });
        Object.defineProperty(this, "shutdown", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                console.debug('Shutting down status store listeners and timers');
                this.detachActivityListeners();
                this.setCountdownEnabled(false);
                this.stopHeartbeat();
                this.clearCountdownTimeouts();
            }
        });
        Object.defineProperty(this, "onActivity", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: throttle(() => {
                var _a;
                if (!this.rootStore.loginStore.sessionActive) {
                    return;
                }
                this.clearCountdownTimeouts();
                this.setCountdownEnabled(false);
                this.setStatus(ACTIVITY_STATUS.ONLINE);
                if ((_a = this.settings) === null || _a === void 0 ? void 0 : _a.awayTimeout) {
                    this.awayTimeoutId = window.setTimeout(() => this.onAway(), this.settings.awayTimeout);
                }
                else {
                    console.warn('Away timeout is not set');
                }
            }, 1000, { leading: true })
        });
        Object.defineProperty(this, "attachActivityListeners", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                windowActivityEvents.forEach((event) => {
                    window.addEventListener(event, this.onActivity, true);
                });
            }
        });
        Object.defineProperty(this, "detachActivityListeners", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                windowActivityEvents.forEach((event) => {
                    window.removeEventListener(event, this.onActivity, true);
                });
            }
        });
        makeObservable(this);
        this.rootStore = rootStore;
        this.settings = Object.assign({}, this.rootStore.generalSettings.user);
        reaction(() => this.rootStore.generalSettings.user, () => {
            this.settings = Object.assign({}, this.rootStore.generalSettings.user);
            if (this.rootStore.loginStore.sessionActive) {
                this.setup();
            }
        });
        reaction(() => this.rootStore.loginStore.sessionActive, () => {
            if (this.rootStore.loginStore.sessionActive) {
                this.setup();
            }
            else {
                this.shutdown();
            }
        });
        if (this.rootStore.loginStore.sessionActive) {
            this.setup();
        }
    }
    setupHeartbeat() {
        var _a;
        this.stopHeartbeat();
        if ((_a = this.settings) === null || _a === void 0 ? void 0 : _a.heartbeatInterval) {
            this.heartbeatIntervalId = window.setInterval(() => {
                var _a;
                if ((_a = this.settings) === null || _a === void 0 ? void 0 : _a.heartbeatInterval) {
                    if (this.statusLatestSave + this.settings.heartbeatInterval <= Date.now()) {
                        if (this.status !== ACTIVITY_STATUS.OFFLINE) {
                            this.saveStatus(this.status);
                        }
                        else {
                            this.stopHeartbeat();
                        }
                    }
                }
                else {
                    console.warn('Heartbeat interval is not set [2]');
                }
            }, this.settings.heartbeatInterval);
        }
        else {
            console.warn('Heartbeat interval is not set [1]');
        }
    }
    stopHeartbeat() {
        if (null !== this.heartbeatIntervalId) {
            clearInterval(this.heartbeatIntervalId);
        }
    }
    /**
     * Clear timeouts counting down before setting user away or starting logout countdown
     */
    clearCountdownTimeouts() {
        if (null !== this.awayTimeoutId) {
            clearTimeout(this.awayTimeoutId);
            this.awayTimeoutId = null;
        }
        if (null !== this.beforeCountdownTimeoutId) {
            clearTimeout(this.beforeCountdownTimeoutId);
            this.beforeCountdownTimeoutId = null;
        }
    }
    onAway() {
        var _a;
        console.debug('User is away');
        this.clearCountdownTimeouts();
        this.setStatus(ACTIVITY_STATUS.AWAY);
        if ((_a = this.settings) === null || _a === void 0 ? void 0 : _a.logoutCountdownStart) {
            this.beforeCountdownTimeoutId = window.setTimeout(() => this.setCountdownEnabled(true), this.settings.logoutCountdownStart);
        }
        else {
            console.warn('Logout countdown start is not set');
        }
    }
    setStatus(status) {
        let changed = status !== this.status;
        this.status = status;
        if (changed && status !== ACTIVITY_STATUS.OFFLINE) {
            this.saveStatus(status);
        }
    }
    saveStatus(status) {
        var _a;
        if ((_a = this.rootStore.loginStore.userDetails) === null || _a === void 0 ? void 0 : _a.uid) {
            getAxios()
                .then((axios) => {
                return axios.post(API_PATH.HEARTBEAT, { status });
            })
                .then(() => {
                this.statusLatestSave = new Date().getTime();
            })
                .catch((error) => {
                console.error(error);
            });
        }
    }
    setCountdownEnabled(enabled) {
        this.logoutCountdown = enabled;
    }
}
__decorate([
    observable
], StatusStore.prototype, "settings", void 0);
__decorate([
    observable
], StatusStore.prototype, "status", void 0);
__decorate([
    observable
], StatusStore.prototype, "logoutCountdown", void 0);
__decorate([
    action
], StatusStore.prototype, "setStatus", null);
__decorate([
    action
], StatusStore.prototype, "setCountdownEnabled", null);
export var ACTIVITY_STATUS;
(function (ACTIVITY_STATUS) {
    // Connected and active during ACTIVITY_TIMEOUTS.AWAY
    ACTIVITY_STATUS["ONLINE"] = "online";
    // Connected and inactive during ACTIVITY_TIMEOUTS.AWAY
    ACTIVITY_STATUS["AWAY"] = "away";
    // Logged out or not connected 
    ACTIVITY_STATUS["OFFLINE"] = "offline";
})(ACTIVITY_STATUS || (ACTIVITY_STATUS = {}));
export default StatusStore;
