var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { action, observable, runInAction, makeObservable } from 'mobx';
import { getAxios } from '../../backend';
import { API_PATH } from '../../constants';
class ChatNotesStore {
    constructor(rootStore) {
        Object.defineProperty(this, "rootStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "notesListLoading", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "notesList", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: observable.array()
        });
        Object.defineProperty(this, "getChatNotes", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (chatId) => __awaiter(this, void 0, void 0, function* () {
                const axios = yield getAxios();
                try {
                    if (!chatId)
                        return;
                    runInAction(() => {
                        this.notesList.clear();
                        this.notesListLoading = true;
                    });
                    const { data } = yield axios.get(`${API_PATH.CHATS}/${chatId}/notes`);
                    runInAction(() => {
                        this.notesList.replace(data);
                    });
                }
                catch (error) {
                    console.error(`Couldn't fetch chat notes: ${error.message}`);
                }
                finally {
                    runInAction(() => {
                        this.notesListLoading = false;
                    });
                }
            })
        });
        Object.defineProperty(this, "addChatNote", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (noteText) => __awaiter(this, void 0, void 0, function* () {
                var _a;
                if (!noteText) {
                    throw new Error('Note text is empty');
                }
                const axios = yield getAxios();
                const { data } = yield axios.post(`${API_PATH.CHATS}/${(_a = this.rootStore.activeChatStore.chat) === null || _a === void 0 ? void 0 : _a._id}/notes`, {
                    text: noteText
                });
                runInAction(() => {
                    this.notesList.push(data);
                });
            })
        });
        Object.defineProperty(this, "deleteChatNote", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (noteId) => __awaiter(this, void 0, void 0, function* () {
                var _b;
                const axios = yield getAxios();
                yield axios.delete(`${API_PATH.CHATS}/${(_b = this.rootStore.activeChatStore.chat) === null || _b === void 0 ? void 0 : _b._id}/notes/${noteId}`);
                runInAction(() => {
                    this.notesList.replace(this.notesList.filter(note => String(note._id) !== noteId));
                });
            })
        });
        makeObservable(this);
        this.rootStore = rootStore;
    }
}
__decorate([
    observable
], ChatNotesStore.prototype, "notesListLoading", void 0);
__decorate([
    action
], ChatNotesStore.prototype, "getChatNotes", void 0);
__decorate([
    action
], ChatNotesStore.prototype, "addChatNote", void 0);
__decorate([
    action
], ChatNotesStore.prototype, "deleteChatNote", void 0);
export default ChatNotesStore;
