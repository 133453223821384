import Autolinker from 'autolinker';
import React from 'react';
export default function MessageMarkdown({ text }) {
    const { children } = parseMarkdown(markdownLinks(text));
    return React.createElement(React.Fragment, null, children.map((child, index) => React.createElement(Node, { key: index, node: child })));
}
function Node({ node }) {
    const renderText = (t) => {
        return React.createElement(React.Fragment, null, t.split('\n').map((line, index, a) => {
            return React.createElement(React.Fragment, { key: index },
                line,
                index < a.length - 1 && React.createElement("br", null));
        }));
    };
    const content = () => {
        return React.createElement(React.Fragment, null,
            node.text && renderText(node.text),
            node.children && node.children.map((child, index) => React.createElement(Node, { key: index, node: child })));
    };
    const linkAttrs = () => {
        if (!node.text) {
            return {};
        }
        const [href, anchor] = node.text.split('|') || [];
        return { href, anchor };
    };
    if (node.type) {
        switch (node.type) {
            case NodeType.bold:
                return React.createElement("b", null, content());
            case NodeType.italic:
                return React.createElement("i", null, content());
            case NodeType.strikethrough:
                return React.createElement("s", null, content());
            case NodeType.monospace:
                return React.createElement("code", null, content());
            case NodeType.link:
                const { href, anchor } = linkAttrs();
                return React.createElement("a", { href: href, target: "_blank", rel: "noopener noreferrer" }, anchor);
        }
    }
    else {
        return content();
    }
}
var NodeType;
(function (NodeType) {
    // none = 'none',
    NodeType["bold"] = "bold";
    NodeType["italic"] = "italic";
    NodeType["strikethrough"] = "strikethrough";
    NodeType["monospace"] = "monospace";
    NodeType["link"] = "link";
})(NodeType || (NodeType = {}));
/** Common parse spec for bold, italic and strikethrough */
const bisCommon = {
    charCount: 1,
    beforeOpeningChars: /[^a-zA-Z0-9]/,
    afterOpeningChars: /\S/,
    beforeClosingChars: /\S/,
    afterClosingChars: /[^a-zA-Z0-9]/,
};
const types = {
    [NodeType.bold]: Object.assign(Object.assign({}, bisCommon), { char: '*' }),
    [NodeType.italic]: Object.assign(Object.assign({}, bisCommon), { char: '_' }),
    [NodeType.strikethrough]: Object.assign(Object.assign({}, bisCommon), { char: '~' }),
    [NodeType.monospace]: {
        char: '`',
        charCount: 3,
        noChildren: true,
        noParent: true,
        multiline: true,
    },
    [NodeType.link]: {
        char: '^',
        charCount: 3,
        noChildren: true,
    },
};
const charMap = Object.fromEntries(Object.entries(types).map(([key, value]) => [value.char, key]));
export function markdownLinks(text) {
    return Autolinker.link(text, {
        replaceFn: function (match) {
            return `^^^${match.getAnchorHref()}|${match.getAnchorText()}^^^`;
        },
        urls: {
            schemeMatches: true,
            tldMatches: true
        },
        email: true,
        phone: true,
        mention: false,
        hashtag: false,
        stripPrefix: false,
        newWindow: true,
    });
}
/**
 * Parse markdown text to tokens tree
 *
 * @param fullText
 * @returns
 */
export function parseMarkdown(fullText) {
    var _a, _b, _c;
    const tree = {
        children: [],
    };
    let charSequences = [], 
    /** Current iteration character index in full text */
    charIndex = 0, 
    /** Nodes to put inside higher level block */
    childNodes = [], 
    /** Definition of opened block that accept no children */
    currentNoChildrenType = null, 
    /** Count of control chars opened before this iteration */
    controlChar = 0, 
    /**
     * Contains previous iteration character, if it wasn't double already, to check if current character is double.
     * After found double, in such iteration set to empty string instead of previous character.
     */
    charDouble = '';
    charLoop: while (charIndex < fullText.length) {
        // WARNING: This will split some unicode characters like emojis to different characters
        // For example 👨‍👨‍👧 will be split to 👨, ‍, 👨, ‍, 👧
        // This is not a problem for us, because of limited set of characters we need, but it's something to keep in mind
        const char = fullText[charIndex];
        if (char !== charDouble
            && charMap[char]
            && (!currentNoChildrenType || currentNoChildrenType.char === char)) {
            const type = charMap[char], typeDef = types[type];
            // Check that after current char satisfied char repeat count for this type.
            // For example if block requires "```" then check that next 2 chars are "`"
            if (checkForwardRepeat(fullText, char, charIndex, typeDef.charCount)) {
                // First formatting block opens
                if (!controlChar) {
                    // If there exist sequences and text is not empty
                    // then put it to tree as text node and reset sequences
                    if (charSequences.length) {
                        const text = charSequences.map(({ s }) => s).join('');
                        if (text.length) {
                            tree.children.push({
                                text,
                            });
                        }
                        charSequences = [];
                    }
                    // If there was child nodes put them to tree and reset child nodes
                    if (childNodes.length) {
                        tree.children.push(...childNodes);
                        childNodes = [];
                    }
                }
                controlChar++;
                /** Characters control sequence, containing required count of characters */
                const seq = char.repeat(typeDef.charCount);
                let si = charSequences.length - 1;
                /** Is block opening sequence found */
                let matched = false;
                // Iterating from latest sequence to first
                sequencesLoop: while (si >= 0) {
                    if (charSequences[si] && charSequences[si].s.startsWith(seq)) {
                        const closeCharIndex = charIndex;
                        // Get matching block sequences
                        const sequences = charSequences.slice(si);
                        if (sequences.length === 0) {
                            break sequencesLoop;
                        }
                        // Need to keep first sequence separately,
                        // because it could include unformatted text,
                        // that should be inserted before child formats nodes
                        let { s: startText, i: openCharIndex } = sequences.shift();
                        let endText = sequences.map(({ s }) => s).join('');
                        let allText = startText + endText;
                        // Can't be closed if not multiline and contains new line
                        if (!typeDef.multiline && allText.includes('\n')) {
                            break sequencesLoop;
                        }
                        // Remove matched sequences from charSequences
                        charSequences.splice(si);
                        // Move iteration forward for more then 1 char in cases like "```"
                        charIndex += typeDef.charCount;
                        // We incremented controlChar for this iteration already,
                        // so because this is closing pair, need to decrement it by 2,
                        // since we pulling also opening char
                        controlChar = controlChar - 2;
                        /** Character right before opening control character of this block */
                        let prevChar = '';
                        // Getting form previous sequence if present
                        if (charSequences.length) {
                            const { s } = charSequences[si - 1];
                            if (s.length) {
                                prevChar = s[s.length - 1];
                            }
                            // Getting from previous root tree text child node if present
                        }
                        else if (tree.children.length) {
                            const lastChild = tree.children[tree.children.length - 1];
                            if (lastChild.text) {
                                prevChar = lastChild.text[lastChild.text.length - 1];
                            }
                        }
                        if (
                        // Check that node text is not empty
                        (allText.length > seq.length || childNodes.length)
                            && (!typeDef.beforeOpeningChars
                                || !prevChar
                                || (
                                // Check that last char of previous sequence satisfies "beforeOpeningChars" condition
                                prevChar && prevChar.match(typeDef.beforeOpeningChars)))
                            && (!typeDef.afterOpeningChars
                                // In case if there exists child nodes, then not need to check character after opening
                                || childNodes.length
                                || !allText[seq.length]
                                || allText[seq.length].match(typeDef.afterOpeningChars))
                            && (!typeDef.beforeClosingChars
                                || childNodes.length
                                || allText[allText.length - 1].match(typeDef.beforeClosingChars))
                            && (!typeDef.afterClosingChars
                                || fullText.length === charIndex
                                || fullText[charIndex].match(typeDef.afterClosingChars))) {
                            startText = startText.slice(seq.length);
                            let node;
                            if (typeDef.noChildren) {
                                node = {
                                    type,
                                    text: startText + endText,
                                    openCharIndex,
                                    closeCharIndex,
                                };
                            }
                            else {
                                node = {
                                    type,
                                    children: [],
                                    openCharIndex,
                                    closeCharIndex,
                                };
                                if (startText.length) {
                                    (_a = node.children) === null || _a === void 0 ? void 0 : _a.push({
                                        text: startText,
                                    });
                                }
                                if (childNodes.length) {
                                    (_b = node.children) === null || _b === void 0 ? void 0 : _b.push(...childNodes.filter((n) => {
                                        return !n.closeCharIndex || n.closeCharIndex > openCharIndex;
                                    }));
                                    childNodes = childNodes.filter((n) => {
                                        return n.closeCharIndex && n.closeCharIndex < openCharIndex;
                                    });
                                }
                                if (endText.length) {
                                    (_c = node.children) === null || _c === void 0 ? void 0 : _c.push({
                                        text: endText,
                                    });
                                }
                            }
                            if (!controlChar || typeDef.noParent || fullText.length === charIndex) {
                                if (charSequences.length) {
                                    tree.children.push(...charSequences
                                        .filter(({ s }) => s.length)
                                        .map(({ s, i }) => {
                                        return {
                                            text: s,
                                            openCharIndex: i,
                                        };
                                    }));
                                    charSequences = [];
                                }
                                tree.children.push(node);
                            }
                            else {
                                if (charSequences.length > controlChar) {
                                    const text = charSequences.splice(controlChar).map(({ s }) => s).join('');
                                    if (text.length) {
                                        childNodes.push({
                                            text,
                                        });
                                    }
                                }
                                childNodes.push(node);
                            }
                        }
                        else {
                            endText += seq;
                            const text = startText + endText;
                            if (text.length) {
                                childNodes.push({
                                    text,
                                });
                            }
                        }
                        matched = true;
                        break sequencesLoop;
                    }
                    si--;
                }
                if (matched) {
                    currentNoChildrenType = null;
                    // Start new sequence after previous block
                    charSequences.push({ s: '', i: charIndex });
                    continue charLoop;
                }
                else {
                    if (typeDef.noChildren) {
                        currentNoChildrenType = typeDef;
                    }
                    // If there exist sequences and previous sequence is not empty
                    // then start new sequence for control character
                    if (charSequences.length && charSequences[charSequences.length - 1].s.length) {
                        charSequences.push({ s: '', i: charIndex });
                    }
                }
            }
        }
        if (!charSequences.length) {
            charSequences.push({ s: '', i: charIndex });
        }
        charSequences[charSequences.length - 1].s += char;
        charIndex++;
        if (char === charDouble) {
            charDouble = '';
        }
        else {
            charDouble = char;
        }
    }
    if (charSequences.length && !(charSequences.length === 1 && charSequences[0].s === '')) {
        childNodes.push(...charSequences.map(({ s, i }) => {
            return {
                text: s,
                openCharIndex: i,
            };
        }));
    }
    if (childNodes.length) {
        tree.children.push(...childNodes);
    }
    tree.children.sort((a, b) => {
        if (a.openCharIndex === undefined || b.openCharIndex === undefined) {
            return 0;
        }
        return a.openCharIndex - b.openCharIndex;
    });
    return tree;
}
/**
 * Check if "char" repeats "repeat" times in "text", starting from "start" index
 *
 * @param text Text to check
 * @param char Char to check
 * @param start Char first position
 * @param repeat Total char repeats required
 * @returns TRUE if char repeats "repeat" times, FALSE otherwise
 */
function checkForwardRepeat(text, char, start, repeat) {
    let i = start;
    while (repeat) {
        if (text[i] !== char) {
            return false;
        }
        i++;
        repeat--;
    }
    return true;
}
