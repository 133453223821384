import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Trans } from 'react-i18next';
import { Select } from 'antd';
import { withStore } from '../../../../../store/rootStore';
import { FILTER_DEPARTMENTS } from '../../../../../constants';
import { MySelect } from '../../../../Base/Form';
import './DepFilter.css';
const { Option } = Select;
class DepartmentsFilter extends Component {
    render() {
        const { departments } = this.props.store.departmentsStore;
        const { currentDepartmentId, setDepartmentFilter, size, disabled } = this.props;
        return (React.createElement(React.Fragment, null,
            React.createElement(MySelect, { className: "filter-departments-dropdown-select", size: size, defaultValue: FILTER_DEPARTMENTS.ALL_DEPARTMENTS, value: currentDepartmentId ? currentDepartmentId : FILTER_DEPARTMENTS.ALL_DEPARTMENTS, onChange: setDepartmentFilter, disabled: disabled },
                React.createElement(Option, { value: FILTER_DEPARTMENTS.ALL_DEPARTMENTS },
                    React.createElement("div", { style: { backgroundColor: '#FFFFFF', border: '1px solid lightgrey' }, className: "filter-departments-option-color" }),
                    React.createElement("p", { className: "filter-departments-option-text" },
                        React.createElement(Trans, { i18nKey: "allDepartments" }))),
                departments.map((department, i) => {
                    return (React.createElement(Option, { key: i, className: "filter-departments-chats", value: department._id },
                        React.createElement("div", { style: { backgroundColor: department.color, border: '1px solid transparent' }, className: "filter-departments-option-color" }),
                        React.createElement("p", { className: "filter-departments-option-text" }, department.name)));
                }))));
    }
}
export default withStore(observer(DepartmentsFilter));
