import React from 'react';
import { Auth } from '@whatsper/texterchat-common';
import { useStore } from '../../../store/rootStore';
import Password from './methods/Password';
import OTP from './methods/OTP';
const LoginMethod = ({ method, shouldUpdatePassword }) => {
    const { loginStore, resetPassword } = useStore();
    switch (method) {
        case Auth.Methods.Method.password:
            return (React.createElement(Password, { shouldUpdatePassword: shouldUpdatePassword, onSubmit: loginStore.loginWithPassword, showPasswordReset: loginStore.showPasswordReset, resetPassword: resetPassword }));
        case Auth.Methods.Method.whatsapp_otp:
            return React.createElement(OTP, { method: Auth.Methods.Method.whatsapp_otp, onSubmit: loginStore.loginWithWhatsAppOTP });
        case Auth.Methods.Method.totp:
            return React.createElement(OTP, { method: Auth.Methods.Method.totp, onSubmit: loginStore.loginWithTOTP });
        default:
            console.error('Unsupported login method: ' + method);
            // TODO: Better error message
            return React.createElement("div", null, "Login method not supported");
    }
};
export default LoginMethod;
