import React from 'react';
import css from "./style.module.css";
import classNames from 'classnames';

export default function HightLayout(props) {
    const { isActive, onClick } = props;
    return (
        <>
            <div className={
                classNames({
                    [css.layout]: true,
                    [css.active]: isActive

                })}
            >
                {props.children}
            </div>
            <div className={
                classNames({
                    ["background"]: true,
                    ["active"]: isActive

                })}
                onClick={() => onClick()}
            ></div>
        </>
    )
}
