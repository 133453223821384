import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { LeftOutlined, RightOutlined } from '@ant-design/icons';
import PhoneFormatter from '../../../../Base/Phone/Formatter';
import { withStore } from '../../../../../store/rootStore';
import { getDirection } from '../../../../../helpers';
import s from './CSVNumbersList.module.scss';
const CSVNumbersList = ({ store: { wizardStore: { exampleTemplates, updateExampleTemplatesStep, exampleTemplatesStep }, }, }) => {
    const decreaseStep = () => {
        if (exampleTemplatesStep > 0) {
            updateExampleTemplatesStep(exampleTemplatesStep - 1);
        }
    };
    const increaseStep = () => {
        if (exampleTemplatesStep < exampleTemplates.length - 1) {
            updateExampleTemplatesStep(exampleTemplatesStep + 1);
        }
    };
    useEffect(() => {
        return () => {
            updateExampleTemplatesStep(0);
        };
    }, []);
    return (React.createElement("div", { className: classNames(s.wrapper, {
            [s.rtl]: getDirection() === 'rtl',
        }) },
        React.createElement("p", { className: s.numberText },
            React.createElement(PhoneFormatter, { phone: exampleTemplates[exampleTemplatesStep].number })),
        React.createElement("div", { className: s.switchBlock },
            React.createElement("button", { className: s.switchButton, onClick: decreaseStep, disabled: exampleTemplatesStep === 0 },
                React.createElement(LeftOutlined, null)),
            React.createElement("p", { className: s.stepsText }, `${exampleTemplatesStep + 1}/${exampleTemplates.length}`),
            React.createElement("button", { className: s.switchButton, onClick: increaseStep, disabled: exampleTemplatesStep === exampleTemplates.length - 1 },
                React.createElement(RightOutlined, null)))));
};
export default withStore(observer(CSVNumbersList));
