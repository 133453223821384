import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import { ExclamationCircleOutlined, LoadingOutlined } from '@ant-design/icons';
import { Trans } from 'react-i18next';
import { WhatsApp } from '@whatsper/texterchat-common';
import { useStore } from '../../../../store/rootStore';
import { CHAT_STATUS } from '../../../../constants';
import TemplatesListFilterable from '../../../Templates/TemplateListFilterable';
import s from './FirstPage.module.scss';
const FirstPage = ({ selectedAccountId }) => {
    const { wizardStore, templateMessagesStore } = useStore();
    useEffect(() => {
        /* Clear store cache if user navigates back from a different template */
        wizardStore.resetStore(false);
    }, []);
    const chooseHandle = (template) => {
        templateMessagesStore.setStoreToDefault();
        wizardStore.setTemplate(Object.assign({}, template));
        wizardStore.changeWizardStep(1);
        wizardStore.setUploadedFile({});
        templateMessagesStore.setTemplateEdited(template);
        if (template.chatStatus) {
            let status = template.chatStatus;
            if (template.chatStatus === CHAT_STATUS.PENDING) {
                status = CHAT_STATUS.BOT;
            }
            templateMessagesStore.handleSetStatus(status);
        }
    };
    const templates = selectedAccountId
        ? templateMessagesStore.getOrLoadTemplateMessagesListByAccountId(selectedAccountId.id).filter((template) => {
            const { status } = template.obj.localizations[0];
            return status === WhatsApp.TemplateLocalizationStatus.APPROVED;
        })
        : [];
    return (React.createElement("div", { className: s['container'] },
        React.createElement("div", { className: s['wizard-warning-block'] },
            React.createElement(ExclamationCircleOutlined, null),
            React.createElement("p", { className: s['bulkWizard'] },
                React.createElement(Trans, { i18nKey: "templateAdvancedSettingsExplanationP1" }))),
        templateMessagesStore.templateMessagesLoading && (React.createElement("div", { className: s['loading__container'] },
            React.createElement(LoadingOutlined, { className: s['loading-icon'] }),
            React.createElement("p", null, "Loading templates..."))),
        !templateMessagesStore.templateMessagesLoading && (React.createElement(TemplatesListFilterable, { templates: templates, onClicked: chooseHandle, selected: wizardStore.chosenTemplate ? wizardStore.chosenTemplate.name : undefined, loading: templateMessagesStore.templateMessagesLoading, noHeader: true }))));
};
export default observer(FirstPage);
