import React, { useState } from 'react';
import { Tabs } from 'antd';
import { Trans } from 'react-i18next';
import Icon from '@ant-design/icons';
import { ReactComponent as DropdownSvg } from '../../assets/icons/arrows/dropdown.svg';
import { ChromePicker } from 'react-color';
import { ImCross } from 'react-icons/im';
import { getDirection } from '../../helpers';
import classNames from 'classnames';
import s from './ColorSelector.module.scss';
const { TabPane } = Tabs;
export const ColorSelector = ({ onChange, color, colors, showNoColor }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedColor, selectColor] = useState(color);
    const isRTL = getDirection() === 'rtl';
    const handleSelectColor = (color) => {
        selectColor(color);
        onChange(color);
    };
    return (React.createElement("div", { className: s.wrapper },
        React.createElement("div", { className: s.inputColor, onClick: () => setIsOpen(!isOpen) },
            React.createElement("div", { className: s.colorIndicator, style: { backgroundColor: selectedColor } }, !color && showNoColor && React.createElement(Trans, { i18nKey: "noColor" })),
            React.createElement(Icon, { component: DropdownSvg, rotate: isOpen ? 0 : 180, className: s.openIcon })),
        isOpen && (React.createElement(Tabs, { defaultActiveKey: "1", className: "tabs" },
            React.createElement(TabPane, { tab: React.createElement(Trans, { i18nKey: "basic" }), key: "1" },
                React.createElement("div", { className: s.colorsWrapper },
                    showNoColor && (React.createElement("div", { className: classNames(s.colorItem, s.noColorItem), onClick: () => handleSelectColor('') },
                        React.createElement(ImCross, null))),
                    colors.map((x, i) => (React.createElement("div", { className: s.colorItem, key: i, style: { backgroundColor: x }, onClick: () => handleSelectColor(x) }))))),
            isRTL && React.createElement("div", { className: s.tabSpacer }),
            React.createElement(TabPane, { tab: React.createElement(Trans, { i18nKey: "custom" }), key: "2", className: s.customPicker },
                React.createElement(ChromePicker, { className: s.picker, color: selectedColor, onChange: (color) => handleSelectColor(color.hex), disableAlpha: true }))))));
};
export default ColorSelector;
