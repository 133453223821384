import React from 'react';
import ChatWindow from '../../Chat/ChatWindow';
import TopSideBar from './TopSideBar/TopSideBar';
import styles from './CenterContent.module.scss';
import ErrorBoundary from '../../ErrorBoundary';
const CenterContent = () => (React.createElement("div", { className: styles.centerContent },
    React.createElement(ErrorBoundary, null,
        React.createElement(TopSideBar, null)),
    React.createElement(ErrorBoundary, null,
        React.createElement(ChatWindow, null))));
export default CenterContent;
