import React from 'react';
import { observer } from 'mobx-react';
import { Trans, withTranslation } from 'react-i18next';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import styles from './NoChatViewPermission.module.scss';
const NoChatViewPermission = () => {
    return (React.createElement("div", { className: styles.blockerContainer },
        React.createElement("div", { className: styles.blockerInnerContainer },
            React.createElement(ExclamationCircleOutlined, { className: styles.blockerImage }),
            React.createElement("p", { className: styles.blockerTitle },
                React.createElement(Trans, { i18nKey: "noChatViewPermission" })))));
};
export default withTranslation()(observer(NoChatViewPermission));
