import { Collapse } from 'antd';
import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { Trans } from 'react-i18next';
import s from './Collapsable.module.scss';
const { Panel } = Collapse;
const Collapsable = ({ title, icon, routes, goToAppPath }) => {
    const onLinkClick = ({ url, path, onClick }) => {
        if (url) {
            window.open(url, '_blank', 'noopener noreferrer');
        }
        if (path) {
            goToAppPath === null || goToAppPath === void 0 ? void 0 : goToAppPath(path);
        }
        if (onClick) {
            onClick();
        }
    };
    return (React.createElement("div", { className: s.wrapper },
        React.createElement(Collapse, { expandIconPosition: "end", bordered: false },
            React.createElement(Panel, { header: React.createElement(List, null,
                    React.createElement(ListItem, { button: true },
                        React.createElement("div", { className: s.panelIcon },
                            React.createElement(ListItemIcon, null, icon)),
                        React.createElement(ListItemText, { primary: React.createElement(Trans, { i18nKey: title }) }))), key: "1" },
                React.createElement("div", { className: s.content }, routes.map(({ url, path, titleKey, icon, disabled, onClick }, index) => (React.createElement(List, { key: index },
                    React.createElement(ListItem, { button: true, onClick: () => onLinkClick({ url, path, onClick }), disabled: disabled },
                        React.createElement(ListItemText, { primary: React.createElement("div", { className: s.title },
                                icon,
                                React.createElement(Trans, { i18nKey: titleKey })) }))))))))));
};
export default Collapsable;
