import { v4 as uuid } from 'uuid';
export function isFileWithUid(file) {
    return typeof file.uid === 'string';
}
export function fileEnsureUid(file) {
    if (isFileWithUid(file)) {
        return file;
    }
    Object.assign(file, { uid: uuid() });
    return file;
}
