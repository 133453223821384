import React, {Component} from 'react';
import {RedoOutlined} from "@ant-design/icons"

import "./IsReadyErrorMessage.css"

class IsReadyErrorMessage extends Component {
    render() {
        return (
            <div className="someErrorContainer">
                <p className="someErrorText">Something went wrong</p>
                <RedoOutlined className="someErrorImage" onClick={()=>window.location.reload()} />
            </div>
        );
    }
}

export default IsReadyErrorMessage;
