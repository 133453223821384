import React, { useState, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchOutlined } from '@ant-design/icons';
import cross from '../../../assets/messageStatuses/Cross.png';
import List from '../List';
import styles from './styles.module.scss';
import { MyInput } from '../../Base/Form';
import Header from '../Header';
import DepartmentsSelector from '../../Chat/common/DepartmentsSelector';
import { useStore } from '../../../store/rootStore';
import { updateLocalStorage } from '../../../helpers';
import { LOCAL_STORAGE_KEYS } from '../../../constants';
const Filters = ({ search, onSearchChanged, department, onDepartmentChanged, loading }) => {
    const { i18n } = useTranslation();
    const replyRef = useRef(null);
    const onDepartmentHandler = (value) => {
        if (value === 'allDepartments') {
            onDepartmentChanged === null || onDepartmentChanged === void 0 ? void 0 : onDepartmentChanged('');
        }
        else {
            onDepartmentChanged === null || onDepartmentChanged === void 0 ? void 0 : onDepartmentChanged(value);
        }
        updateLocalStorage(LOCAL_STORAGE_KEYS.FILTERS.QUICK_REPLIES_DEPARTMENTS_FILTER, value);
    };
    return (React.createElement("div", { className: styles.filtersWrapper },
        React.createElement("div", { className: styles.search },
            React.createElement(SearchOutlined, { className: styles.chatSearchIcon }),
            React.createElement(MyInput, { value: search, onChangeHandler: onSearchChanged, type: "search", size: "middle", forwardRef: replyRef, className: styles.input, placeholder: i18n.t('searchQuickReplies'), bordered: false, loading: loading }),
            search ? (React.createElement("img", { className: styles.cleanSearchInput, src: cross, onClick: () => {
                    var _a;
                    onSearchChanged('');
                    (_a = replyRef.current) === null || _a === void 0 ? void 0 : _a.focus();
                } })) : null),
        React.createElement(DepartmentsSelector, { onChange: onDepartmentHandler, value: department || 'allDepartments', style: styles.depFilter, size: "middle" })));
};
export default function QuickRepliesListFilterable({ items, onClicked, onClose, loading }) {
    const [search, setSearch] = useState('');
    const [department, setDepartment] = useState(localStorage.getItem(LOCAL_STORAGE_KEYS.FILTERS.QUICK_REPLIES_DEPARTMENTS_FILTER) || null);
    const { departmentsStore } = useStore();
    function filterReplies() {
        return items
            .filter((reply) => {
            return reply.text.toLowerCase().includes(search.toLowerCase());
        })
            .filter((reply) => {
            var _a;
            if (department) {
                return (_a = reply.departments) === null || _a === void 0 ? void 0 : _a.includes(department);
            }
            return true;
        });
    }
    return (React.createElement("div", null,
        React.createElement(Header, { onClose: onClose }),
        React.createElement(Filters, { search: search, onSearchChanged: setSearch, department: department, onDepartmentChanged: setDepartment, loading: loading }),
        React.createElement(List, { items: filterReplies(), onClicked: onClicked, loading: loading, departmentsList: departmentsStore.departments })));
}
