import React from "react";
import { Pie } from "@ant-design/plots";
export const randomColors = ["#EE4ACA", "#546BD6", "#00DC83"]; // random colors?
const ReactionsChart = ({ reactions }) => {
    const config = {
        height: 105,
        width: 105,
        appendPadding: 0,
        data: reactions,
        angleField: "count",
        colorField: "title",
        color: randomColors,
        radius: 1,
        innerRadius: 0.7,
        interactions: [
            {
                type: "element-active",
            },
        ],
        label: undefined,
        legend: false,
        animation: false,
        statistic: undefined,
    };
    return React.createElement(Pie, Object.assign({}, config));
};
export default ReactionsChart;
