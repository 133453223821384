export default {
    "identifier": "whatsper-bot",
    "title": "Whatsper Bot",
    "start_node": "start",
    "working_time": {
        "office": {
            "sun-thu": "10:00-13:00,14:00-18:00"
        }
    },
    "prompt_retries": 3,
    "pending_message": {
        "text": "היי זה שוב אני הבוט, מקומכם בתור נשמר, נענה בהקדם.",
        "everyMins": 5
    },
    "abandoned_bot_settings": {
        "first_retry": {
            "timeout": 4,
            "text": [
                "אני עדיין מחכה לתשובה"
            ]
        },
        "abandoned": {
            "timeout": 9,
            "node": "abandoned_bot_sequence_1"
        }
    },
    "nodes": {
        "abandoned_bot_sequence_1": {
            "type": "func",
            "func_type": "crm",
            "func_id": "abandonBotLead",
            "on_complete": "abandoned_bot_sequence_2"
        },
        "abandoned_bot_sequence_2": {
            "type": "notify",
            "messages": [
                "טוב אז אני רואה שיצאת לריצה קלה 😊 אנו מעבירים את הפנייה שלך לנציג"
            ],
            "on_complete": "handoff"
        },
        "start": {
            "type": "notify",
            "messages": [
                "שלום, הבוט(אוליי להעניק שם), אני כאן כדי לעזור ולתת לך מענה מהיר בכל שאלה ובקשה. 👋🏼",
                "חשוב לעדכן כי צ'אט זה מתנהל עם החשבון העסקי הרשמי לפי התנאים המפורטים כאן https://texterchat.com/terms"
            ],
            "on_complete": "check_working_hours"
        },
        "check_working_hours": {
            "type": "func",
            "func_type": "system",
            "func_id": "checkWorkingHours",
            "on_complete": "menu_working_hours",
            "on_failure": "outside_working_hours"
        },
        "outside_working_hours": {
            "type": "prompt",
            "prompt_type": "choice",
            "choices": [
                {
                    "title": "כן",
                    "on_select": "menu_working_hours"
                },
                {
                    "title": "לא",
                    "on_select": "out_of_office_hours_handoff"
                }
            ],
            "on_failure": "agent_handoff",
            "messages": [
                "אנו מתנצלים, אך משרדינו סגורים כעת.",
                "אפשר ליצור קשר שוב בשעות הפעילות בימים א'-ה' בין השעות 08:00-16:00 האם ברצונך להמשיך בכל זאת ולהשאיר הודעה לנציג שירות שיחזור אליך בשעות הפעילות?"
            ]
        },
        "out_of_office_hours_handoff": {
            "type": "notify",
            "messages": [
                "נשמח לעזור לך בשעות הפעילות בימים א'-ה' 08:00-17:00"
            ],
            "on_complete": "resolved"
        },
        "menu_working_hours": {
            "type": "prompt",
            "prompt_type": "choice",
            "choices": [
                {
                    "title": 11111,
                    "on_select": "lead"
                },
                {
                    "title": 22222,
                    "on_select": "accounting"
                },
                {
                    "title": 33333,
                    "on_select": "agent_handoff"
                },
                {
                    "title": "הרשמה ללימודים",
                    "on_select": "agent_handoff"
                }
            ],
            "on_failure": "student_enroll",
            "messages": [
                "נשמח לעזור לך בשיא המהירות 🦌 ובנוחות של הווצאפ… 🤳🏼",
                '',
                "במה אפשר(נוכל) לסייע (לעזור) לך היום?"
            ]
        },
        "student_enroll": {
            "type": "prompt",
            "prompt_type": "choice",
            "choices": [
                {
                    "title": "תודה, הסתדרתי ברישום באתר",
                    "on_select": "no_agent_handoff"
                },
                {
                    "title": "מעבר לנציג שירות",
                    "on_select": "agent_handoff"
                }
            ],
            "on_failure": "agent_handoff",
            "messages": [
                "או לעבור לנציג שירות שיעזור לך להירשם לקורס המתאים"
            ]
        },
        "accounting": {
            "type": "prompt",
            "prompt_type": "choice",
            "choices": [
                {
                    "title": "תודה, הסתדרתי",
                    "on_select": "no_agent_handoff"
                },
                {
                    "title": "מעבר לנציג שירות",
                    "on_select": "agent_handoff"
                }
            ],
            "on_failure": "agent_handoff",
            "messages": [
                "למידע נוסף איך פותחים את מערכת המידע"
            ]
        },
        "lead": {
            "type": "prompt",
            "prompt_type": "choice",
            "choices": [
                {
                    "title": 11111
                },
                {
                    "title": 22222
                }
            ],
            "on_failure": "agent_handoff",
            "on_complete": "agent_handoff",
            "messages": [
                "NEW LEAD"
            ]
        },
        "lead_get_name": {
            "type": "prompt",
            "prompt_type": "text",
            "messages": [
                "נהדר! כבר מסיימים, נשמח להכיר אותך יותר טוב...",
                "",
                "איך קוראים לך?"
            ],
            "on_complete": "lead_get_email"
        },
        "lead_get_email": {
            "type": "prompt",
            "prompt_type": "text",
            "messages": [
                "מעולה, מה כתובת המייל?"
            ],
            "on_complete": "lead_get_address"
        },
        "lead_get_address": {
            "type": "prompt",
            "prompt_type": "text",
            "messages": [
                "מאיפה בארץ? (אזור)"
            ],
            "on_complete": "agent_handoff"
        },
        "agent_handoff": {
            "type": "func",
            "func_type": "system",
            "func_id": "checkWorkingHours",
            "on_complete": "handoff_working_hours",
            "on_failure": "handoff_outside_working_hours"
        },
        "handoff_working_hours": {
            "type": "notify",
            "messages": [
                "תודה שפנית אלינו 🙂",
                "אנו עושים הכל כדי לענות לך בהקדם. בינתיים אנו מזמינים אותך להכנס לאתר הבית שלנו לקבל מידע נוסף וגם לבצע שלל פעולות"
            ],
            "on_complete": "handoff"
        },
        "handoff_outside_working_hours": {
            "type": "notify",
            "messages": [
                "תודה רבה פנייתך התקבלה 🙂",
                "אנו מתנצלים, אך משרדינו סגורים כעת.",
                "",
                "נציג המחלקה ייצור עמך קשר בשעות הפעילות",
                "אנו עובדים בימים א' - ה' 08:00-16:00",
                "",
                "בינתיים אנו מזמינים אותך להכנס לאתר הבית שלנו לקבל מידע נוסף וגם לבצע שלל פעולות"
            ],
            "on_complete": "handoff"
        },
        "no_agent_handoff": {
            "type": "notify",
            "messages": [
                "תודה שפנית אלינו 🙂"
            ],
            "on_complete": "resolved"
        },
        "not_implemented": {
            "type": "notify",
            "messages": [
                "Sorry, Not implemented yet."
            ],
            "on_complete": "handoff"
        }
    }
};
