import React, { Component } from 'react';
import { observer } from "mobx-react";
import { Typography } from "antd";
import { Trans } from "react-i18next";
import { InfoCircleOutlined } from "@ant-design/icons";
import { withStore } from '../../../../store/rootStore';
import SubmitModal from "../../common/SubmitModal";
import styles from "./RemoveChatsModal.module.scss";
import { CHAT_STATUS } from '@whatsper/texterchat-common/constants';
import LoadingScreen from "../../../LoadingScreen";
const { Text } = Typography;
class RemoveChatsModal extends Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                loading: false,
                chatsCount: 0,
            }
        });
        Object.defineProperty(this, "componentDidMount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { hours, status } = this.props;
                const beforeTimestamp = Date.now() - (hours * (60 * 60 * 1000));
                this.setState({ loading: true });
                this.props.store.chatsStore.fetchCount({ beforeTimestamp })
                    .then((count) => {
                    this.setState({
                        loading: false,
                        chatsCount: count.status[CHAT_STATUS[status]],
                    });
                })
                    .catch((error) => {
                    console.error('Error loading chats count', error);
                    this.setState({ loading: false });
                });
            }
        });
        Object.defineProperty(this, "handleSubmit", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { status, hours } = this.props;
                return this.props.store.chatsStore.bulkResolve(status, hours)
                    .then(() => {
                    this.props.onClose();
                });
            }
        });
        Object.defineProperty(this, "renderContents", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { loading, chatsCount } = this.state, { hours } = this.props;
                if (loading) {
                    return React.createElement(LoadingScreen, { style: { position: 'static', marginBottom: '4rem' } });
                }
                return (React.createElement(React.Fragment, null,
                    React.createElement(InfoCircleOutlined, { className: styles.icon }),
                    React.createElement(Text, { className: styles.title },
                        React.createElement(Trans, { i18nKey: "resolveChatsTitle" })),
                    React.createElement(Text, { className: styles.subtitle },
                        React.createElement(Trans, { i18nKey: "resolveChatsSubtitle", values: { chats: chatsCount, hours }, components: { b: React.createElement("strong", null) } }))));
            }
        });
    }
    render() {
        const { onClose, t } = this.props;
        return (React.createElement(SubmitModal, { visible: true, onCancel: onClose, onSubmit: this.handleSubmit, submitTextKey: t("yesResolve") },
            React.createElement("div", { className: styles.wrapper }, this.renderContents())));
    }
}
export default withStore(observer(RemoveChatsModal));
