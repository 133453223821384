import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Trans } from 'react-i18next';
import { Typography, Divider } from 'antd';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { formatPhone } from '../../../Base/Phone/Formatter';
import SingleContact from './SingleContact';
import CommonModal from '../../common/CommonModal';
import { withStore } from '../../../../store/rootStore';
import blankProfilePicture from '../../../../assets/avatars/blankProfilePicture.png';
import s from './ContactMessage.module.scss';
const { Text } = Typography;
const ContactMessage = ({ contacts, invertColor, incoming, store: { activeChatStore, phoneFormat, defaultCountry }, }) => {
    var _a, _b;
    const [toggleModal, setToggleModal] = useState(false);
    const history = useHistory();
    const { theme, checkActiveChatRequested } = activeChatStore;
    const isDisabled = contacts.length === 1 && ((_a = contacts[0].phones) === null || _a === void 0 ? void 0 : _a.length) === 0;
    const getNames = () => {
        const names = contacts.map((contact) => contact.name.formatted_name);
        if (names.length > 1) {
            return React.createElement(Trans, { i18nKey: "contactNames", values: { name: names[0], number: names.length - 1 } });
        }
        else
            return names[0];
    };
    const goToChat = (phone) => {
        const number = formatPhone(phone, phoneFormat, defaultCountry);
        if (number) {
            history.push(`/contact/${number.replace(/[^\d]/g, '')}`);
            checkActiveChatRequested();
        }
    };
    const onButtonClick = () => {
        var _a;
        if (isDisabled)
            return;
        if (contacts.length === 1 && ((_a = contacts[0].phones) === null || _a === void 0 ? void 0 : _a.length) === 1) {
            goToChat(contacts[0].phones[0].phone);
        }
        else {
            setToggleModal(true);
        }
    };
    const onSingleContactClick = (phone) => {
        goToChat(phone);
    };
    return (React.createElement("div", { className: s.wrapper },
        React.createElement("div", { className: s.nameWrapper },
            React.createElement("img", { className: s.avatar, src: blankProfilePicture }),
            React.createElement(Text, { className: classNames(s.text, { [s.whiteText]: invertColor && !incoming }) }, getNames())),
        React.createElement(Divider, { className: s.divider }),
        React.createElement("div", { className: s.contactsButton, onClick: onButtonClick },
            React.createElement(Text, { className: classNames(s.buttonText, {
                    [s.invertLink]: ((_b = theme.additionalData) === null || _b === void 0 ? void 0 : _b.invertChatColor) && !incoming,
                    [s.disabled]: isDisabled,
                }) },
                React.createElement(Trans, { i18nKey: contacts.length > 1 ? 'viewAll' : 'message' }))),
        React.createElement(CommonModal, { visible: toggleModal, setModalVisible: () => setToggleModal(!toggleModal), modalAction: () => setToggleModal(!toggleModal), titleTextKey: 'contacts', modalActionText: 'ok', centerContent: false },
            React.createElement("div", { className: s.contactsList }, contacts.map((contact, i) => (React.createElement(SingleContact, { key: i, contact: contact, action: onSingleContactClick, avatarSrc: blankProfilePicture })))))));
};
export default withStore(observer(ContactMessage));
