import i18n from 'i18next';
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-xhr-backend';
import languageEN from './locate/en/translate.json';
import languageHE from './locate/he/translate.json';
// import languageRU from './locate/ru/translate.json'
i18n
    .use(XHR)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
    resources: {
        en: languageEN,
        he: languageHE,
        // ru: languageRU,
    },
    /* default language when load the website in browser */
    lng: localStorage.getItem("currentLanguage") || "he",
    /* When react i18next not finding any language to as default in borwser */
    fallbackLng: "en",
    /* debugger For Development environment */
    debug: process.env.NODE_ENV === 'development',
    ns: ["translations"],
    defaultNS: "translations",
    keySeparator: ".",
    interpolation: {
        escapeValue: false,
        formatSeparator: ","
    },
    react: {
        useSuspense: true,
        bindI18n: 'languageChanged loaded',
        bindI18nStore: 'added removed',
        nsMode: 'default'
    }
});
export default i18n;
