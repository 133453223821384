import React, { useState } from 'react';
import { SmileOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import EmojiPalette from '../../../EmojiPalette';
import { createPortal } from 'react-dom';
import useCheckIfMobile from '../../../../../helpers/hooks/useCheckIfMobile';
import styles from './SendMessageReaction.module.scss';
const SendMessageReaction = ({ onSendMessageReaction, incoming }) => {
    const [reactionMenuOpen, setReactionMenuOpen] = useState(false);
    const [portalCoords, setPortalCoords] = useState({ x: 0, y: 0 });
    const isMobile = useCheckIfMobile();
    const onEmojiSelectHandle = (emoji) => {
        onSendMessageReaction(emoji.native);
        setReactionMenuOpen(false);
    };
    const onMenuOpen = (e) => {
        e.stopPropagation();
        if (!isMobile) {
            const { x, y } = e.currentTarget.getBoundingClientRect();
            setPortalCoords({ x, y });
        }
        setReactionMenuOpen(!reactionMenuOpen);
    };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: classNames(styles.addReactionContainer, 'send-reaction-container', {
                [styles.incoming]: incoming,
            }), onClick: onMenuOpen },
            React.createElement("div", { className: styles.addReactionButton },
                React.createElement(SmileOutlined, null))),
        reactionMenuOpen &&
            createPortal(React.createElement("div", { className: styles.portalContainer, style: isMobile
                    ? {
                        bottom: 12,
                        left: 12,
                    }
                    : {
                        top: portalCoords.y + 75,
                        left: portalCoords.x,
                    } },
                React.createElement(EmojiPalette, { onClickOutside: () => setReactionMenuOpen(false), onEmojiSelectHandle: onEmojiSelectHandle })), document.body)));
};
export default SendMessageReaction;
