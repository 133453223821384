import React from 'react';
import { Trans } from 'react-i18next';
import { AiOutlineCheckCircle } from 'react-icons/ai';
import YAML from 'yaml';
import classNames from 'classnames';
import { Tooltip } from 'antd';
import { TEMPLATE_PARTS } from '../../../../../constants';
import MessageMarkdown from '../../../../Base/Utils/MessageMarkdown/MessageMarkdown';
import MainBlock from '../../RichMessage/Components/MainBlock';
import CallToActionButtonsBlock from '../../RichMessage/Components/CallToActionButtonsBlock';
import s from '../SpecialMessage.module.scss';
const WhatsAppMessage = ({ message, text, customColor, background, timestamp, timestampUserWhite, status, }) => {
    var _a, _b, _c, _d;
    return (React.createElement(React.Fragment, null,
        message.header && (React.createElement(MainBlock, { type: TEMPLATE_PARTS.HEADER, messageBlock: Object.assign(Object.assign({}, message.header), { format: message.header.type.toUpperCase() }) })),
        text && React.createElement(MessageMarkdown, { text: text }),
        ((_a = message.flow_reply) === null || _a === void 0 ? void 0 : _a.data) && (React.createElement(Tooltip, { overlayStyle: { maxWidth: 350 }, title: React.createElement(RawDataView, { data: (_b = message.flow_reply) === null || _b === void 0 ? void 0 : _b.data }) },
            React.createElement("div", { className: s.sentContainer },
                React.createElement(Trans, { i18nKey: "sent" }),
                React.createElement(AiOutlineCheckCircle, null)))),
        message.footer && React.createElement(MainBlock, { type: TEMPLATE_PARTS.FOOTER, messageBlock: { text: message.footer } }),
        timestamp && (React.createElement("div", { className: classNames(s.receiver, s.richMessageMainBlock) },
            React.createElement("p", { className: classNames(s.timestampUser, {
                    [s.timestampUserWhite]: timestampUserWhite,
                }) }, timestamp),
            status)),
        ((_c = message.flow_cta) === null || _c === void 0 ? void 0 : _c.cta) && (React.createElement(Tooltip, { title: React.createElement(Trans, { i18nKey: "replyFlowPreview" }), overlayClassName: s.tooltipBodyContainer, overlayStyle: { width: 200 } },
            React.createElement("span", null,
                React.createElement(CallToActionButtonsBlock, { messageBlock: { buttons: [{ text: (_d = message.flow_cta) === null || _d === void 0 ? void 0 : _d.cta }] }, background: background, customColor: customColor, withIcon: false }))))));
};
function RawDataView(props) {
    return (React.createElement("pre", null, YAML.stringify(props.data)));
}
export default WhatsAppMessage;
