var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { Component } from 'react';
import { Tag } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import { EntityManagerRoute, Operation } from '../EntityManager';
import TokenForm from './TokenForm';
import { Trans, withTranslation } from 'react-i18next';
import { pick } from 'lodash';
import { getAxios } from '../../backend';
import { API_PATH } from '../../constants';
import s from './ApiTokensManager.module.scss';
import { withStore } from '../../store/rootStore';
import { Permissions } from '@whatsper/texterchat-common';
class ApiTokensManager extends Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                loading: false,
                loadingTokensData: false,
                error: null,
                tokens: [],
                scopesInfo: {},
            }
        });
        Object.defineProperty(this, "getOpSupported", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { hasPermissions } = this.props.store.agentsStore;
                const op = [];
                if (hasPermissions([Permissions.Permission.api_tokens_view])) {
                    op.push(Operation.LIST);
                }
                if (hasPermissions([Permissions.Permission.api_tokens_manage])) {
                    op.push(Operation.CREATE, Operation.EDIT);
                }
                return op;
            }
        });
        Object.defineProperty(this, "loadScopesInfo", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                getAxios()
                    .then((axios) => {
                    return axios.get(API_PATH.API_TOKENS + '/scopes');
                })
                    .then(({ data }) => {
                    this.setState({ scopesInfo: data });
                })
                    .catch((error) => {
                    // TODO: Error reporting
                    console.error(error);
                });
            }
        });
        Object.defineProperty(this, "loadTokens", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setState({ loading: true });
                // Add pagination? Does it need here?
                getAxios()
                    .then((axios) => {
                    return axios.get(API_PATH.API_TOKENS);
                })
                    .then(({ data }) => {
                    this.setState({ loading: false, tokens: data });
                })
                    .catch((error) => {
                    this.setState({ loading: false });
                    // TODO: Error reporting
                    console.error(error);
                });
            }
        });
        Object.defineProperty(this, "loadDataInitial", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if (!Object.keys(this.state.scopesInfo).length) {
                    this.loadScopesInfo();
                }
                if (!this.state.tokens.length) {
                    this.loadTokens();
                }
            }
        });
        Object.defineProperty(this, "render", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                return (React.createElement(EntityManagerRoute, { path: "/manage/api-tokens", onMounted: () => __awaiter(this, void 0, void 0, function* () { return this.loadDataInitial(); }), entities: this.state.tokens, entityIdKey: "token", teaser: TokenTeaser, teaserProps: { scopesInfo: this.state.scopesInfo }, form: TokenForm, formProps: { scopesInfo: this.state.scopesInfo }, renderOperationTitle: this.renderOperationTitle, renderDeleteQuestion: this.renderDeleteQuestion, onEntityDelete: (token) => this.tokenDelete(token), onEntitySave: (data, token) => this.tokenSave(data, token), disabled: () => { return false; }, onClose: () => { }, opSupported: this.getOpSupported(), loading: this.state.loading }));
            }
        });
        Object.defineProperty(this, "renderOperationTitle", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (op, token) => {
                switch (op) {
                    case Operation.LIST:
                        return (React.createElement(Trans, { i18nKey: "tokensManager" }));
                    case Operation.CREATE:
                        return (React.createElement(Trans, { i18nKey: "addToken" }));
                    case Operation.EDIT:
                        return (React.createElement(Trans, { i18nKey: "editTokenName", values: { name: token ? token.title : null } }));
                    case Operation.DELETE:
                        return (React.createElement(Trans, { i18nKey: "deleteTokenName", values: { name: token ? token.title : null } }));
                }
            }
        });
        Object.defineProperty(this, "renderDeleteQuestion", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (token) => {
                return (React.createElement(Trans, { i18nKey: "sureDeleteName", values: { name: token.title } }));
            }
        });
        Object.defineProperty(this, "tokenSave", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (formData, token) => __awaiter(this, void 0, void 0, function* () {
                this.setState({ loading: true });
                formData = this.formDataToTokenProps(formData);
                if (token) {
                    formData.token = token.token;
                }
                try {
                    yield getAxios()
                        .then((axios) => {
                        return axios.post(API_PATH.API_TOKENS, formData);
                    })
                        .then(() => {
                        this.loadTokens();
                    });
                }
                catch (error) {
                    console.error(error);
                    throw new Error('An unexpected error occured');
                }
                finally {
                    this.setState({ loading: false });
                }
            })
        });
        Object.defineProperty(this, "tokenDelete", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (token) => __awaiter(this, void 0, void 0, function* () {
                this.setState({ loading: true });
                try {
                    yield getAxios()
                        .then((axios) => {
                        return axios.post(API_PATH.API_TOKENS + '/delete', { token: token.token });
                    })
                        .then(() => {
                        this.loadTokens();
                    });
                }
                catch (error) {
                    console.error(error);
                    throw new Error('An unexpected error occured');
                }
                finally {
                    this.setState({ loading: false });
                }
            })
        });
    }
    formDataToTokenProps(formData) {
        return Object.assign({}, pick(formData, ['title', 'scopes',]));
    }
}
function TokenTeaser({ entity, scopesInfo }) {
    return (React.createElement("div", { className: s.teaserContainer },
        React.createElement("h4", null, entity.title),
        React.createElement("div", { className: s.teaserTokenList }, entity.scopes.map((scope) => {
            if (!scopesInfo[scope])
                return null;
            return React.createElement(Tag, { key: scope, icon: React.createElement(CheckCircleOutlined, null) }, scopesInfo[scope].title);
        }))));
}
export default withTranslation()(withStore((ApiTokensManager)));
