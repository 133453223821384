import React from 'react';
import { observer } from 'mobx-react';
import { Input } from 'antd';
import classNames from 'classnames';
import { useStore } from '../../../store/rootStore';
import { ReactComponent as QuickRepliesCross } from '../../../assets/messageStatuses/closeCross.svg';
import { LoadingOutlined } from '@ant-design/icons';
import MessagesSearchList from '../ChatsList/MessagesSearchList';
import { MessagesSearchLoading } from '../../../store/MessagesStore';
import { getBackgroundColor, getDirection } from '../../../helpers';
import { useTranslation } from 'react-i18next';
import s from './ChatSearch.module.scss';
const ChatSearch = ({ onMessageSelected, onClose }) => {
    var _a;
    const { activeChatStore } = useStore();
    const { chat, setInChatSearchQuery, inChatSearchQuery, inChatSearchLoading, inChatSearchResults, inChatSearchHasMore, searchInChatMessages, theme, } = activeChatStore;
    const { t } = useTranslation();
    const background = getBackgroundColor(theme, { swapColors: true });
    const loading = inChatSearchLoading !== MessagesSearchLoading.NONE;
    const onResultClick = (messageId) => {
        if (typeof onMessageSelected === 'function') {
            onMessageSelected(messageId);
        }
    };
    return (React.createElement("div", { className: classNames(s.wrapper, {
            [s.rtl]: getDirection() === 'rtl',
        }) },
        React.createElement("div", { className: s.header, style: { background } },
            React.createElement("div", { className: classNames(s.title, {
                    [s.inverted]: theme.invertColors,
                }) }, t('searchMessages', { title: chat === null || chat === void 0 ? void 0 : chat.title })),
            typeof onClose === 'function' && (React.createElement("div", { className: classNames(s.closeModal, {
                    [s.inverted]: (_a = theme.additionalData) === null || _a === void 0 ? void 0 : _a.invertDotsColor,
                }) },
                React.createElement(QuickRepliesCross, { onClick: onClose })))),
        React.createElement("div", { className: s.body },
            React.createElement("div", { className: s.inputWrapper },
                React.createElement(Input, { value: inChatSearchQuery, onChange: (e) => setInChatSearchQuery(e.target.value), className: s.searchInput, placeholder: t('searchPlaceholder'), allowClear: !loading, suffix: loading && React.createElement(LoadingOutlined, null), bordered: false })),
            React.createElement("div", { className: s.resultsWrapper }, inChatSearchLoading === MessagesSearchLoading.ALL ? (React.createElement("div", { className: s.loadingWrapper },
                React.createElement(LoadingOutlined, null))) : inChatSearchResults.messages.length === 0 ? (React.createElement("div", { className: s.noResults },
                React.createElement("div", { className: s.noResultsText }, t('noResults')))) : (React.createElement(MessagesSearchList, { className: s.resultsList, header: null, search: inChatSearchQuery, results: inChatSearchResults.messages, loading: inChatSearchLoading, hasMore: inChatSearchHasMore, onLoadMore: () => searchInChatMessages(true, inChatSearchQuery), onClickMessage: onResultClick, inChatSearch: true }))))));
};
export default observer(ChatSearch);
