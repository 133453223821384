import React from 'react';
import { observer } from 'mobx-react';
import classnames from 'classnames';
import Icon from '@ant-design/icons';
import { Trans, useTranslation } from 'react-i18next';
import { ReactComponent as QuickRepliesCross } from '../../../assets/messageStatuses/closeCross.svg';
import { WIZARD_STEPS } from '../../../constants';
import { getHeaderTypeSpecificMessages } from '../helpers';
import { useStore } from '../../../store/rootStore';
import s from './WizardHeader.module.scss';
const WizardHeader = ({ closeModal, }) => {
    const { wizardStore: { wizardStep, templateHasFile, chosenTemplate } } = useStore();
    const { t } = useTranslation();
    const { wizardHeaderText } = getHeaderTypeSpecificMessages((chosenTemplate === null || chosenTemplate === void 0 ? void 0 : chosenTemplate.header) && chosenTemplate.header.type || null, t);
    const titleMapKeys = {
        [WIZARD_STEPS.ACCOUNT_STEP]: 'bulkSendChannel',
        [WIZARD_STEPS.FIRST_STEP]: 'templateForBulk',
        [WIZARD_STEPS.SECOND_STEP]: templateHasFile ? wizardHeaderText : 'selectResponseAction',
        [WIZARD_STEPS.THIRD_STEP]: templateHasFile ? 'selectResponseAction' : 'uploadCsvFile',
        [WIZARD_STEPS.FOURTH_STEP]: templateHasFile ? 'uploadCsvFile' : 'confirmSendingBulk',
        [WIZARD_STEPS.FIFTH_STEP]: 'confirmSendingBulk',
    };
    return (React.createElement("div", { className: s.wizardHeader },
        React.createElement("div", { className: classnames(s.wizardTitle, s.green) },
            React.createElement(Trans, { i18nKey: titleMapKeys[wizardStep], values: { step: wizardStep + 1 } })),
        React.createElement("div", { onClick: closeModal },
            React.createElement("div", { className: s.closeWizardWrapper },
                React.createElement(Icon, { component: QuickRepliesCross })))));
};
export default observer(WizardHeader);
