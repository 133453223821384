import React, { useEffect } from "react";
import pickerData from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import classNames from "classnames";
import styles from "./EmojiPalette.module.scss";
const EmojiPalette = ({ onClickOutside, onEmojiSelectHandle, className }) => {
    useEffect(() => {
        const handleEsc = (event) => {
            if (event.key === "Escape") {
                onClickOutside === null || onClickOutside === void 0 ? void 0 : onClickOutside();
            }
            ;
        };
        window.addEventListener('keydown', handleEsc);
        return () => {
            window.removeEventListener('keydown', handleEsc);
        };
    }, []);
    return (React.createElement("div", { className: classNames(styles.emojiPickerContainer, className) },
        React.createElement(Picker, { data: pickerData, className: styles.picker, showPreview: false, showSkinTones: false, onClickOutside: onClickOutside, onEmojiSelect: onEmojiSelectHandle, theme: "light" })));
};
export default EmojiPalette;
