import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { InfoCircleOutlined } from '@ant-design/icons';
import { Progress, Tooltip } from 'antd';
import { formatNumberToShortString } from '../../../helpers/string';
import LoadingIcon from '../../Chat/common/LoadingIcon';
import s from './TemplateMessageDrawer.module.scss';
import { LANGUAGES_TYPES } from '../../../constants';
const LimitDashboard = ({ limit, sent, loading }) => {
    const { t, i18n } = useTranslation();
    const dir = i18n.language === LANGUAGES_TYPES.HE ? 'rtl' : 'ltr';
    const usage = loading && !sent ? (React.createElement(LoadingIcon, { additionalStyles: s['template-message__loadingIcon'] })) : sent;
    return (React.createElement("div", { className: s['template-message__limitContainer'] },
        React.createElement("div", { className: s['template-message__limitRow'] },
            React.createElement("div", { className: s['template-message__text'], dir: "rtl" },
                React.createElement(Trans, { i18nKey: "messagesUsedCounter", components: { strong: React.createElement("strong", null) }, dir: dir, values: {
                        usage,
                        limit: formatNumberToShortString(limit)
                    } })),
            React.createElement(Tooltip, { title: t('sendLimitTemplateMenuMessage'), overlayClassName: s['limit-dashboard__tooltip'] },
                React.createElement(InfoCircleOutlined, { className: s['template-message__infoIcon'] }))),
        React.createElement("div", { className: s['template-message__limitRow'] },
            React.createElement(Progress, { percent: Math.round((sent / limit) * 100), strokeColor: "#0FB878" // $primary_green
                , className: s['template-message__progressBar'] }))));
};
export default LimitDashboard;
