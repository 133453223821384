import { action, observable, makeObservable } from "mobx";
import {API_PATH} from "../../constants";
import {getAxios} from '../../backend';

class NotificationsStore {

    constructor(rootStore) {
        makeObservable(this);
        this.rootStore = rootStore
    }

    isNotificationSupported = () =>
    'Notification' in window &&
    'serviceWorker' in navigator &&
    'PushManager' in window

    @action askNotificationPermission = async () => {
        if (this.isNotificationSupported()){
            const that = this
            if (Notification.permission === 'granted' && 'serviceWorker' in navigator) {

                navigator.serviceWorker.ready.then(function(reg) {

                    reg.pushManager.getSubscription().then(function(sub) {

                        if (sub === null) {
                            that.subscribeUser()
                        } else {
                            return
                        }
                    });


                })

            } else if(Notification.permission !== 'denied') {

                Notification.requestPermission().then((permission)=>{
                    if (permission === 'granted') {
                        this.subscribeUser()
                    }
                })
            } else {
                await this.unsubscribeUser()
            }
        }
    }

  triggerAskForNotificationByClient = () => {
      Notification.requestPermission().then((permission)=>{
          if (permission === 'granted') {
              this.subscribeUser()
          }
      })
  }

  subscribeUser = async () => {
      const that = this
      if ('serviceWorker' in navigator) {
          await navigator.serviceWorker.ready.then(function(reg) {
              const {notifications} = that.rootStore.generalSettings;
              if (notifications && notifications.publicKey) {
                reg.pushManager.subscribe({
                    userVisibleOnly: true,
                    applicationServerKey: that.urlBase64ToUint8Array(notifications.publicKey),
                }).then(async (sub) => {
                    await getAxios()
                        .then((axios) => {
                            return axios.post(
                                `${API_PATH.NOTIFICATIONS}/subscribe`, 
                                {
                                    sub: sub,
                                },
                                {
                                    headers: {
                                        "content-type": "application/json"
                                    }
                                }
                            );
                        })
                        .catch(e=>{
                            console.error(`Subscribe error ${e}`)
                        });

                    console.log('Endpoint URL: ', sub);
                }).catch(function(e) {
                    if (Notification.permission === 'denied') {
                        console.error('Permission for notifications was denied');
                    } else {
                        console.error('Unable to subscribe to push', e);
                    }
                });
            } else {
                console.error('No public key to subscribe for notifications!');
            }

          })
      } else {
          console.log("NO SERVICE WORKER")
      }
  }

  unsubscribeUser = async () => {
      const that = this
      if ('serviceWorker' in navigator) {
          await navigator.serviceWorker.ready.then(function(reg) {
              reg.pushManager.getSubscription().then(function(subscription) {
                  if (subscription!==null){
                      subscription.unsubscribe().then(async ()=> {
                          await getAxios()
                            .then((axios) => {
                                    return axios.post(
                                        `${API_PATH.NOTIFICATIONS}/unsubscribe`, 
                                        {
                                            sub: subscription,
                                            uid: that.rootStore.loginStore.userDetails.uid
                                        }
                                    );
                            })
                            .catch(e=>{
                                console.error(`Subscribe error ${e}`)
                            });

                      }).catch(function(e) {
                          // Unsubscription failed
                      })
                  }
              })
          })
      }
  }

  urlBase64ToUint8Array = (base64String) => {
    const padding = "=".repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/\-/g, "+")
        .replace(/_/g, "/");

    const rawData = window.atob(base64);
    const outputArray = new Uint8Array(rawData.length);

    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
  }

}

export default NotificationsStore;
