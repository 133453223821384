import React, { useEffect, useState } from 'react';
import GoogleMapReact from 'google-map-react';
import classNames from 'classnames';
import { SendOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';
import LoadingIcon from '../common/LoadingIcon';
import Marker from '../MessagesTypes/LocationMessage/Marker';
import { getApiKey } from '../../../settings';
import { getBackgroundColor } from '../../../helpers';
import { useStore } from '../../../store/rootStore';
import s from './SendLocationWindow.module.scss';
const SendLocationWindow = ({ coords, onCancel, onSend }) => {
    var _a;
    const [apiKey, setApiKey] = useState('');
    const { i18n, t } = useTranslation();
    const { activeChatStore: { theme }, } = useStore();
    useEffect(() => {
        getApiKey().then((key) => setApiKey(key || ''));
    }, []);
    const { latitude, longitude } = coords;
    const background = getBackgroundColor(theme, { forcePrimaryColor: true });
    return (React.createElement("div", { className: s.container },
        React.createElement("div", { className: s.box },
            React.createElement("div", { className: s.mapContainer }, apiKey ? (React.createElement(GoogleMapReact, { bootstrapURLKeys: { key: apiKey, language: i18n.language }, center: [latitude, longitude], zoom: 15 },
                React.createElement(Marker, { lat: latitude, lng: longitude }))) : (React.createElement(LoadingIcon, { style: { fontSize: 60 } }))),
            React.createElement("div", { className: s.lowerBlock },
                React.createElement(Button, { className: s.cancelButton, onClick: onCancel, type: "default" }, t('cancel')),
                React.createElement("div", { className: s.contentWrapper },
                    React.createElement("span", null, t('sendCurrentLocation')),
                    React.createElement("span", { className: s.subtitle }, latitude === null || latitude === void 0 ? void 0 :
                        latitude.toFixed(4),
                        ", ", longitude === null || longitude === void 0 ? void 0 :
                        longitude.toFixed(4))),
                React.createElement(Button, { className: classNames(s.sendButton, {
                        [s.invertColors]: theme.invertColors || ((_a = theme.additionalData) === null || _a === void 0 ? void 0 : _a.invertChatColor),
                    }), onClick: onSend, style: { background } },
                    React.createElement(SendOutlined, null))))));
};
export default SendLocationWindow;
