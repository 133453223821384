export const formatNumberToShortString = (num) => {
    if (num > 999 && num < 1000000) {
        return (num / 1000).toFixed() + 'k';
    }
    else if (num > 1000000) {
        return (num / 1000000).toFixed() + 'M';
    }
    else if (num < 900) {
        return num;
    }
};
export const has4ConsecutiveSpaces = (str) => {
    return /[ ]{4,}/g.test(str);
};
export const hasNewLine = (str) => {
    return /\r?\n|\r/g.test(str);
};
export const hasTab = (str) => {
    return /[\t]/g.test(str);
};
