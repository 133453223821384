var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { Tooltip, Typography } from 'antd';
import Icon, { QuestionCircleOutlined } from '@ant-design/icons';
import { Trans, withTranslation } from 'react-i18next';
import FileSaver from "file-saver";
import classnames from 'classnames';
import { withStore } from '../../../store/rootStore';
import { CHAT_STATUS } from '../../../constants';
import { ReactComponent as ReplyIcon } from '../../../assets/icons/arrows/replyIcon.svg';
import blankProfilePicture from '../../../assets/avatars/blankProfilePicture.png';
import { getDownloadUrlBasedOnPlatform } from '../../../helpers';
import styles from './ReplyPreview.module.scss';
const { Text } = Typography;
class ReplyPreview extends Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "getReplyType", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (replyType) => {
                if (replyType === CHAT_STATUS.BOT) {
                    return 'withBot';
                }
                else if (replyType === CHAT_STATUS.ASSIGNED) {
                    return 'pendingTo';
                }
                else if (replyType === CHAT_STATUS.RESOLVED) {
                    return 'resolve';
                }
            }
        });
        Object.defineProperty(this, "getAgentOrDepartment", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (agent, department) => {
                var _a;
                const { users } = this.props.store.agentsStore;
                const { departments } = this.props.store.departmentsStore;
                let name = '';
                if (agent) {
                    const chosenAgent = users.find((user) => user.uid === agent);
                    if (chosenAgent) {
                        name = (chosenAgent === null || chosenAgent === void 0 ? void 0 : chosenAgent.displayName) || '';
                    }
                }
                else {
                    const chosenDepartment = departments.find((dep) => dep._id === department);
                    if (chosenDepartment) {
                        name = chosenDepartment === null || chosenDepartment === void 0 ? void 0 : chosenDepartment.name;
                    }
                }
                return name || ((_a = this.props.i18n) === null || _a === void 0 ? void 0 : _a.t('unknown'));
            }
        });
        Object.defineProperty(this, "getDepartmentColor", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (departmentId) => {
                const { departments } = this.props.store.departmentsStore;
                const chosenDepartment = departments.find((dep) => dep._id === departmentId);
                let color = '#FFF';
                if (chosenDepartment) {
                    color = chosenDepartment.color;
                }
                return color;
            }
        });
        Object.defineProperty(this, "downloadFile", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => __awaiter(this, void 0, void 0, function* () {
                var _a, _b, _c;
                if (!this.props.store.activeChatStore.chat)
                    return;
                const { replyAction } = this.props.store.activeChatStore.chat;
                if (replyAction && replyAction.replyToTemplateFile) {
                    const url = yield getDownloadUrlBasedOnPlatform((_a = replyAction.replyToTemplateFile) === null || _a === void 0 ? void 0 : _a.url);
                    if (window.ReactNativeWebView) {
                        window.open((_b = replyAction.replyToTemplateFile) === null || _b === void 0 ? void 0 : _b.url, '_blank');
                    }
                    else {
                        FileSaver.saveAs(url, (_c = replyAction.replyToTemplateFile) === null || _c === void 0 ? void 0 : _c.name);
                    }
                }
            })
        });
    }
    render() {
        const { chat } = this.props.store.activeChatStore;
        if (!(chat === null || chat === void 0 ? void 0 : chat.replyAction)) {
            return null;
        }
        const { replyText, chatStatus, replyToTemplateFile, responsibleAgent, responsibleDepartment, setBotNode } = chat.replyAction;
        return (React.createElement("div", { className: classnames(styles.block) },
            React.createElement(Icon, { className: styles.icon, component: ReplyIcon }),
            React.createElement(Text, { className: classnames(styles.icon, styles.title) },
                React.createElement(Trans, { i18nKey: "autoReplyTitle" })),
            React.createElement(Text, { className: styles.text },
                React.createElement(Trans, { i18nKey: chatStatus ? this.getReplyType(chatStatus) : undefined }),
                chatStatus === CHAT_STATUS.ASSIGNED && (React.createElement(React.Fragment, null,
                    responsibleAgent && React.createElement("img", { className: styles.avatar, src: blankProfilePicture }),
                    responsibleDepartment && (React.createElement("div", { style: { backgroundColor: this.getDepartmentColor(responsibleDepartment) }, className: styles.departmentColor })),
                    React.createElement(Text, { className: styles.assignedToName, title: this.getAgentOrDepartment(responsibleAgent, responsibleDepartment) }, this.getAgentOrDepartment(responsibleAgent, responsibleDepartment))))),
            React.createElement(Tooltip, { arrowPointAtCenter: true, placement: "bottomRight", title: React.createElement(Trans, { i18nKey: "actionWhenReplyHelp" }) },
                React.createElement(QuestionCircleOutlined, { className: styles.labelIcon })),
            chatStatus === CHAT_STATUS.BOT && (React.createElement(React.Fragment, null,
                React.createElement("div", null),
                React.createElement(Text, { className: styles.title },
                    React.createElement(Trans, { i18nKey: "nodeTitle" })),
                React.createElement(Text, { className: styles.text }, setBotNode),
                React.createElement("div", null))),
            (chatStatus === CHAT_STATUS.RESOLVED || chatStatus === CHAT_STATUS.ASSIGNED) &&
                (replyText || replyToTemplateFile) && (React.createElement(React.Fragment, null,
                React.createElement("div", null),
                React.createElement(Text, { className: styles.title },
                    React.createElement(Trans, { i18nKey: "messageTitle" })),
                React.createElement("div", { className: classnames(styles.text, { [styles.withFile]: replyToTemplateFile }) },
                    replyText && (React.createElement(Text, { title: replyText, className: styles.customReplyText }, replyText)),
                    replyToTemplateFile && (React.createElement("div", { className: styles.attachedFile, onClick: this.downloadFile, title: `Download ${replyToTemplateFile.name}` },
                        React.createElement(Text, { className: styles.attachedFileName }, replyToTemplateFile.name)))),
                React.createElement("div", null)))));
    }
}
export default withTranslation()(withStore(observer(ReplyPreview)));
