import React from 'react';
import { Button } from 'antd';
import Files from 'react-files';
import classnames from 'classnames';
import { Trans } from 'react-i18next';
import { observer } from 'mobx-react';
import styles from './FileUploadButton.module.scss';
const FileUploadButton = ({ blockField, handleSetHeader, richTemplateHeader, className, accepts, buttonText }) => {
    return (React.createElement("div", { className: classnames(styles.buttonWrapper, className) },
        React.createElement(Files, { multiple: false, onChange: !blockField ? handleSetHeader : null, accepts: accepts, onError: null, clickable: true },
            React.createElement(Button, { className: classnames(styles.button), type: "primary" }, (richTemplateHeader === null || richTemplateHeader === void 0 ? void 0 : richTemplateHeader.name) || React.createElement(Trans, { i18nKey: buttonText })))));
};
export default observer(FileUploadButton);
