var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import moment from 'moment';
import { Button, message } from 'antd';
import ModalWithoutBottom from '../../../../Chat/common/ModalWithoutBottom';
import { useStore } from '../../../../../store/rootStore';
import { ReactComponent as QuickRepliesPlus } from '../../../../../assets/icons/plus/quickRepliesPlus.svg';
import ExportsList from './components/ExportsList';
import CreateExport from './components/CreateExport';
import { Permissions } from '@whatsper/texterchat-common';
import { observer } from 'mobx-react';
import useCheckIfMobile from '../../../../../helpers/hooks/useCheckIfMobile';
import { useHistory } from 'react-router-dom';
import { getDirectFileUrlToStorage } from '../../../../../store/FilesStore';
import classNames from 'classnames';
import s from './ExportChats.module.scss';
const ExportChats = () => {
    const [showAddExportForm, setShowAddExportForm] = useState(false);
    const { exports, channels, agentsStore, loginStore } = useStore();
    const isMobile = useCheckIfMobile();
    const history = useHistory();
    const canCreate = agentsStore.hasPermissions([Permissions.Permission.export_create]), canDelete = agentsStore.hasPermissions([Permissions.Permission.export_delete]), canDownload = agentsStore.hasPermissions([Permissions.Permission.export_download]);
    const onBack = () => {
        history.push('/');
    };
    const onCreateExport = (values) => __awaiter(void 0, void 0, void 0, function* () {
        try {
            yield exports.validateExport(values);
            const success = yield exports.createChatExport(values);
            if (success) {
                if (values.scheduled) {
                    const time = moment(values.scheduled).calendar();
                    message.success(React.createElement(Trans, { i18nKey: "exportScheduledSuccess", values: { time } }));
                }
                else {
                    message.success(React.createElement(Trans, { i18nKey: "exportRequestedSuccess" }));
                }
                setShowAddExportForm(false);
            }
        }
        catch (err) {
            message.error(React.createElement(Trans, { i18nKey: "exportStartedError" }));
            console.error(err);
        }
    });
    const onDeleteExport = (exportId) => __awaiter(void 0, void 0, void 0, function* () {
        const success = yield exports.deleteChatExport(exportId);
        if (success) {
            message.success(React.createElement(Trans, { i18nKey: "exportDeletedSuccess" }));
        }
    });
    const onDownloadExport = (fileId) => __awaiter(void 0, void 0, void 0, function* () {
        const downloadUrl = yield getDirectFileUrlToStorage(fileId);
        if (!downloadUrl)
            return;
        if (window.ReactNativeWebView) {
            window.ReactNativeWebView.postMessage(JSON.stringify({
                event: 'openURL',
                url: downloadUrl,
            }));
        }
        else {
            window.open(downloadUrl, '_blank');
        }
    });
    useEffect(() => {
        exports.getChatExports();
    }, []);
    return (React.createElement(ModalWithoutBottom, { setModalVisible: onBack, titleTextKey: showAddExportForm ? 'createExport' : 'exports', height: "650px", width: isMobile ? undefined : 1200, visible: true },
        React.createElement("div", { className: s.content },
            canCreate && (React.createElement("div", { className: classNames(s.header, {
                    [s.hidden]: showAddExportForm,
                }) },
                React.createElement(Button, { icon: React.createElement(QuickRepliesPlus, null), className: s.createButton, onClick: () => setShowAddExportForm(true) },
                    React.createElement(Trans, { i18nKey: "createExport" })))),
            showAddExportForm ? (React.createElement(CreateExport, { onCancel: () => setShowAddExportForm(false), onCreate: onCreateExport, onValidate: exports.validateExport, problems: exports.validationProblems, accounts: channels.accounts, loading: exports.loading })) : (React.createElement(ExportsList, { exports: exports.exports, loading: exports.loading, onDelete: canDelete ? onDeleteExport : undefined, onDownload: canDownload ? onDownloadExport : undefined, isMobile: isMobile, accounts: [...channels.accounts] })))));
};
export default observer(ExportChats);
