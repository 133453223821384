/**
 * @file Some specific settings for some OAuth services that have no sense on backend
 */
/** Some additional query parameters for some services */
export const SERVICE_ADDITIONAL_QUERY = {
    salesforce: {
        //  authorize page display mode
        //  "popup" - Compact dialog optimized for modern web browser popups
        display: 'popup',
    },
    google: {
        'prompt[0]': 'consent',
        'prompt[1]': 'select_account',
    },
};
/** Services that should be opened in full window only */
export const SERVICES_REDIRECT_ONLY = [];
