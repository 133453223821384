var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState } from 'react';
import { Modal, Typography, Tooltip } from 'antd';
import { Trans } from "react-i18next";
import { LoadingOutlined } from '@ant-design/icons';
import CommonButton from "../../common/CommonButton";
import blankImage from "../../../../assets/avatars/blankProfilePicture.png";
import styles from "./BlockedChatModal.module.scss";
const { Text } = Typography;
const BlockedChatModal = (props) => {
    const { visible, onCancel, onSubmit, name, phone, block } = props;
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const asyncWrap = () => __awaiter(void 0, void 0, void 0, function* () {
        return onSubmit();
    });
    const handleSubmit = () => __awaiter(void 0, void 0, void 0, function* () {
        setLoading(true);
        setError(false);
        return asyncWrap()
            .then(() => {
            setLoading(false);
            onCancel();
        })
            .catch(() => {
            setLoading(false);
            setError(true);
            setTimeout(() => setError(false), 2000);
        });
    });
    return (React.createElement(Modal, { width: 800, open: visible, closable: false, onCancel: onCancel, footer: null, centered: true, bodyStyle: {
            padding: '0',
            boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)',
            borderRadius: '2px',
        } },
        React.createElement("div", { className: styles.content },
            React.createElement("div", { className: styles.info },
                React.createElement("img", { className: styles.avatar, src: blankImage, alt: "avatar" }),
                React.createElement(Text, { className: styles.name }, name),
                React.createElement(Text, { className: styles.phone }, phone)),
            React.createElement("div", { className: styles.notificationText },
                React.createElement(Trans, { i18nKey: block ? "blockMainText" : "unblockMainText", components: { span: React.createElement("span", { className: styles.alarmText }) }, values: { name } })),
            React.createElement("div", { className: styles.buttons },
                React.createElement(CommonButton, { action: onCancel },
                    React.createElement(Trans, { i18nKey: "cancel" })),
                React.createElement(Tooltip, { open: error, arrowPointAtCenter: true, placement: "top", title: React.createElement(Trans, { i18nKey: "unexpectedError" }) },
                    React.createElement(CommonButton, { action: handleSubmit, alert: true }, loading ? React.createElement(LoadingOutlined, { className: styles.preloader }) :
                        React.createElement(Text, { className: styles.alertButtonText },
                            React.createElement(Trans, { i18nKey: block ? "block" : "unblock" }))))))));
};
export default BlockedChatModal;
