import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Trans, useTranslation } from 'react-i18next';
import { Collapse, Tag } from 'antd';
import classnames from 'classnames';
import { CHAT_STATUS } from '@whatsper/texterchat-common';
import { BoxValueTypes } from '../components/Box';
import Row from '../components/Row';
import PanelSummary from '../components/PanelSummary';
import { withStore } from '../../../store/rootStore';
import { getHoursAndMinutes } from '../helpers';
import { ReactComponent as ClockCircle } from '../../../assets/icons/dashboard/ClockCircle.svg';
import { ReactComponent as CheckSquare } from '../../../assets/icons/dashboard/CheckSquare.svg';
import { ReactComponent as Hourglass } from '../../../assets/icons/dashboard/Hourglass.svg';
import { ReactComponent as IssuesClose } from '../../../assets/icons/dashboard/IssuesClose.svg';
import { ReactComponent as UserAdd } from '../../../assets/icons/dashboard/UserAdd.svg';
import { ReactComponent as Bot } from '../../../assets/icons/leftMenuIcons/bot.svg';
import { FaUserCircle } from 'react-icons/fa';
import { formatAsPercent } from '../../BulkReportsDrawer/helpers';
import s from '../DashboardDrawer.module.scss';
const { Panel } = Collapse;
const Agents = ({ store: { dashboard, agentsStore } }) => {
    const { t } = useTranslation();
    const [openPanels, setOpenPanels] = useState([]);
    /* V5.5 hidden */
    const showNoAgents = false;
    const onPanelChange = (key) => {
        setOpenPanels(key);
    };
    const { [CHAT_STATUS.PENDING]: pending, [CHAT_STATUS.ASSIGNED]: assigned } = dashboard.data;
    return (React.createElement("div", { className: classnames(s['main__container'], s['main__container--agents']) },
        React.createElement("div", { className: s['agents__onlineContainer'] },
            React.createElement(Trans, { i18nKey: "agents" }),
            React.createElement("div", { className: s['agents__onlineValue'] },
                "(",
                t("onlineCount", { count: agentsStore.onlineCount }),
                ")")),
        React.createElement(Collapse, { activeKey: openPanels, onChange: onPanelChange, ghost: true, destroyInactivePanel: true }, [
            ...agentsStore.users,
            ...(showNoAgents
                ? [
                    {
                        uid: null,
                        displayName: t('withoutAgent'),
                        isOnline: false,
                        disabled: false,
                    },
                ]
                : []),
        ]
            .map((a) => {
            return Object.assign(Object.assign({}, a), { isOnline: agentsStore.isUserOnline(a) });
        })
            // @ts-expect-error
            .sort((a, b) => b.isOnline - a.isOnline)
            .filter((a) => !a.disabled)
            .map(({ displayName, uid, isOnline, color = '#636766' }) => {
            var _a, _b;
            const pendingData = pending === null || pending === void 0 ? void 0 : pending.agents.find((d) => d._id === uid);
            const assignedData = assigned === null || assigned === void 0 ? void 0 : assigned.agents.find((d) => d._id === uid);
            const resolvedData = (_a = dashboard.resolvedData) === null || _a === void 0 ? void 0 : _a.agents.find((d) => d._id === uid);
            const historicalAssignedData = (_b = dashboard.historicalData[CHAT_STATUS.ASSIGNED]) === null || _b === void 0 ? void 0 : _b.agents.find((d) => d._id === uid);
            const { chatsCount: totalPending = 0, avgTime: pendingTimeAvgValue, maxTime: pendingMaxTime, } = pendingData || {};
            const { avgTime: takenTimeAvgValue, maxTime: takenMaxTime } = assignedData || {};
            const { chatsCount: totalTaken = 0 } = assignedData || {};
            const { chatsCount: totalAssignedHistorical = 0 } = historicalAssignedData || {};
            const { count: agent_resolved = 0 } = resolvedData || {};
            const pendingTimeAvg = getHoursAndMinutes(pendingTimeAvgValue);
            const pendingTimeMax = getHoursAndMinutes(pendingMaxTime);
            const takenTimeAvg = getHoursAndMinutes(takenTimeAvgValue);
            const takenTimeMax = getHoursAndMinutes(takenMaxTime);
            const getResolvedSummary = () => {
                if (!resolvedData)
                    return;
                const resolvedPercentAll = formatAsPercent((agent_resolved / totalPending + agent_resolved) * 100);
                return (React.createElement("div", { className: s['main__panel--resolvedSummary'] },
                    React.createElement("div", null, resolvedPercentAll)));
            };
            const showExtra = () => {
                return !openPanels.includes(uid);
            };
            return (React.createElement(Panel, Object.assign({ key: uid, header: React.createElement("div", { className: s['main__panel--agentsHeader'] },
                    React.createElement("div", null,
                        React.createElement(FaUserCircle, { style: { fill: color } })),
                    React.createElement("div", { style: { color } }, displayName || uid),
                    isOnline && React.createElement(Tag, { color: "green", className: s["main__panel--online"] }, t('online'))), className: classnames(s['main__panel--all'], s['main__panel--all--agents']) }, (showExtra() && {
                extra: (React.createElement(PanelSummary, { panels: [
                        { icon: React.createElement(ClockCircle, null), title: t('pending'), value: totalPending },
                        {
                            icon: React.createElement(Hourglass, null),
                            title: t('averagePendingTime'),
                            value: dashboard.shouldHideOutsideWorkingHours
                                ? undefined
                                : pendingTimeAvg,
                        },
                        { icon: React.createElement(UserAdd, null), title: t('totalTaken'), value: totalTaken },
                        {
                            icon: React.createElement(CheckSquare, null),
                            title: t('totalResolved'),
                            value: getResolvedSummary(),
                            compact: true,
                        },
                        {
                            icon: React.createElement(IssuesClose, null),
                            title: t('averageTimeToResolve'),
                            value: dashboard.shouldHideOutsideWorkingHours
                                ? undefined
                                : takenTimeAvg,
                        },
                    ], color: color, type: "agents" })),
            })),
                React.createElement(Row, { boxData: [
                        {
                            title: t('pendingChats'),
                            data: {
                                type: BoxValueTypes.SIMPLE,
                                value: totalPending,
                                extraIcon: React.createElement(Bot, null),
                            },
                            color,
                        },
                        {
                            title: t('pendingTime'),
                            data: {
                                type: BoxValueTypes.OVER,
                                value: dashboard.shouldHideOutsideWorkingHours
                                    ? [undefined, undefined]
                                    : [pendingTimeAvg, pendingTimeMax],
                            },
                            color,
                        },
                        {
                            title: t('totalTaken'),
                            data: {
                                type: BoxValueTypes.SIMPLE,
                                value: totalTaken,
                            },
                            color,
                        },
                        {
                            title: t('resolved'),
                            data: {
                                type: BoxValueTypes.CHART_SMALL,
                                extraIcon: React.createElement(Bot, null),
                                value: {
                                    total: totalAssignedHistorical,
                                    agent: agent_resolved,
                                    bot: null,
                                },
                            },
                            color,
                        },
                        {
                            title: t('timeToResolve'),
                            data: {
                                type: BoxValueTypes.OVER,
                                value: dashboard.shouldHideOutsideWorkingHours
                                    ? [undefined, undefined]
                                    : [takenTimeAvg, takenTimeMax],
                            },
                            color,
                        },
                        {
                            title: t('SLA'),
                            data: {
                                type: BoxValueTypes.SIMPLE,
                                value: t('notAvailable'),
                                additionalClassname: s['main__panel--notAvailable'],
                            },
                            color,
                        },
                    ] })));
        }))));
};
export default withStore(observer(Agents));
