import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { TEMPLATE_HEADER_TYPE } from "@whatsper/texterchat-common";
import classnames from "classnames";
import CallToActionButtonsBlock from "./Components/CallToActionButtonsBlock";
import QuickReplyButtonsBlock from "./Components/QuickReplyButtonsBlock";
import MainBlock from "./Components/MainBlock";
import { TEMPLATE_PARTS } from "../../../../constants";
import { withStore } from "../../../../store/rootStore";
import styles from "./RichMessage.module.scss";
const RichMessage = ({ store, parameters, status, timestamp, templateId, templateMessageData, errorReason, background, customColor, timestampUserWhite, }) => {
    const [template, setTemplate] = useState(null);
    const [richMessage, setRichMessage] = useState([]);
    useEffect(() => {
        const template = store.templateMessagesStore.templateMessagesList.find((template) => template.name === templateId);
        if (template) {
            setTemplate(template);
        }
    }, [templateId]);
    useEffect(() => {
        if (template) {
            setRichMessage(template.obj.localizations[0].components);
        }
    }, [template]);
    const hasButtonsOrFile = () => {
        var _a;
        const hasFile = (richMessage === null || richMessage === void 0 ? void 0 : richMessage.find((mb) => mb.type === TEMPLATE_PARTS.HEADER && [TEMPLATE_HEADER_TYPE.IMAGE, TEMPLATE_HEADER_TYPE.DOCUMENT, TEMPLATE_HEADER_TYPE.VIDEO].includes(mb.format /* wasn't able to convert from WhatsApp.TemplateHeaderComponent.format */))) ||
            [TEMPLATE_HEADER_TYPE.IMAGE, TEMPLATE_HEADER_TYPE.DOCUMENT, TEMPLATE_HEADER_TYPE.VIDEO].includes((_a = templateMessageData === null || templateMessageData === void 0 ? void 0 : templateMessageData.header) === null || _a === void 0 ? void 0 : _a.type);
        const hasButtons = (richMessage === null || richMessage === void 0 ? void 0 : richMessage.find((mb) => mb.type === TEMPLATE_PARTS.BUTTONS)) || (templateMessageData === null || templateMessageData === void 0 ? void 0 : templateMessageData.buttons);
        return !!(hasFile || hasButtons);
    };
    const parsedRichMessage = {
        header: richMessage === null || richMessage === void 0 ? void 0 : richMessage.find((mb) => mb.type === TEMPLATE_PARTS.HEADER),
        body: richMessage === null || richMessage === void 0 ? void 0 : richMessage.find((mb) => mb.type === TEMPLATE_PARTS.BODY),
        footer: richMessage === null || richMessage === void 0 ? void 0 : richMessage.find((mb) => mb.type === TEMPLATE_PARTS.FOOTER),
    };
    return (React.createElement("div", { className: classnames(styles.richMessageBlock, {
            [styles.bulkWithRichParams]: hasButtonsOrFile(),
        }) },
        React.createElement("div", { className: classnames(styles.richMessageBox, {
                [styles.error]: errorReason,
                [styles.fullWidth]: hasButtonsOrFile(),
            }), style: { background } },
            (richMessage || templateMessageData) && (React.createElement("div", { className: styles.richMessageContainer },
                (parsedRichMessage.header || (templateMessageData === null || templateMessageData === void 0 ? void 0 : templateMessageData.header)) && (React.createElement(MainBlock, { type: TEMPLATE_PARTS.HEADER, parameters: parameters, messageBlock: (templateMessageData === null || templateMessageData === void 0 ? void 0 : templateMessageData.header) || (parsedRichMessage === null || parsedRichMessage === void 0 ? void 0 : parsedRichMessage.header), customColor: customColor })),
                (parsedRichMessage.body || (templateMessageData === null || templateMessageData === void 0 ? void 0 : templateMessageData.body)) && (React.createElement(MainBlock, { type: TEMPLATE_PARTS.BODY, parameters: parameters, messageBlock: (templateMessageData === null || templateMessageData === void 0 ? void 0 : templateMessageData.body) || (parsedRichMessage === null || parsedRichMessage === void 0 ? void 0 : parsedRichMessage.body), customColor: customColor })),
                (parsedRichMessage.footer || (templateMessageData === null || templateMessageData === void 0 ? void 0 : templateMessageData.footer)) && (React.createElement(MainBlock, { type: TEMPLATE_PARTS.FOOTER, parameters: parameters, messageBlock: (templateMessageData === null || templateMessageData === void 0 ? void 0 : templateMessageData.footer) || (parsedRichMessage === null || parsedRichMessage === void 0 ? void 0 : parsedRichMessage.footer), customColor: customColor })))),
            React.createElement("div", { className: classnames(styles.receiver, styles.richMessageMainBlock) },
                React.createElement("p", { className: classnames({ [styles.timestampUser]: true, [styles.timestampUserWhite]: timestampUserWhite }) }, timestamp),
                status), richMessage === null || richMessage === void 0 ? void 0 :
            richMessage.map((messageBlock, i) => (React.createElement("div", { key: i }, messageBlock.type === TEMPLATE_PARTS.BUTTONS && messageBlock.buttons[0].type !== "QUICK_REPLY" && (React.createElement(CallToActionButtonsBlock, { messageBlock: messageBlock, background: background, customColor: customColor })))))), richMessage === null || richMessage === void 0 ? void 0 :
        richMessage.map((messageBlock, i) => (React.createElement("div", { key: i }, messageBlock.type === TEMPLATE_PARTS.BUTTONS && messageBlock.buttons[0].type === 'QUICK_REPLY' && (React.createElement(QuickReplyButtonsBlock, { messageBlock: messageBlock, error: !!errorReason, background: background, customColor: customColor })))))));
};
export default withStore(observer(RichMessage));
