var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import * as React from 'react';
import { Modal } from 'antd';
import Button from 'antd/es/button';
import { Typography } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons';
import { ReactComponent as EditIcon } from '../../assets/icons/pencil/penIcon.svg';
import { ReactComponent as QuickRepliesPlus } from '../../assets/icons/plus/quickRepliesPlus.svg';
import { ReactComponent as QuickRepliesGreenPlus } from '../../assets/icons/plus/greenPlus.svg';
import { ReactComponent as QuickRepliesDeleteIcon } from '../../assets/icons/deleteIcon/deleteIcon.svg';
import { ReactComponent as QuickRepliesCross } from '../../assets/messageStatuses/closeCross.svg';
import classNames from 'classnames';
import { Trans } from 'react-i18next';
import { Route, useHistory, useLocation } from 'react-router-dom';
import { observer } from 'mobx-react';
// Why do we import from Chat components here???
import NotificationModal from '../Chat/common/NotificationModal';
import ErrorBoundary from '../ErrorBoundary';
import { getDirection } from '../../helpers';
import EntityListItem from './EntityListItem';
import styles from './EntityManager.module.scss';
const { Text } = Typography;
export var Operation;
(function (Operation) {
    Operation[Operation["LIST"] = 1] = "LIST";
    Operation[Operation["CREATE"] = 2] = "CREATE";
    Operation[Operation["EDIT"] = 3] = "EDIT";
    Operation[Operation["DELETE"] = 4] = "DELETE";
})(Operation || (Operation = {}));
const EntityList = observer(function (props) {
    const Teaser = props.teaser;
    let entities = [...props.entities]
        .sort((a, b) => (props === null || props === void 0 ? void 0 : props.disabled) && props.disabled(a) > (props === null || props === void 0 ? void 0 : props.disabled(b)) ? 1 : -1);
    if (props.sorter) {
        entities = entities.sort(props.sorter);
    }
    return (React.createElement("div", { className: styles.listRoot },
        props.loading ? (React.createElement("div", { className: styles.listPreloaderContainer },
            React.createElement(LoadingOutlined, { className: styles.listPreloader }))) : (React.createElement("ul", { className: styles.listContents }, entities.map((entity) => {
            if (props.renderListItem) {
                return props.renderListItem(entity, props);
            }
            return (React.createElement(EntityListItem, { key: entity[props.entityIdKey], entity: entity, entityIdKey: props.entityIdKey, disabled: props.disabled, onEdit: props.onEdit, onDelete: props.onDelete, customListActions: props.customListActions },
                React.createElement(Teaser, Object.assign({ entity: entity }, props.additionalProps))));
        }))),
        typeof props.onCreate === 'function' &&
            React.createElement("div", { className: styles.listActions },
                React.createElement(Button, { className: classNames(styles.createButton, 'big'), onClick: () => props.onCreate && props.onCreate() },
                    React.createElement(Icon, { component: QuickRepliesPlus }),
                    React.createElement(Text, { strong: true }, props.createTitle)))));
});
export default class EntityManager extends React.Component {
    constructor(props) {
        super(props);
        Object.defineProperty(this, "componentDidMount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if (typeof this.props.onMounted === 'function') {
                    this.props.onMounted()
                        .catch((error) => {
                        console.error(error);
                    });
                }
            }
        });
        Object.defineProperty(this, "render", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                return (React.createElement(React.Fragment, null,
                    React.createElement(Modal, { open: true, closable: false, onCancel: () => typeof this.props.onClose === 'function' ? this.props.onClose() : '', footer: null, bodyStyle: {
                            padding: '0',
                            boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)',
                            borderRadius: '2px',
                        } },
                        React.createElement("div", { className: classNames(styles.managerContents, {
                                [styles.rtl]: this.props.isRTL,
                            }) },
                            React.createElement(Header, Object.assign({ operation: this.state.operation, title: this.props.renderOperationTitle(this.state.operation, this.state.currentEntity || undefined), onClose: () => this.props.onClose && this.props.onClose() }, this.props.headerProps)),
                            this.renderContents())),
                    React.createElement(NotificationModal, { visible: this.state.notifyModalVisible, contentText: this.state.notifyModalText || '', onCancel: () => this.closeNotifyModal() })));
            }
        });
        Object.defineProperty(this, "renderContents", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { operation } = this.state;
                const { customAction, customListActions, renderListItem, customActionIcons, sorter } = this.props;
                return (React.createElement(React.Fragment, null,
                    Operation.LIST === operation &&
                        React.createElement(EntityList, { loading: this.props.loading, entities: this.props.entities, teaser: this.props.teaser, additionalProps: this.props.teaserProps, entityIdKey: this.props.entityIdKey, disabled: this.props.disabled, createTitle: this.props.renderOperationTitle(Operation.CREATE), onCreate: !this.props.opSupported || this.props.opSupported.includes(Operation.CREATE)
                                ? () => {
                                    if (customAction) {
                                        customAction();
                                        return;
                                    }
                                    this.setState({ operation: Operation.CREATE });
                                }
                                : void 0, onEdit: !this.props.opSupported || this.props.opSupported.includes(Operation.EDIT)
                                ? (entity) => this.setEdit(entity)
                                : void 0, onDelete: !this.props.opSupported || this.props.opSupported.includes(Operation.DELETE)
                                ? (entity) => this.setDelete(entity)
                                : void 0, sorter: sorter, customListActions: customListActions, customActionIcons: customActionIcons, renderListItem: renderListItem }),
                    (Operation.CREATE === operation || Operation.EDIT === operation) &&
                        React.createElement(EntityFormWrap, { form: this.props.form, additionalProps: this.props.formProps, loading: this.props.loading, entity: this.state.currentEntity, onSave: (formData) => this.saveEntity(formData), onCancel: () => this.toList(), onInput: () => this.setState({ error: null }), error: this.state.error }),
                    Operation.DELETE === operation &&
                        React.createElement(EntityDelete, { loading: this.props.loading, entity: this.state.currentEntity, question: this.props.renderDeleteQuestion(this.state.currentEntity), onContinue: () => this.deleteEntity(), onCancel: () => this.toList(), error: this.state.error })));
            }
        });
        Object.defineProperty(this, "setEdit", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (currentEntity) => {
                this.setState({
                    operation: Operation.EDIT,
                    currentEntity,
                });
            }
        });
        Object.defineProperty(this, "setDelete", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (currentEntity) => {
                this.setState({
                    operation: Operation.DELETE,
                    currentEntity,
                });
            }
        });
        Object.defineProperty(this, "toList", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if (this.props.onEntityCancel) {
                    this.props.onEntityCancel();
                }
                this.setState({
                    operation: Operation.LIST,
                    currentEntity: null
                });
            }
        });
        Object.defineProperty(this, "saveEntity", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (formData) => {
                var _a;
                this.props.onEntitySave(formData, (_a = this.state.currentEntity) !== null && _a !== void 0 ? _a : undefined)
                    .then(() => {
                    this.setState({ operation: Operation.LIST, currentEntity: null });
                    this.openNotifyModal("savedSuccessfully");
                })
                    .catch((error) => {
                    this.setState({ error: error.message || 'An unexpected error occured' });
                });
            }
        });
        Object.defineProperty(this, "deleteEntity", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                if (!this.state.currentEntity)
                    return;
                this.props.onEntityDelete(this.state.currentEntity)
                    .then(() => {
                    this.setState({ operation: Operation.LIST, currentEntity: null });
                    this.openNotifyModal("deletedSuccessfully");
                })
                    .catch((error) => {
                    this.setState({ error: error.message || 'An unexpected error occured' });
                });
            }
        });
        Object.defineProperty(this, "openNotifyModal", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (notifyModalText) => {
                this.setState({ notifyModalVisible: true, notifyModalText });
            }
        });
        Object.defineProperty(this, "closeNotifyModal", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setState({ notifyModalVisible: false, notifyModalText: '' });
            }
        });
        this.state = {
            operation: Operation.LIST,
            currentEntity: null,
            notifyModalVisible: false,
            notifyModalText: null,
            error: null,
        };
    }
}
Object.defineProperty(EntityManager, "defaultProps", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: {
        loading: false,
    }
});
export function EntityManagerRoute(_a) {
    var { path } = _a, props = __rest(_a, ["path"]);
    const history = useHistory(), location = useLocation();
    const onClose = () => {
        if (typeof props.onClose === 'function') {
            props.onClose();
        }
        const prevPath = location.state && location.state.prevPath ? location.state.prevPath : '/';
        history.replace(prevPath);
    };
    return (React.createElement(Route, { exact: true, path: path, render: () => {
            return (React.createElement(EntityManager, Object.assign({}, props, { key: location.pathname, onClose: onClose, isRTL: getDirection() === 'rtl' })));
        } }));
}
function Header({ operation, title, onClose }) {
    const renderIcon = () => {
        switch (operation) {
            case Operation.CREATE:
                return (React.createElement(Icon, { className: styles.titleIcon, component: QuickRepliesGreenPlus }));
            case Operation.EDIT:
                return (React.createElement(Icon, { className: styles.titleIcon, component: EditIcon }));
            case Operation.DELETE:
                return (React.createElement(Icon, { className: styles.titleIcon, component: QuickRepliesDeleteIcon }));
            default:
                return null;
        }
    };
    return (React.createElement("div", { className: styles.header },
        React.createElement("div", { className: classNames(styles.title, {
                [styles.titleGreen]: operation !== Operation.DELETE,
                [styles.titleRed]: operation === Operation.DELETE,
            }) },
            renderIcon(),
            title),
        React.createElement("div", { onClick: () => onClose() },
            React.createElement("div", { className: styles.closeManagerWrapper },
                React.createElement(Icon, { component: QuickRepliesCross })))));
}
function EntityDelete(props) {
    return (React.createElement("div", { className: styles.deleteWrapper },
        React.createElement("div", { className: styles.deleteEditorWrapper },
            React.createElement("div", { className: styles.deleteEntityHolder },
                React.createElement("div", { className: styles.deleteEntityQuestion },
                    React.createElement(Text, null, props.question)))),
        React.createElement("div", { className: styles.deleteEntityBottom },
            React.createElement(Button, { className: classNames('big', styles.operationCancelButton), onClick: () => props.onCancel() },
                React.createElement(Text, null,
                    React.createElement(Trans, { i18nKey: "cancel" }))),
            React.createElement(Button, { disabled: props.loading, className: classNames('big', styles.operationContinueButton), type: "primary", danger: true, onClick: () => props.onContinue() }, !props.loading ? React.createElement("div", null,
                React.createElement(Trans, { i18nKey: "deleteSubmit" })) : React.createElement(LoadingOutlined, null)))));
}
function EntityFormWrap(props) {
    const Form = props.form, [formData, setFormData] = React.useState({}), [canBeSaved, setCanBeSaved] = React.useState(props.entity ? true : false);
    return (React.createElement(ErrorBoundary, null,
        React.createElement("div", { className: styles.editFormWrapper },
            React.createElement("div", { className: styles.editForm },
                React.createElement(Form, Object.assign({ entity: props.entity, onInput: (data, canSave) => {
                        setFormData(data);
                        setCanBeSaved(canSave !== false);
                        props.onInput(data, canSave !== false);
                    }, error: props.error, loading: props.loading }, props.additionalProps))),
            React.createElement("div", { className: styles.editFormBottom },
                React.createElement(Button, { className: classNames('big', styles.operationCancelButton), onClick: () => props.onCancel() },
                    React.createElement(Text, null,
                        React.createElement(Trans, { i18nKey: "cancel" }))),
                React.createElement(Button, { disabled: props.loading || !canBeSaved, className: classNames('big', styles.operationContinueButton), type: "primary", onClick: () => props.onSave(formData) }, !props.loading ? React.createElement("div", null,
                    React.createElement(Trans, { i18nKey: "saveChanges" })) : React.createElement(LoadingOutlined, null))))));
}
