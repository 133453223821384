import React, { useState } from 'react';
import { observer } from 'mobx-react';
import { Trans, withTranslation } from 'react-i18next';
import ActiveIcon from '../../../ActiveIcon';
import { ReactComponent as QuickRepliesCross } from '../../../../../assets/messageStatuses/closeCross.svg';
import { ReactComponent as QuickRepliesDeleteIcon } from '../../../../../assets/icons/deleteIcon/deleteIcon.svg';
import { Typography } from 'antd';
import classnames from 'classnames';
import { useStore } from '../../../../../store/rootStore';
import styles from './TemplateGeneratorTop.module.scss';
import { ReactComponent as QuickRepliesGreenPlus } from '../../../../../assets/icons/plus/greenPlus.svg';
import Icon from '@ant-design/icons';
import SubmitCancelModal from '../AddOrEditTemplate/SubmintCancelModal';
import ChannelSelect from '../../../ChatsList/ChannelSelect';
import useCheckIfMobile from '../../../../../helpers/hooks/useCheckIfMobile';
const { Text } = Typography;
const TemplateGeneratorTop = () => {
    const [showCancelModal, setShowCancelModal] = useState(false);
    const { templateMessagesStore, channels } = useStore();
    const isMobile = useCheckIfMobile();
    const { toggleNewTemplateDrawer, templateDeleting, templateEditing, templateCreating, setStoreToDefault, showManagerCancelModal, showCancelNotifyModal, setSelectedAccount, selectedAccountId, } = templateMessagesStore;
    const onClose = () => {
        if (showManagerCancelModal) {
            setShowCancelModal(true);
        }
        else {
            toggleNewTemplateDrawer();
            setStoreToDefault();
        }
    };
    const isListMode = !templateCreating && !templateEditing && !templateDeleting;
    return (React.createElement("div", { className: classnames(styles.wrapper, { [styles.deleteConfirmation]: templateDeleting }) },
        React.createElement("div", { className: styles.title },
            !templateDeleting && React.createElement(Icon, { className: styles.titleIcon, component: QuickRepliesGreenPlus }),
            templateDeleting && React.createElement(Icon, { className: styles.titleIcon, component: QuickRepliesDeleteIcon }),
            React.createElement(Text, { className: styles.titleText },
                isListMode && React.createElement(Trans, { i18nKey: "templateMessageManager" }),
                templateCreating && React.createElement(Trans, { i18nKey: "createTemplateMessage" }),
                templateEditing && React.createElement(Trans, { i18nKey: "editTemplateMessage" }),
                templateDeleting && React.createElement(Trans, { i18nKey: "deleteTemplateMessage" }))),
        React.createElement("div", { className: styles.channelSelectorWrapper },
            isListMode && (React.createElement(ChannelSelect, { accounts: channels.templateAccounts, activeChannel: selectedAccountId, onChannelClick: setSelectedAccount, className: styles.channelSelector, showAll: false, visible: !isMobile, showBorders: true })),
            React.createElement("div", { className: styles.closeModal },
                React.createElement(ActiveIcon, { component: QuickRepliesCross, action: onClose }))),
        showManagerCancelModal && (React.createElement(SubmitCancelModal, { visible: showCancelModal, onSubmit: () => {
                setShowCancelModal(false);
                toggleNewTemplateDrawer();
                setStoreToDefault();
                showCancelNotifyModal(false);
            }, onCancel: () => setShowCancelModal(false), i18key: "sureToCloseTemplates" }))));
};
export default withTranslation()(observer(TemplateGeneratorTop));
