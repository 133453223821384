import React from 'react';
import styles from './ChatNote.module.scss';
import blankProfilePicture from "../../../../../assets/avatars/blankProfilePicture.png";
import { ReactComponent as TrashOutlinedIcon } from '../../../../../assets/icons/deleteIcon/trash-bin-red.svg';
import Icon from '@ant-design/icons';
import { Popconfirm } from 'antd';
import i18n from '../../../../../i18n';
import moment from 'moment/moment';
const ChatNote = (props) => {
    const { text, createdAt, agentEmail, avatar, handleDeleteNote, hasPermission } = props;
    const renderActions = () => {
        if (!hasPermission)
            return null;
        return (React.createElement("div", { className: styles.chatNoteActions },
            React.createElement(Popconfirm, { placement: "left", title: i18n.t('deleteChatNote'), okText: i18n.t('deleteConfirm'), cancelText: i18n.t('cancel'), onConfirm: handleDeleteNote },
                React.createElement(Icon, { component: TrashOutlinedIcon }))));
    };
    return (React.createElement("div", { className: styles.singleChatNote },
        React.createElement("img", { src: avatar !== null && avatar !== void 0 ? avatar : blankProfilePicture, className: styles.chatNoteAvatar, alt: "Agent avatar" }),
        React.createElement("div", { className: styles.chatNoteInfoBlock },
            React.createElement("div", { className: styles.chatNoteName }, agentEmail || 'Unknown'),
            React.createElement("div", { className: styles.chatNoteText }, text),
            React.createElement("div", { className: styles.chatNoteDate }, moment(createdAt).format('hh:mm A, MMMM Do YYYY'))),
        renderActions()));
};
export default ChatNote;
