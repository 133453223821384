import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { withRouter } from 'react-router-dom';
import { CHAT_STATUS } from '../../../../constants';
import styles from './TopSideBar.module.scss';
import TopBarButton from './atoms/TopBarButton';
import { withStore } from '../../../../store/rootStore';
import { CHATS_GROUP } from '../../../../store/ChatsStore';
class TopSideBar extends Component {
    constructor() {
        super(...arguments);
        Object.defineProperty(this, "onTabSelected", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (chatsGroup) => {
                const { activeChatStore, chatsStore } = this.props.store;
                if (chatsStore.currentGroup !== chatsGroup) {
                    chatsStore.setCurrentGroup(chatsGroup);
                    activeChatStore.removeActiveChat();
                }
                const chatList = document.getElementById('chatsList');
                if (chatList) {
                    chatList.scrollTop = 0;
                }
                this.props.history.push('/');
            }
        });
    }
    render() {
        const { activeChatStore, chatsStore } = this.props.store, { count, countLoading, currentGroup } = chatsStore;
        return (React.createElement("div", null, !activeChatStore.chat || window.innerWidth > 1026 ?
            React.createElement("div", { className: styles.topSidebar },
                React.createElement(TopBarButton, { status: CHAT_STATUS.PENDING, numberOfChats: count.status.PENDING, handleClick: () => this.onTabSelected(CHATS_GROUP.PENDING), active: currentGroup === CHATS_GROUP.PENDING, loading: countLoading }),
                React.createElement(TopBarButton, { status: CHAT_STATUS.ASSIGNED, numberOfChats: count.status.ASSIGNED, handleClick: () => this.onTabSelected(CHATS_GROUP.ASSIGNED), active: currentGroup === CHATS_GROUP.ASSIGNED, loading: countLoading }),
                React.createElement(TopBarButton, { numberOfChats: count.all, handleClick: () => this.onTabSelected(CHATS_GROUP.ALL), active: currentGroup === CHATS_GROUP.ALL, loading: countLoading }),
                React.createElement(TopBarButton, { status: CHAT_STATUS.BOT, numberOfChats: count.status.BOT, handleClick: () => this.onTabSelected(CHATS_GROUP.BOT), active: currentGroup === CHATS_GROUP.BOT, loading: countLoading }),
                React.createElement(TopBarButton, { status: CHAT_STATUS.RESOLVED, numberOfChats: count.status.RESOLVED, handleClick: () => this.onTabSelected(CHATS_GROUP.RESOLVED), active: currentGroup === CHATS_GROUP.RESOLVED, loading: countLoading }),
                React.createElement(TopBarButton, { status: CHAT_STATUS.BULK, numberOfChats: count.status.BULK, handleClick: () => this.onTabSelected(CHATS_GROUP.BULK), active: currentGroup === CHATS_GROUP.BULK, loading: countLoading }))
            : null));
    }
}
export default withRouter(withStore(observer(TopSideBar)));
