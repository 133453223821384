import React, { useEffect, useState } from 'react';
import { Button, Checkbox, DatePicker, Form, Radio, Space, Switch, TimePicker } from 'antd';
import { MySelect } from '../../../../../Base/Form';
import { ReactComponent as CalendarIcon } from '../../../../../../assets/icons/noGroupIcons/calendar-check-line.svg';
import { useForm } from 'antd/lib/form/Form';
import { Trans, useTranslation } from 'react-i18next';
import moment from 'moment';
import { omit, throttle } from 'lodash';
import ProblemsList from './ProblemAlert';
import s from '../ExportChats.module.scss';
const generateRangeFromPeriod = (period) => {
    switch (period) {
        case 'thisMonth':
            return {
                start: moment().startOf('month').toString(),
                end: moment().endOf('month').toString(),
            };
        case 'last3Months':
            return {
                start: moment().subtract(3, 'months').toString(),
                end: moment().toString(),
            };
        case 'last6Months':
            return {
                start: moment().subtract(6, 'months').toString(),
                end: moment().toString(),
            };
        case 'allTime':
            return null;
        default:
            return null;
    }
};
const CreateExport = ({ onCancel, onCreate, onValidate, accounts, loading, problems }) => {
    const [form] = useForm();
    const { t } = useTranslation();
    const [period, setPeriod] = useState('thisMonth');
    const [isScheduled, setIsScheduled] = useState(false);
    const [scheduledDate, setScheduledDate] = useState(null);
    const [scheduledTime, setScheduledTime] = useState(null);
    const getComposedDatetime = (date, time) => {
        return date.clone().set({
            hour: time.hour(),
            minute: time.minute(),
        });
    };
    const scheduledDatetime = isScheduled && scheduledDate && scheduledTime
        ? getComposedDatetime(scheduledDate, scheduledTime)
        : null;
    const _formatValues = (values) => {
        const { period, customDateRange, channelsAccounts } = values;
        const dateRange = period === 'customRange' && customDateRange && customDateRange[0] && customDateRange[1]
            ? {
                start: customDateRange[0].toString(),
                end: customDateRange[1].toString(),
            }
            : generateRangeFromPeriod(period);
        let formData = Object.assign({}, omit(values, 'channelsAccounts', 'customDateRange', 'period', 'scheduledDate', 'scheduledTime'));
        if (dateRange) {
            formData.dateRange = dateRange;
        }
        if (channelsAccounts && channelsAccounts.length > 0) {
            formData.channelsAccounts = channelsAccounts.map((account) => {
                const [accountId, name] = account.split('-');
                return {
                    accountId,
                    name,
                };
            });
        }
        if (isScheduled && values.scheduledDate && values.scheduledTime) {
            formData = Object.assign(Object.assign({}, formData), { scheduled: getComposedDatetime(values.scheduledDate, values.scheduledTime).unix() * 1000 });
        }
        return formData;
    };
    // This variable needed to bypass setting timepicker in form when need to schedule on some timestamp,
    // because timepicker brings problems with rounding value sometimes to less that time that allowed.
    let scheduleOnTime;
    const onSubmit = (values) => {
        const formData = _formatValues(values);
        let s;
        if (!scheduleOnTime) {
            s = formData.scheduled;
        }
        else {
            s = scheduleOnTime;
            scheduleOnTime = undefined;
        }
        onCreate(Object.assign(Object.assign({}, formData), { scheduled: s }));
    };
    const validateFormValues = throttle((values) => {
        /** Don't run validation until range is completed */
        if (values.period === 'customRange' &&
            (!values.customDateRange || !values.customDateRange[0] || !values.customDateRange[1])) {
            return;
        }
        const formData = _formatValues(values);
        onValidate(formData)
            .catch((err) => {
            console.error('Error validating export', err);
        });
    }, 1000);
    const onScheduleExport = (timestamp) => {
        scheduleOnTime = timestamp;
        form.submit();
    };
    useEffect(() => {
        validateFormValues(form.getFieldsValue());
    }, [isScheduled]);
    return (React.createElement("div", { className: s.formWrapper },
        React.createElement(Form, { onFinish: onSubmit, onValuesChange: (_, allValues) => validateFormValues(allValues), form: form, layout: "vertical", disabled: loading, className: s.form },
            React.createElement(ProblemsList, { problems: problems, onScheduleExport: onScheduleExport }),
            React.createElement(Form.Item, { label: React.createElement(Trans, { i18nKey: "dateRange" }), className: s.formItem },
                React.createElement(Form.Item, { name: "period", noStyle: true },
                    React.createElement(Radio.Group, { value: period, onChange: (e) => setPeriod(e.target.value) },
                        React.createElement(Space, { direction: "vertical", size: 8 },
                            React.createElement(Radio, { value: "thisMonth" },
                                React.createElement(Trans, { i18nKey: "thisMonth" })),
                            React.createElement(Radio, { value: "last3Months" },
                                React.createElement(Trans, { i18nKey: "last3Months" })),
                            React.createElement(Radio, { value: "last6Months" },
                                React.createElement(Trans, { i18nKey: "last6Months" })),
                            React.createElement(Radio, { value: "allTime" },
                                React.createElement(Trans, { i18nKey: "allTime" })),
                            React.createElement(Radio, { value: "customRange" },
                                React.createElement(Trans, { i18nKey: "customRange" }))))),
                period === 'customRange' && (React.createElement(Form.Item, { name: "customDateRange", noStyle: true },
                    React.createElement(DatePicker.RangePicker, { format: "MMM Do YY", picker: "date", placeholder: [t('allPeriod'), t('allPeriod')], className: s.rangePicker, dropdownClassName: s['reports-selector__datePickerDropdown'], suffixIcon: React.createElement(CalendarIcon, { className: s['reports-selector__inputIcon'] }), allowClear: false, disabledDate: (current) => current && current > moment().endOf('day') })))),
            React.createElement(Form.Item, { label: React.createElement(Trans, { i18nKey: "channelsAccounts" }), name: "channelsAccounts", className: s.formItem },
                React.createElement(MySelect, { mode: "multiple", options: accounts.map((account) => {
                        var _a;
                        return ({
                            label: account.title || ((_a = account.metadata) === null || _a === void 0 ? void 0 : _a.accountTitle),
                            value: `${account.accountId}-${account.channel}`,
                        });
                    }) })),
            React.createElement(Form.Item, { label: React.createElement(Trans, { i18nKey: "includeAttachments" }), name: "includeAttachments", valuePropName: "checked", className: s.formItem },
                React.createElement(Switch, { className: s.switch })),
            React.createElement(Form.Item, { label: React.createElement(Trans, { i18nKey: "includeEmptyChats" }), name: "includeEmptyChats", valuePropName: "checked", className: s.formItem },
                React.createElement(Switch, { className: s.switch })),
            React.createElement(Form.Item, { className: s.formItem },
                React.createElement("div", { className: s.scheduleWrapper },
                    React.createElement(Checkbox, { checked: isScheduled, onChange: () => setIsScheduled(!isScheduled) },
                        React.createElement(Trans, { i18nKey: "scheduleDelayedExport" })),
                    isScheduled && (React.createElement(React.Fragment, null,
                        React.createElement(Form.Item, { label: React.createElement(Trans, { i18nKey: "date" }), name: "scheduledDate", noStyle: true },
                            React.createElement(DatePicker, { value: scheduledDate, onChange: setScheduledDate, format: "MMM Do YY", className: s.scheduledPicker, disabledDate: (current) => current && current < moment().startOf('day'), allowClear: false, showToday: false })),
                        React.createElement(Form.Item, { label: React.createElement(Trans, { i18nKey: "time" }), name: "scheduledTime", noStyle: true },
                            React.createElement(TimePicker, { value: scheduledTime, onChange: setScheduledTime, className: s.scheduledPicker, format: "HH:mm", minuteStep: 15, inputReadOnly: true, showNow: false, allowClear: false }))))))),
        React.createElement("div", { className: s.footer },
            React.createElement(Button, { className: s.button, size: "large", onClick: onCancel },
                React.createElement(Trans, { i18nKey: "cancel" })),
            React.createElement(Button, { htmlType: "submit", type: "primary", size: "large", className: s.button, loading: loading, disabled: problems.length > 0, onClick: form.submit, title: problems.length > 0 ? 'Please check errors' : undefined },
                !scheduledDatetime &&
                    React.createElement(Trans, { i18nKey: "export" }),
                !!scheduledDatetime &&
                    React.createElement(Trans, { i18nKey: "scheduleExport", values: { time: scheduledDatetime.calendar() } })))));
};
export default CreateExport;
