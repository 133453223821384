import { WarningOutlined } from '@ant-design/icons';
import { Trans, useTranslation } from 'react-i18next';
import moment from 'moment';
import { Exports } from '@whatsper/texterchat-common';
import { Alert, Button, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import { formatDaySecondsAsTime } from '../../../../../../helpers/time';
import s from '../ExportChats.module.scss';
var Validation = Exports.Validation;
const WEEKDAY_NAMES_LIST = {
    0: 'Sunday',
    1: 'Monday',
    2: 'Tuesday',
    3: 'Wednesday',
    4: 'Thursday',
    5: 'Friday',
    6: 'Saturday',
};
const ProblemsList = ({ problems, onScheduleExport }) => {
    return (React.createElement(React.Fragment, null, problems.map((problem, index) => {
        const renderDescription = () => {
            switch (problem.type) {
                case Validation.ProblemType.timeOfTheDay:
                    return React.createElement(TimeOfTheDayAlertDescription, { problem: problem });
                case Validation.ProblemType.tooManyChats:
                    return React.createElement(TooManyChatsAlertDescription, { problem: problem });
                case Validation.ProblemType.tooSoon:
                    return React.createElement(TooSoonAlertDescription, { problem: problem, onSchedule: () => onScheduleExport(problem.nextAllowed) });
            }
        };
        return (React.createElement(Alert, { key: `${problem.type}${index}`, message: React.createElement(Trans, { i18nKey: `${problem.type}Error` }), description: React.createElement("span", { className: s.alertDescription }, renderDescription()), className: s.alert, type: "error", icon: React.createElement(WarningOutlined, null), showIcon: true }));
    })));
};
function TimeOfTheDayAlertDescription({ problem }) {
    const { t } = useTranslation();
    const [weekSchedule, setWeekSchedule] = useState({});
    const formatTimeframe = (timeframe) => {
        return `${formatDaySecondsAsTime(timeframe.begin)} - ${formatDaySecondsAsTime(timeframe.end)}`;
    };
    useEffect(() => {
        const schedule = {};
        Object.entries(problem.weekSchedule).forEach(([day, timeframes]) => {
            const dayName = WEEKDAY_NAMES_LIST[Number(day)];
            schedule[dayName] = timeframes
                .map(formatTimeframe)
                .join(', ');
        });
        setWeekSchedule(schedule);
    }, [problem.weekSchedule]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Trans, { i18nKey: "timeOfTheDayErrorDescription", values: {
                day: t(WEEKDAY_NAMES_LIST[problem.exceptTime.weekday]),
                timeframe: formatTimeframe(problem.exceptTime.period),
            }, components: { b: React.createElement("strong", null) }, key: "timeOfTheDayErrorDescription" }),
        React.createElement(Tooltip, { overlayStyle: { maxWidth: 300 }, title: React.createElement("div", { className: s.scheduleTooltip },
                React.createElement("div", null,
                    React.createElement(Trans, { i18nKey: "exportNotAllowedLabel" })),
                Object.entries(weekSchedule).map(([day, time]) => (React.createElement("div", null,
                    React.createElement(Trans, { i18nKey: day }),
                    ": ",
                    time)))), key: "timeOfTheDayErrorTooltip" },
            React.createElement(Button, { className: s.button, size: "small", type: "primary" },
                React.createElement(Trans, { i18nKey: "weekSchedule" })))));
}
function TooManyChatsAlertDescription({ problem }) {
    return (React.createElement(Trans, { i18nKey: "tooManyChatsErrorDescription", values: problem, components: { b: React.createElement("strong", null) } }));
}
function TooSoonAlertDescription({ problem, onSchedule }) {
    const nextAllowed = moment(problem.nextAllowed).calendar();
    return (React.createElement(React.Fragment, null,
        React.createElement(Trans, { i18nKey: `tooSoonErrorDescription_${problem.reason}` }),
        React.createElement(Button, { onClick: onSchedule, className: s.button, size: "small", type: "primary", key: "tooSoonErrorButton" },
            React.createElement(Trans, { i18nKey: "scheduleExport", values: { time: nextAllowed } }))));
}
export default ProblemsList;
