import React from 'react';
import { Button, Typography } from 'antd';
import Icon from '@ant-design/icons';
import classNames from 'classnames';
import { Trans } from 'react-i18next';
import { ReactComponent as QuickRepliesDeleteIcon } from '../../../../../assets/icons/deleteIcon/deleteIcon.svg';
import styles from './DeleteConfirmation.module.scss';
const { Text } = Typography;
const DeleteConfirmation = ({ reply, deleteReply, onCancel }) => {
    return (React.createElement("div", { className: styles.addReplyWrapper },
        React.createElement("div", { className: styles.titleRepliesDeleteConfirmation },
            React.createElement("div", { className: styles.redDeleteIcon },
                React.createElement(Icon, { component: QuickRepliesDeleteIcon })),
            React.createElement(Text, { className: styles.repliesAddTitleText },
                React.createElement(Trans, { i18nKey: "sureDeleteReply" }))),
        React.createElement("div", { className: styles.repliesTextEditorWrapper },
            React.createElement("div", { className: styles.deleteReplyHolder },
                React.createElement("div", { className: styles.deleteReplyText },
                    React.createElement(Text, null, reply.text)))),
        React.createElement("div", { className: styles.addReplyBottom },
            React.createElement(Button, { className: classNames('big', styles.addReplyCancelButton), onClick: onCancel },
                React.createElement(Text, null,
                    React.createElement(Trans, { i18nKey: "cancel" }))),
            React.createElement(Button, { className: classNames('big', styles.addReplyDeleteButton), type: "primary", danger: true, onClick: () => deleteReply() },
                React.createElement(Text, null,
                    React.createElement(Trans, { i18nKey: "deleteSubmit" }))))));
};
export default DeleteConfirmation;
