import React, { Suspense, useEffect } from 'react';
import { observer } from 'mobx-react';
import { MuiThemeProvider } from '@material-ui/core';
import MainPageLayout from './components/MainPage/MainPageLayout';
import Login from './components/Login';
import PrivateRoute from './routes/PrivateRoute';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import LoadingScreen from './components/LoadingScreen';
import muiTheme from './styles/muiTheme';
import { useStore } from './store/rootStore';
import { USER_STATES } from './constants';
import useHandleInactiveTab from './helpers/hooks/useHandleInactiveTab';
import lazyWithRetry from './helpers/lazyWithRetry';
import 'antd/dist/antd.min.css';
import 'moment/locale/he';
import './App.css';
import './globalStyles.css';
const BotAdmin = lazyWithRetry(() => import('./components/BotAdmin/BotAdmin'));
const App = () => {
    const { loginStore, appState } = useStore();
    const inactiveTabModal = useHandleInactiveTab();
    const isLoggedIn = loginStore.userState === USER_STATES.LOGGED_IN;
    useEffect(() => {
        loginStore.getCurrentUser();
    }, []);
    if (loginStore.loading) {
        return React.createElement(LoadingScreen, null);
    }
    return (React.createElement(MuiThemeProvider, { theme: muiTheme },
        React.createElement(Router, null,
            React.createElement(Suspense, { fallback: React.createElement(LoadingScreen, null) },
                React.createElement(Switch, null,
                    React.createElement(Route, { exact: true, path: "/login", component: Login }),
                    React.createElement(PrivateRoute, { path: "/bot-admin", component: BotAdmin, isLoggedIn: isLoggedIn, appState: appState }),
                    React.createElement(PrivateRoute, { path: "/:section?", component: MainPageLayout, isLoggedIn: isLoggedIn, appState: appState })),
                inactiveTabModal))));
};
export default observer(App);
