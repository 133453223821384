import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import parsePhoneNumber, { formatNumber } from 'libphonenumber-js';
import { PHONE_FORMAT } from '../../../constants';
import { useStore } from '../../../store/rootStore';
function formatNational(number, country) {
    const formatted = formatNumber(number, country, 'NATIONAL', { humanReadable: true }).replace(/\s/g, '-');
    const prefixSplit = formatted.indexOf('-');
    if (-1 !== prefixSplit) {
        return formatted.substring(0, prefixSplit) + '-' + formatted.substring(prefixSplit).replace(/[^\d]/g, '');
    }
    return formatted;
}
function formatInternational(number, country) {
    return formatNumber(number, country, 'INTERNATIONAL').replace(/\s/g, '-');
}
export function formatPhone(phone, format, defaultCountry) {
    let parsedNumber;
    // Try to infer the country from the phone number
    if (phone.startsWith('+')) {
        parsedNumber = parsePhoneNumber(phone);
    }
    // If no country inferred, try to parse against default country
    if (!parsedNumber) {
        parsedNumber = parsePhoneNumber(phone.toString(), defaultCountry);
    }
    // Try to add + if not present
    if (!parsedNumber && !phone.startsWith('+')) {
        phone = '+' + phone;
        parsedNumber = parsePhoneNumber(phone, defaultCountry);
    }
    if (!parsedNumber) {
        // Invalid - can't handle, so return as is.
        return phone;
    }
    if (PHONE_FORMAT.SMART === format) {
        return parsedNumber.country === defaultCountry
            ? formatNational(parsedNumber.nationalNumber, parsedNumber.country)
            : formatInternational(parsedNumber.nationalNumber, parsedNumber.country);
    }
    else if (PHONE_FORMAT.NATIONAL === format) {
        return formatNational(parsedNumber.nationalNumber, parsedNumber.country);
    }
    return formatInternational(parsedNumber.nationalNumber, parsedNumber.country);
}
/**
 * Format number in requested format,
 * or if format property not specified - in national
 * if phone coutry same as customer default coutry
 * and international if not same
 */
const PhoneFormatter = ({ phone }) => {
    const store = useStore();
    const [formatted, setFormatted] = useState('');
    useEffect(() => {
        setFormatted(formatPhone(phone, store.phoneFormat, store.defaultCountry));
    }, [phone, store.phoneFormat, store.defaultCountry]);
    return React.createElement(React.Fragment, null, formatted);
};
export default observer(PhoneFormatter);
