import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { getPortalDOMId, getPortalKey } from './common';
export const Portal = ({ name, children, waitHost }) => {
    const id = getPortalDOMId(name);
    const [element, setElement] = useState(document.getElementById(id));
    // Sometimes happens race conditions when portal host DOM element is not rendered/mounted yet,
    // so we need optionally to wait for it.
    // I have tried MutationObserver and spend few hours trying to make it work, 
    // but seems in React environment it is not so easy to use it.
    // So using dead-simple setInterval for now.
    useEffect(() => {
        if (!element && waitHost) {
            let interval = setInterval(() => {
                const e = document.getElementById(id);
                if (e) {
                    setElement(e);
                    clearInterval(interval);
                }
            }, 100);
            return () => clearInterval(interval);
        }
    }, []);
    return element ? createPortal(children, element, getPortalKey(name)) : null;
};
