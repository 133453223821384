import { LoadingOutlined } from '@ant-design/icons';
import { Checkbox, Select, Typography } from 'antd';
import { observer } from 'mobx-react';
import React, { Component } from 'react';
import { Trans } from "react-i18next";
import { getAxios } from '../../backend';
import { API_PATH } from '../../constants';
import { withStore } from '../../store/rootStore';
import { MySelect } from '../Base/Form';
import { Permissions } from '@whatsper/texterchat-common';
const { Option } = Select;
const { Text } = Typography;
const NONE = '__NONE__';
var Usage;
(function (Usage) {
    Usage["default"] = "default";
    Usage["user"] = "user";
})(Usage || (Usage = {}));
class NewAuthorization extends Component {
    constructor(props) {
        super(props);
        Object.defineProperty(this, "state", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                loading: false,
                serviceSelected: NONE,
                scopeSelected: [],
                services: {},
                usage: Usage.default,
                canManageOwn: false,
                canManageDefault: false,
            }
        });
        Object.defineProperty(this, "componentDidMount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.servicesLoad();
            }
        });
        Object.defineProperty(this, "servicesLoad", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setState({ loading: true });
                getAxios()
                    .then((axios) => {
                    return axios.get(`${API_PATH.AUTH}/oauth/services`);
                })
                    .then(({ data }) => {
                    const services = {};
                    data.forEach((service) => {
                        services[service.name] = service;
                    });
                    this.setState({ services });
                })
                    .catch((error) => {
                    console.error(error);
                })
                    .finally(() => {
                    this.setState({ loading: false });
                });
            }
        });
        Object.defineProperty(this, "renderScopesChoose", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { serviceSelected, services } = this.state;
                const scopeSelected = this.state.scopeSelected;
                if (serviceSelected === NONE) {
                    return null;
                }
                const scope = services[serviceSelected].scope || [];
                return (React.createElement("div", null,
                    React.createElement("h4", null, "Authorize scope"),
                    scope.sort().map((name) => {
                        return (React.createElement(Checkbox, { checked: name ? scopeSelected.includes(name) : false, key: name, onChange: (e) => {
                                this.onScopeChanged(name, e.target && e.target.checked);
                            } }, name));
                    })));
            }
        });
        Object.defineProperty(this, "onScopeChanged", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (name, selected) => {
                let scopeSelected = this.state.scopeSelected;
                if (selected && !scopeSelected.includes(name)) {
                    scopeSelected.push(name);
                }
                else {
                    scopeSelected = scopeSelected.filter((n) => n !== name);
                }
                this.setValues({ scopeSelected });
            }
        });
        Object.defineProperty(this, "setValues", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (values) => {
                this.setState(values, () => {
                    const { serviceSelected, scopeSelected, services, usage } = this.state;
                    this.props.onInput({
                        service: services[serviceSelected] || null,
                        scope: scopeSelected,
                        usage,
                    }, this.state.serviceSelected !== NONE);
                });
            }
        });
        const canManageAll = props.store.agentsStore.hasPermissions([Permissions.Permission.oauth_manage_all]);
        if (canManageAll) {
            this.state.canManageOwn = true;
            this.state.canManageDefault = true;
        }
        else {
            this.state.canManageOwn = props.store.agentsStore.hasPermissions([Permissions.Permission.oauth_manage_own]);
            this.state.canManageDefault = props.store.agentsStore.hasPermissions([Permissions.Permission.oauth_manage_default]);
            if (!this.state.canManageDefault) {
                this.state.usage = Usage.user;
            }
        }
    }
    render() {
        const { serviceSelected, services, loading, usage, canManageDefault, canManageOwn, } = this.state;
        if (loading) {
            return React.createElement(LoadingOutlined, { className: "loader-spinner" });
        }
        return (React.createElement(React.Fragment, null,
            React.createElement("div", null,
                React.createElement(MySelect, { value: serviceSelected, onChange: (serviceSelected) => this.setValues({ serviceSelected }) },
                    serviceSelected === NONE &&
                        React.createElement(Option, { disabled: true, value: NONE }, "Choose service"),
                    Object.values(services).map((service) => {
                        return (React.createElement(Option, { key: service.name, value: service.name }, service.title || service.name));
                    }))),
            (canManageDefault || canManageOwn) &&
                React.createElement("div", null,
                    React.createElement("label", { style: { display: 'block' } },
                        React.createElement(Text, null,
                            React.createElement(Trans, { i18nKey: "oauthTokenUsage" }))),
                    React.createElement(MySelect, { value: usage, onChange: (value) => this.setValues({ usage: value }), options: [
                            ...(canManageDefault ? [{
                                    label: React.createElement(Trans, { i18nKey: "oauthTokenUsageApp" }),
                                    value: Usage.default,
                                }] : []),
                            ...(canManageOwn ? [{
                                    label: React.createElement(Trans, { i18nKey: "oauthTokenUsageMy" }),
                                    value: Usage.user
                                }] : []),
                        ] })),
            (serviceSelected !== NONE && services[serviceSelected].description) &&
                React.createElement("div", null, services[serviceSelected].description),
            this.renderScopesChoose()));
    }
}
export default withStore(observer(NewAuthorization));
