import React from 'react';
import { Modal } from 'antd';
import { Trans } from "react-i18next";
import classnames from "classnames";
import Icon from "@ant-design/icons";
import styles from "./SimpleModal.module.scss";
import { ReactComponent as QuickRepliesCross } from "../../../../assets/messageStatuses/closeCross.svg";
/**
 * @deprecated THIS NEEDS TO BE REWRITTEN!!!
 *
 * @param props
 */
const ModalWithoutBottom = (props) => {
    const { visible, setModalVisible, afterClose, children, titleTextKey, titleTextValues, error, height, width, centerContent, headerIcon: HeaderIcon, buttons } = props;
    return (React.createElement(Modal, { width: width || 800, open: visible, closable: false, onCancel: setModalVisible, afterClose: () => {
            if (afterClose)
                afterClose();
        }, footer: null, centered: true, bodyStyle: {
            padding: '0',
            boxShadow: '0px 0px 4px rgba(0, 0, 0, 0.2)',
            borderRadius: '2px',
        } },
        React.createElement("div", { className: styles.simpleModalContainer, style: { height: height && `${height}vh` } },
            React.createElement("div", { className: styles.simpleModalHeader },
                React.createElement("div", { className: classnames(styles.simpleModalTitle, { [styles.simpleModalGreenUnderline]: !error, [styles.simpleModalRedUnderline]: error }) },
                    HeaderIcon && React.createElement(Icon, { className: styles.simpleModalTitleIcon, component: () => React.createElement(HeaderIcon, null) }),
                    React.createElement(Trans, { i18nKey: `${titleTextKey}`, values: titleTextValues })),
                React.createElement("div", { className: styles.closeModal, onClick: () => setModalVisible() },
                    React.createElement("div", { className: styles.closeIconWrapper },
                        React.createElement(Icon, { component: QuickRepliesCross })))),
            React.createElement("div", { className: classnames(styles.content, { [styles.centerContent]: centerContent }) }, children),
            !!buttons &&
                React.createElement("div", { className: styles.buttonsWrap }, buttons))));
};
export default ModalWithoutBottom;
