import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Modal } from "antd";
import classNames from "classnames";
import WizardHeader from "./WizardHeader";
import WizardBody from "./WizardBody";
import WizardBottom from "./WizardBottom";
import { withStore } from "../../store/rootStore";
import { getDirection } from "../../helpers";
import s from "./WizardModal.module.scss";
const WizardModal = ({ closeModal, store, visible }) => {
    const [selectedAccountId, setSelectedAccountId] = useState(null);
    useEffect(() => {
        if (selectedAccountId) {
            store.templateMessagesStore.syncAndLoadTemplateMessages(selectedAccountId.id);
        }
    }, [selectedAccountId]);
    return (React.createElement(Modal, { width: 800, open: visible, closable: false, onCancel: () => {
            closeModal();
            store.wizardStore.resetStore();
        }, footer: null, centered: true, bodyStyle: {
            padding: "0",
            boxShadow: "0px 0px 4px rgba(0, 0, 0, 0.2)",
            borderRadius: "2px",
        } },
        React.createElement("div", { className: classNames(s.bulkMessageWrapper, {
                [s.rtl]: getDirection() === "rtl",
            }) },
            React.createElement(WizardHeader, { closeModal: closeModal }),
            React.createElement(WizardBody, { selectedAccountId: selectedAccountId, setSelectedAccountId: setSelectedAccountId }),
            React.createElement(WizardBottom, { closeModal: closeModal, selectedAccountId: selectedAccountId }))));
};
export default withStore(observer(WizardModal));
