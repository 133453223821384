import React from 'react';
import { Checkbox, Tooltip } from 'antd';
import { Trans } from 'react-i18next';
import classNames from 'classnames';
import { MEDIA_TYPE } from '@whatsper/texterchat-common';
import LoadingIcon from '../../../../../Chat/common/LoadingIcon';
import DocumentMessage from '../../../../../Chat/MessagesTypes/DocumentMessage';
import s from '../FilesMediaLinks.module.scss';
const MediaItem = ({ isLegacy, mediaGroup, onDownloadLegacyImage, selectedFiles, getFilePreviewUrl, getFileUrl, onLoadedData, onFileSelect, videosLoaded, showCheckbox, mediaItem, background, }) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const { fileId, filename, mediaType, url } = mediaItem;
    return (React.createElement(Tooltip, { overlayStyle: { maxWidth: 300 }, placement: "right", title: isLegacy ? (React.createElement("div", { className: s.tooltipContainer },
            React.createElement("div", { className: s.tooltipInfo },
                React.createElement(Trans, { i18nKey: "legacyFileNoGroupDownload" })),
            React.createElement("div", { className: s.tooltipAction, onClick: () => {
                    if (url) {
                        onDownloadLegacyImage(url, filename);
                    }
                } },
                React.createElement(Trans, { i18nKey: 'downloadHere' })))) : undefined },
        React.createElement("div", { className: classNames(s[`${mediaGroup}Box`], {
                [s.legacy]: isLegacy,
            }), onClick: () => {
                if (isLegacy)
                    return;
                onFileSelect(fileId === null || fileId === void 0 ? void 0 : fileId.toString());
            } },
            mediaType === MEDIA_TYPE.IMAGE ? (React.createElement("img", { src: fileId ? getFilePreviewUrl(fileId.toString()) : url, alt: filename || 'image preview', className: classNames(s.image, {
                    [s.checkedBox]: selectedFiles.includes((_a = fileId === null || fileId === void 0 ? void 0 : fileId.toString()) !== null && _a !== void 0 ? _a : ''),
                }) })) : mediaType === MEDIA_TYPE.VIDEO ? (React.createElement("div", { className: classNames(s.videoWrapper, {
                    [s.loading]: !videosLoaded[(_b = fileId === null || fileId === void 0 ? void 0 : fileId.toString()) !== null && _b !== void 0 ? _b : ''],
                    [s.checkedBox]: selectedFiles.includes((_c = fileId === null || fileId === void 0 ? void 0 : fileId.toString()) !== null && _c !== void 0 ? _c : ''),
                }) },
                React.createElement("video", { src: fileId ? getFileUrl(fileId.toString()) : url, onLoadedData: () => onLoadedData(fileId === null || fileId === void 0 ? void 0 : fileId.toString()) }),
                !videosLoaded[(_d = fileId === null || fileId === void 0 ? void 0 : fileId.toString()) !== null && _d !== void 0 ? _d : ''] && (React.createElement("div", { className: s.videoLoader },
                    React.createElement(LoadingIcon, { additionalStyles: s['loading-icon'] }))))) : mediaType === MEDIA_TYPE.DOC ? (React.createElement("div", { className: s.docBox, style: { background }, onClick: () => onFileSelect(fileId === null || fileId === void 0 ? void 0 : fileId.toString()) },
                React.createElement(Checkbox, { checked: selectedFiles.includes((_e = fileId === null || fileId === void 0 ? void 0 : fileId.toString()) !== null && _e !== void 0 ? _e : ''), className: s.docBoxCheckbox }),
                React.createElement(DocumentMessage, { message: {
                        media: [mediaItem],
                    }, additionalClassName: s.docBoxMessage }))) : null,
            showCheckbox && (React.createElement(Checkbox, { className: classNames(s.mediaBoxCheckbox, {
                    [s.checked]: selectedFiles.includes((_f = fileId === null || fileId === void 0 ? void 0 : fileId.toString()) !== null && _f !== void 0 ? _f : ''),
                }), checked: selectedFiles.includes((_g = fileId === null || fileId === void 0 ? void 0 : fileId.toString()) !== null && _g !== void 0 ? _g : ''), disabled: !!isLegacy })))));
};
export default MediaItem;
