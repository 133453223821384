import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as serviceWorker from './serviceWorker';
import { getStore } from './store/rootStore';
import App from './App';
import Head from './Head';
import { getConfig } from './settings';
import { I18nextProvider } from 'react-i18next';
import i18n from './i18n';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import { setMomentLocaleOnLanguageChange } from './components/LeftDrawer/LanguageSelect/helpers';
setMomentLocaleOnLanguageChange(i18n.language);
getConfig()
    .then(({ theme }) => {
    const rootStore = getStore();
    if (theme === null || theme === void 0 ? void 0 : theme.name) {
        rootStore.setPartnerTheme(theme.name);
    }
    ReactDOM.render(React.createElement(ErrorBoundary, null,
        React.createElement(I18nextProvider, { i18n: i18n },
            React.createElement(Head, null),
            React.createElement(App, null))), document.getElementById('root'));
})
    .catch((error) => {
    console.error('Error initializing app', error);
});
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register();
