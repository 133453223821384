import React, { useEffect, useState } from 'react';
import { TbMicrophone } from 'react-icons/tb';
import { Trans } from 'react-i18next';
import { SendOutlined } from '@ant-design/icons';
import classNames from 'classnames';
import { Button } from 'antd';
import { useAudioRecorder } from 'react-audio-voice-recorder';
import { convertSecondsToTimer, getBackgroundColor } from '../../../helpers';
import { useStore } from '../../../store/rootStore';
import { ReactComponent as TrashOutlinedIcon } from '../../../assets/icons/deleteIcon/trash-bin-red.svg';
import s from './AudioRecordBar.module.scss';
const AudioRecorderBar = ({ onSendAudioMessage, onClose }) => {
    var _a;
    const [voiceBlob, setVoiceBlob] = useState(null);
    const { activeChatStore: { theme }, } = useStore();
    const onSend = () => {
        if (voiceBlob) {
            const file = new File([voiceBlob], 'audio.mp3', { type: 'audio/mpeg' });
            onSendAudioMessage(file);
        }
    };
    const { startRecording, stopRecording, recordingBlob, isRecording, recordingTime } = useAudioRecorder();
    useEffect(() => {
        startRecording();
    }, []);
    useEffect(() => {
        if (!recordingBlob)
            return;
        setVoiceBlob(recordingBlob);
    }, [recordingBlob]);
    const background = getBackgroundColor(theme, { forcePrimaryColor: true });
    return (React.createElement("div", { className: s.container },
        React.createElement(Button, { icon: React.createElement(TrashOutlinedIcon, null), onClick: () => onClose(), className: classNames('voice-recorder_controlgeneric', s.deleteButton, {
                [s.show]: !isRecording && voiceBlob,
            }), shape: "circle", type: "primary", danger: true }),
        React.createElement("div", { className: s.recordingWrapper }, isRecording || !voiceBlob ? (React.createElement("div", { className: s.recording },
            React.createElement("span", { className: s.timer },
                React.createElement(Trans, { i18nKey: "recordingDots" })),
            React.createElement("span", null, convertSecondsToTimer(recordingTime)))) : (React.createElement("audio", { src: voiceBlob ? URL.createObjectURL(voiceBlob) : '', className: s.audio, controls: true }))),
        isRecording || !voiceBlob ? (React.createElement(Button, { icon: React.createElement(TbMicrophone, null), onClick: stopRecording, className: s.recordButton, shape: "circle", type: "primary", disabled: !isRecording && !voiceBlob })) : (React.createElement(Button, { className: classNames(s.sendButton, {
                [s.invertColors]: theme.invertColors || ((_a = theme.additionalData) === null || _a === void 0 ? void 0 : _a.invertChatColor),
            }), onClick: onSend, style: { background } },
            React.createElement(SendOutlined, null)))));
};
export default AudioRecorderBar;
