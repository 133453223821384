import { MenuOutlined, PlusOutlined } from "@ant-design/icons";
import classnames from "classnames";
import React from 'react';
import Logo from "../../../assets/logos/texterLogos/texterBigLogo.svg";
import styles from './TopBar.module.scss';
const TopBar = ({ toggleMobileDrawer, isDrawerOpen, partnerTheme }) => {
    return (React.createElement("div", { className: styles.top },
        React.createElement("div", { className: styles.topBar },
            React.createElement("img", { className: styles.image, src: partnerTheme ? partnerTheme.iconSmall : Logo, alt: 'Logo' }),
            isDrawerOpen ?
                React.createElement(PlusOutlined, { onClick: () => toggleMobileDrawer(), className: classnames(styles.topBarIcon, styles.topBarIconCross) }) :
                React.createElement(MenuOutlined, { onClick: () => toggleMobileDrawer(), className: styles.topBarIcon }))));
};
export default TopBar;
