import React, { useState } from 'react';
import { Dropdown, Typography, Radio, Divider, Tooltip, Button } from 'antd';
import Icon, { CaretDownOutlined, CaretUpOutlined, CheckCircleOutlined, SearchOutlined } from '@ant-design/icons';
import { AiOutlineSetting, AiOutlineShareAlt, AiOutlineUserSwitch } from 'react-icons/ai';
import { Trans } from 'react-i18next';
import classNames from 'classnames';
import { RiLinkM, RiMailCheckLine, RiMailCloseLine } from 'react-icons/ri';
import { ReactComponent as Star } from '../../../../assets/icons/chatStatuses/star.svg';
import { ReactComponent as YellowStar } from '../../../../assets/icons/chatStatuses/yellowStar.svg';
import { ReactComponent as LockClosed } from '../../../../assets/icons/lock/lockClosed.svg';
import { ReactComponent as LockOpen } from '../../../../assets/icons/lock/lockOpen.svg';
import { AfterResolveChatAction } from '../../../../constants';
import { getDirection } from '../../../../helpers';
import styles from '../UserInfo.module.scss';
const { Text } = Typography;
const MobileMenu = ({ children, blocked, important, unsubscribed, sessionIsOver, markAsImportant, showSubscribeModal, onBlockUserClick, onShareChatClick, onAssignToClick, onShowFilesMediaLinks, onSearchInChat, resolveChatProps, }) => {
    const [assignMenuOpen, setAssignMenuOpen] = useState(false);
    const dropdownMenu = () => {
        const { onAfterResolveActionChange, afterResolveAction, toggleSelectBotModal, resolveChat, selectedBotNode, disabled, } = resolveChatProps;
        const isRTL = getDirection() === 'rtl';
        const items = [
            {
                key: 'markAsSolved',
                icon: React.createElement(CheckCircleOutlined, null),
                disabled,
                label: (React.createElement("div", { className: classNames(styles.markAsSolvedMobileMenuContainer, {
                        [styles.disabled]: disabled
                    }) },
                    React.createElement("div", { onClick: () => {
                            if (disabled)
                                return;
                            resolveChat();
                            setAssignMenuOpen(false);
                        } },
                        React.createElement("div", { className: styles.radioOptionBoldText },
                            React.createElement(Trans, { i18nKey: "resolveChat" })),
                        afterResolveAction && afterResolveAction !== AfterResolveChatAction.NONE && (React.createElement("div", { className: styles.radioOptionSubText }, afterResolveAction === AfterResolveChatAction.RUN_BOT
                            ? (isRTL
                                ? React.createElement(Trans, { i18nKey: 'runBotRTL', values: { botNode: selectedBotNode } })
                                : React.createElement(Trans, { i18nKey: 'runBot', values: { botNode: selectedBotNode } }))
                            : React.createElement(Trans, { i18nKey: afterResolveAction, values: { botNode: selectedBotNode } })))),
                    React.createElement(Button, { type: "ghost", size: "small", disabled: disabled, icon: assignMenuOpen ? React.createElement(CaretUpOutlined, null) : React.createElement(CaretDownOutlined, null), onClick: (e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            if (disabled)
                                return;
                            setAssignMenuOpen(!assignMenuOpen);
                        } }))),
            },
            ...(assignMenuOpen
                ? [
                    {
                        key: 'markAsSolvedOptions',
                        className: styles.assignToMobileMenuContainer,
                        onClick: (e) => {
                            e.domEvent.preventDefault();
                        },
                        label: (React.createElement(Radio.Group, { onChange: (e) => onAfterResolveActionChange(e.target.value), value: !sessionIsOver ? afterResolveAction : AfterResolveChatAction.NONE },
                            React.createElement(Radio, { className: styles.radioOption, value: AfterResolveChatAction.NONE },
                                React.createElement("span", null,
                                    React.createElement("div", { className: styles.radioOptionBoldText },
                                        React.createElement(Trans, { i18nKey: "resolveChat" })))),
                            React.createElement(Divider, { className: styles.radioDivider }),
                            React.createElement(Radio, { className: styles.radioOption, disabled: sessionIsOver, value: AfterResolveChatAction.SEND_CLOSING_MESSAGE },
                                React.createElement("div", { className: styles.radioOptionBoldText },
                                    React.createElement(Text, { className: classNames(styles.radioOptionText, {
                                            [styles.disabled]: sessionIsOver,
                                        }) },
                                        React.createElement(Trans, { i18nKey: "resolveChat" }))),
                                React.createElement("div", { className: classNames(styles.radioOptionSubText, {
                                        [styles.buttonGreyText]: disabled,
                                    }) },
                                    React.createElement(Trans, { i18nKey: 'sendClosingMessage' }))),
                            React.createElement(Divider, { className: styles.radioDivider }),
                            React.createElement(Radio, { className: styles.radioOption, disabled: sessionIsOver, value: AfterResolveChatAction.RUN_BOT },
                                React.createElement("span", { className: styles.withBotContainer },
                                    React.createElement("div", null,
                                        React.createElement("div", { className: styles.radioOptionBoldText },
                                            React.createElement(Text, { className: classNames(styles.radioOptionText, {
                                                    [styles.disabled]: sessionIsOver,
                                                }) },
                                                React.createElement(Trans, { i18nKey: "resolveChat" }))),
                                        React.createElement("div", { className: classNames(styles.radioOptionSubText, {
                                                [styles.buttonGreyText]: disabled,
                                            }) }, isRTL
                                            ? React.createElement(Trans, { i18nKey: 'runBotRTL', values: { botNode: selectedBotNode } })
                                            : React.createElement(Trans, { i18nKey: 'runBot', values: { botNode: selectedBotNode } }))),
                                    React.createElement(Tooltip, { title: !sessionIsOver ? React.createElement(Trans, { i18nKey: "selectBotNode" }) : '', placement: "topLeft" },
                                        React.createElement(AiOutlineSetting, { onClick: (e) => {
                                                e.stopPropagation();
                                                e.preventDefault();
                                                toggleSelectBotModal();
                                            } })))))),
                    },
                ]
                : []),
            {
                key: 'assignTo',
                icon: React.createElement(AiOutlineUserSwitch, null),
                label: React.createElement(Trans, { i18nKey: "assignToDots" }),
                onClick: onAssignToClick,
            },
            {
                type: 'divider',
            },
            {
                key: 'markAsImportant',
                icon: React.createElement(Icon, { className: styles.menuIcon, component: important ? YellowStar : Star }),
                label: React.createElement(Trans, { i18nKey: important ? 'unmarkAsImportant' : 'markAsImportant' }),
                onClick: markAsImportant,
            },
            {
                key: 'shareChat',
                icon: React.createElement(AiOutlineShareAlt, null),
                label: React.createElement(Trans, { i18nKey: "shareChat" }),
                onClick: onShareChatClick,
            },
            {
                key: 'unsubscribeFromTM',
                icon: React.createElement(Icon, { className: styles.menuIcon, component: unsubscribed ? RiMailCheckLine : RiMailCloseLine }),
                label: React.createElement(Trans, { i18nKey: unsubscribed ? 'resubscribeToTM' : 'unsubscribeFromTM' }),
                onClick: showSubscribeModal,
            },
            {
                key: 'filesMediaLinks',
                icon: React.createElement(Icon, { className: styles.menuIcon, component: RiLinkM }),
                label: React.createElement(Trans, { i18nKey: "filesMediaLinks" }),
                onClick: onShowFilesMediaLinks,
            },
            {
                key: 'blockUser',
                icon: (React.createElement(Icon, { className: classNames(styles.menuIcon, styles.errorIcon), component: blocked ? LockOpen : LockClosed })),
                label: (React.createElement(Text, { className: styles.alertText },
                    React.createElement(Trans, { i18nKey: blocked ? 'unblockUser' : 'blockUser' }))),
                onClick: onBlockUserClick,
            },
            {
                key: "searchInChat",
                icon: React.createElement(Icon, { className: styles.menuIcon, component: SearchOutlined }),
                label: React.createElement(Trans, { i18nKey: 'searchInChat' }),
                onClick: onSearchInChat,
            },
        ];
        return { items };
    };
    return (React.createElement(Dropdown, { trigger: ['click'], onOpenChange: (open) => {
            if (!open)
                setAssignMenuOpen(false);
        }, placement: "bottomRight", menu: dropdownMenu(), overlayClassName: styles.dropdownMenu, getPopupContainer: (triggerNode) => triggerNode.parentElement }, children));
};
export default MobileMenu;
