import { createTheme } from "@material-ui/core";
const muiTheme = createTheme({
    typography: {
        fontFamily: [
            // 'Roboto', - Roboto, the default MUI font, was causing sudden issues with the font-weight
            'Helvetica',
            'Arial',
            'sans-serif',
        ].join(','),
    }
});
export default muiTheme;
