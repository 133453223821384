import { useEffect, useState } from 'react';
import { MOBILE_WIDTH } from '../../constants';
const useCheckIfMobile = (compareToWidth) => {
    const [width, setWidth] = useState(window.innerWidth);
    const handleWindowSizeChange = () => {
        setWidth(window.innerWidth);
    };
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        };
    }, []);
    return width <= (compareToWidth || MOBILE_WIDTH);
};
export default useCheckIfMobile;
