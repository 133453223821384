import React, { Component } from 'react';
import { withTranslation, Trans } from 'react-i18next';
import { Checkbox, Dropdown, Tooltip } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import cross from '../../../assets/messageStatuses/Cross.png';
import styles from './ConversationSearch.module.scss';
import { withStore } from '../../../store/rootStore';
import { observer } from 'mobx-react';
import { MyInput } from '../../Base/Form';
class ConversationSearch extends Component {
    constructor(props) {
        super(props);
        Object.defineProperty(this, "inputRef", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "onChange", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (inputValue) => {
                this.setState({ inputValue }, () => this.onSearch());
            }
        });
        Object.defineProperty(this, "cleanSearchInput", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.setState({
                    inputValue: '',
                    inMessages: this.props.inMessages === 'always',
                }, () => this.onSearch());
            }
        });
        Object.defineProperty(this, "onSearch", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { inputValue, inMessages } = this.state, { onSearch, minQuery = 3 } = this.props;
                if (inputValue.length >= minQuery) {
                    onSearch(inputValue, inMessages);
                }
                else {
                    onSearch(null, inMessages);
                }
            }
        });
        this.state = {
            inputValue: '',
            inMessages: props.inMessages === 'always',
        };
        this.inputRef = React.createRef();
    }
    componentWillUnmount() {
        this.props.onSearch("", this.state.inMessages);
    }
    render() {
        const { t, minQuery = 3, dropdownMenu, dropdownIcon: Icon, loading } = this.props, { inputValue } = this.state;
        return (React.createElement(React.Fragment, null,
            React.createElement("div", { className: styles.conversationSearch },
                React.createElement(SearchOutlined, { className: styles.chatSearchIcon }),
                React.createElement(Tooltip, { title: React.createElement("span", null,
                        React.createElement(Trans, { i18nKey: "pleaseTypeAtLeast" }),
                        " ",
                        React.createElement("b", null, minQuery - inputValue.length),
                        " ",
                        React.createElement(Trans, { i18nKey: "moreSymbolsToStartSearch" })), overlayClassName: inputValue.length >= minQuery ? styles.hidden : undefined, trigger: "focus" },
                    React.createElement(MyInput, { forwardRef: this.inputRef, type: "search", placeholder: t('searchChat'), value: this.state.inputValue, onChangeHandler: this.onChange, loading: loading && inputValue.length >= minQuery, bordered: false, allowClear: { clearIcon: React.createElement("img", { className: styles.cleanSearchInput, src: cross, onClick: this.cleanSearchInput }) } })),
                React.createElement(Dropdown, { trigger: ['click'], placement: "bottomRight", menu: dropdownMenu, overlayStyle: { width: '100%', left: 0 }, getPopupContainer: (triggerNode) => triggerNode.parentElement }, React.createElement(Icon, { className: styles.openMenuIcon }))),
            this.props.inMessages === 'available' && !!this.state.inputValue.length &&
                React.createElement("div", { className: styles.conversationSearchFulltext },
                    React.createElement(Checkbox, { checked: this.state.inMessages, onChange: (e) => this.setState((state) => ({ inMessages: e.target.checked }), () => this.onSearch()) },
                        React.createElement(Trans, { i18nKey: "searchInMessages" })))));
    }
}
Object.defineProperty(ConversationSearch, "defaultProps", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: {
        minQuery: 3,
        inMessages: 'always',
    }
});
export default withTranslation()(withStore(observer(ConversationSearch)));
