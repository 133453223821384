var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import React, { useState, useEffect } from 'react';
import { observer } from "mobx-react";
import { Typography, Button } from 'antd';
import Icon, { LoadingOutlined } from '@ant-design/icons';
import { Trans } from "react-i18next";
import { useStore } from '../../../../store/rootStore';
import QuickRepliesField from './QuickRepliesField/QuickRepliesField';
import AddOrEditReplyField from './AddOrEditReplyField';
import DeleteConfirmation from './DeleteConfirmation/DeleteCofirmation';
import { ReactComponent as QuickRepliesFolder } from '../../../../assets/icons/file/folderIcon.svg';
import { ReactComponent as QuickRepliesPlus } from '../../../../assets/icons/plus/quickRepliesPlus.svg';
import Header from '../../../QuickReplies/Header';
import './style.css';
const { Text } = Typography;
const EditQuickReplies = (props) => {
    const [currentReply, setCurrentReply] = useState(null);
    const [status, setStatus] = useState('list');
    const [isLoading, setLoading] = useState(false);
    const store = useStore();
    const { closeFunction, isNoreplies } = props;
    const { quickRepliesList, addQuickReply, editQuickReply, deleteQuickReply } = store.quickRepliesStore;
    useEffect(() => {
        if (isNoreplies) {
            setCurrentReply({
                _id: '',
                text: '',
            });
        }
    }, []);
    const onChange = (reply, isNew) => __awaiter(void 0, void 0, void 0, function* () {
        var _a;
        setLoading(true);
        try {
            if (isNew) {
                yield addQuickReply(reply);
            }
            else {
                const id = (_a = quickRepliesList.find(r => r._id === reply._id)) === null || _a === void 0 ? void 0 : _a._id;
                yield editQuickReply(id, reply);
            }
        }
        finally {
            setLoading(false);
            reset();
        }
    });
    const deleteReplyFunc = (id) => {
        setLoading(true);
        deleteQuickReply(id).finally(() => setLoading(false));
        reset();
    };
    const openAddNewReply = () => {
        setStatus('add');
    };
    const reset = () => {
        setCurrentReply(null);
        setStatus('list');
    };
    const setReplyEditted = (reply, isEditing) => {
        if (!isEditing) {
            reset();
        }
        else {
            setCurrentReply(reply);
            setStatus('edit');
        }
    };
    const setReplyDeleting = (reply, isDeleting) => {
        if (!isDeleting) {
            reset();
        }
        else {
            setCurrentReply(reply);
            setStatus('delete');
        }
    };
    return (React.createElement("div", { className: "editQuickRepliesWrapper" },
        status === 'list' &&
            React.createElement(React.Fragment, null,
                React.createElement(Header, { onClose: closeFunction }),
                isLoading && (React.createElement("div", { className: "quick-replies-loader-container" },
                    React.createElement(LoadingOutlined, { className: "quick-replies-loader-spinner" }))),
                React.createElement("div", { className: "existingReplies" }, (quickRepliesList === null || quickRepliesList === void 0 ? void 0 : quickRepliesList.length) ? quickRepliesList.map((reply) => React.createElement(QuickRepliesField, { key: reply._id, reply: reply, departmentsList: store.departmentsStore.departments, isEditing: (currentReply === null || currentReply === void 0 ? void 0 : currentReply._id) === reply._id, setReplyEditted: setReplyEditted, setReplyDeleting: setReplyDeleting })) : (React.createElement("div", { className: "noRepliesContainer" },
                    React.createElement("div", { className: "noRepliesBody" },
                        React.createElement(Icon, { className: "noRepliesIcon", component: QuickRepliesFolder }),
                        React.createElement("div", { className: "noRepliesBodyText" },
                            React.createElement(Text, { strong: true }, "You didn't create any quick replies yet")))))),
                React.createElement("div", { className: "ReplyBottom" },
                    React.createElement(Button, { className: "big", onClick: openAddNewReply },
                        React.createElement(Icon, { component: QuickRepliesPlus }),
                        React.createElement(Text, { strong: true },
                            React.createElement(Trans, { i18nKey: "createNewQuickReply" }))))),
        React.createElement("div", { className: "replyEditBoxWrapper" },
            status === 'delete' && currentReply && (React.createElement(DeleteConfirmation, { onCancel: reset, deleteReply: () => currentReply && deleteReplyFunc(currentReply._id), reply: currentReply })),
            (status === 'add' || status === 'edit') && (React.createElement(AddOrEditReplyField, { reply: status === 'edit' ? currentReply : undefined, onChange: onChange, onCancel: reset })))));
};
export default observer(EditQuickReplies);
