import { Alert, Button } from 'antd';
import React from 'react';
import s from './ErrorBoundary.module.scss';
class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        Object.defineProperty(this, "onPageRefresh", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                window.location.reload();
            }
        });
        this.state = { hasError: false, message: '' };
    }
    static getDerivedStateFromError(error) {
        return { hasError: true, message: error.message };
    }
    componentDidCatch(error, errorInfo) {
        // logErrorToMyService(error, errorInfo);
    }
    render() {
        if (this.state.hasError) {
            return (React.createElement(Alert, { message: "Ooops! Something went wrong.", showIcon: true, description: this.state.message, type: "error", action: React.createElement(Button, { size: "small", type: "default", onClick: this.onPageRefresh, className: s.button }, "Refresh") }));
        }
        return this.props.children;
    }
}
export default ErrorBoundary;
