import React, { Suspense, useState } from 'react';
import { observer } from 'mobx-react';
import Icon from '@ant-design/icons';
import { Dropdown } from 'antd';
import classnames from 'classnames';
import { isRegularMessage, MessagingChannelFeature, Permissions, isSpecialMessage } from '@whatsper/texterchat-common';
import { useTranslation } from 'react-i18next';
import lazyWithRetry from '../../../../../helpers/lazyWithRetry';
import { withStore } from '../../../../../store/rootStore';
import { ReactComponent as DownArrowWhite } from '../../../../../assets/icons/arrows/downArrowWhite.svg';
import { ReactComponent as DownArrowBlack } from '../../../../../assets/icons/arrows/downArrowBlack.svg';
import './MessageContextMenu.css';
const BotEditorModal = lazyWithRetry(() => import('../../../BotEditorModal'));
const MessageContextMenu = ({ message, incoming, handleForwardMessage, store, messageId, background, hovered, }) => {
    var _a, _b, _c;
    const { t } = useTranslation();
    const [botMenuOpen, setBotMenuOpen] = useState(false);
    const botMsgInfo = isRegularMessage(message)
        ? message.botMsgInfo || ((_a = message.metadata) === null || _a === void 0 ? void 0 : _a.botMsgInfo)
        : null;
    const { sessionIsOver, chat } = store.activeChatStore;
    const isAssignedAgent = (chat === null || chat === void 0 ? void 0 : chat.agent.uid) === ((_b = store.loginStore.userDetails) === null || _b === void 0 ? void 0 : _b._id);
    const supportsDelete = () => {
        const adapter = (chat === null || chat === void 0 ? void 0 : chat.channelInfo) ? store.channels.getAccountAdapter({
            id: chat.channelInfo.accountId,
            name: chat.channelInfo.name,
        }) : null;
        return store.channels.hasFeatures([MessagingChannelFeature.deleteMessage], adapter);
    };
    const handleOpenBot = () => {
        setBotMenuOpen(!botMenuOpen);
    };
    const deleteMessage = () => {
        store.messages.deleteMessage(messageId);
    };
    const canForward = isRegularMessage(message)
        && (
        // Maybe later we will implement some API to decide if the message can be forwarded,
        // but for now safer to disallow forwarding of special messages
        isSpecialMessage(message)
            // We don't want to allow forwarding of status messages in any case
            || !((_c = message.special) === null || _c === void 0 ? void 0 : _c.isStatusMessage));
    const menu = () => {
        const { setReplyMessage } = store.activeChatStore;
        const { hasPermissions } = store.agentsStore;
        const items = [
            ...(!sessionIsOver
                ? [
                    {
                        key: 'replyMessage',
                        label: t('replyMessage'),
                        onClick: () => setReplyMessage(message),
                    },
                ]
                : []),
            ...(incoming && supportsDelete()
                ? [
                    {
                        key: 'deleteMessage',
                        label: t('deleteMessage'),
                        onClick: deleteMessage,
                    },
                ]
                : []),
            ...canForward
                ? [{
                        key: 'forwardMessage',
                        label: t('forwardMessage'),
                        onClick: handleForwardMessage,
                    }]
                : [],
            ...(hasPermissions([Permissions.Permission.bots_edit]) && botMsgInfo
                ? [
                    {
                        key: 'editBot',
                        label: t('editBot'),
                        onClick: handleOpenBot,
                    },
                ]
                : []),
        ];
        return { items, style: { maxWidth: 150 } };
    };
    return (React.createElement(React.Fragment, null,
        hovered && isAssignedAgent && (React.createElement(Dropdown, { trigger: ['click'], menu: menu() },
            React.createElement("div", { className: classnames('context-menu-trigger-wrapper', {
                    'context-menu-incoming': incoming,
                }), style: Object.assign({}, (!incoming && { background })) },
                React.createElement(Icon, { component: incoming ? DownArrowBlack : DownArrowWhite })))),
        botMsgInfo && (React.createElement(Suspense, { fallback: React.createElement(React.Fragment, null) },
            React.createElement(BotEditorModal, { openModal: botMenuOpen, setOpenModal: handleOpenBot, botMsgInfo: botMsgInfo })))));
};
export default withStore(observer(MessageContextMenu));
