import React from 'react';
import Icon, { SearchOutlined } from '@ant-design/icons';
import { Trans } from 'react-i18next';
import classNames from 'classnames';
import { Dropdown, Typography } from 'antd';
import { RiLinkM, RiMailCheckLine, RiMailCloseLine } from 'react-icons/ri';
import { ReactComponent as Star } from '../../../../assets/icons/chatStatuses/star.svg';
import { ReactComponent as YellowStar } from '../../../../assets/icons/chatStatuses/yellowStar.svg';
import { ReactComponent as LockClosed } from '../../../../assets/icons/lock/lockClosed.svg';
import { ReactComponent as LockOpen } from '../../../../assets/icons/lock/lockOpen.svg';
import { getDirection } from '../../../../helpers';
import styles from '../UserInfo.module.scss';
const { Text } = Typography;
const DesktopMenu = ({ children, important, unsubscribed, blocked, showSubscribeModal, markAsImportant, onShowFilesMediaLinks, onBlockUserClick, onSearchInChat, }) => {
    const dropdownMenu = () => {
        const items = [
            {
                key: 'markAsImportant',
                icon: React.createElement(Icon, { className: styles.menuIcon, component: important ? YellowStar : Star }),
                label: React.createElement(Trans, { i18nKey: important ? 'unmarkAsImportant' : 'markAsImportant' }),
                onClick: markAsImportant,
            },
            {
                key: 'unsubscribeFromTM',
                icon: React.createElement(Icon, { className: styles.menuIcon, component: unsubscribed ? RiMailCheckLine : RiMailCloseLine }),
                label: React.createElement(Trans, { i18nKey: unsubscribed ? 'resubscribeToTM' : 'unsubscribeFromTM' }),
                onClick: showSubscribeModal,
            },
            {
                key: 'filesMediaLinks',
                icon: React.createElement(Icon, { className: styles.menuIcon, component: RiLinkM }),
                label: React.createElement(Trans, { i18nKey: "filesMediaLinks" }),
                onClick: onShowFilesMediaLinks,
            },
            {
                key: 'blockUser',
                icon: (React.createElement(Icon, { className: classNames(styles.menuIcon, styles.errorIcon), component: blocked ? LockOpen : LockClosed })),
                label: (React.createElement(Text, { className: styles.alertText },
                    React.createElement(Trans, { i18nKey: blocked ? 'unblockUser' : 'blockUser' }))),
                onClick: onBlockUserClick,
            },
            {
                key: "searchInChat",
                icon: React.createElement(Icon, { className: styles.menuIcon, component: SearchOutlined }),
                label: React.createElement(Trans, { i18nKey: 'searchInChat' }),
                onClick: onSearchInChat,
            },
        ];
        return { items };
    };
    return (React.createElement(Dropdown, { trigger: ['click'], placement: getDirection() === 'rtl' ? 'bottomLeft' : 'bottomRight', menu: dropdownMenu(), getPopupContainer: (triggerNode) => triggerNode.parentElement }, children));
};
export default DesktopMenu;
