import React from 'react';
import { Gauge } from '@ant-design/plots';
const rangeColors = {
    red: '#C84242',
    yellow: '#D9B64C',
    green: '#0FB878', // $primary_green;
};
const SLAGauge = ({ gaugeData }) => {
    const color = () => {
        if (gaugeData.percent < 0.331) {
            return rangeColors.red;
        }
        if (gaugeData.percent < 0.661) {
            return rangeColors.yellow;
        }
        return rangeColors.green;
    };
    const config = {
        percent: gaugeData.percent === 0 ? 0.01 : gaugeData.percent,
        appendPadding: 0,
        range: {
            color: color(),
        },
        startAngle: Math.PI,
        endAngle: 2 * Math.PI,
        indicator: undefined,
    };
    return React.createElement(Gauge, Object.assign({}, config));
};
export default SLAGauge;
