import React from "react";
import { observer } from "mobx-react";
import Icon from "@ant-design/icons";
import { Trans } from "react-i18next";
import { ReactComponent as CsvFile } from "../../../../../../assets/csvFilesAndIcons/csvFile.svg";
import { ReactComponent as CsvBucket } from "../../../../../../assets/csvFilesAndIcons/csvBucket.svg";
import { useStore } from "../../../../../../store/rootStore";
import classNames from "classnames";
import { getDirection } from "../../../../../../helpers";
import s from "./FileUploadSuccess.module.scss";
const FileUploadSuccess = () => {
    const { wizardStore } = useStore();
    const { name, warning, warningMessage, warningValue } = wizardStore.uploadedFile || {};
    const deleteCsvHandle = () => {
        wizardStore.setUploadedFile({});
    };
    return (React.createElement("div", { className: s["file-load-success-main"] },
        React.createElement("div", { className: s["csv-upload-success-info"] },
            React.createElement(Icon, { component: CsvFile }),
            React.createElement("div", { className: classNames(s["text-wrapper"], {
                    [s.rtl]: getDirection() === "rtl",
                }) },
                React.createElement("p", { className: s["csv-upload-success-text"] }, name),
                warning && (React.createElement("span", { className: s["warning-text"] },
                    React.createElement(Trans, { i18nKey: `${warningMessage}`, values: warningValue }))))),
        React.createElement(Icon, { className: s["delete-csv-file"], onClick: deleteCsvHandle, component: CsvBucket })));
};
export default observer(FileUploadSuccess);
